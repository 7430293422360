import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./LearningSession.css";
import "../DebugSession/DebugSession.css";
import LeaderboardLearningSession from "../LeaderboardLearningSession/LeaderboardLearningSession";
import Learning from "../Learning/Learning";


const LearningSession = ({ data, socket, useStore } : { data: any, socket: any, useStore: any }) => {
    const { sessionId, join } = useParams();

    const joinNumber = useMemo(() => Number(join), []);
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [schemeName, setSchemeName] = useState<string>("");
    const [logs, setLogs] = useState<any>([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [totalProblems, setTotalProblems] = useState([]);
    const [problemsSolved, setProblemsSolved] = useState([]);
    const [endSessionAnimation, setEndSessionAnimation] = useState<boolean>(false);
    const [animationTextSize, setAnimationTextSize] = useState<number>(50);
    const animationRef = useRef<any>();
    const [startAnimation, setStartAnimation] = useState<boolean>(true);
    const [cell, setCell] = useState();
    const [semiStation, setSemiStation] = useState();
    const [scenario, setScenario] = useState();
    const [scenarios, setScenarios] = useState();
    const [dispatcher, setDispatcher] = useState<boolean>(true);
    const [time, setTime] = useState<number>(0);
    const [savedElements, setSavedElements] = useState([]);
    const [manoeuverFile, setManoeuverFile] = useState([]);
    const [sessionIdx, setSessionIdx] = useState<number>(1);
    const [sessionFinished, setSessionFinished] = useState<boolean>(false);
    const [sessionAdmin, setSessionAdmin] = useState<boolean>(false);

    const location = useLocation();
    const sessionName = location?.state?.sessionName || window.history.state?.sessionName;
    console.log(sessionName);
    // if (!sessionName) navigate(-1);
    console.log(window.history);



    

    let times = useMemo(() => 0, []);

    // const getJoinedSession = async () => {
    //     try {
    //         const options = {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         };
    //         const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/join-session-student/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${data.userData.userId}/${sessionId}`, options);
    //         const response = await request.json();
            
    //         if (response?.alreadyEntered) {
    //             setStartAnimation(false);
    //             setEndSessionAnimation(true);
    //         }
    //         else setStartAnimation(true);
    //         times++;

    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // useLayoutEffect(() => {
    //     if (times > 0) return;
    //     // if (data.userData.type !== "student") return;
    //     // getJoinedSession().catch(err => console.log(err));
    // }, []);
    
    useLayoutEffect(() => {
        if (window.history.state?.start) {
            setEndSessionAnimation(true);
            return;
        }

        if (!animationRef?.current || !startAnimation) return;
        // setAnimationTextSize(70);
        const timer = setTimeout(() => {
            console.log(animationRef?.current);
            if (!animationRef?.current || !startAnimation) return;
            // setAnimationTextSize(1);
            animationRef.current.innerText = '2';
            setAnimationTextSize(50);
            setTimeout(() => {
                // setAnimationTextSize(1);
                animationRef.current.innerText = '1';
                setAnimationTextSize(50);
                setTimeout(() => {
                    // setAnimationTextSize(1)
                    setAnimationTextSize(20);
                    animationRef.current.innerText = 'Start';
                    setTimeout(() => {
                        setEndSessionAnimation(true);
                        // setTimeout(() => window.location.reload(), 500);
                    }, 750);
                }, 500);
            }, 500);
        }, 500);

        return () => {
            clearTimeout(timer);
        };

    }, [startAnimation]);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/check-id/${data.userData.teacherId}/${data.userData.userId}/${sessionId}/${data.userData.type}`, options);
                const response = await request.json();
                if (!response.success) return navigate(-1);
                console.log(await response);
                const currResResults = await response?.results?.[0];
                const currResults = JSON.parse(await currResResults.results);
                const userResults = await currResults.find((result : { userId: string }) => result.userId === data.userData.userId);
                
                if (!userResults) return;
                setSessionAdmin(await response?.sessionAdmin);
                setProblemsSolved(userResults.problemsSolved);
                setProblems(JSON.parse(currResResults.problems).filter((problem : any) => !userResults.problemsSolved.includes(problem.point)));
                setTotalProblems(JSON.parse(currResResults.problems || "[]"));
                setLogs(userResults?.logs);
                console.log(userResults, currResults);
                setSchemeName(currResResults?.schemeName || "");
                setSemiStation(currResResults?.semiStation);
                setCell(currResResults?.cell);
                setScenario(currResResults?.scenario);
                setScenarios(userResults.scenarios);
                setDispatcher(currResResults?.dispatcher || false);
                setTime(userResults?.time || 0);
                setSavedElements(userResults?.savedElements || []);
                setManoeuverFile(userResults?.manoeuverFile || []);
                setSessionIdx(response?.sessionIdx);
                setSessionFinished(userResults?.finished);
                console.log(userResults);
            } catch (e) {
                console.log(e);
            }
        })(); 

        
    }, []);

    useEffect(() => {
        if (data.userData.type !== "teacher") return;
        socket.on("update-results-learning", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/get-results-of-session/${data.userData.teacherId || data.userData.userId}/${data.userData.userId}/${sessionId}`, options);
                const response = await request.json();
                            
                if (response.success) setLeaderboard(response.results);
            } catch (e) {
                console.log(e);
            }
        });

    }, []);

    return (sessionAdmin && !joinNumber) || sessionFinished ? (
        <LeaderboardLearningSession data={data} socket={socket} sessionName={sessionName} />
    ) : endSessionAnimation && schemeName.length ? (
        <Learning sessionIdx={sessionIdx} data={data} manoeuverFile={manoeuverFile} savedElements={savedElements} time={time} currentScenario={scenario} dispatcher={dispatcher} scenarios={scenarios} problemsSolved={problemsSolved} totalProblems={totalProblems || []} problems={problems} logs={logs || []} schemeName={schemeName} sessionName={sessionName} semiStation={semiStation} cell={cell}  />
    ) : (
        <div className="animation-container">
            <h1 ref={animationRef} style={{ fontSize: `${animationTextSize}vmax` }}>3</h1>
        </div>
    );
};

export default LearningSession;