import { faCopy, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPaste, faRotate, faScissors } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const rightClickData = [
    {
        id: 1,
        title: "Copy",
        icon: <FontAwesomeIcon icon={faCopy} className="right-menu-icon" />
    },

    {
        id: 2,
        title: "Paste",
        icon: <FontAwesomeIcon icon={faPaste} className="right-menu-icon" />
    },

    {
        id: 3,
        title: "Cut",
        icon: <FontAwesomeIcon icon={faScissors} className="right-menu-icon" />
    },
    

    {
        id: 4,
        title: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} className="right-menu-icon" />,
        danger: true
    },

    {
        id: 5,
        title: "Flip",
        icon: <FontAwesomeIcon icon={faRotate} className="right-menu-icon" />,
    }
];

export default rightClickData;