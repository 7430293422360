import { useCookies } from "react-cookie";
import Header from "../Components/Header/Header";
import { useEffect, useState } from "react";
import "./Students.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faDoorOpen, faEllipsis, faEnvelopeOpen, faFlagCheckered, faListOl, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark, faStar, faUser, faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Students = ({ data, socket } : { data: any, socket: any }) => {

    const [cookies, setCookie] = useCookies();
    const [students, setStudents] = useState<Array<Object>>([]);
    const navigate = useNavigate();
    // alert(data.userData.type);
    if (data.userData.type !== "teacher") navigate(-1);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            };

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/teacher/get-students/${data.userData.userId}`, options);
            const response = await request.json();
            if (response.success) setStudents(response.students);
            console.log(response?.students);
        })();
    }, []);

    return (
        <div className="students-container">
            <Header data={data} socket={socket} />

            <h2 className="title">{cookies.language === "en" ? "Students" : "Elevi"}</h2>

            <div className="leaderboard">

                <div className="row">

                    <div className="column">
                        <FontAwesomeIcon icon={faListOl} className="column-icon" />
                        <p>{cookies?.language === "en" ? "No." : "Nr."}</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faUser} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Name" : "Nume"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faUser} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Username" : "Username"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faEnvelope} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Email" : "Email"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Grade" : "Clasa"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faEllipsis} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Options" : "Optiuni"}</p>
                    </div>
         
                </div>

                {students && students.length ? students.map((result : any, idx : number) => (
                    <div className="user" key={idx}>
                        <div className="column">
                            <p>{idx + 1}</p>
                        </div>

                        <div className="column">
                            <p>{result.firstName} {result.lastName}</p>
                        </div>

                        <div className="column">
                            <p>{result.username}</p>
                        </div>

                        <div className="column">
                            <p>{result?.email}</p>
                        </div>
                        
                        <div className="column">
                            <p>{result?.grade || (cookies?.language === "en" ? "Undefined" : "Nedefinit")}</p>
                        </div>

                        <div className="column">
                            <div className="action-container" onClick={() => navigate(`/edit-student/${result.userId}`)}>
                                <div className="hover-text">
                                    <p>{cookies?.language === "en" ? "Edit" : "Editeaza"}</p>
                                </div>
                                <div className="action-button">
                                    <FontAwesomeIcon icon={faPencilAlt} className="action-icon" />
                                </div>
                            </div>
                           
                        </div>

                    </div>
                )) : null}
            </div>

            <div className="add-button" onClick={() => navigate("/create-student")} style={{ opacity: 1, cursor: "pointer" }}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
                <p>{cookies?.language === "en" ? "Add student" : "Adauga elev"}</p>
            </div>    
          
        </div>
    );
};

export default Students;