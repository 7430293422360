import { useCookies } from "react-cookie";
import "./Header_Home.css";
import ZenLogoWhite from "../../imgs/ZenLogo_white.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const HeaderHome = ({ data, activePage } : { data: any, activePage: string | null }) => {

    const [cookies, setCookie] = useCookies();
    const [foundUser, setFoundUser] = useState<boolean>(data?.userData?.email === "mihaibbb10@gmail.com");
    const navigation = useNavigate();

    const headerRef = useRef<HTMLDivElement | null>(null);

    // const userConnected = async () => {
    //     if (!cookies["user-id"] || !cookies["pwd"]) return;
    //     const options = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             hashedPassword: cookies["pwd"],
    //             userId: cookies["user-id"]
    //         })
    //     };

    //     try {
    //         const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login-remember`, options);
    //         const response = await request.json();
    //         if (!response.success) return;
    //         setFoundUser(response?. ? "admin" : "user");
    //     } catch(e) {
    //         console.log(e);
    //     }
    // };

    // useEffect(() => {
        
    // }, []);
    

    return (
        <header ref={headerRef} className="home-header">
            <div className="list">
                <div className="left-container">
                
                    <div className="items">
                        <p className={`item ${activePage === "home" ? "active" : ""}`}>
                            <a href="/">{cookies?.language === "en" ? "Home" : "Acasa" }</a>
                        </p>
                        <p className={`item ${activePage === "about-us" ? "active" : ""}`}>
                            <a href="/about-us">{cookies?.language === "en" ? "About us" : "Despre noi"}</a>
                        </p>
                        <p className={`item ${activePage === "contact" ? "active" : ""}`}>
                            <a href="/contact">Contact</a>
                        </p>
                    </div>
                </div>

                <div className="logo">
                    
                    <img src={ZenLogoWhite} alt="Zentec Logo" width={55} height={55} />
                    <p className="logo-text">ZenVolt</p>
                    
                </div>
            
                {/* <p className="item">Zen news</p> */}

                <div className="right-container">
                    <div className="button" onClick={() => foundUser ? navigation("/categories") : navigation("/login")}>
                        {!foundUser ? 
                            <p>{cookies.language === "en" ? "Login" : "Login"}</p> :
                            <p>{cookies.language === "en" ? "Software" : "Aplicatie"}</p>
                        }
                    </div>
                </div>
            </div>
            
            <div className="navigation-icon" onClick={() => headerRef?.current?.classList?.toggle("active-navigation")}>
                <div className="line" />
                <div className="line" />
                <div className="line" />
            </div>
        </header>
    );
};

export default HeaderHome;