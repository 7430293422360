import { faAngleLeft, faFileLines, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import "../Projects/Projects.css";
import "./HighTensionProjects.css";
import "../Categories/Categories.css";
import Header from "../../Components/Header/Header";
import TestingSessions from "../TestingSessions/TestingSessions";
import LearningSessions from "../LearningSessions/LearningSessions";

const HighTensionProjects = ({ data, socket } : { data: any, socket: any }) => {
    console.log(data);
    

    const navigate = useNavigate();
    const [category, setCategory] = useState("projects");
    const location = useLocation();
    const { module } = useParams();
    const [cookies, setCookie] = useCookies();
    const [activeOption, setActiveOption] = useState<number>(module === "learning" ? 1 : module === "training" ? 2 : module === "testing" ? 3 : (location?.state?.activeOption || 1));
    const [debugViewOption, setDebugViewOption] = useState<number>(location?.state?.testingViewOption || (data.userData.type === "teacher" ? 0 : 1));
    
    useEffect(() => {
        document.body.classList.remove("grid-body");
        document.querySelector("html")?.classList.remove("grid-body");

    }, [location]);

    useEffect(() => {
        if (activeOption === location?.state?.activeOption) return;
        navigate(`/high-tension/${module}`, { state: { activeOption: activeOption } });
    }, [activeOption]);

    useLayoutEffect(() => {
        if (debugViewOption === location?.state?.testingViewOption) return;
        navigate(`/high-tension/${module}`, { state: { activeOption: activeOption, testingViewOption: debugViewOption } });
    }, [debugViewOption]);

    useEffect(() => {
        
    }, []);

    const removeSession = async (projectId : string, e : any) => {
        
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/project/remove-project/${data.userData.userId}/${projectId}`, options);
            const response = await request.json();
            if (response.success) window.location.reload();
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content categories-content project-content">

            <Header data={data} socket={socket} />

            <div className="name-container">
                <div className="name">
                    
                    <FontAwesomeIcon icon={faUser} className="user-icon" />
                    <p>{data.userData.username}</p>
                    <p>{data.userData.type === "teacher" ? (cookies?.language === "en" ? "(Teacher)" : "(Profesor)") : (cookies?.language === "en" ? "(Student)" : "(Elev)")}</p>
                </div>
                {/* <div className="line" /> */}
            </div>

            {/* <div className="switch-options squared">

                <div className={`switch-option ${activeOption === 1 ? "active" : ""}`} onClick={() => {
                    setActiveOption(1);
                    setDebugViewOption(0);
                }}>
                    <p>{cookies?.language === "en" ? "Learning" : "Invatare"}</p>
                </div>

                <div className={`switch-option ${activeOption === 2 ? "active" : ""}`} onClick={() => {
                    setActiveOption(2);
                    setDebugViewOption(0);
                }}>
                    <p>{cookies?.language === "en" ? "Training" : "Antrenare"}</p>
                </div>

                <div className={`switch-option ${activeOption === 3 ? "active" : ""}`} onClick={() => {
                    setActiveOption(3);
                    setDebugViewOption(0);
                }}>
                    <p>{cookies?.language === "en" ? "Testing" : "Testare"}</p>
                </div> 
            </div> */}

            <div className="go-back" onClick={() => navigate(`/categories`)}>
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
            </div>

            <div className="module-title-container">
                <h2 className="title-module">{cookies?.language === "en" ? (activeOption === 1 ? "Learning" : activeOption === 2 ? "Training" : activeOption === 3 ? "Testing" : "") : (activeOption === 1 ? "Modul Invatare" : activeOption === 2 ? "Modul Antrenare" : activeOption === 3 ? "Modul Testare" : "")}</h2>
            </div>


            {activeOption === 3 || activeOption === 1 ? (
                <div className="switch-options">
                    
                    {data.userData.type === "teacher" || activeOption === 1 ? <div className={`switch-option ${debugViewOption === 0 ? "active" : ""}`} onClick={() => setDebugViewOption(0)}>
                        <p>{cookies?.language === "en" ? "Create Session" : "Creeaza Sesiune"}</p>
                    </div> : null}
                    
                    <div className={`switch-option ${debugViewOption === 1 ? "active" : ""}`} onClick={() => setDebugViewOption(1)}>
                        <p>{cookies?.language === "en" ? "Ongoing Sessions " : "Sesiuni in derulare"}</p>
                    </div>

                    <div className={`switch-option ${debugViewOption === 2 ? "active" : ""}`} onClick={() => setDebugViewOption(2)}>
                        <p>{cookies?.language === "en" ? "Finished Sessions" : "Sesiuni Finalizate"}</p>
                    </div>
                    
                </div>
            ) : null}
            
            {(activeOption === 3 || activeOption === 1) && debugViewOption === 0 ? <h2 className="title">{cookies?.language === "en" ? "Choose scenario" : "Alege scenariu"}</h2> : null}
            
            {activeOption === 1 && debugViewOption === 0 ? <div className="work-in-progress">
                {/* <img src={workInProgress} alt="work in progress" />
                <h2>{cookies?.language === "en" ? "Module in work" : "Modul in lucru!"}</h2> */}
                <div className="projects-container high-tension learning-module">

                    <div />

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "closing-cell" } })}>
                        <div className="project learn learn-module">
                            <h2>{cookies.language === "en" ? "Returning to the normal scheme" : "Revenirea la schema normala"}</h2>
                            
                            {/* 
                            <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}

                        </div>
                    </div>

                    <div />

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "disconnected" } })}>
                        <div className="project learn learn-module">

                            <h2>{cookies.language === "en" ? "Disconnected" : "Deconectata"}</h2>
                            
                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

             

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "warm-condition" } })}>
                        <div className="project learn learn-module">
                            <h2>{cookies.language === "en" ? "Warm condition" : "Stare calda"}</h2>
                            
                            {/* 
                            <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "warm-reserve" } })}>
                        <div className="project learn learn-module">
                            <h2>{cookies.language === "en" ? "Warm Reserve" : "Rezerva calda"}</h2>
                            
                            {/* 
                            <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "ssv" } })}>
                        <div className="project learn learn-module">

                            <h2>{cookies.language === "en" ? "Separately visible condition" : "Stare separat vizibila"}</h2>
                            
                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>


                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "cold-condition" } })}>
                        <div className="project learn learn-module">

                            <h2>{cookies.language === "en" ? "Cold Condition" : "Stare Rece"}</h2>
                            
                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "cold-reserve" } })}>
                        <div className="project learn learn-module">

                            <h2>{cookies.language === "en" ? "Cold Reserve" : "Rezerva Rece"}</h2>
                            
                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-learning/1`, { state: { scenario: "slp" } })}>
                        <div className="project learn learn-module">
                            <h2>{cookies.language === "en" ? "Ground state" : "Stare legata la pamant"}</h2>
                            
                            {/* 
                            <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>
                    

                </div>
            </div> : null}

            {activeOption === 2 ? <div className="projects-container high-tension">
                    <div className="project-gradient" onClick={(e : any) => navigate(`/training/1`)}>
                        <div className="project learn">
                            <h2>{cookies.language === "en" ? "System Connection Station (SCS)" : "Stație Racord Sistem (SRS)"}</h2>

                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>

                        
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/training/2`)}>
                        <div className="project learn">
                            <h2>{cookies.language === "en" ? "Deep Connection Station (DCS)" : "Stație Racord Adânc (SRA)"}</h2>

                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>

                
            </div> : activeOption === 3 && debugViewOption === 0 && data.userData.type === "teacher" ? (
                <div className="projects-container high-tension">
                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-testing/1`, { state: { scenario: "shock" } })}>
                        <div className="project test">
                            <h2>{cookies.language === "en" ? "Electrocution colleague" : "Electrocutare coleg"}</h2>

                            {/* <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>

                        
                    </div>

                    <div className="project-gradient" onClick={(e : any) => navigate(`/options-testing/1`, { state: { scenario: "fire" } })}>
                        <div className="project test">
                            <h2>{cookies.language === "en" ? "Fire" : "Incendiu"}</h2>

                            {/* 
                            <div className="format">
                                <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                <p>A4</p>
                            </div> */}


                        </div>
                    </div>
                </div>
            ) : debugViewOption === 1 && activeOption === 3 ? (
                <TestingSessions data={data} socket={socket} finished={false} key={0} />
            ) : debugViewOption === 2 && activeOption === 3 ? (
                <TestingSessions data={data} socket={socket} finished={true} key={1} />
            ) : debugViewOption === 1 && activeOption === 1 ? (
                <LearningSessions data={data} socket={socket} finished={false} key={2} />
            ) : debugViewOption === 2 && activeOption === 1 ? (
                <LearningSessions data={data} socket={socket} finished={true} key={3} />
            ) : null}
            
            
        </div>
    );
};

export default HighTensionProjects;