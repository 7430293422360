import bobinaReleu from "../imgs/bobina_releu.svg";
import contactNormalDeschis from "../imgs/contact_normal_deschis.svg";
import actionareElectromecanicaCuTemporizareLaAnclansare from "../imgs/actionare_electromecanica_cu_temporizare_la_anclansare.svg";
import actionareElecromecanicaCuTemporizareLaAnclansareSiLaRevenire from "../imgs/actionare_electromecanica_cu_temporizare_la_anclansare_si_la_revenire.svg";
import jumActionareElectromecanicaCuTemporizareLaAnclansareSiLaRevenire from "../imgs/jum_actionare_electromecanica_cu_temporizare_la_anclansare_si_la_revenire.svg";
import actionareElectromecanicaCuTemporizareLaRevenire from "../imgs/actionare_electromecanica_cu_temporizare_la_revenire.svg";
import conductorLung from "../imgs/conductor_lung.svg";
import conductorLat from "../imgs/conductor_lat.svg";
import coltStangaSus from "../imgs/colt_stanga_sus.svg";
import coltDreaptaSus from "../imgs/colt_dreapta_sus.svg";
import coltStangaJos from "../imgs/colt_stanga_jos.svg";
import coltDreaptaJos from "../imgs/colt_dreapta_jos.svg";
import derivatieDinConductor from "../imgs/derivatie_din_conductor.svg";
import sirDeClame from "../imgs/sir_de_clame.svg";
import jumSirDeClame1 from "../imgs/jum_sir_de_clame_1.svg";
import jumSirDeClame2 from "../imgs/jum_sir_de_clame_2.svg";
import priza_de_pamant_de_protectie from "../imgs/priza_de_pamant_de_protectie.svg";
import pamant from "../imgs/pamant.svg";
import condensator from "../imgs/condensator.svg";
import contactNormalInchis from "../imgs/contact_normal_inchis.svg";
import hupa_claxon from "../imgs/hupa_claxon.svg";
import mecanism_de_comutare_cu_declansare_mecanica from "../imgs/mecanism_de_comutare_cu_declansare_mecanica.svg";
import mecanism_de_comutare_cu_declansare_mecanica_punctat from "../imgs/mecanism_de_comutare_cu_declansare_mecanica_punctat.svg";
import actionare_electromecanica_a_unui_releu_termic from "../imgs/actionare_electromecanica_a_unui_releu_termic.svg";
import contact_normal_deschis_cu_temporizare_la_actionare from "../imgs/contact_normal_deschis_cu_temporizare_la_actionare.svg";
import contact_normal_inchis_cu_temporizare_la_revenire from "../imgs/contact_normal_inchis_cu_temporizare_la_revenire.svg";
import buton_cu_revenire from "../imgs/buton_cu_revenire.svg";
import buton_cu_contact_normal_inchis from "../imgs/buton_cu_contact_normal_inchis.svg";
import intreruptor_de_pozitie_normal_deschis from "../imgs/intreruptor_de_pozitie_normal_deschis.svg";
import intreruptor_de_pozitie_normal_inchis from "../imgs/intreruptor_de_pozitie_normal_inchis.svg";
import generator from "../imgs/generator.svg";
import motor from "../imgs/motor.svg";
import motor_de_curent_continuu from "../imgs/motor_de_curent_continuu.svg";
import motor_de_curent_alternativ from "../imgs/motor_de_curent_alternativ.svg";
import motor_asincron_trifazat_cu_rotorul_in_scurtcircuit from "../imgs/motor_asincron_trifazat_cu_rotorul_in_scurtcircuit.svg";
import motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_stea from "../imgs/motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_stea.svg";
import motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_triunghi from "../imgs/motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_triunghi.svg";

import motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_punctat from "../imgs/motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_punctat.svg";
import motor_asincron_trifazat_cu_rotor_cu_inele from "../imgs/motor_asincron_trifazat_cu_rotor_cu_inele.svg";
import siguranta from "../imgs/siguranta.svg";
import indicator_luminos from "../imgs/indicator_luminos.svg";
import indicator_luminos_aprins from "../imgs/indicator_luminos_aprins.svg";
import conductor_oblic_45 from "../imgs/conductor_oblic_45.svg";
import conductor_oblic_135 from "../imgs/conductor_oblic_135.svg";
import contact_normal_deschis_actionare_manuala from "../imgs/contact_normal_deschis_actionare_manuala.svg";
import contact_normal_inchis_actionare_manuala from "../imgs/contact_normal_inchis_actionare_manuala.svg";
import contact_normal_deschis_actionare_prin_apasare from "../imgs/contact_normal_deschis_actionare_prin_apasare.svg";
import contact_normal_inchis_actionare_prin_apasare from "../imgs/contact_normal_inchis_actionare_prin_apasare.svg";
import contact_normal_deschis_actionare_prin_tragere from "../imgs/contact_normal_deschis_actionare_prin_tragere.svg";
import contact_normal_inchis_actionare_prin_tragere from "../imgs/contact_normal_inchis_actionare_prin_tragere.svg";
import contact_normal_deschis_actionare_prin_rotire from "../imgs/contact_normal_deschis_actionare_prin_rotire.svg";
import contact_normal_inchis_actionare_prin_rotire from "../imgs/contact_normal_inchis_actionare_prin_rotire.svg";
import contact_normal_deschis_actionare_prin_cheie from "../imgs/contact_normal_deschis_actionare_prin_cheie.svg";
import contact_normal_inchis_actionare_prin_cheie from "../imgs/contact_normal_inchis_actionare_prin_cheie.svg";
import contact_normal_deschis_actionare_prin_role_senzori from "../imgs/contact_normal_deschis_actionare_prin_role_senzori.svg";
import contact_normal_inchis_actionare_prin_role_senzori from "../imgs/contact_normal_inchis_actionare_prin_role_senzori.svg";
import contact_normal_deschis_actionare_cu_mecanism_cu_stocare_de_energie from "../imgs/contact_normal_deschis_actionare_cu_mecanism_cu_stocare_de_energie.svg";
import contact_normal_inchis_actionare_cu_mecanism_cu_stocare_de_energie from "../imgs/contact_normal_inchis_actionare_cu_mecanism_cu_stocare_de_energie.svg";
import contact_normal_deschis_actionare_cu_motor from "../imgs/contact_normal_deschis_actionare_cu_motor.svg";
import contact_normal_inchis_actionare_cu_motor from "../imgs/contact_normal_inchis_actionare_cu_motor.svg";
import contact_normal_deschis_intreruptor_pentru_oprire_de_urgenta from "../imgs/contact_normal_deschis_intreruptor_pentru_oprire_de_urgenta.svg";
import contact_normal_inchis_intreruptor_pentru_oprire_de_urgenta from "../imgs/contact_normal_inchis_intreruptor_pentru_oprire_de_urgenta.svg";
import contact_normal_deschis_prin_protectia_electromagnetica_la_supracurent from "../imgs/contact_normal_deschis_prin_protectia_electromagnetica_la_supracurent.svg";
import contact_normal_inchis_prin_protectia_electromagnetica_la_supracurent from "../imgs/contact_normal_inchis_prin_protectia_electromagnetica_la_supracurent.svg";
import contact_normal_deschis_prin_protectia_termica_la_supracurent from "../imgs/contact_normal_deschis_prin_protectia_termica_la_supracurent.svg";
import contact_normal_inchis_prin_protectia_termica_la_supracurent from "../imgs/contact_normal_inchis_prin_protectia_termica_la_supracurent.svg";
import contact_normal_deschis_actionare_prin_nivel_de_lichid from "../imgs/contact_normal_deschis_actionare_prin_nivel_de_lichid.svg";
import contact_normal_inchis_actionare_prin_nivel_de_lichid from "../imgs/contact_normal_inchis_actionare_prin_nivel_de_lichid.svg";
import releu_de_presiune_minima_contact_normal_deschis from "../imgs/releu_de_presiune_minima_contact_normal_deschis.svg";
import releu_de_presiune_presostat_contact_normal_inchis from "../imgs/releu_de_presiune_presostat_contact_normal_inchis.svg";
import intreruptor_cu_plutitor_contact_normal_deschis from "../imgs/intreruptor_cu_plutitor_contact_normal_deschis.svg";
import intreruptor_cu_plutitor_contact_normal_inchis from "../imgs/intreruptor_cu_plutitor_contact_normal_inchis.svg";
import transformatoare_cu_doua_infasurari_2 from "../imgs/transformatoare_cu_doua_infasurari_2.svg";
import transformatoare_cu_doua_infasurari from "../imgs/transformatoare_cu_doua_infasurari.svg";
import autotransformator from "../imgs/autotransformator.svg";
import transformatoare_de_curent from "../imgs/transformatoare_de_curent.svg";
import transformatoare_de_curent_2 from "../imgs/transformatoare_de_curent_2.svg";
import regleta_1 from "../imgs/regleta_1.svg";
import regleta_2 from "../imgs/regleta_2.svg";
import siguranta_deschisa from "../imgs/siguranta_deschisa.svg";
import contact_normal_deschis_copy from "../imgs/contact_normal_deschis_copy.svg";
import contact_normal_inchis_copy from "../imgs/contact_normal_inchis_copy.svg";
import contur from "../imgs/contur.svg";
import cruce from "../imgs/cruce.svg";
import conductor_lung_bulina from "../imgs/conductor_lung_bulina.svg";
import conductor_lat_punctat from "../imgs/conductor_lat_punctat.svg";
import derivatie_din_conductor_punctat from "../imgs/derivatie_din_conductor_punctat.svg";
import releu_termic from "../imgs/releu_termic.svg";
import conductor_lung_punctat from "../imgs/conductor_lung_punctat.svg";
import colt_stanga_jos_punctat from "../imgs/colt_stanga_jos_punctat.svg";
import colt_dreapta_jos_punctat from "../imgs/colt_dreapta_jos_punctat.svg";
import regleta_1_dotless from "../imgs/regleta_1_dotless.svg";
import semi_cruce from "../imgs/semi_cruce.svg";
import masa from "../imgs/masa.svg";
import contact_normal_deschis_automentinere from "../imgs/contact_normal_deschis_automentinere.svg";
import contact_normal_deschis_actionare_prin_apasare_automentinere from "../imgs/contact_normal_deschis_actionare_prin_apasare_automentinere.svg";
import contact_normal_inchis_automentinere from "../imgs/contact_normal_inchis_automentinere.svg";
import contact_normal_inchis_actionare_prin_apasare_automentinere from "../imgs/contact_normal_inchis_actionare_prin_apasare_automentinere.svg";
import motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit from "../imgs/motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit.svg";
import conductor_lung_protectie from "../imgs/conductor_lung_protectie.svg";
import conductor_lung_protectie_invers from "../imgs/conductor_lung_protectie_invers.svg";
import conductor_lung_sageata from "../imgs/conductor_lung_sageata.svg";
import conductor_lung_sageata_final from "../imgs/conductor_lung_sageata_final.svg";
import conductor_diagonal_45 from "../imgs/conductor_diagonal_45.svg";
import conductor_diagonal_135 from "../imgs/conductor_diagonal_135.svg";
import contact_normal_deschis_automentinere_stanga from "../imgs/contact_normal_deschis_automentinere_stanga.svg";
import contact_normal_inchis_automentinere_stanga from "../imgs/contact_normal_inchis_automentinere_stanga.svg";
import steaua from "../imgs/steaua.svg";
import triunghi from "../imgs/triunghi.svg";
import intreruptor_tripolar from "../imgs/intreruptor_tripolar.svg";
import intreruptor_tripolar_2 from "../imgs/intreruptor_tripolar_2.svg";

const SCHEME_ELEMENTS : any = {

    conductor_lung: {
        imageSource: conductorLung,
        realSource: [conductorLung],
        name: {
            ro: "Conductor (Lungime)",
            en: "Conductor (Length)"
        },
        category: "conductors"
    },

    conductor_lung_sageata: {
        imageSource: conductor_lung_sageata,
        realSource: [conductor_lung_sageata],
        name: {
            ro: "Conductor cu sageata (Lungime)",
            en: "Conductor with arrow (Length)"
        },
        category: "conductors"
    },

    conductor_lung_sageata_final: {
        imageSource: conductor_lung_sageata_final,
        realSource: [conductor_lung_sageata_final],
        name: {
            ro: "Conductor cu sageata la capat (Lungime)",
            en: "Conductor with arrow at the end (Length)"
        },
        category: "conductors"
    },

    conductor_diagonal_45: {
        imageSource: conductor_diagonal_45,
        realSource: [conductor_diagonal_45],
        name: {
            ro: "Conductor diagonal 45°",
            en: "Diagonal conductor 45°"
        },
        category: "conductors"
    },

    conductor_diagonal_135: {
        imageSource: conductor_diagonal_135,
        realSource: [conductor_diagonal_135],
        name: {
            ro: "Conductor diagonal 135°",
            en: "Diagonal conductor 135°"
        },
        category: "conductors"
    },

    conductor_lung_protectie: {
        imageSource: conductor_lung_protectie,
        realSource: [conductor_lung_protectie],
        category: "conductors",
        name: {
            ro: "Conductor lung protectie",
            en: "Protection conductor (long)"
        },
    },

    conductor_lung_protectie_invers: {
        imageSource: conductor_lung_protectie_invers,
        realSource: [conductor_lung_protectie_invers],
        category: "conductors",
        name: {
            ro: "Conductor lung protectie invers",
            en: "Reverse protection conductor (long)"
        },
        
    },

    conductor_lat: {
        imageSource: conductorLat,
        realSource: [conductorLat],
        name: {
            ro: "Conductor (Latime)",
            en: "Conductor (Width)"
        },
        category: "conductors"
    },

    conductor_oblic_45: {
        imageSource: conductor_oblic_45,
        realSource: [conductor_oblic_45],
        name: {
            ro: "Conductor Oblic 45°",
            en: "Oblique conductor 45°"
        },
        category: "conductors"
    },

    conductor_oblic_135: {
        imageSource: conductor_oblic_135,
        realSource: [conductor_oblic_135],
        name: {
            ro: "Conductor Oblic 135°",
            en: "Oblique conductor 135°"
        },
        category: "conductors"
    },

    bobina_releu: {
        imageSource: bobinaReleu,
        realSource: [bobinaReleu],
        name: {
            ro: "Bobina Releu",
            en: "Relay Coil"
        },
        category: "bobines"
    },

    contact_normal_deschis: {
        imageSource: contactNormalDeschis,
        realSource: [contactNormalDeschis],
        name: {
            ro: "Contact Normal Deschis",
            en: "Normally Open Contact"
        },
        category: "contacts"
    },

    contact_normal_inchis: {
        imageSource: contactNormalInchis,
        realSource: [contactNormalInchis],
        name: {
            ro: "Contact Normal Inchis",
            en: "Normally Close Contact"
        },
        category: "contacts"
    },

  
    intreruptor_tripolar: {
        imageSource: intreruptor_tripolar,
        realSource: [intreruptor_tripolar, intreruptor_tripolar_2],
        name: {
            ro: "Intreruptor tripolar",
            en: "Tripolar intreruptor"
        },
        align: "vertical",
        category: "contacts"
    },


    colt_stanga_sus: {
        imageSource: coltStangaSus,
        realSource: [coltStangaSus],
        name: {
            ro: "Colt Stanga-Sus",
            en: "Top-Left Corner"
        },
        category: "conductors",
    },

    colt_dreapta_sus: {
        imageSource: coltDreaptaSus,
        realSource: [coltDreaptaSus],
        name: {
            ro: "Colt Dreapta-Sus",
            en: "Top-Right Corner"
        },
        category: "conductors",
    },

    colt_stanga_jos: {
        imageSource: coltStangaJos,
        realSource: [coltStangaJos],
        name: {
            ro: "Colt Stanga-Jos",
            en: "Bottom-Left Corner"
        },
        category: "conductors",
    },

    colt_dreapta_jos: {
        imageSource: coltDreaptaJos,
        realSource: [coltDreaptaJos],
        name: {
            ro: "Colt Dreapta-Jos",
            en: "Bottom-Right Corner"
        },
        category: "conductors",
    },

    derivatie_din_conductor: {
        imageSource: derivatieDinConductor,
        realSource: [derivatieDinConductor],
        name: {
            ro: "Derivatie din conductor",
            en: "Derivation from the conductor"
        },
        category: "conductors",
    },

    actionare_electromecanica_cu_temporizare_la_anclansare: {
        imageSource: actionareElectromecanicaCuTemporizareLaAnclansare,
        realSource: [actionareElectromecanicaCuTemporizareLaAnclansare],
        name: {
            ro: "Actionare electromecanica cu temporizare la anclansare",
            en: "Electromechanical actuation with timing on engagement"
        },
        category: "bobines",
    },

    actionare_electromecanica_cu_temporizare_la_anclansare_si_la_revenire: {
        imageSource: actionareElecromecanicaCuTemporizareLaAnclansareSiLaRevenire,
        realSource: [actionareElectromecanicaCuTemporizareLaAnclansare, jumActionareElectromecanicaCuTemporizareLaAnclansareSiLaRevenire],
        name: {
            ro: "Actionare electromecanica cu temporizare la anclansare si la revenire",
            en: "Electromechanical actuation with engagement and return delay"
        },
        category: "bobines",
    },

    actionare_electromecanica_cu_temporizare_la_revenire: {
        imageSource: actionareElectromecanicaCuTemporizareLaRevenire,
        realSource: [actionareElectromecanicaCuTemporizareLaRevenire],
        name: {
            ro: "Actionare electromecanica cu temporizare la revenire",
            en: "Electromechanical actuation with return delay"
        },
        category: "contacts",
    },

    sir_de_clame: {
        imageSource: sirDeClame,
        realSource: [jumSirDeClame2, jumSirDeClame1],
        name: {
            ro: "Sir de cleme",
            en: "String of clams"
        },
        category: "others",
    },
    
    priza_de_pamant_de_protectie: {
        imageSource: priza_de_pamant_de_protectie,
        realSource: [priza_de_pamant_de_protectie],
        name: {
            ro: "Priza de pamant de protectie",
            en: "Protective earth socket"
        },
        category: "others",
    },

    indicator_luminos: {
        imageSource: indicator_luminos,
        realSource: [indicator_luminos],
        name: {
            ro: "Indicator luminos",
            en: "Luminous indicator"
        },
        category: "others",
    },

    indicator_luminos_aprins: {
        imageSource: indicator_luminos_aprins,
        realSource: [indicator_luminos_aprins],
        name: {
            ro: "Indicator luminos aprins",
            en: "Luminous indicator turned on"
        },
        category: "others",
        show: false,
    },

    pamant: {
        imageSource: pamant,
        realSource: [pamant],
        name: {
            ro: "Legare la pamant",
            en: "Ground connection"
        },
        category: "others"
    },

    condensator: {
        imageSource: condensator,
        realSource: [condensator],
        name: {
            ro: "Condensator",
            en: "Capacitor"
        },
        category: "others"
    },

    hupa_claxon: {
        imageSource: hupa_claxon,
        realSource: [hupa_claxon],
        name: {
            ro: "Hupa, Claxon",
            en: "Hoot, Horn"
        },
        category: "others"
    },

    mecanism_de_comutare_cu_declansare_mecanica: {
        imageSource: mecanism_de_comutare_cu_declansare_mecanica,
        realSource: [mecanism_de_comutare_cu_declansare_mecanica],
        name: {
            ro: "Mecanism de comutare cu declansare mecanica",
            en: "Switching mechanism with mechanical release"
        },
        category: "bobines"
    },

    actionare_electromecanica_a_unui_releu_termic: {
        imageSource: actionare_electromecanica_a_unui_releu_termic,
        realSource: [actionare_electromecanica_a_unui_releu_termic],
        name: {
            ro: "Actionare electromecanica a unui releu termic",
            en: "Electromechanical actuation of a thermal relay"
        },
        category: "others"
    },

    contact_normal_deschis_cu_temporizare_la_actionare: {
        imageSource: contact_normal_deschis_cu_temporizare_la_actionare,
        realSource: [contact_normal_deschis_cu_temporizare_la_actionare],
        name: {
            ro: "Contact normal deschis cu temporizare la actionare",
            en: "Normally open contact with actuation delay"
        },
        category: "contacts"
    },

    contact_normal_inchis_cu_temporizare_la_revenire: {
        imageSource: contact_normal_inchis_cu_temporizare_la_revenire,
        realSource: [contact_normal_inchis_cu_temporizare_la_revenire],
        name: {
            ro: "Contact normal inchis cu temporizare la revenire",
            en: "Normally closed contact with return delay"
        },
        category: "contacts"
    },

    buton_cu_revenire: {
        imageSource: buton_cu_revenire,
        realSource: [buton_cu_revenire],
        name: {
            ro: "Buton cu revenire",
            en: "Back button"
        },
        category: "buttons"
    },

    buton_cu_contact_normal_inchis: {
        imageSource: buton_cu_contact_normal_inchis,
        realSource: [buton_cu_contact_normal_inchis],
        name: {
            ro: "Buton cu contact normal deschis",
            en: "Button with normally open contact"
        },
        category: "buttons"
    },

    intreruptor_de_pozitie_normal_deschis: {
        imageSource: intreruptor_de_pozitie_normal_deschis,
        realSource: [intreruptor_de_pozitie_normal_deschis],
        name: {
            ro: "Intreruptor de pozitie normal deschis",
            en: "Normally open position switch"
        },
        category: "contacts"
    },

    intreruptor_de_pozitie_normal_inchis: {
        imageSource: intreruptor_de_pozitie_normal_inchis,
        realSource: [intreruptor_de_pozitie_normal_inchis],
        name: {
            ro: "Intreruptor de pozitie normal inchis",
            en: "Normally closed position switch"
        },
        category: "contacts"
    },


    releu_de_presiune_minima_contact_normal_deschis: {
        imageSource: releu_de_presiune_minima_contact_normal_deschis,
        realSource: [releu_de_presiune_minima_contact_normal_deschis],
        name: {
            ro: "Releu de presiune minima, presostat, contact normal deschis",
            en: "Minimum pressure relay, pressure switch, normally open contact"
        },
        category: "contacts"
    },

    releu_de_presiune_presostat_contact_normal_inchis: {
        imageSource: releu_de_presiune_presostat_contact_normal_inchis,
        realSource: [releu_de_presiune_presostat_contact_normal_inchis],
        name: {
            ro: "Releu de presiune, presostat, contact normal inchis",
            en: "Pressure relay, pressure switch, normally closed contact"
        },
        category: "contacts"
    },  

    intreruptor_cu_plutitor_contact_normal_deschis: {
        imageSource: intreruptor_cu_plutitor_contact_normal_deschis,
        realSource: [intreruptor_cu_plutitor_contact_normal_deschis],
        name: {
            ro: "Intreruptor cu plutitor, contact normal deschis",
            en: "Float switch, normally open contact"
        },
        category: "contacts"
    },

    intreruptor_cu_plutitor_contact_normal_inchis: {
        imageSource: intreruptor_cu_plutitor_contact_normal_inchis,
        realSource: [intreruptor_cu_plutitor_contact_normal_inchis],
        name: {
            ro: "Intreruptor cu plutitor, contact normal inchis",
            en: "Float switch, normally closed contact"
        },
        category: "contacts"
    },
    siguranta: {
        imageSource: siguranta,
        realSource: [siguranta],
        name: {
            ro: "Siguranta",
            en: "Fuse"
        },
        category: "others"
    },

    transformatoare_cu_doua_infasurari: {
        imageSource: transformatoare_cu_doua_infasurari,
        realSource: [transformatoare_cu_doua_infasurari],
        name: {
            ro: "Transformatoare cu doua infasurari",
            en: "Transformers with two windings"
        },
        category: "engines"
    },


    transformatoare_cu_doua_infasurari_2: {
        imageSource: transformatoare_cu_doua_infasurari_2,
        realSource: [transformatoare_cu_doua_infasurari_2],
        name: {
            ro: "Transformatoare cu doua infasurari",
            en: "Transformers with two windings"
        },
        category: "engines"
    },

    autotransformator: {
        imageSource: autotransformator,
        realSource: [autotransformator],
        name: {
            ro: "Autotransformator",
            en: "Autotransformer"
        },
        category: "engines"
    },

    transformatoare_de_curent: {
        imageSource: transformatoare_de_curent,
        realSource: [transformatoare_de_curent],
        name: {
            ro: "Transformator de curent",
            en: "Current transformer"
        },
        category: "engines"
    },

    transformatoare_de_curent_2: {
        imageSource: transformatoare_de_curent_2,
        realSource: [transformatoare_de_curent_2],
        name: {
            ro: "Transformator de curent",
            en: "Current transformer"
        },
        category: "engines"
    },


    generator: {
        imageSource: generator,
        realSource: [generator],
        name: {
            ro: "Generator",
            en: "Generator"
        },
        category: "engines"
    },

    motor: {
        imageSource: motor,
        realSource: [motor],
        name: {
            ro: "Motor",
            en: "Motor"
        },
        category: "engines"
    },

    motor_de_curent_continuu: {
        imageSource: motor_de_curent_continuu,
        realSource: [motor_de_curent_continuu],
        name: {
            ro: "Motor de curent continuu",
            en: "Direct current motor"
        },
        category: "engines"
    },

    motor_de_curent_alternativ: {
        imageSource: motor_de_curent_alternativ,
        realSource: [motor_de_curent_alternativ],
        name: {
            ro: "Motor de curent alternativ",
            en: "Alternative current motor"
        },
        category: "engines"
    },

    motor_asincron_trifazat_cu_rotorul_in_scurtcircuit: {
        imageSource: motor_asincron_trifazat_cu_rotorul_in_scurtcircuit,
        realSource: [motor_asincron_trifazat_cu_rotorul_in_scurtcircuit],
        name: {
            ro: "Motor asincron trifazat cu rotorul in scurtcircuit",
            en: "Three-phase asynchronous motor with short-circuited rotor"
        },
        category: "engines"
    },

    motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit: {
        imageSource: motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit,
        realSource: [motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_pornit],
        show: false,
    },

    motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_triunghi: {
        imageSource: motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_triunghi,
        realSource: [motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_triunghi],
        show: false,
    },

    motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_stea: {
        imageSource: motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_stea,
        realSource: [motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_stea],
        show: false,
    },

    motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_punctat: {
        imageSource: motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_punctat,
        realSource: [motor_asincron_trifazat_cu_rotorul_in_scurtcircuit_punctat],
        show: false
    },
    
    motor_asincron_trifazat_cu_rotor_cu_inele: {
        imageSource: motor_asincron_trifazat_cu_rotor_cu_inele,
        realSource: [motor_asincron_trifazat_cu_rotor_cu_inele],
        name: {
            ro: "Motor asincron trifazat cu rotor cu inele",
            en: "Three-phase asynchronous motor with ring rotor"
        },
        category: "engines"
    },

    contact_normal_deschis_actionare_manuala: {
        imageSource: contact_normal_deschis_actionare_manuala,
        realSource: [contact_normal_deschis_actionare_manuala],
        name: {
            ro: "Contact normal deschis cu actionare manuala",
            en: "Normally open contact with manual operation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_manuala: {
        imageSource: contact_normal_inchis_actionare_manuala,
        realSource: [contact_normal_inchis_actionare_manuala],
        name: {
            ro: "Contact normal inchis cu actionare manuala",
            en: "Normally closed contact with manual actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_apasare: {
        imageSource: contact_normal_deschis_actionare_prin_apasare,
        realSource: [contact_normal_deschis_actionare_prin_apasare],
        name: {
            ro: "Contact normal deschis actionare prin apasare",
            en: "Normally open contact actuated by pressing"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_apasare: {
        imageSource: contact_normal_inchis_actionare_prin_apasare,
        realSource: [contact_normal_inchis_actionare_prin_apasare],
        name: {
            ro: "Contact normal inchis cu actionare prin apasare",
            en: "Normally closed contact with actuation by pressing"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_tragere: {
        imageSource: contact_normal_deschis_actionare_prin_tragere,
        realSource: [contact_normal_deschis_actionare_prin_tragere],
        name: {
            ro: "Contact normal deschis cu actionare prin tragere",
            en: "Normally open contact with pull actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_tragere: {
        imageSource: contact_normal_inchis_actionare_prin_tragere,
        realSource: [contact_normal_inchis_actionare_prin_tragere],
        name: {
            ro: "Contact normal inchis cu actionare prin tragere",
            en: "Normally closed contact with pull actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_rotire: {
        imageSource: contact_normal_deschis_actionare_prin_rotire,
        realSource: [contact_normal_deschis_actionare_prin_rotire],
        name: {
            ro: "Contact normal deschis cu actionare prin rotire",
            en: "Normally open contact with rotary actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_rotire: {
        imageSource: contact_normal_inchis_actionare_prin_rotire,
        realSource: [contact_normal_inchis_actionare_prin_rotire],
        name: {
            ro: "Contact normal inchis cu actionare prin rotire",
            en: "Normally closed contact with rotary actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_cheie: {
        imageSource: contact_normal_deschis_actionare_prin_cheie,
        realSource: [contact_normal_deschis_actionare_prin_cheie],
        name: {
            ro: "Contact normal deschis cu actionare prin cheie",
            en: "Normally open contact with key actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_cheie: {
        imageSource: contact_normal_inchis_actionare_prin_cheie,
        realSource: [contact_normal_inchis_actionare_prin_cheie],
        name: {
            ro: "Contact normal inchis cu actionare prin cheie",
            en: "Normally closed contact with key actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_role_senzori: {
        imageSource: contact_normal_deschis_actionare_prin_role_senzori,
        realSource: [contact_normal_deschis_actionare_prin_role_senzori],
        name: {
            ro: "Contact normal deschis cu actionare prin role senzori",
            en: "Normally open contact with sensor roller actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_role_senzori: {
        imageSource: contact_normal_inchis_actionare_prin_role_senzori,
        realSource: [contact_normal_inchis_actionare_prin_role_senzori],
        name: {
            ro: "Contact normal inchis cu actionare prin role senzori",
            en: "Normally closed contact with sensor roller actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_cu_mecanism_cu_stocare_de_energie: {
        imageSource: contact_normal_deschis_actionare_cu_mecanism_cu_stocare_de_energie,
        realSource: [contact_normal_deschis_actionare_cu_mecanism_cu_stocare_de_energie],
        name: {
            ro: "Contact normal deschis cu actionare cu mecanism cu stocare de energie",
            en: "Normally open contact with mechanism actuation with energy storage"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_cu_mecanism_cu_stocare_de_energie: {
        imageSource: contact_normal_inchis_actionare_cu_mecanism_cu_stocare_de_energie,
        realSource: [contact_normal_inchis_actionare_cu_mecanism_cu_stocare_de_energie],
        name: {
            ro: "Contact normal inchis cu actionare cu mecanism cu stocare de energie",
            en: "Normally closed contact with mechanism actuation with energy storage"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_cu_motor: {
        imageSource: contact_normal_deschis_actionare_cu_motor,
        realSource: [contact_normal_deschis_actionare_cu_motor],
        name: {
            ro: "Contact normal deschis cu actionare cu motor",
            en: "Normally open contact with motor actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_cu_motor: {
        imageSource: contact_normal_inchis_actionare_cu_motor,
        realSource: [contact_normal_inchis_actionare_cu_motor],
        name: {
            ro: "Contact normal inchis cu actionare cu motor",
            en: "Normally closed contact with motor actuation"
        },
        category: "contacts"
    },

    contact_normal_deschis_intreruptor_pentru_oprire_de_urgenta: {
        imageSource: contact_normal_deschis_intreruptor_pentru_oprire_de_urgenta,
        realSource: [contact_normal_deschis_intreruptor_pentru_oprire_de_urgenta],
        name: {
            ro: "Contact normal deschis cu intreruptor pentru oprire de urgenta",
            en: "Normally open contact with emergency stop switch"
        },
        category: "contacts"
    },

    contact_normal_inchis_intreruptor_pentru_oprire_de_urgenta: {
        imageSource: contact_normal_inchis_intreruptor_pentru_oprire_de_urgenta,
        realSource: [contact_normal_inchis_intreruptor_pentru_oprire_de_urgenta],
        name: {
            ro: "Contact normal inchis cu intreruptor pentru oprire de urgenta",
            en: "Normally closed contact with emergency stop switch"
        },
        category: "contacts"
    },

    contact_normal_deschis_prin_protectia_electromagnetica_la_supracurent: {
        imageSource: contact_normal_deschis_prin_protectia_electromagnetica_la_supracurent,
        realSource: [contact_normal_deschis_prin_protectia_electromagnetica_la_supracurent],
        name: {
            ro: "Contact normal deschis prin protectia electromagnetica la supracurent",
            en: "Normally open contact through electromagnetic overcurrent protection"
        },
        category: "contacts"
    },
    
    contact_normal_inchis_prin_protectia_electromagnetica_la_supracurent: {
        imageSource: contact_normal_inchis_prin_protectia_electromagnetica_la_supracurent,
        realSource: [contact_normal_inchis_prin_protectia_electromagnetica_la_supracurent],
        name: {
            ro: "Contact normal inchis prin protectia electromagnetica la supracurent",
            en: "Normally closed contact through electromagnetic overcurrent protection"
        },
        category: "contacts"
    },

    contact_normal_deschis_prin_protectia_termica_la_supracurent: {
        imageSource: contact_normal_deschis_prin_protectia_termica_la_supracurent,
        realSource: [contact_normal_deschis_prin_protectia_termica_la_supracurent],
        name: {
            ro: "Contact normal deschis prin protectia termica la supracurent",
            en: "Normally open contact through overcurrent thermal protection"
        },
        category: "contacts"
    },

    contact_normal_inchis_prin_protectia_termica_la_supracurent: {
        imageSource: contact_normal_inchis_prin_protectia_termica_la_supracurent,
        realSource: [contact_normal_inchis_prin_protectia_termica_la_supracurent],
        name: {
            ro: "Contact normal inchis prin protectia termica la supracurent",
            en: "Normally closed contact through overcurrent thermal protection"
        },
        category: "contacts"
    },

    contact_normal_deschis_actionare_prin_nivel_de_lichid: {
        imageSource: contact_normal_deschis_actionare_prin_nivel_de_lichid,
        realSource: [contact_normal_deschis_actionare_prin_nivel_de_lichid],
        name: {
            ro: "Contact normal deschis cu actionare prin nivel de lichid",
            en: "Normally open contact with liquid level actuation"
        },
        category: "contacts"
    },

    contact_normal_inchis_actionare_prin_nivel_de_lichid: {
        imageSource: contact_normal_inchis_actionare_prin_nivel_de_lichid,
        realSource: [contact_normal_inchis_actionare_prin_nivel_de_lichid],
        name: {
            ro: "Contact normal inchis cu actionare prin nivel de lichid",
            en: "Normally closed contact with liquid level actuation"
        },
        category: "contacts"
    },

    regleta_1: {
        imageSource: regleta_1,
        realSource: [regleta_1],
        name: {
            ro: "Regleta",
            en: "Regleta"
        },
        category: "others"
    },

    regleta_2: {
        imageSource: regleta_2,
        realSource: [regleta_2],
        name: {
            ro: "Regleta",
            en: "Regleta"
        },
        show: false
    },

    siguranta_deschisa: {
        imageSource: siguranta_deschisa,
        realSource: [siguranta_deschisa],
        name: {
            ro: "Siguranta",
            en: "Siguranta"
        },
        show: false
    },

    contact_normal_deschis_copy: {
        imageSource: contact_normal_deschis_copy,
        realSource: [contact_normal_deschis_copy],
      
        show: false
    },

    contact_normal_inchis_copy: {
        imageSource: contact_normal_inchis_copy,
        realSource: [contact_normal_inchis_copy],
      
        show: false
    },

    contur: {
        imageSource: contur,
        realSource: [contur],
        show: false
    },

    cruce: {
        imageSource: cruce,
        realSource: [cruce],
        show: true,
        name: {
            ro: "Cruce",
            en: "Cross"
        },
        category: "conductors"
    },
    
    conductor_lung_bulina: {
        imageSource: conductor_lung_bulina,
        realSource: [conductor_lung_bulina],
        show: false
    },

    conductor_lat_punctat: {
        imageSource: conductor_lat_punctat,
        realSource: [conductor_lat_punctat],
        show: false
    },

    derivatie_din_conductor_punctat: {
        imageSource: derivatie_din_conductor_punctat,
        realSource: [derivatie_din_conductor_punctat],
        show: true,
        category: "conductors",
        name: {
            ro: "Derivatie din conductor punctat",
            en: "Derivation from conductor dotted"
        }
    },
    
    releu_termic: {
        imageSource: releu_termic,
        realSource: [releu_termic],
        show: false
    },

    conductor_lung_punctat: {
        imageSource: conductor_lung_punctat,
        realSource: [conductor_lung_punctat],
        show: false
    },

    colt_stanga_jos_punctat: {
        imageSource: colt_stanga_jos_punctat,
        realSource: [colt_stanga_jos_punctat],
        show: false
    },

    colt_dreapta_jos_punctat: {
        imageSource: colt_dreapta_jos_punctat,
        realSource: [colt_dreapta_jos_punctat],
        show: false
    },

    regleta_1_dotless: {
        imageSource: regleta_1_dotless,
        realSource: [regleta_1_dotless],
        show: false
    },

    semi_cruce: {
        imageSource: semi_cruce,
        realSource: [semi_cruce],
        show: false
    },

    masa: {
        imageSource: masa,
        realSource: [masa],
        name: {
            ro: "Masa (carcasa)",
            en: "Mass (case)"
        },
        category: "others"
    },

    contact_normal_deschis_automentinere: {
        imageSource: contact_normal_deschis_automentinere,
        realSource: [contact_normal_deschis_automentinere],
        name: {
            ro: "Contact normal deschis cu automentinere",
            en: "Normally open contact with self-maintenance"
        },
        show: false
    },

    contact_normal_deschis_actionare_prin_apasare_automentinere: {
        imageSource: contact_normal_deschis_actionare_prin_apasare_automentinere,
        realSource: [contact_normal_deschis_actionare_prin_apasare_automentinere],
        name: {
            ro: "Contact normal deschis cu actionare prin apasare si automentinere",
            en: "Normally open contact with push actuation and self-maintenance"
        },
        show: false
    },

    contact_normal_inchis_automentinere: {
        imageSource: contact_normal_inchis_automentinere,
        realSource: [contact_normal_inchis_automentinere],
        name: {
            ro: "Contact normal inchis cu automentinere",
            en: "Normally closed contact with self-maintenance"
        },
        show: false
    },

    contact_normal_inchis_actionare_prin_apasare_automentinere: {
        imageSource: contact_normal_inchis_actionare_prin_apasare_automentinere,
        realSource: [contact_normal_inchis_actionare_prin_apasare_automentinere],
        name: {
            ro: "Contact normal inchis cu actionare prin apasare si automentinere",
            en: "Normally closed contact with push actuation and self-maintenance"
        },
        show: false
    },

    contact_normal_deschis_automentinere_stanga: {
        imageSource: contact_normal_deschis_automentinere_stanga,
        realSource: [contact_normal_deschis_automentinere_stanga],
        name: {
            ro: "Contact normal deschis prin automentinere (stanga)",
            en: "Normally closed contact with push self-maintenance (left)"
        },
        show: false
    },

    contact_normal_inchis_automentinere_stanga: {
        imageSource: contact_normal_inchis_automentinere_stanga,
        realSource: [contact_normal_inchis_automentinere_stanga],
        name: {
            ro: "Contact normal deschis prin automentinere (stanga)",
            en: "Normally closed contact with push self-maintenance (left)"
        },
        show: false
    },

    steaua: {
        imageSource: steaua, 
        realSource: [steaua],
        name: {
            ro: "Steaua",
            en: "The star",
        },
        show: false,
    },

    triunghi: {
        imageSource: triunghi,
        realSource: [triunghi],
        name: {
            ro: "Triunghi",
            en: "Triangle"
        },
        show: false,
    },



};

export default SCHEME_ELEMENTS;