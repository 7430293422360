import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Home from './Pages/Home/Home';
import { create } from "zustand";
import {useStoreProps, useStoreActions} from './Interfaces/useStore';
import Login from './Pages/Login/Login';
import { useCookies } from 'react-cookie';
import Project from './Pages/Project/Project';
import Projects from './Pages/Projects/Projects';
import CreateProject from './Pages/CreateProject/CreateProject';
import PredefinedScheme from './Pages/PredefinedScheme/PredefinedScheme';
import PredefinedSchemeDebug from './Pages/PredefinedSchemeDebug/PredefinedSchemeDebug';
import io from "socket.io-client";
import Students from './Students/Students';
import CreateStudent from './Pages/CreateStudent/CreateStudent';
// import Sessions from './Pages/Sessions/Sessions';
import DebugSession from './Pages/DebugSession/DebugSession';
import LeaderboardDebugSession from './Pages/LeaderboardDebugSession/LeaderboardDebugSession';
import EditProject from './Pages/EditProject/EditProject';
import EditStudent from './Pages/EditStudent/EditStudent';
import Training from './Pages/Training/Training';
import CreateAccount from './Pages/CreateAccount/CreateAccount';
import Categories from './Pages/Categories/Categories';
import HighTensionProjects from './Pages/HighTensionProjects/HighTensionProjects';
import Testing from './Pages/Testing/Testing';
import OptionsTesting from './Pages/OptionsTesting/OptionsTesting';
import TestingSession from './Pages/TestingSession/TestingSession';
import LeaderboardTestingSession from './Pages/LeaderboardTestingSession/LeaderboardTestingSession';
import OptionsLearning from './Pages/OptionsLearning/OptionsLearning';
import LearningSession from './Pages/LearningSession/LearningSession';
import LeaderboardLearningSession from './Pages/LeaderboardLearningSession/LeaderboardLearningSession';

function App() {

  const [cookies, setCookie, removeCookie] = useCookies();
  const [data, setData] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [socket, setSocket] = useState<Object | null>(null);
  const [finished, setFinished] = useState<boolean>(false);


  const initialRestState: any = {
    activeEditMenu: false,
    lockImage: false,
    language: "ro",
    activeEditOption: null,
    activeSignsMenu: true,
    elements: [],
    gridActivated: true,
    printCall: 0,
    addSignMenu: false,
    selectedCell: null,
    isGrid: true,
    signColor: "#000",
    openMenu: true,
    current: false
  };

  const initialState: useStoreProps = {
    activeEditMenu: false,
    lockImage: false,
    language: "ro",
    activeEditOption: null,
    activeSignsMenu: true,
    elements: [],
    gridActivated: true,
    printCall: 0,
    addSignMenu: false,
    selectedCell: null,
    isGrid: true,
    signColor: "#000",
    openMenu: true,
    schemeStructure: [],
    current: false
  };

  const useStore = create<useStoreProps & useStoreActions>()((set) => ({
    ...initialState,
    changeActiveElementsMenu: () => set((state) => ({ activeSignsMenu: !state.activeSignsMenu })),
    imageLocking: () => set((state) => ({ lockImage: !state.lockImage })),
    changeEditMenu: (status = null) => set((state) => ({ activeEditMenu: status !== null ? status : !state.activeEditMenu })),
    changeIsSelectedSign: (x, y, page = 1) => set((state) => ({ elements: state.elements.map((element) => element.x === x && element.y === y && element.page === page ? {...element, isSelectedSign: !element.isSelectedSign } : {...element, isSelectedSign: false}) })),
    changeLanguage: (currLanguage) => set((state) => ({ language: currLanguage })),
    changeActiveEditOption: (currActiveEditOption) => set((state) => ({ activeEditOption: currActiveEditOption })),
    changeIsLockedSign: (x, y, page = 1) => set((state) => ({ elements: state.elements.map((element) => element.x === x && element.y === y && element.page === page ? {...element, isLockedSign: !element.isLockedSign} : element) })),
    addSign: (x, y, page, signName, flip = 0, texts = [], signColor = "#000", signPosition = "horizontal") => set((state) => ({ elements: [...state.elements, { x: x, y: y, page: page, name: signName, isSelectedSign: true, hasCurrent: false, isLockedSign: false, texts: texts, flip: flip, signColor: signColor || "#000", signPosition: signPosition }] })),
    removeSign: (x, y, page) => set((state) => ({ elements: state.elements.filter(element => element.page !== page || element.x !== x || element.y !== y) })),
    changeSignPosition: (oldX, oldY, oldPage, newX, newY, newPage) => set((state) => ({ elements: state.elements.map((element) => element.x === oldX && element.y === oldY && element.page === oldPage ? {...element, x: newX, y: newY, page: newPage} : element) })),
    replaceSign: (x, y, page, newName) => set((state) => ({ elements: state.elements.map(element => element.x === x && element.y === y && element.page === page ? {...element, name: newName} : element) })),
    editName: (texts, x, y, page = 1) =>  set((state) => ({ elements: state.elements.map(element => element.x === x && element.y === y && element.page === page ? {...element, texts: texts} : element) })),
    changeGridActivated: (newState : boolean | null = null) => set((state) => ({ gridActivated: newState || !state?.gridActivated })),
    reset: () => set((state) => ({...initialRestState, schemeStructure: state.schemeStructure})),
    printProject: () => set((state) => ({ printCall: state.printCall + 1 })),
    changeAddSignMenu: () => set((state) => ({ addSignMenu: !state.addSignMenu })),
    removeSelectedSign: () => set((state) => ({ elements: state.elements.map(element => {
      return {...element, isSelectedSign: false };
    })})),
    changeElements: (newElements) => set((state) => ({ elements: newElements })),
    changeHasCurrent: (x, y, page, newCurrent) => set((state) => ({ elements: state.elements.map(element => element.x === x && element.y === y && element.page === page ? {...element, hasCurrent: newCurrent} : element) })),
    changeSignTextPosition: (x, y, page, textIdx, positionX, positionY) => set((state) => ({ elements: state.elements.map((element) => element.x === x && element.y === y && element.page === page ? {...element, texts: element.texts.map((text, currTextIdx : number) => textIdx === currTextIdx ? {...text, positionX, positionY} : text) } : element)})),
    changeSelectedCell: (x, y, page = 1) => set((state) => ({ selectedCell: x === state.selectedCell?.x && y === state.selectedCell?.y && (page || 1) === state.selectedCell?.page ? null : { x: x, y: y, page: page } })),
    removeSelectedCell: () => set((state) => ({ selectedCell: null })),
    changeGrid: (newState : boolean | null = null) => set((state) => ({ isGrid: newState != null ? newState: !state.isGrid })),
    flipSign: (x, y, flip, page = 1) => set((state) => ({ elements: state.elements.map(element => element.x === x && element.y === y && element.page === page ? {...element, flip: flip } : element) })),
    changeSignName: (x, y, page, texts) => set((state) => ({ elements: state.elements.map(element => element.x === x && element.y === y && element.page === page ? { ...element, texts: texts } : element) })),
    changeOpenMenu: (status = null) => set((state) => ({ openMenu: status || !state.openMenu })),
    changeSchemeStructure: (newSchemeStructure) => set((state) => ({ schemeStructure: newSchemeStructure })),
    changeOpenSchemeStructure: (open, dotNumber) => set((state) => ({ schemeStructure: state.schemeStructure.map((element: any) => dotNumber === element.dotNumber ? {...element, open: open} : element)})),
    changeScrewSchemeStructure: (dotNumber, screw) => set((state) => ({ schemeStructure: state.schemeStructure.map((element: any) => dotNumber === element.dotNumber ? {...element, screw: screw} : element)})),
    changeSolvedSchemeStructure: (dotNumber, solved) => set((state) => ({ schemeStructure: state.schemeStructure.map((element: any) => dotNumber === element.dotNumber ? {...element, solved: solved} : element)})),
    changeCurrent: () => set((state) => ({ current: !state.current }))
  }));

  useEffect(() => {
    document.addEventListener("wheel", function(event){
      const active : any = document?.activeElement;
      if (active?.type && active?.type === "number") active?.blur();
      
  });
  }, []);

  // const currStore = useStore((state: any) => state);

  // useEffect(() => {
  //   console.log(currStore);
  // }, [currStore]);

  useEffect(() => {
    // For scrolling effects
  
    // (async () => {
    //     const cacheNames = await caches?.keys();
    //     cacheNames && cacheNames.forEach(cacheName => caches.delete(cacheName));
    // })();

    (async () => {
        if (!cookies["user-id"] || !cookies["pwd"]) {
            setData(false);
            setFinished(true);
            return;
        }
        
        const [userId, password] = [cookies["user-id"], cookies["pwd"]];
        console.log(cookies);
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                userId,
                hashedPassword: password
            })
        };

        try {

          const loginRequest = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login-remember`, options);
          const result = await loginRequest.json();
          if (!await result.success) {
              removeCookie('user-id');
              removeCookie('pwd');
              removeCookie('theme');
              setData(false);
              setFinished(true);

              return;
          }

          //if (await result.admin && navigation())
          console.log(await result);
          setData(await result);
          setIsAdmin(await result.admin);
          setFinished(true);

        } catch (e) {
          console.log(e);
        }

        // if (cookies?.theme === 'light') {

        //     document.documentElement.style.setProperty("--background", "#f7f7f7");
        //     document.documentElement.style.setProperty("--contrast-color", "rgb(46,128,251)");            
        //     document.documentElement.style.setProperty("--contrast-color-highlight", "rgb(53 110 196)");

        //     document.documentElement.style.setProperty("--contrast-color-2", "rgb(12 94 217)");
        //     document.documentElement.style.setProperty("--contrast-color-3", "#FF447C");
        //     document.documentElement.style.setProperty("--background-color", "#f5f5f5");
        //     document.documentElement.style.setProperty("--background-contrast", "rgba(0, 0, 0, .6)");
        //     document.documentElement.style.setProperty("--dark-color", "#666");
        //     document.documentElement.style.setProperty("--light-color", "#fff");
        //     document.documentElement.style.setProperty("--text-color", "#222");
        //     document.documentElement.style.setProperty("--text-color-2", "#000");
        //     document.documentElement.style.setProperty("--text-color-3", "#666");
        //     document.documentElement.style.setProperty("--text-color-4", "#999");
        //     document.documentElement.style.setProperty("--selector-color", "rgba(34, 184, 207, 0.5)");
        //     document.documentElement.style.setProperty("--over-color", "rgba(255, 255, 255, .2)");
        //     document.documentElement.style.setProperty("--over-color-2", "rgba(255, 255, 255, .6)");
        //     document.documentElement.style.setProperty("--over-difference-color", "rgba(0, 0, 0, .2)");
        //     document.documentElement.style.setProperty("--over-difference-color-2", "rgba(0, 0, 0, .075)");
        //     document.documentElement.style.setProperty("--over-difference-color-3", "linear-gradient(135deg, rgba(125, 125, 255, 0.18), rgba(125, 125, 255, .45))");
        //     document.documentElement.style.setProperty("--error-color", "crimson");
        //     document.documentElement.style.setProperty("--success-color", "#589858");
        //     document.documentElement.style.setProperty("--selected-color", "rgba(96, 61, 128, .973)");
        //     document.body.classList.add("light");
        // }
    })();

  }, []);

  useEffect(() => {
    // For scrolling effects

    if (data) return;
  
    (async () => {
        const cacheNames = await caches?.keys();
        cacheNames && cacheNames.forEach(cacheName => caches.delete(cacheName));
    })();

    // cookies.language && setLanguage(cookies.language);

    (async () => {
        if (!cookies["user-id"] || !cookies["pwd"]) {
            setData(false);
            return;
        }
        
        const [userId, password] = [cookies["user-id"], cookies["pwd"]];
        console.log(cookies);
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                userId,
                hashedPassword: password
            })
        };

        const loginRequest = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login-remember`, options);
        const result = await loginRequest.json();
        console.log(result);
        if (!await result.success) {
            removeCookie('user-id');
            removeCookie('pwd');
            removeCookie('theme');
            setData(false);
            return;
        }
        //if (await result.admin && navigation())
        console.log(await result);
        setData(await result);
        setIsAdmin(await result.admin);
      
    })();

  }, []);


  useEffect(() => {
    if (!process.env.REACT_APP_BACKEND_URL || !data) return;
    
    const socket = io(process.env.REACT_APP_BACKEND_URL);
    setSocket(socket);
    
    if (data.userData.type === "teacher") socket.emit("teacher-connect", data.userData.userId);
    else socket.emit("student-connect", data.userData.teacherId);

    // unsubscribe from event for preventing memory leaks
    return () => {
        socket.disconnect();
    };

  }, [data]);

  useEffect(() => {
    console.log(data, socket);
  }, [data, socket]);

  const exactProps = { exact: true };

  return data !== null && finished ? (
    <BrowserRouter>
      <Routes>
        {data && socket ? (
          <>
            <Route path="" element={<Home data={data} />} {...exactProps} />
            <Route path="categories" element={<Categories data={data} socket={socket} />} />
            <Route path="low-tension/:module" element={<Projects data={data} socket={socket} />} />
            <Route path="high-tension/:module" element={<HighTensionProjects data={data} socket={socket} />} />

            <Route path="project/:projectId/:currentPage" element={<Project useStore={useStore} data={data} />} />
            <Route path="predefined-scheme-debug/:schemeNumber" element={<PredefinedSchemeDebug useStore={useStore} data={data} problems={null} totalProblems={null} problemsSolved={null} notes="" isTeacher={true} />} />
            <Route path="predefined-scheme/:schemeNumber" element={<PredefinedScheme useStore={useStore} data={data} />} />
            <Route path="create-project" element={<CreateProject data={data} />} />
            <Route path="edit-project/:projectId" element={<EditProject data={data} />} />
            <Route path="students" element={<Students data={data} socket={socket} />} />
            <Route path="create-student" element={<CreateStudent data={data} />} />
            <Route path="edit-student/:studentId" element={<EditStudent data={data} />} />
            {/* <Route path="sessions" element={<Sessions data={data} socket={socket} /> } /> */}
            <Route path="session/:sessionId/leaderboard" element={<LeaderboardDebugSession data={data} socket={socket} />} />
            <Route path="session/:sessionId/:schemeNumber/:join" element={<DebugSession data={data} socket={socket} useStore={useStore} />} />
            <Route path="options-testing/:optionParam" element={<OptionsTesting data={data} socket={socket} />} />
            <Route path="options-learning/:optionParam" element={<OptionsLearning data={data} socket={socket} />} />
            
            <Route path="session-testing/:sessionId/leaderboard" element={<LeaderboardTestingSession data={data} socket={socket} sessionName="" />} />
            <Route path="session-testing/:sessionId/:join" element={<TestingSession data={data} socket={socket} useStore={useStore} />} />

            <Route path="session-learning/:sessionId/leaderboard" element={<LeaderboardLearningSession data={data} socket={socket} sessionName="" />} />
            <Route path="session-learning/:sessionId/:join" element={<LearningSession data={data} socket={socket} useStore={useStore} />} />

            {/* <Route path="session/:sessionId/:schemeNumber" element={<DebugSession data={data} socket={socket} useStore={useStore} />} /> */}

            <Route path="training/:schemeNumber" element={<Training data={data} />} />
            {/* <Route path="testing/:schemeNumber" element={<Testing data={data} />} /> */}
            <Route path="create-account" element={<CreateAccount data={data} />} />
            <Route path="*" element={ <Navigate to="/" /> } />
          </>
        
        ) : (   
          <>     
            <Route path="" element={<Home data={data} />} {...exactProps} />
            <Route path="login" element={<Login />} />
            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
          </>
        )}

        {/* <Route path="*" element={<Login />} /> */}


        {/* <Route path="*" element={<Navigate to="/" />} /> */}

       
      </Routes>
    </BrowserRouter>
  ) : <div></div>;
}

export default App;
