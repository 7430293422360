import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LeaderboardDebugSession from "../LeaderboardDebugSession/LeaderboardDebugSession";
import PredefinedScheme from "../PredefinedScheme/PredefinedScheme";
import PredefinedSchemeDebug from "../PredefinedSchemeDebug/PredefinedSchemeDebug";
import "./TestingSession.css";
import "../DebugSession/DebugSession.css";
import Testing from "../Testing/Testing";
import LeaderboardTestingSession from "../LeaderboardTestingSession/LeaderboardTestingSession";


const TestingSession = ({ data, socket, useStore } : { data: any, socket: any, useStore: any }) => {
    const { sessionId, join } = useParams();

    const joinNumber = useMemo(() => Number(join), []);
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [schemeName, setSchemeName] = useState<string>("");
    const [logs, setLogs] = useState<string>("");
    const [leaderboard, setLeaderboard] = useState([]);
    const [totalProblems, setTotalProblems] = useState([]);
    const [problemsSolved, setProblemsSolved] = useState([]);
    const [endSessionAnimation, setEndSessionAnimation] = useState<boolean>(false);
    const [animationTextSize, setAnimationTextSize] = useState<number>(50);
    const animationRef = useRef<any>();
    const [startAnimation, setStartAnimation] = useState<boolean>(false);
    const [cell, setCell] = useState();
    const [semiStation, setSemiStation] = useState();
    const [scenario, setScenario] = useState();

    const location = useLocation();
    const sessionName = location?.state?.sessionName || window.history.state?.sessionName;
    // if (!sessionName) navigate(-1);
    console.log(window.history);

    let times = useMemo(() => 0, []);

    const getJoinedSession = async () => {
        try {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/join-session-student-testing/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${data.userData.userId}/${sessionId}`, options);
            const response = await request.json();
            
            if (response?.alreadyEntered) {
                setStartAnimation(false);
                setEndSessionAnimation(true);
            }
            else setStartAnimation(true);
            times++;

        } catch (e) {
            console.log(e);
        }
    };

    useLayoutEffect(() => {
        if (times > 0) return;
        // if (data.userData.type !== "student") return;
        getJoinedSession().catch(err => console.log(err));
    }, []);
    
    useLayoutEffect(() => {
        if (!animationRef?.current || !startAnimation) return;
        // setAnimationTextSize(70);
        const timer = setTimeout(() => {
            console.log(animationRef?.current);
            if (!animationRef?.current || !startAnimation) return;
            // setAnimationTextSize(1);
            animationRef.current.innerText = '2';
            setAnimationTextSize(50);
            setTimeout(() => {
                // setAnimationTextSize(1);
                animationRef.current.innerText = '1';
                setAnimationTextSize(50);
                setTimeout(() => {
                    // setAnimationTextSize(1)
                    setAnimationTextSize(20);
                    animationRef.current.innerText = 'Start';
                    setTimeout(() => {
                        setEndSessionAnimation(true);
                        // setTimeout(() => window.location.reload(), 500);
                    }, 750);
                }, 500);
            }, 500);
        }, 500);

        return () => {
            clearTimeout(timer);
        };

    }, [startAnimation]);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/check-id-testing/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();
                // alert(JSON.stringify(response));
                if (!response.success) return navigate(-1);
                console.log(response);
                const currResResults = response.results;
                const currResults = JSON.parse(currResResults.results);
                const userResults = currResults.find((result : { userId: string }) => result.userId === data.userData.userId);
                if (!userResults) return;
                setProblemsSolved(userResults.problemsSolved);
                setProblems(JSON.parse(currResResults.problems).filter((problem : any) => !userResults.problemsSolved.includes(problem.point)));
                setTotalProblems(JSON.parse(currResResults.problems));
                setLogs(userResults?.logs);
                console.log(userResults?.logs);
                setSchemeName(currResResults?.schemeName || "");
                setSemiStation(currResResults?.semiStation);
                setCell(currResResults?.cell);
                setScenario(currResResults?.scenario);

            } catch (e) {
                console.log(e);
            }
        })(); 

        
    }, []);

    useEffect(() => {
        if (data.userData.type !== "teacher") return;
        socket.on("update-results-testing", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/get-results-of-session-testing/${data.userData.userId}/${sessionId}`, options);
                const response = await request.json();

                if (response.success) setLeaderboard(response.results);
            } catch (e) {
                console.log(e);
            }
        });

    }, []);

    return data.userData.type === "teacher" && !joinNumber ? (
        <LeaderboardTestingSession data={data} socket={socket} sessionName={sessionName} />
    ) : endSessionAnimation && schemeName.length ? (
        <Testing data={data} scenario={scenario} problemsSolved={problemsSolved} totalProblems={totalProblems} problems={problems} logs={logs} schemeName={schemeName} sessionName={sessionName} semiStation={semiStation} cell={cell}  />
    ) : (
        <div className="animation-container">
            <h1 ref={animationRef} style={{ fontSize: `${animationTextSize}vmax` }}>3</h1>
        </div>
    );
};

export default TestingSession;