import { faFileLines, faPlus, faTrashCan, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "../DebugSessions/DebugSessions.css";

const TestingSessions = ({ data, socket, finished } : { data: any, socket: any, finished: boolean }) => {
   
    const navigate = useNavigate();
    const [category, setCategory] = useState("projects");
    const location = useLocation();
    const [cookies, setCookie] = useCookies();
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/get-sessions-testing/${data.userData?.type === "student" ? data.userData.teacherId : data.userData.userId}`, options);
                const response = await request.json();
                console.log(response);
                if (response.success) {
                    setSessions(response.sessions.filter((session : any) => session.finished == finished && (!JSON.parse(session.results).some((result : any) => result.userId === data.userData.userId && result.finished) || finished)));
                } else console.log(response);
            } catch (e) {
                console.log(e);
            }
            
        })();
    }, []);

    const removeSession = async (sessionId : string, e : any) => {
        if (data.userData.type !== "teacher") return;
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/remove-session-testing/${data.userData.userId}/${sessionId}`, options);
            const response = await request.json();
            console.log(response);
            if (response.success) window.location.reload();
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };

    return sessions ? (
        <div className="content">

            {sessions?.length ?
                <div className="projects-container">

                    {sessions.map((session : { schemeName: string, semiStation: string, sessionId: string, number: number, cell: string, scenario: string }, idx : number) => (
                        <div className="project-gradient" key={idx} onClick={(e : any) => e.target.tagName !== "svg" && e.target.tagName !== "path" ? navigate(`/session-testing/${session.sessionId}/0`, { state: { sessionName: `${cookies?.language === "en" ? "Session" : "Sesiune"} ${session.schemeName} ${session.cell === "*" ? session.semiStation : session.cell} - ${session.scenario === "shock" ? (cookies?.language === "en" ? "Shock" : "Electrocutare") : (cookies?.language === "en" ? "Fire" : "Incendiu")} (${idx})` } }) : null}>
                            <div className="project">
                                <h2>{cookies?.language === "en" ? "Session" : "Sesiune"} {session.schemeName} {session.cell === "*" ? session.semiStation : session.cell} {"-"} {session.scenario === "shock" ? (cookies?.language === "en" ? "Shock" : "Electrocutare") : (cookies?.language === "en" ? "Fire" : "Incendiu")} ({idx})</h2>
                                {/* 
                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>{session?.format || "A4"}</p>
                                </div> */}

                                {data.userData.type === "teacher" ? <FontAwesomeIcon icon={faTrashCan} className="delete-icon" onClick={async (e : any) => await removeSession(session.sessionId, e)} /> : null}
                                
                            </div>
                        </div>
                    ))}
                </div>
            : 
                <div className="empty-container">
                    <h2>{cookies?.language === "en" ? "No sessions created." : "Nu au fost create sesiuni."}</h2>
                </div>
            }

            {/* <div className="add-button" onClick={() => navigate("/create-session")}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
            </div> */}


        </div>

    ) : <h1>Processing...</h1>;
};

export default TestingSessions;