import { faBuilding, faBuildingUser, faChalkboardTeacher, faChalkboardUser, faEnvelope, faLock, faSchool, faUser, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "../Login/Login.css";
import "./CreateAccount.css";

const CreateAccount = ({ data } : { data : any }) => {


    const [cookies, setCookie] = useCookies();
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();
    
    const [inputs, setInputs] = useState({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      school: "",
      type: "teacher",
      password: "",
      department: "",
      teacherUsername: "",
      modules: JSON.parse(data.userData?.modules || "0") || ["edit", "functional", "debug", "learn", "train", "test"]
    });

    // useEffect(() => {
    //     const sign_in_btn : any = document.querySelector("#sign-in-btn");
    //     const sign_up_btn : any = document.querySelector("#sign-up-btn");
    //     const container : any = document.querySelector(".container");
    
    //     sign_up_btn.addEventListener("click", () => {
    //         resetStates();
    //         container.classList.add("sign-up-mode");
    //     });
    
    //     sign_in_btn.addEventListener("click", () => {
    //         resetStates();
    //         container.classList.remove("sign-up-mode");
    //     });
    // }, []);

    const resetStates = () => {
      setInputs({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        school: "",
        type: "teacher",
        password: "",
        department: "",
        teacherUsername: "",
        modules: ["edit", "functional", "debug", "learn", "train", "test"]
      });
    };

    const getTeacherId = async () => {
        try {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/get-teacher-id/${inputs.teacherUsername}`, options);
            const response = await request.json();
            if (response.success) return await response.teacherId;
            return null;
        } catch (e) {
            console.log(e);
        }

    };


    const signup = async (e : MouseEvent) => {
      e.preventDefault();

      setError("");
      if (!inputs.firstName.length || !inputs.lastName.length || !inputs.username.length || !inputs.email.length || !inputs.password.length) 
        return setError("Completati campurile obligatorii!");
      const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!inputs.email.match(emailValidation)) 
        return setError("Email-ul nu este valid!");
      if (inputs.password.length < 8)
        return setError("Parola trebuie sa contina minim 8 caractere");


      try {

        const teacherId = await getTeacherId();

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: inputs.username,
            password: inputs.password,
            firstName: inputs.firstName, 
            lastName: inputs.lastName, 
            school: inputs.school, 
            email: inputs.email, 
            type: inputs.type, 
            active: null,
            department: inputs.department,
            teacherId,
            modules: inputs.modules
          })
        };

        const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/signup`, options);
        const response = await request.json();
        if (response.success) {
          window.location.replace("/projects");
        } else setError(response.message.ro);
      } catch (e) {
        console.log(e);
      }
    };

    const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      setInputs(currInputs => {
        return {
          ...currInputs,
          [e.target.name]: e.target.value
        };
      });
    };
    
    useEffect(() => {
        if (!data.userData.admin) navigate(-1);
    }, []);

    
    return (
        <div className="container sign-up-mode">
          <div className="forms-container">
            <div className="signin-signup">
              
              <form action="#" className="sign-up-form">
                <h2 className="title">Creează un cont</h2>

                <div className="inputs">
                <div className="input-switch">
                  <h3>Care este profesia ta?</h3>
                    <div className="categories">
                      <div className="category active" onClick={(e : any) => {
                        document.querySelectorAll(".category")[0].classList.add("active");
                        document.querySelectorAll(".category")[1].classList.remove("active");
                        setInputs(currInputs => {
                          return {...currInputs, 
                            type: "teacher"
                          };
                        });
                      }}>
                        <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} />
                        <p>Profesor</p>
                      </div>

                      <div className="category" onClick={(e : any) => {
                        document.querySelectorAll(".category")[1].classList.add("active");
                        document.querySelectorAll(".category")[0].classList.remove("active");
                        setInputs(currInputs => {
                          return {...currInputs, 
                            type: "student"
                          };
                        });
                      }}>
                        <FontAwesomeIcon className="category-icon" icon={faUserGraduate} />
                        <p>Elev</p>
                      </div>
                    </div>
                 
                </div>

  
                <div className="input-field">
                  <FontAwesomeIcon icon={faUser} className="input-icon"/>
                  <input type="text" name="firstName" value={inputs.firstName} onChange={handleInputChange} placeholder="Nume *" />
                </div>

                <div className="input-field">
                  <FontAwesomeIcon icon={faUser} className="input-icon"/>
                  <input type="text" name="lastName" value={inputs.lastName} onChange={handleInputChange} placeholder="Prenume *" />
                </div>

                <div className="input-field">
                  <FontAwesomeIcon icon={faUser} className="input-icon"/>
                  <input type="text" name="username" value={inputs.username} onChange={handleInputChange} placeholder="Nume de utilizator *" />
                </div>

                {inputs.type === "student" ? <div className="input-field">
                  <FontAwesomeIcon icon={faChalkboardTeacher} className="input-icon"/>
                  <input type="text" name="teacherUsername" value={inputs.teacherUsername} onChange={handleInputChange} placeholder="Nume de utilizator Profesor *" />
                </div> : null}

                <div className="input-field">
                    <FontAwesomeIcon icon={faEnvelope} className="input-icon"/>
                    <input type="email" name="email" value={inputs.email} onChange={handleInputChange} placeholder="Email *" />
                </div>

                <div className="input-field">
                  <FontAwesomeIcon icon={faLock} className="input-icon"/>
                  <input type="password" name="password" value={inputs.password} onChange={handleInputChange} placeholder="Parola *" />
                </div>

                <div className="input-field">
                  <FontAwesomeIcon icon={faSchool} className="input-icon"/>
                  <input type="text" name="school" value={inputs.school} onChange={handleInputChange} placeholder="Scoala / companie" />
                </div>

                <div className="input-field">
                  <FontAwesomeIcon icon={faBuildingUser} className="input-icon"/>
                  <input type="text" name="department" value={inputs.department} onChange={handleInputChange} placeholder="Departament" />
                </div>

                <div className="input-switch">
                  <h3>{cookies?.language === "en" ? "Modules access" : "Access module"}</h3>
                    <div className="categories flex-column">
                      <div className={`category ${inputs?.modules?.includes("edit") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("edit") ? currInputs.modules.filter((input : string) => input !== "edit") : [...currInputs.modules, "edit"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Edit Scheme (Low Tension)" : "Editare Schema (Joasa Tensiune)"}</p>
                      </div>

                      <div className={`category ${inputs?.modules?.includes("functional") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("functional") ? currInputs.modules.filter((input : string) => input !== "functional") : [...currInputs.modules, "functional"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Functional Scheme (Low Tension)" : "Functionare Scheme (Joasa Tensiune)"}</p>
                      </div>

                      <div className={`category ${inputs?.modules?.includes("debug") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("debug") ? currInputs.modules.filter((input : string) => input !== "debug") : [...currInputs.modules, "debug"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Debugging Scheme (Low Tension)"  : "Depanare Schema (Joasa Tensiune)"}</p>
                      </div>

                      <div className={`category ${inputs?.modules?.includes("learn") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("learn") ? currInputs.modules.filter((input : string) => input !== "learn") : [...currInputs.modules, "learn"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Learning (High Tension)" : "Invatare (Inalta Tensiune)"}</p>
                      </div>

                      <div className={`category ${inputs?.modules?.includes("train") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("train") ? currInputs.modules.filter((input : string) => input !== "train") : [...currInputs.modules, "train"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Training (High Tension)" : "Antrenare (Inalta Tensiune)"}</p>
                      </div>

                      <div className={`category ${inputs?.modules?.includes("test") ? "active" : ""}`} onClick={(e : any) => {
                        let target = e.target;
                        while (!target.classList.contains("category")) target = target.parentElement;
                        setInputs(currInputs => {
                          return {...currInputs, 
                            modules: currInputs.modules.includes("test") ? currInputs.modules.filter((input : string) => input !== "test") : [...currInputs.modules, "test"]
                          };
                        });
                      }}>
                        {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                        <p>{cookies?.language === "en" ? "Testing (High Tension)" : "Testare (Inalta Tensiune)"}</p>
                      </div>


                    </div>
                 
                </div>

            
                </div>
                <input type="submit" className="btn" value="Sign up" onClick={async (e : any ) => await signup(e)} />
                {error.length ? <p className="error">{error}</p> : null}
                {/* <p className="social-text">Or Sign up with social platforms</p> */}
                {/* <div className="social-media">
                  <a href="#" className="social-icon">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-google"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div> */}
              </form>
            </div>
          </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Ești nou aici?</h3>
            <p>
              Descoperă ușurință de a crea scheme electrice cu ZenVolt, unde posibilitățile sunt limitate doar de imaginația ta.
            </p>
            <button className="btn transparent" id="sign-up-btn">
              Sign up
            </button>
          </div>
          {/* <img src="img/log.svg" className="image" alt="" /> */}
        </div>
        <div className="panel right-panel">
          <div className="content">
           
          </div>
          {/* <img src="img/register.svg" className="image" alt="" /> */}
        </div>
      </div>
    </div>
    );
};

export default CreateAccount;