import { faBuilding, faBuildingUser, faChalkboardUser, faDoorOpen, faEnvelope, faLock, faSchool, faUser, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import "../Login/Login.css";

const EditStudent = ({ data } : { data: any }) => {

  const [cookies, setCookie] = useCookies();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const { studentId } = useParams();
  if (!studentId || data.userData.type === "student") navigate(-1);

  const [inputs, setInputs] = useState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        type: "student",
        teacherId: data.userData.userId,
        school: data.userData?.school || "",
        department: data.userData?.department || "",
        grade: "",
        modules: [...JSON.parse(data.userData.modules)] || ["edit", "functional", "debug", "learn", "train", "test"]
    
  });
  
  useEffect(() => {
    console.log(inputs.modules);
    // setInputs((currValues) => {
    //   return {...currValues, modules: [...currValues.modules]}
    // });
  }, [inputs]);

    useEffect(() => {
      (async () => {
        try {
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          };
          const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/teacher/get-students/${data.userData.userId}`, options);
          const response = await request.json();
          if (response.success) {
            const currentStudent = response.students.find((student: any) => student.userId === studentId);
            if (currentStudent) setInputs(currInputs => {
              return {...currInputs,
                username: currentStudent.username,
                firstName: currentStudent.firstName,
                lastName: currentStudent.lastName,
                email: currentStudent.email,
                teacherId: data.userData.userId,
                school: currentStudent?.school || "",
                department: currentStudent?.department || "",
                grade: currentStudent.grade,
                modules: [...JSON.parse(currentStudent?.modules)] || ["edit", "functional", "debug", "learn", "train", "test"]
              };
          });
          } else console.log(response);
        } catch (e) {
          console.log(e);
        }
      })();
    }, []);

    const resetStates = () => {
      setInputs({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        type: "student",
        teacherId: data.userData.userId,
        school: data.userData?.school || "",
        department: data.userData?.department || "",
        grade: "",
        modules: data.userData?.modules || ["edit", "functional", "debug", "learn", "train", "test"]
      });
    };

    const signup = async (e : MouseEvent) => {
      e.preventDefault();

      setError("");
      if (!inputs.firstName.length || !inputs.lastName.length || !inputs.username.length || !inputs.email.length) 
        return setError("Completati campurile obligatorii!");
      const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!inputs.email.match(emailValidation)) 
        return setError("Email-ul nu este valid!");



      try {
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: inputs.username,
            firstName: inputs.firstName, 
            lastName: inputs.lastName, 
            email: inputs.email, 
            type: inputs.type, 
            active: null,
            teacherId: inputs.teacherId,
            department: inputs.department, 
            school: inputs.school,
            grade: inputs.grade,
            studentId: studentId,
            modules: inputs.modules
          })
        };

        const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/teacher/edit-student`, options);
        const response = await request.json();
        if (response.success) {
        //   setCookie("user-id", response.userId);
        //   setCookie("pwd", response.hashedPassword);
          
        //   window.history.replaceState({
        //     page: "projects"
        //   }, "");
          window.location.replace("/students");
        } else setError(response.message.ro);
      } catch (e) {
        console.log(e);
      }
    };

    const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      setInputs(currInputs => {
        return {
          ...currInputs,
          [e.target.name]: e.target.value
        };
      });
    };
    
    return (
        <div className="container">
            <div className="forms-container">
                <div className="signin-signup">
                
                    <form action="#" className="sign-in-form">
                        <h2 className="title">Editează un cont de elev</h2>

                        <div className="inputs">

                            <div className="input-field">
                            <FontAwesomeIcon icon={faUser} className="input-icon"/>
                            <input type="text" name="firstName" value={inputs.firstName} onChange={handleInputChange} placeholder="Nume *" />
                            </div>

                            <div className="input-field">
                            <FontAwesomeIcon icon={faUser} className="input-icon"/>
                            <input type="text" name="lastName" value={inputs.lastName} onChange={handleInputChange} placeholder="Prenume *" />
                            </div>

                            <div className="input-field">
                            <FontAwesomeIcon icon={faUser} className="input-icon"/>
                            <input type="text" name="username" value={inputs.username} onChange={handleInputChange} placeholder="Nume de utilizator *" />
                            </div>

                            <div className="input-field">
                                <FontAwesomeIcon icon={faEnvelope} className="input-icon"/>
                                <input type="email" name="email" value={inputs.email} onChange={handleInputChange} placeholder="Email *" />
                            </div>


                            <div className="input-field">
                              <FontAwesomeIcon icon={faSchool} className="input-icon"/>
                              <input type="text" name="school" value={inputs.school} onChange={handleInputChange} placeholder="Scoala" />
                            </div>

                            <div className="input-field">
                              <FontAwesomeIcon icon={faDoorOpen} className="input-icon"/>
                              <input type="text" name="grade" value={inputs.grade} onChange={handleInputChange} placeholder="Clasa" />
                            </div>

                            <div className="input-switch">
                            <h3>{cookies?.language === "en" ? "Modules access" : "Access module"}</h3>
                              <div className="categories flex-column">
                                <div className={`category ${inputs?.modules?.includes("edit") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    console.log(currInputs.modules);
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("edit") ? currInputs.modules.filter((input : string) => input !== "edit") : [...currInputs.modules, "edit"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Edit Scheme (Low Tension)" : "Editare Schema (Joasa Tensiune)"}</p>
                                </div>

                                <div className={`category ${inputs?.modules?.includes("functional") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("functional") ? currInputs.modules.filter((input : string) => input !== "functional") : [...currInputs.modules, "functional"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Functional Scheme (Low Tension)" : "Functionare Scheme (Joasa Tensiune)"}</p>
                                </div>

                                <div className={`category ${inputs?.modules?.includes("debug") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("debug") ? currInputs.modules.filter((input : string) => input !== "debug") : [...currInputs.modules, "debug"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Debugging Scheme (Low Tension)"  : "Depanare Schema (Joasa Tensiune)"}</p>
                                </div>

                                <div className={`category ${inputs?.modules?.includes("learn") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("learn") ? currInputs.modules.filter((input : string) => input !== "learn") : [...currInputs.modules, "learn"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Learning (High Tension)" : "Invatare (Inalta Tensiune)"}</p>
                                </div>

                                <div className={`category ${inputs?.modules?.includes("train") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("train") ? currInputs.modules.filter((input : string) => input !== "train") : [...currInputs.modules, "train"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Training (High Tension)" : "Antrenare (Inalta Tensiune)"}</p>
                                </div>

                                <div className={`category ${inputs?.modules?.includes("test") ? "active" : ""}`} onClick={(e : any) => {
                                  let target = e.target;
                                  while (!target.classList.contains("category")) target = target.parentElement;
                                  setInputs(currInputs => {
                                    return {...currInputs, 
                                      modules: currInputs.modules.includes("test") ? currInputs.modules.filter((input : string) => input !== "test") : [...currInputs.modules, "test"]
                                    };
                                  });
                                }}>
                                  {/* <FontAwesomeIcon className="category-icon" icon={faChalkboardUser} /> */}
                                  <p>{cookies?.language === "en" ? "Testing (High Tension)" : "Testare (Inalta Tensiune)"}</p>
                                </div>


                              </div>
                 
                
                            </div>
                          </div>
                        <input type="submit" className="btn" value={cookies?.language === "en" ? "Save Changes" : "Salveaza Modificarile"} onClick={async (e : any ) => await signup(e)} />
                        {error.length ? <p className="error">{error}</p> : null}
                        
                    </form>
                </div>
            </div>

        </div>
    );
};

export default EditStudent;