import SRS from "../SRS/SRS";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import "./Learning.css";
import "../Testing/Testing.css";
import generatorImage from "../../imgs/generator2.svg";
import generatorInchis from "../../imgs/generator-inchis.svg";
import statie from "../../imgs/statie.svg";
import warningSoundSrc from "../../sounds/warning.mp3";
import errorSoundSrc from "../../sounds/real_error.mp3";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBookOpen, faCheck, faComment, faFlagCheckered, faMicrophone, faPrint, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import electrocutareColeg from "../../imgs/electrocutare_coleg.gif";
import MicRecorder from "mic-recorder-to-mp3";
import pageAudio from "../../sounds/page_flip.mp3";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const Learning = ({ data, problemsSolved, currentScenario, totalProblems, problems, logs, schemeName, sessionName, cell, semiStation, dispatcher, scenarios, time, savedElements, manoeuverFile, sessionIdx }) => {

    const intreruptorArr = useMemo(() => [], []);

    const [startSession, setStartSession] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [errorSound, setErrorSound] = useState();
    const [warningSound, setWarningSound] = useState();
    const errorSoundRef = useRef();
    const warningSoundRef = useRef();
    const { sessionId } = useParams();
    const navigate = useNavigate();

    const [targetElements, setTargetElements] = useState();
    const [currentLogs, setCurrentLogs] = useState(logs || []);
    const [currentProblemsSolved, setCurrentProblemsSolved] = useState(problemsSolved || []);
    const [currentProblems, setCurrentProblems] = useState(problems);
    const [studentText, setStudentText] = useState("");
    const [audioResponse, setAudioResponse] = useState("");
    const [currentTime, setCurrentTime] = useState(Number(time));
    const [currentScenarios, setCurrentScenarios] = useState(scenarios);
    const [cookieSavedElements, setCookieSavedElements] = useState(savedElements || []);
    const [currentManoeuverFile, setCurrentManoeuverFile] = useState(manoeuverFile);
    const [reverseIdx, setReverseIdx] = useState(-1); // Check when manoeuver is reversing
    const [fileManoeuverActive, setFileManoeuverActive] = useState(true);
    const [activeBox, setActiveBox] = useState(false);
    const [sessionFinished, setSessionFinished] = useState(false);
    const [realCookiesSaved, setRealCookiesSaved] = useState(savedElements);


    const nextPageRef = useRef(null);
    const prevPageRef = useRef(null);

    const page1Ref = useRef(null);
    const page2Ref = useRef(null);
    const bookRef = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);

    
    // Mic Recorder Setup

    const Mp3Recorder = new MicRecorder({ bitRate: 128 });

    useEffect(() => {
        if (!errorSoundRef?.current) return;
        setErrorSound(errorSoundRef.current);
    }, [errorSoundRef]);

    useEffect(() => {
        if (!warningSoundRef?.current) return;
        // alert("A");
        setWarningSound(warningSoundRef.current);
    }, [warningSoundRef]);

    const errorJSON = (elem) => {
        try {
            console.log(JSON.stringify(elem));
        } catch (e) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (!window.history.state?.start) {
            window.history.replaceState({
                start: true
            }, "");
            
            window.location.reload();
        }
    }, []);

 
    // useLayoutEffect(() => {
    //     (async () => {

    //         console.log(currentProblemsSolved.filter(problemArr => !errorJSON(problemArr)), currentLogs, sessionId);
    
    //         try {
    //             const options = {
    //                 method: "PUT",
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 },
    //                 body: JSON.stringify({
    //                     currLogs: currentLogs,
    //                     studentId: data.userData.userId,
    //                     teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
    //                     studentId: data.userData.userId,
    //                     sessionId: sessionId,
    //                     problemsSolved: currentProblemsSolved.filter(problemArr => !errorJSON(problemArr)),
    //                 })
    //             };

    //             const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/edit-session-student`, options);
    //             const response = await request.json();
    //             if (response.success) {
    //                 console.log("SUCCESS", currentLogs);
    //             } else console.log(response);
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     })();
    // }, [currentLogs, currentProblemsSolved]);


    let inEffect = useMemo(() => false, []);

    useEffect(() => {
        if (inEffect) return;
        setTargetElements(problems.map(arrProblem => {
            return arrProblem.map(problem => {
                if (problem.number) return document.querySelector(`.${problem.name}[number="${problem.number}"]`);
                return document.querySelector(`.${problem.name}`);
            });
        }));

        inEffect = true;
    }, []);

    let currentElements;

    useEffect(() => {
        console.log(targetElements);
    }, [targetElements]);

    const realTargetElements = useMemo(() => targetElements, [targetElements]);
    
    const googleAudioRef = useRef(null);
    const [googleAudioUrl, setGoogleAudioUrl] = useState("");

    // useEffect(() => {
    //     if (!googleAudioRef?.current) return;
    //     googleAudioRef.current.play().catch((error) => {
    //         document.addEventListener('click', () => {
    //             googleAudioRef.current.play();
    //         }, { once: true } );

    //         // document.documentElement.click();
    //     });

    // }, [googleAudioRef]);

    // useEffect(() => {
    //     if (!googleAudioUrl?.length) return;
    //     console.log("T");
    //     document.querySelector(".tst").click();
    // }, [googleAudioUrl]);

    const startButton = (e) => {
        if (startSession) return;
        try {
            createSessionScript();
            // e?.target?.parentElement?.classList?.remove("active");
            const audio = new Audio(googleAudioUrl);
            audio.play();
            
            currService.currentAudio = audio;
            audio.addEventListener("loadeddata", () => {
                currService.duration = audio.duration;
            });

            setFileManoeuverActive(false);

            setStartSession(true);

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        console.log(manoeuverFile);
        if (!manoeuverFile?.length) createManoeuverFile();
        // if (Boolean(cookies?.[`accept-${sessionId}`])) startButton();
    }, []);

    useEffect(() => {
        if (reverseIdx !== -1) return;
        let idx = -1;
        currentManoeuverFile.forEach((scenario, currIdx) => {
            if (scenario?.reverse && idx === -1) idx = currIdx;
        });
        console.log(idx);
        setReverseIdx(idx - 1);
    }, [currentManoeuverFile]);

    let currService = { currentAudio : null, duration: 0 };
    
    const textToSpeech = async (text, playAudio = false) => {
        if (!dispatcher) return;
        // if (!googleAudioRef?.current) return;
        try {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            };
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/text-to-speech/${data.userData.userId}/${text}`, options);
            const blob = await response.blob();

            // Create a Blob object from the fetched audio data
            const audioBlob = new Blob([blob], { type: 'audio/wav' });
            // Create a URL for the Blob object
            const audioUrl = URL.createObjectURL(audioBlob);
            console.log(audioUrl);

            // Create an audio element and set the URL as its source
            // const audio = new Audio(audioUrl);
            // console.log(audio);
            // Play the audio
            setGoogleAudioUrl(audioUrl);
            // googleAudioRef.current.src = audioUrl

            if (playAudio) {
                const audio = new Audio(audioUrl);
                audio.play();
                currService.currentAudio = audio;
                audio.addEventListener("loadeddata", () => {
                    currService.duration = audio.duration;
                });
              
            }
            
        // Create a download link and click it to initiate the download
        
        } catch (e) {
            console.log(e);
        }
    };

    const isPlayingAudio = () => {
        if (!currService?.currentAudio) return false;
        return currService.currentAudio
            && currService.currentAudio.currentTime > 0
            && !currService.currentAudio.paused
            && !currService.currentAudio.ended;
    };

    useEffect(() => {
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                teacherId: data.userData.teacherId, 
                studentId: data.userData.userId, 
                problemsSolved: problemsSolved, 
                currLogs: currentLogs, 
                sessionId: sessionId, 
                time: currentTime,
                scenarios: currentScenarios,
                savedElements: realCookiesSaved,
                manoeuverFile: currentManoeuverFile
            })
        };

        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/edit-session-student`, options)
        .then(req => req.json())
        .then(res => {
            // if (!window.history.state?.start && currentManoeuverFile?.length) { 
            //     window.history.replaceState({
            //         start: true
            //     }, "");
            //     window.location.reload();
            // }

        })
        .catch(error => {
            console.log(error);
        });
    }, [currentTime, currentLogs, currentScenarios, realCookiesSaved, currentManoeuverFile]);

    // Timer updating

    useEffect(() => {
        const interval = setInterval(() => {
            if (document.visibilityState === 'visible') {
                setCurrentTime(curr => curr + 1);
            }
            
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const pageAudioRef = useRef();

    // Events for manoeuver file
    
    useEffect(() => {

        if (!prevPageRef?.current || !nextPageRef?.current || !bookRef?.current || !page1Ref?.current || !page2Ref?.current || !pageAudioRef?.current) return;

        const openBook = () => {
            bookRef.current.style.transform = "translateX(50%)";
            // prevPageRef.current.style.transform = "translateX(-100%)";
            // nextPageRef.current.style.transform = "translateX(100%)";

            prevPageRef.current.style.left = `20px`;
            nextPageRef.current.style.right = `20px`;
        };
    
        const closeBook = (isAtBeginning) => {
            if (isAtBeginning) {
                bookRef.current.style.transform = "translateX(0%)";
            } else {
                bookRef.current.style.transform = "translateX(100%)";
            }

            prevPageRef.current.style.left = `25%`;
            nextPageRef.current.style.right = `25%`;
        };


        const goPrevPage = () => {
            if (currentPage <= 1) {
                return;
            }

            pageAudioRef.current.play();

            switch (currentPage) {
                case 2:
                    closeBook(true);
                    page1Ref.current.classList.remove("flipped");
                    page1Ref.current.style.zIndex = 3;
                    prevPageRef.current.classList.add("hide");
                    break;
                case 3:
                    page2Ref.current.classList.remove("flipped");
                    page2Ref.current.style.zIndex = 2;
                    nextPageRef.current.classList.remove("hide");
                    break;
                default:
                    throw new Error("unknown state");
            }

            setCurrentPage(curr => curr - 1);
        };

        const goNextPage = () => {
            if (currentPage > 2) return;

            pageAudioRef.current.play();

            switch (currentPage) {
                case 1: 
                    openBook();
                    page1Ref.current.classList.add("flipped");
                    page1Ref.current.style.zIndex = 1;
                    prevPageRef.current.classList.remove("hide");
                    break;
                case 2:
                    page2Ref.current.classList.add("flipped");
                    page2Ref.current.style.zIndex = 2;
                    nextPageRef.current.classList.add("hide");
                    break;
                default:
                    throw new Error("unknown state");
            }

            setCurrentPage(curr => curr + 1);
        };

        prevPageRef.current.addEventListener("click", goPrevPage);
        nextPageRef.current.addEventListener("click", goNextPage);

        return () => {
            prevPageRef?.current?.removeEventListener("click", goPrevPage);
            nextPageRef?.current?.removeEventListener("click", goNextPage);
        };
    }, [currentPage, nextPageRef, prevPageRef, bookRef, page1Ref, page2Ref, pageAudioRef]);
    

    const [audioBlocked, setAudioBlocked] = useState(false);
    const [isRecordingAudio, setIsRecordingAudio] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const startRecordingAudio = async () => {

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream, { audioBitsPerSecond: 128000 });
            let chunks = [];
            recorder.ondataavailable = e => {
                chunks.push(e.data);
               
            };

            recorder.onstop = async () => {

                if (studentText?.length) {
                    const blob = new Blob(chunks, { type: 'audio/mp3' });
                    const formData = new FormData();
                    formData.append('audio', blob, 'audio_data.mp3');
                    console.log(blob);
                    const options = {
                        method: "POST",
                       
                        body: formData
                    };
        
            
                    const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/verify-audio/${scripts[0]?.student}`, options)
                    const response = await request.json();
                    const correct = response.correct;
                    console.log("Correct: ", correct);
                    if (correct) {
                        setCurrentLogs(curr => [...curr, { type: "correct", script: scripts[0]?.teacher }]);
                    }
                    await verifyAudio(correct);
                }
           

                chunks = []; // Reset chunks for new recordings
            };

            setMediaRecorder(recorder);
            setIsRecordingAudio(true);
            recorder.start();
        } catch (e) {
            console.log(e);
        }
        
        
    };

    const finishRecordingAudio = async () => {
    
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            setIsRecordingAudio(false);
        }
    };

    const [scripts, setScripts] = useState([]);
    const [isOpen, setIsOpen] = useState(-1);
    const [verifyManoeuver, setVerifyManoeuver] = useState(false);
    const [verifyMousePosition, setVerifyMousePosition] = useState(false);

    useEffect(() => {
        console.log(currentLogs);
    }, [currentLogs]);


    const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

    const createManoeuverFile = () => {
        const thisManoeuverFile = [];
       
        let idx = 0;

        const finishedScenarios = [];

        console.log(scenarios);

        scenarios.forEach((scenario1, scenarioIdx) => {
            const cell = scenario1.cell;
            const station = scenario1.station;
            const line = scenario1.line;
            const scenario = scenario1.scenario;


            if (scenarioIdx === 0) thisManoeuverFile.push({ 
                text: cookies?.language === "en" ? (scenario === "opening-cell" ? "According to the disconnected scheme." : "According to the normal operating scheme") : (scenario === "opening-cell" ? "Conform schemei deconectate." : "Conform schemei normale de funcționare.") 
            });
            else {
                
                thisManoeuverFile.push({
                    text: cookies?.language === "en" ? `At the disposal of Internal Energy Dispatcher is brought in ${scenario === "closing-cell" ? "a normal operating state" : scenario === "disconnected" ? "a disconnected state" : scenario === "warm-condition" ? "a warm state" : scenario === "warm-reserve" ? "a warm reserve" : scenario === "ssv" ? "a separately visible state" : scenario === "cold-condition" ? "a cold state" : scenario === "cold-reserve" ? "a cold reserve" : scenario === "slp" ? "ground state" : ""} ${station === "SRS" ? "SRA" : "SRS"}-${cell} via:` : `La dispoziția D.E.I. se aduce în ${scenario === "closing-cell" ? "stare normală de funcționare" : scenario === "disconnected" ? "stare deconectată" : scenario === "warm-condition" ? "stare caldă" : scenario === "warm-reserve" ? "rezerva caldă" : scenario === "ssv" ? "stare separat vizibilă" : scenario === "cold-condition" ? "stare rece" : scenario === "cold-reserve" ? "rezerva rece" : scenario === "slp" ? "stare legată la pământ" : ""} ${station === "SRS" ? "SRA" : "SRS"}-${cell} prin: `,
                    number: idx.toString(),
                    dispatcherExecution: "",
                    studentExecution: "",
                    finished: false,
                });

                if (scenario === "disconnected" || scenario === "warm-condition" || scenario === "warm-reserve") {
                    

                    if (finishedScenarios.some(currScenario2 => currScenario2.scenario === scenario) || currentScenarios[0] === "opening-cell") {
                        

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and connects thhe line separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica și se inchide separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.1`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            reverse: true,
                            autoComplete: true
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The connected position of the line separator is checked at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se verifică poziția inchis a separatorului de linie de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.2`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false
                        });


                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and connects bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica și se inchide separatorul de bară de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.3`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The connected position of the bar separator ${line} is checked at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se verifică poziția inchis a separatorului de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.4`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false
                        });

                      
    
                    } else {



                        // thisManoeuverFile.push({
                        //     teacher: cookies?.language === "en" ? `Open the switch from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Deschide întreruptorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        //     student: cookies?.language === "en" ? `I understood, i open the switch from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, deschid întreruptorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        //     name: "intreruptor",
                        //     line: line,
                        //     cell: cell
                        // });
                        

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and disconnects intreruptor from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica și se deconectează intreruptorul de ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.1`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The disconnected position of the intreruptor is checked at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se verifică poziția deconectat a intreruptorului la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.2`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and disconnects ± B.C. from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica si se deconecteaza ± B.C. de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.3`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });
                        
                    }
    
                } else if (scenario === "ssv" || scenario === "cold-condition" || scenario === "cold-reserve") {
                    
                    if (finishedScenarios.some(currScenario2 => currScenario2.scenario === scenario)) {

                        
                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The number 1 mobile S.C.C is being unmounted from the exit of the ${line} bar separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se demonteaza S.C.C. mobil nr. 1 de la iesire din separatorul de bara ${line} de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.1`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            autoComplete: true,
                            finished: false,
                            reverse: true
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The number 2 mobile S.C.C is being unmounted from the exit of the ${line} bar separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se demonteaza S.C.C. mobil nr. 2 de la iesire din separatorul de bara ${line} de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.2`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            autoComplete: true,
                            finished: false
                        });

    
                    } else {
                    
                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and disconnects line separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica și se deschide separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.1`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });


                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The disconnected position of the line separator is checked at ${station === 'SRS' ? "SRA" : "SRS"}-${cell}.` : `Se verifică poziția deschis a separatorului de linie la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.2`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and disconnects bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica și se deschide separatorul de bară de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.3`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `The disconnected position of the bar separator ${line} is checked at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se verifică poziția deschis a separatorului de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.4`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false
                        });

                    }
                } else if (scenario === "slp") {

                    thisManoeuverFile.push({
                        text: cookies?.language === "en" ? `Checks the lack of tension at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se V.L.T la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                        number: `${idx}.1`,
                        dispatcherExecution: "",
                        studentExecution: "",
                        finished: false,
                        autoComplete: true
                    });

                    thisManoeuverFile.push({
                        text: cookies?.language === "en" ? `The number 1 mobile S.C.C is being mounted from the exit of the ${line} bar separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se monteaza S.C.C. mobil nr. 1 de la intrare din separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                        number: `${idx}.2`,
                        dispatcherExecution: "",
                        studentExecution: "",
                        autoComplete: true,
                        finished: false,
                        autoComplete: true
                    });

                    thisManoeuverFile.push({
                        text: cookies?.language === "en" ? `The number 2 mobile S.C.C is being mounted from the exit of the ${line} bar separator from ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se monteaza S.C.C. mobil nr. 2 de la intrare din separatorul de line de la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                        number: `${idx}.3`,
                        dispatcherExecution: "",
                        studentExecution: "",
                        autoComplete: true,
                        finished: false,
                        autoComplete: true
                    });

                } else {
                    if (scenarios?.[0]?.scenario === "opening-cell") {
                        
                    } else {
                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and connects fuse ± B.C. at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica si se conecteaza siguranta ± B.C. la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.1`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });    

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Identifies and connects intreruptor at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se identifica si se conecteaza intreruptorul la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.2`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                            autoComplete: true
                        });    

                        thisManoeuverFile.push({
                            text: cookies?.language === "en" ? `Verifies the intreruptor's connected position at ${station === "SRS" ? "SRA" : "SRS"}-${cell}.` : `Se verifica pozitia conectata a intreruptorului la ${station === "SRS" ? "SRA" : "SRS"}-${cell}.`,
                            number: `${idx}.3`,
                            dispatcherExecution: "",
                            studentExecution: "",
                            finished: false,
                        });  
                    }
                }

                if (currentScenario === scenario) {
                    idx++;

                    thisManoeuverFile.push({
                        text: cookies?.language === "en" ? `D.E.I. must be announced about the end of the manoeuver.` : `Se anunta D.E.I. de terminarea manevrei.`,
                        number: `${idx}`,
                        dispatcherExecution: "",
                        studentExecution: "",
                        finished: false,
                        autoComplete: true
                    });

                } else if (scenarioIdx === scenarios.length - 1) {
                    idx++;
                    thisManoeuverFile.push({
                        text: cookies?.language === "en" ? `D.E.I. must be announced about the return to the normal functioning scheme.` : `Se anunta D.E.I. de revenirea la schema normala de functionare.`,
                        number: `${idx}`,
                        dispatcherExecution: "",
                        studentExecution: "",
                        finished: false,
                        autoComplete: true
                    });
                }
            }

            idx++;

            finishedScenarios.push(scenario1);
        });

        setCurrentManoeuverFile(thisManoeuverFile);


    };


    const createSessionScript = () => {
        console.log(currentScenarios);
        if (currentScenarios[0]?.scenario === "closing-cell") setIsOpen(0);
        else setIsOpen(1);

        const currentScripts = [];
        let currentScenario = "";
        const finishedScenarios = [];

        const filteredScenarios = currentScenarios.filter((scenario, idx) => {
            if (scenario.finished || idx === 0) finishedScenarios.push(scenario);
            return idx !== 0 && !scenario.finished;
        });
        
        filteredScenarios.forEach((currScenario, scenarioIdx) => {

            const cell = currScenario.cell;
            const station = currScenario.station;
            const line = currScenario.line;
            const scenario = currScenario.scenario;
              
            currentScripts.push({
                teacher: cookies?.language === "en" ? `Bring the ${station === "SRS" ? "SRA" : "SRS"} ${line} into ${scenario === "closing-cell" ? "a normal operating state" : scenario === "disconnected" ? "a disconnected state" : scenario === "warm-condition" ? "a warm state" : scenario === "warm-reserve" ? "a warm reserve" : scenario === "ssv" ? "a separately visible state" : scenario === "cold-condition" ? "a cold state" : scenario === "cold-reserve" ? "a cold reserve" : scenario === "slp" ? "ground state" : ""}` : `Adu în ${scenario === "closing-cell" ? "stare normală de funcționare" : scenario === "disconnected" ? "stare deconectată" : scenario === "warm-condition" ? "stare caldă" : scenario === "warm-reserve" ? "rezerva caldă" : scenario === "ssv" ? "stare separat vizibilă" : scenario === "cold-condition" ? "stare rece" : scenario === "cold-reserve" ? "rezerva rece" : scenario === "slp" ? "stare legată la pământ" : ""} ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                student: cookies?.language === "en" ? `I understood I will bring the ${station === "SRS" ? "SRA" : "SRS"} ${line} into ${scenario === "closing-cell" ? "a normal operating state" : scenario === "disconnected" ? "a disconnected state" : scenario === "warm-condition" ? "a warm state" : scenario === "warm-reserve" ? "a warm reserve" : scenario === "ssv" ? "a separately visible state" : scenario === "cold-condition" ? "a cold state" : scenario === "cold-reserve" ? "a cold reserve" : scenario === "slp" ? "ground state" : ""}` : `Am înțeles aduc în ${scenario === "closing-cell" ? "stare normală de funcționare" : scenario === "disconnected" ? "stare deconectată" : scenario === "warm-condition" ? "stare caldă" : scenario === "warm-reserve" ? "rezerva caldă" : scenario === "ssv" ? "stare separat vizibilă" : scenario === "cold-condition" ? "stare rece" : scenario === "cold-reserve" ? "rezerva rece" : scenario === "slp" ? "stare legată la pământ" : ""} ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                changeState: true,
                oneCommand: false,
                name: null,
                cell: null,
                line: null,
                number: null
            });
            
            if (scenario === "disconnected" || scenario === "warm-condition" || scenario === "warm-reserve") {
                if (finishedScenarios.some(currScenario2 => currScenario2.scenario === scenario) || currentScenarios[0] === "opening-cell") {

                    
                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Close the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Închide separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will close the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, închid separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        line: line,
                        number: line.includes("II") ? 2 : 1,
                        cell: cell
                    });

                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Close the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Închide separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will close the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, închid separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        number: 3,
                        line: line,
                        cell: cell
                    });

                } else {
                    // currentScripts.push({
                    //     teacher: cookies?.language === "en" ? `Open the switch from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Deschide întreruptorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    //     student: cookies?.language === "en" ? `I understood, i open the switch from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, deschid întreruptorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    //     name: "intreruptor",
                    //     line: line,
                    //     cell: cell
                    // });

                    currentScripts[currentScripts.length - 1].oneCommand = true;
                    currentScripts[currentScripts.length - 1].name = "intreruptor";
                    currentScripts[currentScripts.length - 1].line = line;
                    currentScripts[currentScripts.length - 1].cell = cell;
                    
                }

                currentScripts.push({
                    teacher: "",
                    student: cookies?.language === "en" ? `${station === "SRS" ? "SRA" : "SRS"} ${cell} was brought to the ${scenario === "disconnected" ? "disconnected state" : scenario === "warm-reserve" ? "warm reserve" : scenario === "warm-condition" ? "warm condition" : ""}` : `S-a adus în ${scenario === "disconnected" ? "stare deconectată" : scenario === "warm-condition" ? "stare caldă" : scenario === "warm-reserve" ? "rezervă caldă" : ""} ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    changeState: true
                });

            } else if (scenario === "ssv" || scenario === "cold-condition" || scenario === "cold-reserve") {
                
                if (finishedScenarios.some(currScenario2 => currScenario2.scenario === scenario)) {


        
                    currentScripts[currentScripts.length - 1].oneCommand = true;
                    currentScripts[currentScripts.length - 1].name = "separator";
                    currentScripts[currentScripts.length - 1].line = line;
                    currentScripts[currentScripts.length - 1].cell = cell;
                    currentScripts[currentScripts.length - 1].number = 4;

                } else {
                
                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Open the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Deschide separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will open the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, deschid separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        number: 3,
                        line: line,
                        cell: cell
                    });

                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Open the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Deschide separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will open the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am înțeles, deschid separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        line: line,
                        number: line.includes("II") ? 2 : 1,
                        cell: cell
                    });

                }

                currentScripts.push({
                    teacher: "",
                    student: cookies?.language === "en" ? `${station === "SRS" ? "SRA" : "SRS"} ${cell} was brought to the ${scenario === "ssv" ? "separately visible state" : scenario === "cold-reserve" ? "cold reserve" : scenario === "cold-condition" ? "cold condition" : ""}` : `S-a adus în ${scenario === "ssv" ? "stare separat vizibilă" : scenario === "cold-condition" ? "stare rece" : scenario === "cold-reserve" ? "rezervă rece" : ""} ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    changeState: true
                });

            } else if (scenario === "slp") {

                // currentScripts[currentScripts.length - 1].oneCommand = true;
                // currentScripts[currentScripts.length - 1].name = "separator";
                // currentScripts[currentScripts.length - 1].line = line;
                // currentScripts[currentScripts.length - 1].cell = cell;
                // currentScripts[currentScripts.length - 1].number = 4;

                currentScripts.push({
                    teacher: "",
                    student: cookies?.language === "en" ? `${station === "SRS" ? "SRA" : "SRS"} ${cell} was brought to the grounded state` : `S-a adus în stare legată la pământ ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    changeState: true
                });

            } else if (scenario === "closing-cell") {
                if (finishedScenarios.some(currScenario2 => currScenario2.scenario === scenario)) {
                    

                    currentScripts[currentScripts.length - 1].oneCommand = true;
                    currentScripts[currentScripts.length - 1].name = "intreruptor";
                    currentScripts[currentScripts.length - 1].line = line;
                    currentScripts[currentScripts.length - 1].cell = cell;

                } else {
                    const isGenerator = document.querySelector(`.generator-${cell}`);
                    if (isGenerator) currentScripts.push({
                        teacher: cookies?.language === "en" ? `Close the generator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Închide generatorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will close the generator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am ințeles, închid generatorul de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "generator",
                        line: line,
                        cell: cell
                     
                    });

                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Close the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Închide separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will close the bar separator ${line} from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am ințeles, închid separatorul de bară ${line} de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        line: line,
                        number: line.includes("II") ? 2 : 1,
                        cell: cell
                    });
                    
                    currentScripts.push({
                        teacher: cookies?.language === "en" ? `Close the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Închide separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        student: cookies?.language === "en" ? `I understood, i will close the line separator from ${station === "SRS" ? "SRA" : "SRS"} ${cell}` : `Am ințeles, închid separatorul de linie de la ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                        name: "separator",
                        number: 3,
                        line: line,
                        cell: cell
                    });

                }

                currentScripts.push({
                    teacher: "",
                    student: cookies?.language === "en" ? `${station === "SRS" ? "SRA" : "SRS"} ${cell} was brought into normal operation state` : `S-a adus în stare normală de funcționare ${station === "SRS" ? "SRA" : "SRS"} ${cell}`,
                    changeState: true
                });
            }
           
            if (scenarioIdx !== filteredScenarios.length - 1) {
                currentScripts.push({
                    teacher: cookies?.language === "en" ? `Correct! Wait for the next command.` : `Corect! Așteaptă următoarea comandă.`,
                    student: "",
                    delay: randomBetween(3000, 9000) 
                });    
            } else currentScripts.push({
                teacher: cookies?.language === "en" ? `Correct! You've completed the learning session successfully!` : `Corect! Ai terminat cu succes sesiunea de învățare!`,
                student: "",
                delay: randomBetween(3000, 9000) 
            });
     
            finishedScenarios.push(currScenario);
        });

        console.log(currentScripts);
        setScripts(currentScripts);

    };

    const updateSessionScript = async () => {
        try {

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        console.log(currentManoeuverFile)
    }, [currentManoeuverFile]);

    useEffect(() => {
        if (!scripts?.length) return;
        const delay = scripts?.[0]?.delay || 0;

        console.log(scripts);
  
        if (scripts?.[0]?.teacher.length) {
            textToSpeech(scripts?.[0]?.teacher, true);

            if (scripts?.length === 1) setTimeout(() => {
                finishSessionStudent();
            }, 500);

            if (!delay) {
                // Time update for dispatcher execution
                setCurrentManoeuverFile(currFileManoeuver => {
                    let found = false, idxs = [];
                    currFileManoeuver.forEach((elem, idx) => {
                        if (!found && !elem.finished && idx !== 0) {
                            found = true;
                            idxs.push(idx);
                        }
                    });

                    let currentIdx = idxs[0] + 1;
                    while (currentIdx < currFileManoeuver.length && currFileManoeuver?.[currentIdx]?.autoComplete) {
                        idxs.push(currentIdx);
                        currentIdx++;
                    }

                    console.log(idxs);

                    return currFileManoeuver.map((elem, idx) => {
                        if (idxs.includes(idx)) return {...elem, 
                            dispatcherExecution: `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`, 
                        };

                        return elem;
                        
                    });
                });
            }
           
        }

        if (scripts?.[0]?.student?.length) setStudentText(scripts?.[0]?.student);
        else setStudentText("");

        if (delay) {
            setCurrentScenarios(currScenarios => {
                let found = false;
                return currScenarios.map((scenario, idx) => {
                    if (!found && !scenario.finished && idx !== 0) {
                        found = true;
                        return {...scenario, finished: true};
                    }

                    return scenario;
                })
            });
            
            const timeout = setTimeout(() => {
                setScripts(currScripts => currScripts.filter((_, idx) => idx !== 0));
            }, delay);

            // cookieSavedElements.forEach(element => {
            //     console.log(element);
            //     setCookie(element.name, element.value ? 1 : 0);
            // });

            return () => {
                clearTimeout(timeout);
            };  
        }

    }, [scripts]);

    const repeatScript = async () => {
        textToSpeech(cookies?.language === "en" ? "I understood, I will repeat the command!" : "Am înțeles, voi repeta comanda!", true);
        setTimeout(async () => {
            await textToSpeech(scripts?.[0]?.teacher, true);

            
        }, 4000);

    };



    const verifyAudio = async (audioResponse) => {

        if (audioResponse === "correct") {

            if (scripts?.[0]?.changeState) {
                setRealCookiesSaved(cookieSavedElements);
            }


            if (scripts?.[0]?.changeState && !scripts?.[0]?.oneCommand) {
                setVerifyManoeuver(false);
                setScripts(currScripts => currScripts.filter((_, idx) => idx !== 0));

                
                // Time update for student execution
                setCurrentManoeuverFile(currFileManoeuver => {
                    let found = false, idxs = [];
                    currFileManoeuver.forEach((elem, idx) => {
                        if (!found && !elem.finished && idx !== 0) {
                            found = true;
                            idxs.push(idx);
                        }
                    });

                    let currentIdx = idxs[0] + 1;
                    while (currentIdx < currFileManoeuver.length && currFileManoeuver?.[currentIdx]?.autoComplete) {
                        idxs.push(currentIdx);
                        currentIdx++;
                    }

                    return currFileManoeuver.map((elem, idx) => {
                        if (idxs.includes(idx)) return {...elem, 
                            finished: true, 
                            studentExecution: `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`,
                            dispatcherExecution: elem?.dispatcherExecution?.length ? elem?.dispatcherExecution : `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`
                        };

                        return elem;
                        
                    });
                });


            } else {
                // await textToSpeech(cookies?.language === "en" ? "Correct, execute!" : "Corect, execută!", true);
                setVerifyMousePosition(true);
            }
            setStudentText("");
        } 
        else if (audioResponse === "repeat") await repeatScript();
        else if (audioResponse === "wrong") {
            await textToSpeech(cookies?.language === "en" ? "I didn't understand, please repeat!" : "Nu am înțeles, repetă te rog!", true);
            setCurrentLogs(currLogs => [...currLogs, { type: 'wrong', reason: 'repeat' }]);
        }
    };

    
    const updateData = async () => {

    };

    let oneTime = false;

    useEffect(() => {
        if (isOpen === -1) return;
        if (isOpen === 1) {
            if (schemeName === "SRS") {
                removeCookie("learning-CMIIA-cell");
                removeCookie("learning-OE1-consumer");
                removeCookie("learning-CMIIB-cell");
                removeCookie('learning-PelicanuI-separator-2');
                removeCookie("learning-OE3-consumer");
                removeCookie("learning-OE3-intreruptor");
                removeCookie("learning-PelicanuI-generator");
                removeCookie("learning-LPGS2-consumer");
                removeCookie("learning-OE4-consumer");
                removeCookie("learning-3T-consumer");
                removeCookie("learning-2T-consumer");
                removeCookie("learning-CLII-separator-1");
                removeCookie("learning-OE4-separator-3");
                removeCookie("learning-PelicanuII-intreruptor");
                removeCookie("learning-OE3-separator-3");
                removeCookie("learning-CLI-separator-1");
                removeCookie("learning-OE3-separator-1");
                removeCookie("learning-OE1-intreruptor");
                removeCookie("learning-CMIA-cell");
                removeCookie("learning-CLII-separator-2");
                removeCookie("learning-CMIA-separator-3");
                removeCookie("learning-CMIA-cell");
                removeCookie("learning-CLII-separator-1");
                removeCookie("learning-OE4-separator-3");
                removeCookie("learning-PelicanuII-intreruptor");
                removeCookie("learning-OE3-separator-3");
                removeCookie("learning-CLI-separator-1");
                removeCookie("learning-OE3-separator-1");
                removeCookie("learning-OE4-consumer");
                removeCookie("learning-OE1-separator-2");
                removeCookie("learning-CMIIA-separator-3");
                removeCookie("learning-CMIIB-separator-3");
                removeCookie("learning-CMIB-separator-3");
                removeCookie("learning-OE2-intreruptor");
                removeCookie("learning-PelicanuI-intreruptor");
                removeCookie("learning-CLI-intreruptor");
                removeCookie("learning-OE2-consumer");
                removeCookie("learning-PelicanuII-separator-1");
                removeCookie("learning-LPGS2-separator-1");
                removeCookie("learning-CLI-separator-2");
                removeCookie("learning-3T-separator-1");
                removeCookie("learning-2T-separator-1");
                removeCookie("learning-LPGS2-intreruptor");
                removeCookie("learning-PelicanuII-generator");
                removeCookie("learning-3T-intreruptor");
                removeCookie("learning-PelicanuII-separator-3");
                removeCookie("learning-OE1-intreruptor");
                removeCookie("learning-PelicanuI-separator-3");
                removeCookie("learning-OE1-separator-3");
                removeCookie("learning-LPGS2-separator-3");
                removeCookie("learning-OE3-intreruptor");
                removeCookie("learning-OE2-separator-3");
                removeCookie("learning-OE4-intreruptor");
                removeCookie("learning-OE4-separator-1");
                removeCookie("learning-OE2-separator-2");
                removeCookie("learning-3T-consumer");
                removeCookie("learning-CLII-intreruptor");
                removeCookie("learning-CMIB-cell");
                removeCookie("learning-2T-intreruptor");
    
            }
        } else {
            console.log(schemeName);
            let noCookie = cookies?.["learning-CMIIA-cell"];
            if (schemeName === "SRS") {
                setCookie("learning-CMIIA-cell", 1);
                setCookie("learning-OE1-consumer", 1);
                setCookie("learning-CMIIB-cell", 1);
                setCookie('learning-PelicanuI-separator-2', 1);
                setCookie("learning-OE3-consumer", 1);
                setCookie("learning-OE3-intreruptor", 1);
                setCookie("learning-PelicanuI-generator", 1);
                setCookie("learning-LPGS2-consumer", 1);
                setCookie("learning-OE4-consumer", 1);
                setCookie("learning-3T-consumer", 1);
                setCookie("learning-2T-consumer", 1);
                setCookie("learning-CLII-separator-1", 1);
                setCookie("learning-OE4-separator-3", 1);
                setCookie("learning-PelicanuII-intreruptor", 1);
                setCookie("learning-OE3-separator-3", 1);
                setCookie("learning-CLI-separator-1", 1);
                setCookie("learning-OE3-separator-1", 1);
                setCookie("learning-OE1-intreruptor", 1);
                setCookie("learning-CMIA-cell", 1);
                setCookie("learning-CLII-separator-2", 1);
                setCookie("learning-CMIA-separator-3", 1);
                setCookie("learning-CMIA-cell", 1);
                setCookie("learning-CLII-separator-1", 1);
                setCookie("learning-OE4-separator-3", 1);
                setCookie("learning-PelicanuII-intreruptor", 1);
                setCookie("learning-OE3-separator-3", 1);
                setCookie("learning-CLI-separator-1", 1);
                setCookie("learning-OE3-separator-1", 1);
                setCookie("learning-OE4-consumer", 1);
                setCookie("learning-OE1-separator-2", 1);
                setCookie("learning-CMIIA-separator-3", 1);
                setCookie("learning-CMIIB-separator-3", 1);
                setCookie("learning-CMIB-separator-3", 1);
                setCookie("learning-OE2-intreruptor", 1);
                setCookie("learning-PelicanuI-intreruptor", 1);
                setCookie("learning-CLI-intreruptor", 1);
                setCookie("learning-OE2-consumer", 1);
                setCookie("learning-PelicanuII-separator-1", 1);
                setCookie("learning-LPGS2-separator-1", 1);
                setCookie("learning-CLI-separator-2", 1);
                setCookie("learning-3T-separator-1", 1);
                setCookie("learning-2T-separator-1", 1);
                setCookie("learning-LPGS2-intreruptor", 1);
                setCookie("learning-PelicanuII-generator", 1);
                setCookie("learning-3T-intreruptor", 1);
                setCookie("learning-PelicanuII-separator-3", 1);
                setCookie("learning-OE1-intreruptor", 1);
                setCookie("learning-PelicanuI-separator-3", 1);
                setCookie("learning-OE1-separator-3", 1);
                setCookie("learning-LPGS2-separator-3", 1);
                setCookie("learning-OE3-intreruptor", 1);
                setCookie("learning-OE2-separator-3", 1);
                setCookie("learning-OE4-intreruptor", 1);
                setCookie("learning-OE4-separator-1", 1);
                setCookie("learning-OE2-separator-2", 1);
                setCookie("learning-3T-consumer", 1);
                setCookie("learning-CLII-intreruptor", 1);
                setCookie("learning-CMIB-cell", 1);
                setCookie("learning-2T-intreruptor", 1);
    
            }
        }

        console.log(savedElements);

        savedElements.forEach(element => {
            setCookie(element.name, element.value ? 1 : 0);
        });

       
        // const timer = setTimeout(() => window.location.reload(), 500)
    }, [isOpen]);

    useEffect(() => {
        console.log(cookies);
    }, [cookies]);


    useEffect(() => {
        const timer1 = setTimeout(() => {
            window.scrollTo( 0, 30 );
        }, 50);
    
        const timer2 = setTimeout(() => {
            
            cookies && Object.keys(cookies).forEach(cookiec => {

                if (cookiec?.includes("learning")) {
                    let cookie = cookiec.replaceAll("learning-", "");
                    if (cookie !== "user-id" && cookie.includes("-")) {
                        const cookieSplits = cookie.split("-");
                    
                        const cookieName = cookieSplits[0];
                        const cookieType = cookieSplits[1];
                        const cookieValue = Number(cookies[cookiec]) === 1;
                    

                        const elements = document.querySelectorAll(`.${cookieType}-${cookieName}`);
                        console.log(elements, cookieSplits)
                        if (elements && elements.length) {
                            if (cookieSplits.length === 3) {
                                const cookieNumber = cookieSplits[2];
                                const currentElement = [...elements].find(element => Number(element.getAttribute("number")) === Number(cookieNumber));
                                if (currentElement) {
                                    currentElement.classList.add(cookieValue ? "active" : "inactive");
                                    currentElement.classList.remove(cookieValue ? "inactive" : "active");
                                }
                            } else {
                                elements[0].classList.add(cookieValue ? "active" : "inactive");
                                elements[0].classList.remove(cookieValue ? "inactive" : "active");
                                if (cookieType === "generator") {
                                    // alert("AA");
                                    const image = elements[0].querySelector("img");
                                    image.src = generatorInchis;
                                } else if (cookieType === "cell") {
                                    try {
                                        const circle = elements[0].querySelector(".text-circle");
                                        const line = cookieName.slice(2);
                                        const barSeparatorIPelicanuI = document.querySelector(`.separator-PelicanuI[number="1"]`);
                                        const barSeparatorIIPelicanuI = document.querySelector(`.separator-PelicanuI[number="2"]`);
                                        const lineSeparatorPelicanuI = document.querySelector(`.separator-PelicanuI[number="3"]`);
                                        const intreruptorPelicanuI = document.querySelector(`.intreruptor-PelicanuI`);
                                        const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                                        
                                        const barSeparatorIPelicanuII = document.querySelector(`.separator-PelicanuII[number="1"]`);
                                        const barSeparatorIIPelicanuII = document.querySelector(`.separator-PelicanuII[number="2"]`);
                                        const lineSeparatorPelicanuII = document.querySelector(`.separator-PelicanuII[number="3"]`);
                                        const intreruptorPelicanuII = document.querySelector(`.intreruptor-PelicanuII`);
                                        const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                                
                                
                                        if (barSeparatorIPelicanuI.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                                            circle.innerText = 'P1';
                                        } 
                                        if (barSeparatorIIPelicanuI.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                                            circle.innerText = 'P1';
                                        }
                                        
                                        if (barSeparatorIPelicanuII.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                                            circle.innerText = 'P2';
                                        } 
                                        if (barSeparatorIIPelicanuII.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                                            circle.innerText = 'P2';
                                        }
                                    
                                    } catch (e) {
                                        alert(e);
                                    }
                                }
                            }
                        }
                    }
                }
            
            });
        
        }, 100);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };

    }, [cookies]);

    useEffect(() => {
    
    
        const separatorsOne = document.querySelectorAll('.separator[number="1"]');
        const separatorsTwo = document.querySelectorAll('.separator[number="2"]');
        const separators = [...separatorsOne, ...separatorsTwo];
    
        const lineSeparators = document.querySelectorAll('.separator[number="3"]');
        const clpSeparators = document.querySelectorAll('.separator[number="4"]');
        const intreruptors = document.querySelectorAll(".intreruptor");
        const messageContainer = document.querySelector('.message-content');
        const virIntreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
        const virIntreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
        const virCTVA = document.querySelector(".intreruptor-CTVA");
        const virCTVB = document.querySelector(".intreruptor-CTVB");
        const cellSeparators = [...lineSeparators].filter(separator => separator.getAttribute("id").includes("CM"));
    
        const refreshSeparator = (e) => {
            let lineSeparator = e?.target || e;
            while (lineSeparator && (!lineSeparator?.classList?.contains("separator") || !lineSeparator?.getAttribute("number")?.toString() === "3"))
                lineSeparator = lineSeparator.parentElement;

            console.log(lineSeparator);

            const separatorId = lineSeparator.getAttribute("id");
            // if (document.querySelector(`.separator[id="${separatorId}"][number="4"]`) && document.querySelector(`.separator[id="${separatorId}"][number="4"]`).classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `C.L.P de pe linia ${separatorId} trebuie sa fie deschis pentru a manevra separatorul de linie!`;
            //     messageContainer.classList.add("active");
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");
    
            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // } 
    
            if (lineSeparator.classList.contains("inactive")) {
                // if (document.querySelector(`.separator[id="${separatorId}"][number="1"]`) && document.querySelector(`.separator[id="${separatorId}"][number="1"]`).classList.contains('inactive') && document.querySelector(`.separator[id="${separatorId}"][number="2"]`) && document.querySelector(`.separator[id="${separatorId}"][number="2"]`).classList.contains('inactive')) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Un separator de bara de pe linia ${separatorId} trebuie sa fie inchis pentru a inchide separatorul de linie!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
    
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
                //     return;
                // }
    
                lineSeparator.classList.remove("inactive");
                lineSeparator.classList.add("active");
                try {
                    // setCookie(`learning-${separatorId}-separator-3`, '1');
                    
                } catch (e) {
                    alert(e);
                }
    
                if (separatorId.includes('CM')) {
                    const letterLine = separatorId.includes("A") ? "II" : "I";
                    const cellLine = separatorId.includes("II") ? "II" : "I";
                    const realLetterLine = separatorId.includes("A") ? "A" : "B";
    
                    const barSeparatorI = document.querySelector(`.separator-Pelicanu${letterLine}[number="1"]`);
                    const barSeparatorII = document.querySelector(`.separator-Pelicanu${letterLine}[number="2"]`);
                    const lineSeparator = document.querySelector(`.separator-Pelicanu${letterLine}[number="3"]`);
                    const generator = document.querySelector(`.generator-Pelicanu${letterLine}`);
                    const intreruptor = document.querySelector(`.intreruptor-Pelicanu${letterLine}`);
    
                    const otherLetterLine = letterLine === "I" ? "II" : "I";
    
                    const otherBarSeparatorI = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="1"]`);
                    const otherBarSeparatorII = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="2"]`);
                    const otherLineSeparator = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="3"]`);
                    const otherGenerator = document.querySelector(`.generator-Pelicanu${otherLetterLine}`);
                    const otherIntreruptor = document.querySelector(`.intreruptor-Pelicanu${otherLetterLine}`);
    
                    const ctvB = document.querySelector(`.intreruptor-CTVB`);
                    const ctvA = document.querySelector(`.intreruptor-CTVA`);
    
                    
                    const firstCondition = generator.classList.contains("inactive") || (cellLine === "II" && barSeparatorII.classList.contains("inactive") && ctvB.classList.contains("inactive")) || (cellLine === "I" && barSeparatorI.classList.contains("inactive") && ctvA.classList.contains("inactive")) || lineSeparator.classList.contains('inactive') || intreruptor.classList.contains('inactive'); 
                    const secondCondition = otherGenerator.classList.contains("inactive") || (cellLine === "II" && otherBarSeparatorII.classList.contains("inactive") && ctvB.classList.contains("inactive")) || (cellLine === "I" && otherBarSeparatorI.classList.contains("inactive") && ctvA.classList.contains("inactive")) || otherLineSeparator.classList.contains('inactive') || otherIntreruptor.classList.contains('inactive'); 
                    
                    // if (firstCondition && secondCondition) return;
                    
    
                    if (firstCondition && !secondCondition) {
                        const separatorsCL = [...document.querySelectorAll(`.separator-CL${cellLine}`)];
                        const intreruptorCL = document.querySelector(`.intreruptor-CL${cellLine}`);
    
                        if (intreruptorCL.classList.contains("active") && separatorsCL.every(separator => separator.classList.contains("active"))) {
        
                            const cell = document.querySelector(`.cell-${separatorId}`);
                            cell.classList.add("active");
                            cell.classList.remove("inactive");
                            enableCell(cell);
                        }
                        
                    } else {
                        const cell = document.querySelector(`.cell-${separatorId}`);
                        cell.classList.add("active");
                        cell.classList.remove("inactive");
                        enableCell(cell);
                    }
    
                    const oppositeLetterLine = realLetterLine === "A" ? "B" :"A";
                    const oppositeLine = cellLine + oppositeLetterLine; 
                    const separatorsCL = [...document.querySelectorAll(`.separator-CL${cellLine}`)];
                    const intreruptorCL = document.querySelector(`.intreruptor-CL${cellLine}`);
                    const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                    const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
    
    
                    if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                    if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                    if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
    
                    oppositeCM.classList.add("active");
                    oppositeCM.classList.remove("inactive");
                    enableCell(oppositeCM);
                }
    
                return;
            }
    
            const intreruptor = document.querySelector(`.intreruptor[id="${separatorId}"]`);
            // if (intreruptor && intreruptor.classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `Intreruptorul de pe linia ${separatorId} trebuie sa fie deconectat pentru a deschide separatorul de linie!`;
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     messageContainer.classList.add("active");
    
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");
    
            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // }
    
            lineSeparator.classList.remove("active");
            lineSeparator.classList.add("inactive");
    
    
            try {
                // setCookie(`learning-${separatorId}-separator-3`, '0');
    
            
            } catch (e) {
                alert(e);
            }
    
    
            if (separatorId.includes("CM")) {
                const cell = document.querySelector(`.cell-${separatorId}`);
                cell.classList.remove("active");
                cell.classList.add("inactive");
                
                disableCell(cell, true);
            } 

        };
    
        const refreshIntreruptor = (e, withoutColorChange = false, ctvTrigger = false, trafoWarning = true) => {
                
            let intreruptor = e?.target || e;
            console.log(intreruptor);
            while (intreruptor && !intreruptor?.classList?.contains("intreruptor")) {
                intreruptor = intreruptor.parentElement;
                console.log(intreruptor)
            }
            console.log(intreruptor);
                if (withoutColorChange && !ctvTrigger) {
                    refreshIntreruptor(virCTVA, false, true);
                    refreshIntreruptor(virCTVB, false, true);
                    refreshIntreruptor(virCTVA, false, true);
                    refreshIntreruptor(virCTVB, false, true);
                
                }
    
                let intreruptorId = intreruptor.getAttribute("id");
    
                // If intreruptor is active
                
                if (intreruptor.classList.contains("active")) {
                    if (!withoutColorChange) {
                        intreruptor.classList.remove("active");
                        intreruptor.classList.add("inactive");
                    }
    
                    try {
                      
                        // setCookie(`learning-${intreruptorId}-intreruptor`, '0');
    
                    } catch (e) {
                        alert(e);
                    }
    
                    if (intreruptorId.includes("Pelicanu")) {
                        
                        const letter = intreruptorId === "PelicanuI" ? "B" : "A";
                        const oppositeLetter = letter === "A" ? "B" : "A";
                        const separatorBarI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                        const separatorBarII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                        const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                        const number = separatorBarI.classList.contains("active") ? "I" : "II";
                        const oppositeNumber = number === "I" ? "II" : "I"; 
                        const oppositeNumberPelican = intreruptorId.includes("II") ? "I" : "II";
                        const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositeNumberPelican}`);
                        const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${number}${oppositeLetter}"]`);
                        const oppositeLineSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[number="3"]`);
                        const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositeNumberPelican}`);
                        const clSepartorI = document.querySelector(`.separator-CL${number}[number="1"]`);
                        const clSeparatorII = document.querySelector(`.separator-CL${number}[number="2"]`);
                        const clIntreruptor = document.querySelector(`.intreruptor-CL${number}`);
                        const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                        const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
                        const oppositeCTV = letter === "A" ? intreruptorCTVB : intreruptorCTVA;
                        const currentCTV = letter === "A" ? intreruptorCTVA : intreruptorCTVB;
                        const oppositeBarSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${oppositeNumber}${oppositeLetter}"]`);
                        
                        const oppositeCLIntreruptor = document.querySelector(`.intreruptor-CL${oppositeNumber}`);
                        const oppositeCellSeparator = document.querySelector(`.separator-CM${oppositeNumber}${letter}`);
                        const oppositeCell = document.querySelector(`.cell-CM${oppositeNumber}${letter}`);
                        
                        if (oppositeCLIntreruptor.classList.contains("inactive")) {
                            oppositeCell.classList.add("inactive");
                            oppositeCell.classList.remove("active");
                            disableCell(oppositeCell);
                        }
    
                        const disableCellCondition = 
                        (
                            oppositeCTV.classList.contains("active") && 
                            oppositeBarSeparator.classList.contains("active") &&
                            oppositeLineSeparator.classList.contains("active") && 
                            oppositeGenerator.classList.contains("active") && 
                            clIntreruptor.classList.contains("active")
                        ) ||
                        (
                            currentCTV.classList.contains("active") &&
                            oppositeBarSeparator.classList.contains("active") &&
                            oppositeLineSeparator.classList.contains("active") && 
                            oppositeGenerator.classList.contains("active") && 
                            clIntreruptor.classList.contains("active")
                        );
    
                        if (separatorBarI.classList.contains("inactive") && separatorBarII.classList.contains("inactive")) return;
                        
                        if ((letter === "A" && !disableCellCondition) || (letter === "B" && clSepartorI.classList.contains("active") && clSeparatorII.classList.contains("active") && clIntreruptor.classList.contains("active") && !disableCellCondition)) {
                            const cellI = document.querySelector(`.cell-CM${number}A`);
                            cellI.classList.remove("active");
                            cellI.classList.add("inactive");
                            disableCell(cellI);
                        }
    
                        if ((letter === "B" && !disableCellCondition) || (letter === "A" && clSepartorI.classList.contains("active") && clSeparatorII.classList.contains("active") && clIntreruptor.classList.contains("active") && !disableCellCondition)) {
                            const cellII = document.querySelector(`.cell-CM${number}B`);
                            cellII.classList.remove("active");
                            cellII.classList.add("inactive");
            
                            disableCell(cellII);
                        }
    
                        if (oppositeIntreruptor.classList.contains("inactive") || oppositeGenerator.classList.contains("inactive")) {
                            
                            const otherCellA = document.querySelector(`.cell-CM${oppositeNumber}A`);
                            const otherCellB = document.querySelector(`.cell-CM${oppositeNumber}B`);
                            const currentCellA = document.querySelector(`.cell-CM${number}A`);
                            const currentCellB = document.querySelector(`.cell-CM${number}B`);
                            otherCellA.classList.remove("active");
                            otherCellA.classList.add("inactive");
                            otherCellB.classList.add("inactive");
                            otherCellB.classList.remove("active");
                            currentCellA.classList.add("inactive");
                            currentCellA.classList.remove("active");
                            currentCellB.classList.add("inactive");
                            currentCellB.classList.remove("active");
                            disableCell(otherCellA);
                            disableCell(otherCellB);
                            disableCell(currentCellA);
                            disableCell(currentCellB);
                        }
            
                        
                    } else {
                        const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                        if (consumer) {
                            consumer.classList.add("inactive");
                            consumer.classList.remove("active");
                            disableConsumer(consumer);
                        }
            
                    }
    
                    if (intreruptorId.includes("CL")) {
                        const number = intreruptorId.includes("II") ? "II" : "I";
    
                        const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                        const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                        const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                        const generatorPelicanuI  = document.querySelector('.generator-PelicanuI');
                        const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                        const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                        const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                        const generatorPelicanuII  = document.querySelector('.generator-PelicanuII');    
                        const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                        const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
    
    
                        if (number === "I") {
                            if (
                                separatorPelicanuI1.classList.contains("active") && 
                                separatorPelicanuI2.classList.contains("inactive") && 
                                intreruptorPelicanuI.classList.contains("active") &&
                                generatorPelicanuI.classList.contains("active") &&
                                (
                                    separatorPelicanuII1.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuII2.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive") ||
                                    intreruptorCTVA.classList.contains("inactive")
                                )
                            ) {
                                const CMA = document.querySelector(".cell-CMIA");
                                CMA.classList.remove("active");
                                CMA.classList.add("inactive");
                                disableCell(CMA);
                            } else if (
                                separatorPelicanuII1.classList.contains("active") && 
                                separatorPelicanuII2.classList.contains("inactive") &&
                                intreruptorPelicanuII.classList.contains("active") && 
                                generatorPelicanuII.classList.contains("active") &&
                                (
                                    separatorPelicanuI1.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuI2.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive") ||
                                    intreruptorCTVB.classList.contains("inactive")
                                )
                            ) {
                                const CMB = document.querySelector(".cell-CMIB");
                                CMB.classList.remove("active");
                                CMB.classList.add("inactive");
                                disableCell(CMB);
                            }
                        } else if (number === "II") {
                            if (
                                separatorPelicanuI1.classList.contains("inactive") && 
                                separatorPelicanuI2.classList.contains("active") && 
                                intreruptorPelicanuI.classList.contains("active") &&
                                generatorPelicanuI.classList.contains("active") &&
                                (
                                    separatorPelicanuII2.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuII1.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive") ||
                                    intreruptorCTVA.classList.contains("inactive")
                                )
                            ) {
                                const CMA = document.querySelector(".cell-CMIIA");
                                CMA.classList.remove("active");
                                CMA.classList.add("inactive");
                                disableCell(CMA);
                            } else if (
                                separatorPelicanuII1.classList.contains("inactive") && 
                                separatorPelicanuII2.classList.contains("active") && 
                                intreruptorPelicanuII.classList.contains("active") && 
                                generatorPelicanuII.classList.contains("active") &&
                                (
                                    separatorPelicanuI2.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuI1.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive") ||
                                    intreruptorCTVB.classList.contains("inactive")
                                )
                            ) {
                                const CMB = document.querySelector(".cell-CMIIB");
                                CMB.classList.remove("active");
                                CMB.classList.add("inactive");
                                disableCell(CMB);
                            }
                        }
    
                        // refreshIntreruptor(virIntreruptorPelicanuI, true);
                        // refreshIntreruptor(virIntreruptorPelicanuII, true);
                        // refreshIntreruptor(virIntreruptorPelicanuI, true);
                        // refreshIntreruptor(virIntreruptorPelicanuII, true);
                        cellSeparators.forEach(cell => {
                    
                            refreshSeparator(cell);
                            refreshSeparator(cell);
                        });
                    }
    
                    if (intreruptorId.includes("CTV")) {
                        const activeLine = document.querySelector(`.separator-${intreruptorId}[number="1"]`).classList.contains("active") ? "1" : "2";
                        const oppositeLine = activeLine === "1" ? "2" : "1";
                        const oppositePelicanLine = intreruptorId.includes("II") ? "I" : "II";
                        const letter = intreruptorId.includes("II") ? "A" : "B";
                        const oppositeLetter = intreruptorId.includes("II") ? "A" : "B";
                        const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositePelicanLine}[number="${oppositeLine}"]`);
                        const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositePelicanLine}`);
                        const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositePelicanLine}`);
                        const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${oppositeLine === "1" ? "I" : "II"}`);
    
                        const oppositeLineCell = document.querySelector(`.cell-CM${oppositeLine === "2" ? "II" : "I"}${letter}`);
                        const oppositeLineCellSeparator =  document.querySelector(`.separator-CM${oppositeLine === "2" ? "II" : "I"}${letter}`);
                        const otherOppositeLineCell = document.querySelector(`.cell-CM${oppositeLine === "2" ? "II" : "I"}${oppositeLetter}`);
                        const otherOppositeLineCellSeparator = document.querySelector(`.separator-CM${oppositeLine === "2" ? "II" : "I"}${oppositeLetter}`);
    
                
    
                        if ((!oppositeSeparator.classList.contains("active") || !oppositeIntreruptor.classList.contains("active") || !oppositeGenerator.classList.contains("active")) && !oppositeIntreruptorCL.classList.contains("active")) {
                            
                            if (oppositeLineCellSeparator) {
                                oppositeLineCell.classList.add("inactive");
                                oppositeLineCell.classList.remove("active");
                            }
    
                            if (otherOppositeLineCellSeparator) {
                                otherOppositeLineCell.classList.add("inactive");
                                otherOppositeLineCell.classList.remove("active");
                            }
    
                            disableCell(oppositeLineCell);
                            disableCell(otherOppositeLineCell);
    
                        }
    
                        if (oppositeSeparator.classList.contains("active") && oppositeIntreruptor.classList.contains("active") && oppositeGenerator.classList.contains("active") && !oppositeIntreruptorCL.classList.contains("active")) {
                            if (oppositeLineCellSeparator) {
                                oppositeLineCell.classList.add("inactive");
                                oppositeLineCell.classList.remove("active");
                            }
    
                            disableCell(oppositeLineCell);
                        }
    
                        cellSeparators.forEach(cell => {
                            refreshSeparator(cell);
                            refreshSeparator(cell);
                        });
    
                    }
    
                    return;
                }
    
             
    
                    if ((intreruptorId.includes("2T") || intreruptorId.includes("3T")) && trafoWarning) {
                        const oppositeName = intreruptorId.includes("2T") ? "Trafo 3T" : "Trafo 2T";
                        const oppositeId = intreruptorId.includes("2T") ? "3T" : "2T";
                        const oppositeConsumer = document.querySelector(`.consumer-${oppositeId}`);
                    } 
    
                    if ((intreruptorId.includes("Pelicanu") || intreruptorId.includes("CL") || intreruptorId.includes("CTV") || intreruptorId === "2T" || intreruptorId === "3T" || intreruptorId === "4T") && (!withoutColorChange && !ctvTrigger)) {
                        
                        intreruptorArr.push(intreruptor.getAttribute("id"));            
                        console.log(intreruptorArr);
                        messageContainer.classList.remove("active");

                        if (intreruptorId.includes("CTV") && (!withoutColorChange && !ctvTrigger)) {
                           
                            messageContainer.classList.remove("active");

                            intreruptorId = intreruptorArr.at(-1);
                            intreruptor = document.querySelector(`.intreruptor-${intreruptorId}`);
            
                            // Separators from current id & checking if there is an active separator
                            const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
            
                            // TODO: check tension on the line
                            // TODO: check if there is no potential differences from generators

                            if (!withoutColorChange) {
                                intreruptor.classList.remove("inactive");
                                intreruptor.classList.add("active");
                            }
                    
                            try {
                            
                                // setCookie(`learning-${intreruptorId}-intreruptor`, "1");

                            } catch (e) {
                                alert(e);
                            }

                            const letter = intreruptorId === "CTVB" ? "B" : "A";
                            const oppositeLetter = letter === "A" ? "B" : "A";
                            const currentPelicanNumber = letter === "A" ? "II" : "I";
                            const pelicanNumber = oppositeLetter === "A" ? "II" : "I";
                            const currentGenerator = document.querySelector(`.generator[line="${letter}"]`);
                            const oppositeGenerator = document.querySelector(`.generator[line="${oppositeLetter}"]`);
                            const currentIntreruptor = document.querySelector(`.intreruptor[line="${letter}"]`);
                            const oppositeInteruptor = document.querySelector(`.intreruptor[line="${oppositeLetter}"]`);  
                            const oppositeActiveLine = document.querySelector(`.separator-Pelicanu${pelicanNumber}[number="1"]`).classList.contains("active") ? "I" : "II";
                            const oppositeLineSeparator =  document.querySelector(`.separator-Pelicanu${currentPelicanNumber}[number="1"]`).classList.contains("active") ? "II" : "I";
                            const currentActiveLine = document.querySelector(`.separator-Pelicanu${currentPelicanNumber}[number="1"]`).classList.contains("active") ? "I" : "II";
                            const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${oppositeActiveLine}`);       
                            const intreruptorCL = document.querySelector(`.intreruptor-CL${currentActiveLine}`);  

                            if ((currentIntreruptor.classList.contains('active') && currentGenerator.classList.contains('active')) || (oppositeInteruptor.classList.contains('active') && oppositeGenerator.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active"))) {
                                const firstCellSeparator = document.querySelector(`.separator-CMI${letter}`);
                                const secondCellSeparator = document.querySelector(`.separator-CMII${letter}`);
                                const firstCell = document.querySelector(`.cell-CMI${letter}`);
                                const secondCell = document.querySelector(`.cell-CMII${letter}`);
                                if (firstCellSeparator.classList.contains("active")) {
                                    firstCell.classList.add("active");
                                    firstCell.classList.remove("inactive");
                                }

                                if (secondCellSeparator.classList.contains("active")) {
                                    secondCell.classList.add("active");
                                    secondCell.classList.remove("inactive");
                                }

                                const otherCell = document.querySelector(`.cell-CM${oppositeLineSeparator}${oppositeLetter}`);
                                const otherCellSeparator =  document.querySelector(`.separator-CM${oppositeLineSeparator}${oppositeLetter}`);
                                if (intreruptorCL.classList.contains("active")) {
                                    enableCell(otherCell);
                                    if (otherCellSeparator.classList.contains("active")) {
                                        otherCell.classList.add("active");
                                        otherCell.classList.remove("inactive");
                                    }
                                }

                                
                                enableCell(firstCell);
                                enableCell(secondCell);
                            }

                            cellSeparators.forEach(cell => {
                                refreshSeparator(cell);
                                refreshSeparator(cell);
                            });  
                            
                            } else {
                                
                                intreruptorId = intreruptorArr.at(-1);
                                intreruptor = document.querySelector(`.intreruptor-${intreruptorId}`);
                                if (!intreruptorId.includes("Pelicanu") && !intreruptorId.includes("CL") && !intreruptorId.includes("CTV")) {
                
                                    const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                    const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                    const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                    const line = activeBarSeparator.getAttribute("line");
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                   
                                }
                    
                    
                            // Separators from current id & checking if there is an active separator
                            const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
                      
                            
                            if (separators.length === 3) {
                              
                                const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4);
                              
                            }
                    
                            // TODO: check tension on the line
                            // TODO: check if there is no potential differences from generators
                            if (!withoutColorChange) {
                                intreruptor.classList.remove("inactive");
                                intreruptor.classList.add("active");
                            }
                
                            try {
                                // setCookie(`learning-${intreruptorId}-intreruptor`, "1");
                            
                            } catch (e) {
                                alert(e);
                            }
                    
                            if (intreruptorId.includes("Pelicanu")) {
                                
                                const letterLine = intreruptorId === "PelicanuI" ? "B" : "A";
                                const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                                const generator = document.querySelector(`.generator-${intreruptorId}`);
                                
                                if (generator.classList.contains("inactive")) return; // no warning
                                if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return; // no warning
                                if (lineSeparator.classList.contains('inactive')) return; // no warning
                    
                                const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                                const line = numberLine + letterLine;
                                const oppositeNumberLine = (numberLine === "I" ? "II" : "I") + letterLine;
                                const otherCellSeparator = document.querySelector(`.separator-CM${oppositeNumberLine}[number="3"]`);
    
                                const otherOppositeLine = (numberLine === "I" ? "II" : "I") + (letterLine === "A" ? "B" : "A");
                                const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${numberLine === "I" ? "II" : "I"}`);
                                const otherOppositeLineSeparatorCM = document.querySelector(`.separator-CM${otherOppositeLine}`);
    
                                
                                const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                                const currentCTV = document.querySelector(`.intreruptor-CTV${letterLine}`);
                                
                                const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                                const oppositeLine = numberLine + oppositeLetterLine; 
                    
                                const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                                const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                                const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                            
                    
                                if (cellSeparator.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
                                
    
                                if (currentCTV.classList.contains("active") && otherCellSeparator.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${oppositeNumberLine}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
    
                                if (currentCTV.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active") && otherOppositeLineSeparatorCM.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${otherOppositeLine}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
                    
                    
                                if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                                if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                                if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
                    
                    
                                const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                                oppositeCM.classList.add("active");
                                oppositeCM.classList.remove("inactive");
                    
                                enableCell(oppositeCM);
                    
                            } else {
                                const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                    
                                if (consumer) {
                                    if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) {
                                        consumer.classList.add("inactive");
                                        consumer.classList.remove("active");
                                        disableConsumer(consumer);
                                    } else {
                                        const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                        const line = activeBarSeparator.getAttribute("line");
                                        const cell = document.querySelector(`.cell-CM${line}`);
                                        if (cell.classList.contains("active")) {
                                            consumer.classList.add("active");
                                            consumer.classList.remove("inactive");
                                            enableConsumer(consumer);
                                        }
                                    }
                                }
                    
                            
                                
                            }
                    
                            if (intreruptorId.includes("CL")) {
                                const number = intreruptorId.includes("II") ? "II" : "I";
                                const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                                const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                    
                                const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                                const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                    
                                const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                                const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                    
                                const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                                const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                    
                                if (number === "I") {
                                    if (separatorPelicanuI1.classList.contains("active") && separatorPelicanuI2.classList.contains("inactive") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                        const CMA = document.querySelector(".cell-CMIA");
                                        const separatorCMA = document.querySelector('.separator-CMIA[number="3"]');
                                        if (separatorCMA.classList.contains("active")) {
                                            CMA.classList.add("active");
                                            CMA.classList.remove("inactive");
                                        }
                    
                                        enableCell(CMA, separatorCMA.classList.contains("active"));
                    
                                    
                                    } else if (separatorPelicanuII1.classList.contains("active") && separatorPelicanuII2.classList.contains("inactive") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                        const CMB = document.querySelector(".cell-CMIB");
                                        const separatorCMB = document.querySelector('.separator-CMIB[number="3"]');
                                        if (separatorCMB.classList.contains("active")) {
                                            CMB.classList.add("active");
                                            CMB.classList.remove("inactive");
                                        
                                        }
                                        enableCell(CMB, separatorCMB.classList.contains("active"));
                                    }
                                } else if (number === "II") {
                                    if (separatorPelicanuI1.classList.contains("inactive") && separatorPelicanuI2.classList.contains("active") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                        const CMA = document.querySelector(".cell-CMIIA");
                                        const separatorCMA = document.querySelector('.separator-CMIIA[number="3"]');
                                        if (separatorCMA.classList.contains("active")) {
                                            CMA.classList.add("active");
                                            CMA.classList.remove("inactive");
                                        
                                        }
                                        enableCell(CMA, separatorCMA.classList.contains("active"));
                                    
                                    } else if (separatorPelicanuII1.classList.contains("inactive") && separatorPelicanuII2.classList.contains("active") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                        const CMB = document.querySelector(".cell-CMIIB");
                                        const separatorCMB = document.querySelector('.separator-CMIIB');
                                        if (separatorCMB.classList.contains("active")) {
                                            CMB.classList.add("active");
                                            CMB.classList.remove("inactive");
                                        
                                        }
                    
                                        enableCell(CMB, separatorCMB.classList.contains("active"));
                                    }
                                }
    
                                // refreshIntreruptor(virIntreruptorPelicanuI, true);
                                // refreshIntreruptor(virIntreruptorPelicanuII, true);
                                // refreshIntreruptor(virIntreruptorPelicanuI, true);
                                // refreshIntreruptor(virIntreruptorPelicanuII, true);
                                cellSeparators.forEach(cell => {
                    
                                    refreshSeparator(cell);
                                    refreshSeparator(cell);
                                });
                                
                    
                            }   
                            }
            
                    }
        
                    else {
                        
                        if (!intreruptorId.includes("Pelicanu") && !intreruptorId.includes("CL") && !intreruptorId.includes("CTV")) {
            
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                            const line = activeBarSeparator.getAttribute("line");
                            const cell = document.querySelector(`.cell-CM${line}`);
                        
                        }
                
                
                        // Separators from current id & checking if there is an active separator
                        const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
                       
                      
                
                        // TODO: check tension on the line
                        // TODO: check if there is no potential differences from generators
                        if (!withoutColorChange) {
                            intreruptor.classList.remove("inactive");
                            intreruptor.classList.add("active");
                        }
                
                        if (intreruptorId.includes("Pelicanu")) {
                            
                            const letterLine = intreruptorId === "PelicanuI" ? "B" : "A";
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                            const generator = document.querySelector(`.generator-${intreruptorId}`);
                
                
                            if (generator.classList.contains("inactive")) return; // no warning
                            if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return; // no warning
                            if (lineSeparator.classList.contains('inactive')) return; // no warning
                
                            const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                            const line = numberLine + letterLine;
                
                            
                            const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                
                
                            const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                            const oppositeLine = numberLine + oppositeLetterLine; 
                
                            const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                            const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                            const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                        
                
                            if (cellSeparator.classList.contains("active")) {
                                const cell = document.querySelector(`.cell-CM${line}`);
                                cell.classList.add("active");
                                cell.classList.remove("inactive");
                                enableCell(cell);
                            }
                
                
                            if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                            if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                            if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
                
                
                            const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                            oppositeCM.classList.add("active");
                            oppositeCM.classList.remove("inactive");
                
                            enableCell(oppositeCM);
                
                        } else {
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                
                            if (consumer) {
                                if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) {
                                    consumer.classList.add("inactive");
                                    consumer.classList.remove("active");
                                    disableConsumer(consumer);
                                } else {
                                    const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                    const line = activeBarSeparator.getAttribute("line");
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                    if (cell.classList.contains("active")) {
                                        consumer.classList.add("active");
                                        consumer.classList.remove("inactive");
                                        enableConsumer(consumer);
                                    }
                                }
                            }
                
                        
                            
                        }
                
                        if (intreruptorId.includes("CL")) {
                            const number = intreruptorId.includes("II") ? "II" : "I";
                            const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                            const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                
                            const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                            const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                
                            const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                            const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                
                            const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                            const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                
                            if (number === "I") {
                                if (separatorPelicanuI1.classList.contains("active") && separatorPelicanuI2.classList.contains("inactive") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                    const CMA = document.querySelector(".cell-CMIA");
                                    const separatorCMA = document.querySelector('.separator-CMIA[number="3"]');
                                    if (separatorCMA.classList.contains("active")) {
                                        CMA.classList.add("active");
                                        CMA.classList.remove("inactive");
                                    }
                
                                    enableCell(CMA, separatorCMA.classList.contains("active"));
                
                                
                                } else if (separatorPelicanuII1.classList.contains("active") && separatorPelicanuII2.classList.contains("inactive") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                    const CMB = document.querySelector(".cell-CMIB");
                                    const separatorCMB = document.querySelector('.separator-CMIB[number="3"]');
                                    if (separatorCMB.classList.contains("active")) {
                                        CMB.classList.add("active");
                                        CMB.classList.remove("inactive");
                                    
                                    }
                                    enableCell(CMB, separatorCMB.classList.contains("active"));
                                }
                            } else if (number === "II") {
                                if (separatorPelicanuI1.classList.contains("inactive") && separatorPelicanuI2.classList.contains("active") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                    const CMA = document.querySelector(".cell-CMIIA");
                                    const separatorCMA = document.querySelector('.separator-CMIIA[number="3"]');
                                    if (separatorCMA.classList.contains("active")) {
                                        CMA.classList.add("active");
                                        CMA.classList.remove("inactive");
                                    
                                    }
                                    enableCell(CMA, separatorCMA.classList.contains("active"));
                                
                                } else if (separatorPelicanuII1.classList.contains("inactive") && separatorPelicanuII2.classList.contains("active") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                    const CMB = document.querySelector(".cell-CMIIB");
                                    const separatorCMB = document.querySelector('.separator-CMIIB');
                                    if (separatorCMB.classList.contains("active")) {
                                        CMB.classList.add("active");
                                        CMB.classList.remove("inactive");
                                    
                                    }
                
                                    enableCell(CMB, separatorCMB.classList.contains("active"));
                                }
                            }
                
                        }
                
                    }
            };
    
    
        

        const separatorFunctionality = async e => {
            let separator = e?.target || e;
            while (separator && !separator?.classList?.contains("separator")) separator = separator.parentElement;
            console.log(separator);
            const separatorId = separator.getAttribute("id");
           

            const currIntreruptor = document.querySelector(`.intreruptor[id="${separatorId}"]`);
            const oppositeNumber = Number(separator.getAttribute("number")) === 1 ? 2 : 1;
            const oppositeSeparator = document.querySelector(`.separator[number="${oppositeNumber}"][id="${separatorId}"]`);

            if (separator.classList.contains("inactive")) {

                // Check for the clp separator from the current line to be inactive
                if (separator.getAttribute("line")) {
                    const clpCMSeparator = document.querySelector(`.separator-CM${separator.getAttribute("line")}[number="4"]`);
                  
                }

                separator.classList.remove("inactive");
                separator.classList.add("active");
            } else {
                const lineSeparator = document.querySelector(`.separator[id="${separatorId}"][number="3"]`);
               
                separator.classList.remove("active");
                separator.classList.add("inactive");
            }

            try {
                console.log("cookie set", separator.classList.contains("active"));
            
            } catch (e) {
                alert(e);
            }
        };
    
        const clpSeparatorEvent = async (e) => {

            let separator = e.target || e;
            while (!separator.classList.contains("separator")) separator = separator.parentElement;
       
            const separatorId = separator.getAttribute("id");
            const otherElements = [...document.querySelectorAll(`div[id="${separatorId}"]:not([number="4"])`)];

            if (separatorId.includes("CM")) {
                const separatorLine = separatorId.slice(2);
                const barSeparators = document.querySelectorAll(`.separator[line="${separatorLine}"]`);
                const barSeparatorActive = [...barSeparators].some(separator => {
                    return separator.classList.contains("active")
                });

            }
        

            if (separator.classList.contains("inactive")) {
                separator.classList.remove("inactive");
                separator.classList.add("active");
                try {
                    
                    // setCookie(`learning-${separatorId}-separator-4`, '1');
                
                } catch (error) {
                    alert(error);
                }
                return;
            }

            separator.classList.remove("active");
            separator.classList.add("inactive");
        
            
        };
    
  
        const generatorFunctionality = async (e) => {
            let target = e?.target || e;
            const generator = target?.classList?.contains("generator") ? target : target.parentElement;
            console.log(generator);
            const image = generator.querySelector("img");
            const generatorId = generator.getAttribute("id");       

            if (generator.classList.contains("active")) {
                console.log("A");
                generator.classList.remove("active");
                generator.classList.add("inactive");
                image.src = generatorImage;
                
                const barSeparatorI = document.querySelector(`.separator-${generatorId}[number="1"]`);
                const barSeparatorII = document.querySelector(`.separator-${generatorId}[number="2"]`);
                const number = barSeparatorI.classList.contains("active") ? "I" : "II";
                const oppositeNumber = number === "I" ? "II" : "I"; 
                const currentNumber = barSeparatorI.classList.contains("active") ? "I" : "II";
                const letter = generatorId === "PelicanuI" ? "B" : "A";
                const oppositeLetter = letter === "B" ? "A" : "B";
                const oppositeNumberPelican = generatorId.includes("II") ? "I" : "II";
                const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositeNumberPelican}`);
                const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${number}${oppositeLetter}"]`);
                const oppositeLineSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[number="3"]`);
                const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositeNumberPelican}`);
                const clSepartorI = document.querySelector(`.separator-CL${number}[number="1"]`);
                const clSeparatorII = document.querySelector(`.separator-CL${number}[number="2"]`);
                const clIntreruptor = document.querySelector(`.intreruptor-CL${number}`);
                const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
                const oppositeCTV = letter === "A" ? intreruptorCTVB : intreruptorCTVA;
                const currentCTV = letter === "A" ? intreruptorCTVA : intreruptorCTVB;
                const oppositeBarSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${oppositeNumber}${oppositeLetter}"]`);

                    
                const oppositeCLIntreruptor = document.querySelector(`.intreruptor-CL${oppositeNumber}`);
                const oppositeCell = document.querySelector(`.cell-CM${oppositeNumber}${letter}`);
                
                if (oppositeCLIntreruptor.classList.contains("inactive")) {
                    oppositeCell.classList.add("inactive");
                    oppositeCell.classList.remove("active");
                    disableCell(oppositeCell);
                }

                const disableCellCondition = 
                (
                    oppositeCTV.classList.contains("active") && 
                    oppositeBarSeparator.classList.contains("active") &&
                    oppositeLineSeparator.classList.contains("active") && 
                    oppositeGenerator.classList.contains("active") && 
                    oppositeIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active")
                ) ||
                (
                    currentCTV.classList.contains("active") &&
                    oppositeBarSeparator.classList.contains("active") &&
                    oppositeLineSeparator.classList.contains("active") && 
                    oppositeGenerator.classList.contains("active") &&  
                    oppositeIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active")
                );

                
                if (
                    (letter === "A" && !disableCellCondition) ||
                    (  
                        letter === "B" && 
                        clSepartorI.classList.contains("active") && 
                        clSeparatorII.classList.contains("active") &&
                        clIntreruptor.classList.contains("active") &&
                        !disableCellCondition
                    )
                ) {
                    const cellI = document.querySelector(`.cell-CM${currentNumber}A`);
                    cellI.classList.remove("active");
                    cellI.classList.add("inactive");
                    disableCell(cellI);
                }

                if (
                    (letter === "B" && !disableCellCondition) ||
                    (  
                        letter === "A" && 
                        clSepartorI.classList.contains("active") && 
                        clSeparatorII.classList.contains("active") &&
                        clIntreruptor.classList.contains("active") && 
                        !disableCellCondition
                    )
                ) {
                    const cellII = document.querySelector(`.cell-CM${currentNumber}B`);
                    cellII.classList.remove("active");
                    cellII.classList.add("inactive");
                    disableCell(cellII);
                }

                if (oppositeIntreruptor.classList.contains("inactive") || oppositeGenerator.classList.contains("inactive")) {
                        
                    const otherCellA = document.querySelector(`.cell-CM${oppositeNumber}A`);
                    const otherCellB = document.querySelector(`.cell-CM${oppositeNumber}B`);
                    const currentCellA = document.querySelector(`.cell-CM${number}A`);
                    const currentCellB = document.querySelector(`.cell-CM${number}B`);
                    otherCellA.classList.remove("active");
                    otherCellA.classList.add("inactive");
                    otherCellB.classList.add("inactive");
                    otherCellB.classList.remove("active");
                    currentCellA.classList.add("inactive");
                    currentCellA.classList.remove("active");
                    currentCellB.classList.add("inactive");
                    currentCellB.classList.remove("active");
                    disableCell(otherCellA);
                    disableCell(otherCellB);
                    disableCell(currentCellA);
                    disableCell(currentCellB);
                }
                
            } else {
                const clpSeparator = document.querySelector(`.separator-${generator.getAttribute("id")}[number="4"]`)
                
                generator.classList.add("active");
                generator.classList.remove("inactive");
                console.log(generatorInchis);

                image.src = generatorInchis;

                const letterLine = generatorId === "PelicanuI" ? "B" : "A";
                const barSeparatorI = document.querySelector(`.separator-${generatorId}[number="1"]`);
                const barSeparatorII = document.querySelector(`.separator-${generatorId}[number="2"]`);
                const lineSeparator = document.querySelector(`.separator-${generatorId}[number="3"]`);
                const intreruptor = document.querySelector(`.intreruptor-${generatorId}`);

                const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                const oppositeNumberLine = (numberLine === "I" ? "II" : "I") + letterLine;

                const line = numberLine + letterLine;
                
                const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                if (cellSeparator.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${line}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                const oppositeLine = numberLine + oppositeLetterLine; 

                const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                const otherOppositeLine = (numberLine === "I" ? "II" : "I") + (letterLine === "A" ? "B" : "A");
                const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${numberLine === "I" ? "II" : "I"}`);
                const otherOppositeLineSeparatorCM = document.querySelector(`.separator-CM${otherOppositeLine}`);
                const otherCellSeparator = document.querySelector(`.separator-CM${oppositeNumberLine}[number="3"]`);

                
                const currentCTV = document.querySelector(`.intreruptor-CTV${letterLine}`);

                if (currentCTV.classList.contains("active") && otherCellSeparator.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${oppositeNumberLine}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                if (currentCTV.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active") && otherOppositeLineSeparatorCM.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${otherOppositeLine}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning

                oppositeCM.classList.add("active");
                oppositeCM.classList.remove("inactive");
                enableCell(oppositeCM);
            }

        };
    
        const disableCell = (cell, fromLineSeparatorCM = false) => {
            const line = cell.getAttribute("id").slice(2);
            const barSeparators = document.querySelectorAll(`.separator[line="${line}"]`);
            
            if (!fromLineSeparatorCM) {
                barSeparators.forEach(barSeparator => {
                    const barSeparatorId = barSeparator.getAttribute("id");
            
                    if (barSeparator.classList.contains("active")) {
                        const consumer = document.querySelector(`.consumer-${barSeparatorId}`);
                        if (consumer) {
                            consumer.classList.add("inactive");
                            consumer.classList.remove("active");
                            disableConsumer(consumer);
                        }
                    
                    }
                });
            }
    
        
    
            const circleText = cell.querySelector('.text-circle');
            circleText.innerText = '';
    
        
            try {
                // setCookie(`learning-${cell.getAttribute("id")}-cell`, "0");
            } catch (e) {
                alert(e);
            }
            
        
        };
    
        const enableCell = (cell, textAppearing = true) => {
            const line = cell.getAttribute("id").slice(2);
            const barSeparators = document.querySelectorAll(`.separator[line="${line}"]`);
    
            barSeparators.forEach(barSeparator => {
                const barSeparatorId = barSeparator.getAttribute("id");
                
                const number = Number(barSeparator.getAttribute("number"));
                const otherBarSeparator = document.querySelector(`.separator-${barSeparatorId}[number="${number === 1 ? 2 : 1}"]`);
                const lineSeparator = document.querySelector(`.separator-${barSeparatorId}[number="3"]`);
                const intreruptor = document.querySelector(`.intreruptor-${barSeparatorId}`);
                
                if (barSeparator.classList.contains("active") && (lineSeparator ? lineSeparator.classList.contains("active") : true) && intreruptor.classList.contains("active") && otherBarSeparator.classList.contains("inactive")) {
                    const consumer = document.querySelector(`.consumer-${barSeparatorId}`);
                    if (consumer) {
                        consumer.classList.add("active");
                        consumer.classList.remove("inactive");
                        enableConsumer(consumer);
                    }
        
                }
                
            });
    
            if (textAppearing) {
    
                try {
                    const circle = cell.querySelector(".text-circle");
                    const barSeparatorIPelicanuI = document.querySelector(`.separator-PelicanuI[number="1"]`);
                    const barSeparatorIIPelicanuI = document.querySelector(`.separator-PelicanuI[number="2"]`);
                    const lineSeparatorPelicanuI = document.querySelector(`.separator-PelicanuI[number="3"]`);
                    const intreruptorPelicanuI = document.querySelector(`.intreruptor-PelicanuI`);
                    const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                    
                    const barSeparatorIPelicanuII = document.querySelector(`.separator-PelicanuII[number="1"]`);
                    const barSeparatorIIPelicanuII = document.querySelector(`.separator-PelicanuII[number="2"]`);
                    const lineSeparatorPelicanuII = document.querySelector(`.separator-PelicanuII[number="3"]`);
                    const intreruptorPelicanuII = document.querySelector(`.intreruptor-PelicanuII`);
                    const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
            
            
                    if (barSeparatorIPelicanuI.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                        circle.innerText = 'P1';
                    } 
                    if (barSeparatorIIPelicanuI.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                        circle.innerText = 'P1';
                    }
                    
                    if (barSeparatorIPelicanuII.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                        circle.innerText = 'P2';
                    } 
                    if (barSeparatorIIPelicanuII.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                        circle.innerText = 'P2';
                    }
                
                } catch (e) {
                    alert(e);
                }
            
            
            }
    
            try {
                // setCookie(`learning-${cell.getAttribute("id")}-cell`, "1");
            } catch (e) {
                alert(e);
            }
            
            
        };
    
        const disableConsumer = (consumer) => {
            try {
                // setCookie(`learning-${consumer.getAttribute("id")}-consumer`, "0");
            } catch (e) {
                alert(e);
            }
            
        };
    
        const enableConsumer = (consumer) => {
            try {
                // setCookie(`learning-${consumer.getAttribute("id")}-consumer`, "1");
            } catch (e) {
                alert(e);
            }
        };
    


        const handleClick = async (e) => {

            if (!startSession || fileManoeuverActive) return;

            if (!verifyManoeuver) {
                // +1 mistake for student & text to speech for wrong manoeuver
                console.log(currService);
                if (!isPlayingAudio()) await textToSpeech(cookies?.language === "en" ? "Wrong manoeuver!" : "Manevră greșită!", true);
                

                return;
            }
            

            let element = e.target;
            if (element.classList.contains("line") || element.tagName.toLowerCase() === "img") element = element.parentElement;
            console.log(element?.classList?.[0], element?.getAttribute("number"), scripts?.[0]);


            let result;

            if (element?.classList?.[0] === scripts?.[0]?.name && (element?.getAttribute("number") == null || element.getAttribute("number").toString() === scripts?.[0]?.number.toString()) && element.getAttribute("id").toString() === scripts?.[0]?.cell.toString()) {
                // Correct
                result = {
                    type: 'correct',
                };
            } else {
                // Wrong
                result = {
                    type: 'wrong'
                };

            }

            // if (!currentLogs.some(log => JSON.stringify(log) === JSON.stringify(result) && result.type === "correct")) 
            setCurrentLogs(currLogs => [...currLogs, result]);

            if (result.type === "correct") {

                setScripts(currScripts => currScripts.filter((_, idx) => idx !== 0));
                setCookieSavedElements(currElements => [...currElements, {
                    name: `learning-${element.getAttribute("id")}-${element?.classList?.[0]}${element?.getAttribute("number") ? `-${element?.getAttribute("number")}` : ""}`,
                    value: element.classList.contains("active")
                }]);


                element.style.borderColor = "#000";
                element.classList.remove("active-select");

                if (element.classList.contains("generator")) generatorFunctionality(element);
                else if (element.classList.contains("intreruptor")) refreshIntreruptor(element);
                else if (element.classList.contains("separator") && Number(element.getAttribute("number")) === 4) clpSeparatorEvent(element);
                else if (element.classList.contains("separator") && Number(element.getAttribute("number")) === 3) refreshSeparator(element);
                else if (element.classList.contains("separator")) separatorFunctionality(element);

                setVerifyManoeuver(false);

            } else {
                return await textToSpeech(cookies?.language === "en" ? "Wrong manoeuver!" : "Manevră greșită!");
            }
            
        };

        const elements = document.querySelectorAll("div[id]:not(.consumer):not(.cell):not(#root)");

        elements.forEach(element => {
           element.addEventListener("click", handleClick); 
        });


        return () => {

            elements.forEach(element => {
                element.removeEventListener("click", handleClick);
            });
        };
    
    }, [cookies, verifyManoeuver]);

    useEffect(() => {
        console.log(currentLogs);
    }, [currentLogs]);

    useEffect(() => {
       
    }, [currentLogs]);

    useEffect(() => {
        if (!scripts?.length) return;
        if (!scripts?.[0]?.name) return;
        if (!verifyMousePosition) return;
        const element = scripts[0].number != null ? document.querySelector(`.${scripts[0].name}[number="${scripts[0].number}"][id="${scripts[0].cell}"]`) : document.querySelector(`.${scripts[0].name}[id="${scripts[0].cell}"]`);
        console.log(element);
        const targetX = element.getBoundingClientRect().left + (element.getBoundingClientRect().width / 2) + window.scrollX;
        const targetY = element.getBoundingClientRect().top + (element.getBoundingClientRect().height / 2) + window.scrollY;

        let verified = false;

        const mouseMoveEvent = async (e) => {
            if (verified) return;
            const clientX = e.clientX;
            const clientY = e.clientY;
            
            let currentElementHover = document.elementFromPoint(clientX, clientY);

            while (currentElementHover && currentElementHover !== element) {
                currentElementHover = currentElementHover?.parentElement;
            }

            if (currentElementHover === element) {
                verified = true;
                element.style.borderColor = "#4481eb";
                element.classList.add("active-select");
                await textToSpeech(cookies?.language === "en" ? "Correct, execute!" : "Corect, execută!", true);
                setVerifyManoeuver(true);
                setVerifyMousePosition(false);


                // Time update for student execution
                setCurrentManoeuverFile(currFileManoeuver => {
                    let found = false, idxs = [];
                    currFileManoeuver.forEach((elem, idx) => {
                        if (!found && !elem.finished && idx !== 0) {
                            found = true;
                            idxs.push(idx);
                        }
                    });

                    let currentIdx = idxs[0] + 1;
                    while (currentIdx < currFileManoeuver.length && currFileManoeuver?.[currentIdx]?.autoComplete) {
                        idxs.push(currentIdx);
                        currentIdx++;
                    }

                    return currFileManoeuver.map((elem, idx) => {
                        if (idxs.includes(idx)) return {...elem, 
                            finished: true, 
                            studentExecution: `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`,
                            dispatcherExecution: elem?.dispatcherExecution?.length ? elem?.dispatcherExecution : `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`
                        };

                        return elem;
                        
                    });
                });
            }
        };
        
        window.addEventListener("mousemove", mouseMoveEvent);

        return () => {
            window.removeEventListener("mousemove", mouseMoveEvent);
        };
    }, [verifyMousePosition]);
    


    const scenarioPaperRef = useRef();

    useEffect(() => {
        if (!scenarioPaperRef?.current) return;
        const timer = setTimeout(() => {
            scenarioPaperRef?.current?.classList.add("active");
        }, 400);

        return () => {
            clearTimeout(timer);
        };
    }, [scenarioPaperRef]);


    useEffect(() => {
        const consumers = document.querySelectorAll(".consumer");

        consumers.forEach(consumer => {
            consumer.style.left = `${consumer.getBoundingClientRect().left + 2.5}px`; 
        });

    }, []);



    const finishSessionStudent = async () => {

        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                sessionId: sessionId,
                teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
                studentId: data.userData.userId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-learning/finish-session-student`, options);
            const response = await request.json();
            if (response.success) window.location.replace(`/session-learning/${sessionId}/leaderboard`);

        } catch (e) {
            console.log(e);
        }
    };

    const page1FrontRef = useRef();
    const page1BackRef = useRef();
    const page2FrontRef = useRef();
    const page2BackRef = useRef();
    const acceptContainerRef = useRef();

    const printManoeuverFile = async () => {
        
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [297, 210]
        });

        acceptContainerRef.current.style.display = 'none';

        // pageDataRef.current.style.display = 'flex';


        if (page1FrontRef.current.parentElement.classList.contains("flipped")) 
            page1FrontRef.current.classList.add("flip-y");

        const canvas1 = await html2canvas(page1FrontRef?.current);
        const imgData = canvas1.toDataURL('image/png');


        pdf.addImage(imgData, "png", 0, 0, 210, 297); 
        pdf.addPage();

        if (!page1BackRef.current.parentElement.classList.contains("flipped")) 
            page1BackRef.current.classList.add("back-flip-y");

        const canvas2 = await html2canvas(page1BackRef?.current);
        const imgData2 = canvas2.toDataURL("image/png");

        pdf.addImage(imgData2, "png", 0, 0, 210, 297);
        pdf.addPage();

        if (page2FrontRef.current.parentElement.classList.contains("flipped")) 
            page2FrontRef.current.classList.add("flip-y");

        const canvas3 = await html2canvas(page2FrontRef?.current);
        const imgData3 = canvas3.toDataURL("image/png");

        pdf.addImage(imgData3, "png", 0, 0, 210, 297);
        pdf.addPage();

        if (!page2BackRef.current.parentElement.classList.contains("flipped")) 
            page2BackRef.current.classList.add("back-flip-y");

        const canvas4 = await html2canvas(page2BackRef?.current);
        const imgData4 = canvas4.toDataURL("image/png");

        pdf.addImage(imgData4, "png", 0, 0, 210, 297);

        acceptContainerRef.current.style.display = 'flex';

        page1FrontRef.current.classList.remove("flip-y");
        page1BackRef.current.classList.remove("back-flip-y");
        page2FrontRef.current.classList.remove("flip-y");
        page2BackRef.current.classList.remove("back-flip-y");

        pdf.save(cookies?.language === "en" ? `Manoeuver_file.pdf` : `Foaie_de_manevra.pdf`);
    };


    return (
        <>
            <div className="scheme-container">
                {schemeName.toLowerCase() === "srs" ?  <SRS data={data} /> : null}
                
                <div className="project-name">
                    <h2>{sessionName || ""}</h2>
                </div>

                <div className="scenario-button" onClick={() => {
                    setFileManoeuverActive(true);
                }}>
                    <FontAwesomeIcon icon={faBookOpen} className="scenario-icon" />
                </div>

                {/* <div className="scenario-paper" ref={scenarioPaperRef}>
                    <h2>{cookies?.language === "en" ? "" : ""}</h2>
                    {scenario === "shock" ? 
                        (cookies?.language === "en" ? <p>You are in a critical situation. One of your colleagues was electro-shocked in  <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. You will need to execute the necesarry manoeuveres in order to get him out of that situation. <br /> Good luck! </p> : <p>Te afli intr-o situatie critica. Unul dintre colegii tai a fost electrocutat in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. Tu va trebui sa executi manevrele necesare pentru a-l scoate din acea situatie. <br /> Mult succes! </p>) :
                        (cookies?.language === "en" ? <p>You are in a critical situation. It will be a future fire in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. You will need to execute the necesarry manoeuveres in order to avoid the future fire spread in our electrical system. <br /> Good luck! </p> : <p>Te afli intr-o situatie critica. Urmeaza sa fie un viitor incendiu in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. Tu va trebui sa executi manevrele necesare pentru a preveni viitorul incendiu. <br /> Mult succes! </p>)
                    }

                    <div className="close-button" onClick={() => scenarioPaperRef.current.classList.remove("active")}>
                        <FontAwesomeIcon className="close-icon" icon={faTimes}/>
                    </div>

                    {scenario === "shock" ? <img src={electrocutareColeg} alt="electrocutare coleg imagine" /> : null}
                </div> */}

                {/* <audio ref={googleAudioRef} style={{ display: 'none' }} /> */}
                
                
                {/* <div className="start-session-button high-tension" onClick={async () => await finishSessionStudent()}>
                    {<p>{cookies?.language === "en" ? "Finish session" : "Termina in sesiune"}</p>}
                    <FontAwesomeIcon icon={faFlagCheckered} className="button-icon" />
                </div> */}

                {startSession && !fileManoeuverActive ? <div className={`microphone-container ${isRecordingAudio ? "recording-audio" : "not-show"}`} onClick={() => isRecordingAudio ? finishRecordingAudio() : startRecordingAudio()}>
                    <FontAwesomeIcon icon={faMicrophone} className="microphone-icon" />
                    {isRecordingAudio ? <p>{cookies?.language === "en" ? "Stop Recording" : "Oprire inregistrare"}</p> : <p>{cookies?.language === "en" ? "Speak" : "Vorbește"}</p>}
                    {isRecordingAudio ? <div className="recording-dot" /> : null}

                </div> : null}

                <div className="clock-container">
                    <h2>{parseInt(currentTime / 60) < 10 ? `0${parseInt(currentTime / 60)}` : parseInt(currentTime / 60)}:{currentTime % 60 < 10 ? `0${currentTime % 60}` : currentTime % 60}</h2>
                </div>

                {startSession && !fileManoeuverActive ? <div className={`microphone-container close-container left-container not-show`} onClick={() => {
                    navigate(-1);
                }}>
                    <FontAwesomeIcon icon={faTimes} className="microphone-icon" />
                    <p>{cookies?.language === "en" ? "Close session" : "Închide sesiunea"}</p> 
                </div> : null}

                <button type="button" className="tst" style={{ display: 'none' }} />
            </div>

            
            {/* <div className="start-button-container active">
                <button type="button" className="start-button" onClick={(e) => startButton(e)}>{cookies?.language === "en" ? "Start" : "Start"}</button>
            </div> */}

    

            {currentManoeuverFile?.length ? <div className={`manoeuver-big-file ${fileManoeuverActive ? "active" : ""}`}>

                <button className="button-manoeuver-file prev-button hide" ref={prevPageRef}>
                    <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
                </button>

                <div className="manoeuver-file" ref={bookRef}>
                    <div ref={page1Ref} className="paper-manoeuver-file page-1">

                        <div className="front" ref={page1FrontRef}>
                            <div className="first-row">
                                <div className="col-1">
                                    <p>Societatea S.A. ...................</p>
                                    <p>{cookies?.language === "en" ? "Unit" : "Unitate"} : .................................</p>
                                    <p>{cookies?.language === "en" ? "Sub-unit" : "Subunitate"} : .........................</p>
                                </div>

                                <div className="col-2">
                                    <p>{cookies?.language === "en" ? "Creator (name, date, signature)" : "Întocmit (nume, data, semnatura)"}</p> 
                                    <p>................................................................................</p>
                                    <p>{cookies?.langauge === "en" ? "Approved (name, date, signature)" : "Aprobat (nume, data, semnatura)"}</p>
                                    <p>................................................................................</p>
                                    <p>{cookies?.language === "en" ? "Controlled (name, date, signature)" : "Controlat (nume, data, semnatura)"}</p>
                                    <p>................................................................................</p>
                                </div>
                            </div>

                            <h2 className="file-title">{cookies?.language === "en" ? "EXECUTION MANEUVER SHEET NO." : "FOAIE DE MANEVRĂ DE EXECUȚIE NR."} {sessionIdx}</h2>

                            <div className="row-manoeuver-file">
                                <p style={{ fontWeight: "bold" }}>{cookies?.language === "en" ? `Theme: Broughting in ${currentScenario === "closing-cell" ? "a normal operating state" : currentScenario === "disconnected" ? "a disconnected state" : currentScenario === "warm-condition" ? "a warm state" : currentScenario === "warm-reserve" ? "a warm reserve" : currentScenario === "ssv" ? "a separately visible state" : currentScenario === "cold-condition" ? "a cold state" : currentScenario === "cold-reserve" ? "a cold reserve" : currentScenario === "slp" ? "ground state" : ""} ${schemeName}-${cell}` : `Tema: Aducerea în ${currentScenario === "closing-cell" ? "stare normală de funcționare" : currentScenario === "disconnected" ? "stare deconectată" : currentScenario === "warm-condition" ? "stare caldă" : currentScenario === "warm-reserve" ? "rezerva caldă" : currentScenario === "ssv" ? "stare separat vizibilă" : currentScenario === "cold-condition" ? "stare rece" : currentScenario === "cold-reserve" ? "rezerva rece" : currentScenario === "slp" ? "stare legată la pământ" : ""} ${schemeName}-${cell}`}</p>
                                <p>{cookies?.language === "en" ? `Purpose: Revision at ${schemeName}-${cell}` : `Scopul: Revizie la ${schemeName}-${cell}`}</p>
                                <p>{cookies?.language === "en" ? `I.O.S. : ${(currentScenario === "opening-cell" ? "According to the disconnected scheme." : "According to the normal operating scheme")}` : `S.O.I. : ${(currentScenario === "opening-cell" ? "Conform schemei deconectate." : "Conform schemei normale de funcționare.")}`}</p>
                            </div>

                            <div className="flex-row" style={{ marginTop: "1vw" }}>
                                <div className="col">
                                    <p style={{ fontWeight: "600" }}>{cookies?.language === "en" ? "A. Withdrawal from operation" : "A. RETRAGEREA DIN EXPLOATARE"}</p>
                                    <p>{cookies?.language === "en" ? " : ..........................." : `R.M.E. : ...........................`}</p>
                                    <p>{cookies?.language === "en" ? " : ..............................." : `E.M. : ...............................`}</p>
                                    <p>{cookies?.language === "en" ? " : .........................." : `R.M.C. : ..........................`}</p>
                                    <p>{cookies?.language === "en" ? " : ............................": `T.C.A. : ............................`}</p>
                                    <p>{cookies?.language === "en" ? `Data executarii : ${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}.${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}.${new Date().getFullYear()}` : `Data executarii : ${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}.${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}.${new Date().getFullYear()}`}</p>

                                </div>

                                <div className="col">
                                    <p style={{ fontWeight: "600" }}>{cookies?.language === "en" ? "B. RETURN" : "B. REVENIREA"}</p>
                                    <p>{cookies?.language === "en" ? " : ..........................." : `R.M.E. : ...........................`}</p>
                                    <p>{cookies?.language === "en" ? " : ..........................." : `E.M. : ...........................`}</p>
                                    <p>{cookies?.language === "en" ? " : ..........................." : `R.M.C. : ...........................`}</p>
                                    <p>{cookies?.language === "en" ? " : ..........................." : `T.C.A. : ...........................`}</p>
                                    <p>{cookies?.language === "en" ? `Data executarii : ${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}.${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}.${new Date().getFullYear()}` : `Data executarii : ${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}.${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}.${new Date().getFullYear()}`}</p>

                                </div>
                            </div>
                            
                            <div className="table">
                                <div className="layout-1">

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Hour, minute" : "Ora, minut"}</p>
                                    </div>

                                    <div className="content-col">
                                        <p style={{ fontWeight: "bold", fontSize: ".85vw" }}>{cookies?.language === "en" ? "A. EXECUTION OF THE MANOEUVER" : "A. DESFĂȘURAREA MANEVREI"}</p>
                                    </div>
                                </div>

                                <div className="layout-2 layout-center">

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Ready execut." : "Dispus execut."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Exec. confirm." : "Confirm execut."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Current no." : "Nr. crt."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Succession" : "Succesiunea"}</p>
                                    </div>

                                </div>

                                {currentManoeuverFile.map((manoeuver, manoeuverIdx) => {
                                    if (manoeuverIdx === 0) return <></>;

                                    if (manoeuverIdx < reverseIdx && manoeuverIdx <= 8) return (
                                        <div className="layout-2 layout">
                                            <div className="content-col">
                                                <p>{manoeuver?.dispatcherExecution}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{manoeuver?.studentExecution}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{manoeuver?.number}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{manoeuver?.text}</p>
                                            </div>
                                        </div>
                                    );

                                    return <></>;
                                })}

                            </div>
                           
                        </div>

                        <div className="back" ref={page1BackRef}>
                            <div className="back-content">
                                <div className="table">
                                
                                    <div className="layout-2 layout-center">

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Ready execut." : "Dispus execut."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Exec. confirm." : "Confirm execut."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Current no." : "Nr. crt."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Succession" : "Succesiunea"}</p>
                                        </div>

                                    </div>

                                    {currentManoeuverFile.map((manoeuver, manoeuverIdx) => {
                                        if (manoeuverIdx < 9) return <></>;

                                        if (manoeuverIdx < reverseIdx) return (
                                            <div className="layout-2 layout">
                                                <div className="content-col">
                                                    <p>{manoeuver?.dispatcherExecution}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{manoeuver?.studentExecution}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{manoeuver?.number}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{manoeuver?.text}</p>
                                                </div>
                                            </div>
                                        );

                                        return <></>;
                                    })}

                                </div>
                            </div>
                           
                        </div>

                    </div>

                    <div ref={page2Ref} id="p2" className="paper-manoeuver-file page-2">

                        <div className="front"  ref={page2FrontRef}>
                            <div className="table">
                                <div className="layout-1">

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Hour, minute" : "Ora, minut"}</p>
                                    </div>

                                    <div className="content-col">
                                        <p style={{ fontWeight: "bold", fontSize: "0.85vw" }}>{cookies?.language === "en" ? "B. RETURN" : "B. REVENIRE"}</p>
                                    </div>
                                </div>

                                <div className="layout-2 layout-center">

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Ready execut." : "Dispus execut."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Exec. confirm." : "Confirm execut."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Current no." : "Nr. crt."}</p>
                                    </div>

                                    <div className="content-col">
                                        <p>{cookies?.language === "en" ? "Succession" : "Succesiunea"}</p>
                                    </div>

                                </div>

                                {currentManoeuverFile.map((file, idx) => {
                                    if (idx < reverseIdx || idx >= reverseIdx + 20) return <></>;
                                    return (
                                        <div className="layout-2 layout">

                                            <div className="content-col">
                                                <p>{currentManoeuverFile?.[idx]?.dispatcherExecution}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{currentManoeuverFile?.[idx]?.studentExecution}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{currentManoeuverFile?.[idx]?.number}</p>
                                            </div>

                                            <div className="content-col">
                                                <p>{currentManoeuverFile?.[idx]?.text}</p>
                                            </div>

                                        </div>
                                    );
                                })}

                                {!startSession && currentManoeuverFile?.length - 1 <= reverseIdx + 20 && !Boolean(cookies?.[`accept-${sessionId}`]) ? <div className="accept-container" ref={acceptContainerRef}>
                                    <div className="box" onClick={(e) => {
                                        setActiveBox(true);
                                        setTimeout(() => startButton(e), 2000);
                                        setTimeout(() => {
                                            setCookie(`accept-${sessionId}`, true);
                                        }, 3000);
                                    }}>
                                        {activeBox ? <FontAwesomeIcon icon={faCheck} className="check-icon" /> : null}
                                    </div>
                                    <p>{cookies?.language === "en" ? "I understand and I agree." : "Am înțeles și sunt de acord."}</p>

                                </div> : null}

                            </div>
                        </div>

                        <div className="back" ref={page2BackRef}>
                            <div id="b2" className="back-content">
                                {currentManoeuverFile?.length > reverseIdx + 20 ? <div className="table">
                                

                                    <div className="layout-2 layout-center">

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Ready execut." : "Dispus execut."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Exec. confirm." : "Confirm execut."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Current no." : "Nr. crt."}</p>
                                        </div>

                                        <div className="content-col">
                                            <p>{cookies?.language === "en" ? "Succession" : "Succesiunea"}</p>
                                        </div>

                                    </div>

                                    {currentManoeuverFile.map((file, idx) => {
                                        if (idx <= reverseIdx + 20) return <></>;
                                        return (
                                            <div className="layout-2 layout">

                                                <div className="content-col">
                                                    <p>{currentManoeuverFile?.[idx]?.dispatcherExecution}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{currentManoeuverFile?.[idx]?.studentExecution}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{currentManoeuverFile?.[idx]?.number}</p>
                                                </div>

                                                <div className="content-col">
                                                    <p>{currentManoeuverFile?.[idx]?.text}</p>
                                                </div>

                                            </div>
                                        );
                                    })}

                                </div> : null}

                                {!startSession && currentManoeuverFile?.length - 1 > reverseIdx + 20 && !Boolean(cookies?.[`accept-${sessionId}`]) ? <div className="accept-container">
                                    <div className="box" onClick={(e) => {
                                        setActiveBox(true);
                                        setTimeout(() => startButton(e), 1200);
                                    }}>
                                        {activeBox ? <FontAwesomeIcon icon={faCheck} className="check-icon" /> : null}
                                    </div>
                                    <p>{cookies?.language === "en" ? "I understand and I agree." : "Am înțeles și sunt de acord."}</p>
                                </div> : null}

                               

                            </div>
                        </div>
                        
                    </div>

        

                </div>

                <button className="button-manoeuver-file next-button" ref={nextPageRef}>
                    <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                </button>

                {!startSession || fileManoeuverActive ? <div className="print-button" onClick={() => {
                    printManoeuverFile();
                }}>
                    <FontAwesomeIcon icon={faPrint} className="print-icon" />
                    <p>{cookies?.language === "en" ? "Print" : "Printeaza"}</p>
                </div> : null}

                {(startSession && fileManoeuverActive) || Boolean(cookies?.[`accept-${sessionId}`]) ? <div className={`microphone-container close-container not-show`} onClick={(e) => {
                    startButton(e);
                    setFileManoeuverActive(false);
                }}>
                    <FontAwesomeIcon icon={faTimes} className="microphone-icon" />
                    <p>{cookies?.language === "en" ? "Close manoeuver sheet" : "Închide foaia de manevră"}</p> 
                </div> : null}

           

                <audio style={{ display: 'none' }} ref={pageAudioRef} src={pageAudio} />
                
            </div> : null}

        </>
       
    );
};

export default Learning;