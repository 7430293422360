import SRS from "../SRS/SRS";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import "./Testing.css";
import generatorImage from "../../imgs/generator2.svg";
import generatorInchis from "../../imgs/generator-inchis.svg";
import statie from "../../imgs/statie.svg";
import warningSoundSrc from "../../sounds/warning.mp3";
import errorSoundSrc from "../../sounds/real_error.mp3";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faComment, faFlagCheckered, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import electrocutareColeg from "../../imgs/electrocutare_coleg.gif";

const Testing = ({ data, problemsSolved, scenario, totalProblems, problems, logs, schemeName, sessionName, cell, semiStation }) => {

    const intreruptorArr = useMemo(() => [], []);

    const [cookies, setCookie] = useCookies();
    const [errorSound, setErrorSound] = useState();
    const [warningSound, setWarningSound] = useState();
    const errorSoundRef = useRef();
    const warningSoundRef = useRef();
    const { sessionId } = useParams();


    const [targetElements, setTargetElements] = useState();
    const [currentLogs, setCurrentLogs] = useState(logs || []);
    const [currentProblemsSolved, setCurrentProblemsSolved] = useState(problemsSolved || []);
    const [currentProblems, setCurrentProblems] = useState(problems);

    useEffect(() => {
        if (!errorSoundRef?.current) return;
        setErrorSound(errorSoundRef.current);
    }, [errorSoundRef]);

    useEffect(() => {
        if (!warningSoundRef?.current) return;
        // alert("A");
        setWarningSound(warningSoundRef.current);
    }, [warningSoundRef]);

    const errorJSON = (elem) => {
        try {
            console.log(JSON.stringify(elem));
        } catch (e) {
            return true;
        }

        return false;
    };

    useEffect(() => {

    });

    useLayoutEffect(() => {
        (async () => {

            console.log(currentProblemsSolved.filter(problemArr => !errorJSON(problemArr)), currentLogs, sessionId);
    
            try {
                const options = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        currLogs: currentLogs,
                        studentId: data.userData.userId,
                        teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
                        sessionId: sessionId,
                        problemsSolved: currentProblemsSolved.filter(problemArr => !errorJSON(problemArr))
                    })
                };

                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/edit-session-student-testing`, options);
                const response = await request.json();
                if (response.success) {
                    console.log("SUCCESS", currentLogs);
                } else console.log(response);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [currentLogs, currentProblemsSolved]);

    let inEffect = useMemo(() => false, []);

    useEffect(() => {
        if (inEffect) return;
        setTargetElements(problems.map(arrProblem => {
            return arrProblem.map(problem => {
                if (problem.number) return document.querySelector(`.${problem.name}[number="${problem.number}"]`);
                return document.querySelector(`.${problem.name}`);
            });
        }));

        inEffect = true;
    }, []);

    let currentElements;

    useEffect(() => {
        console.log(targetElements);
    }, [targetElements]);

    const realTargetElements = useMemo(() => targetElements, [targetElements]);

    const handleClick = (e) => {
        let element = e.target;
        if (element.classList.contains("line") || element.tagName.toLowerCase() === "img") element = element.parentElement;
        console.log(currentProblems);
        let result;
        console.log(targetElements);
        targetElements.forEach((group, groupIdx) => {
            group.forEach((currElement, elementIdx) => {
                if (currElement === element) {

                    const isOtherCellStarted = currentProblems?.find((problemGroup, groupIdx2) => groupIdx2 !== groupIdx ? problemGroup.some(elem => elem?.started) : false)?.[0]?.name?.split("-")?.[1];
                    console.log(isOtherCellStarted);
                    const isCLP = element.classList.contains("separator") && Number(element?.getAttribute("number") || "-1") === 4;
                    const targetElementsFiltered = targetElements.map(currGroup => currGroup.filter((elem) => !(elem.classList.contains("separator") && Number(elem?.getAttribute("number") || "-1") === 4)));
                    const emptyTargetElements = targetElementsFiltered.every(currGroup => currGroup.length === 0);

                    if (elementIdx === 0 && (cell === "*" && isCLP ? emptyTargetElements : true) && !isOtherCellStarted) {
                        result = {
                            type: "correct",
                            message: cookies?.language === "en" ? `The student manuevered ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "of bar type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "of line type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "of knife tied to the ground type" : ""} from ${element.getAttribute("id")}.` : `Elevul a manevrat ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "de bara" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "de linie" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "de tip C.L.P" : ""} de la ${element.getAttribute("id")}.`
                        };

                   
                        // setCurrentProblemsSolved(currData => [...currData, element]);
                        
                    } else if (cell === "*" && isCLP && !emptyTargetElements) {
                        result = {
                            type: "wrong",
                            message: cookies?.language === "en" ? `The student manuevered ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "of bar type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "of line type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "of knife tied to the ground type" : ""} from ${element.getAttribute("id")} and this should have been manuevered at the end.` : `Elevul a manevrat ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "de bara" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "de linie" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "de tip C.L.P" : ""} de la ${element.getAttribute("id")}, iar acesta ar fi trebuit manevrat la final.`
                        };
                    } else if (isOtherCellStarted) {
                        result = {
                            type: "wrong",
                            message: cookies?.language === "en" ? `The student manuevered ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "of bar type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "of line type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "of knife tied to the ground type" : ""} from ${element.getAttribute("id")}, while he / she didn't finish manuevering the cell ${isOtherCellStarted}.` : `Elevul a manevrat ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "de bara" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "de linie" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "de tip C.L.P" : ""} de la ${element.getAttribute("id")}, insa acesta nu a terminat de manevrat ${isOtherCellStarted}.`
                        };
                    } else result = {
                        type: "wrong",
                        message: cookies?.language === "en" ? `The student maneuvered ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "of bar type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "of line type" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "of knife tied to the ground type" : ""} from ${element.getAttribute("id")}, instead of maneuvering ${currentProblems?.[groupIdx]?.[0]?.name?.split("-")?.[0]} from ${group?.[0]?.getAttribute("id")}.` : `Elevul a manevrat ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[elementIdx]?.name?.split("-")?.[elementIdx]} ${currentProblems?.[groupIdx]?.[elementIdx]?.number === 2 || currentProblems?.[groupIdx]?.[elementIdx]?.number === 1 ? "de bara" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 3 ? "de linie" : currentProblems?.[groupIdx]?.[elementIdx]?.number === 4 ? "de tip C.L.P" : ""} de la ${element.getAttribute("id")}, in loc de a manevra ${currentProblems?.[groupIdx]?.[0]?.name?.split("-")?.[0]} ${currentProblems?.[groupIdx]?.[0]?.number === 2 || currentProblems?.[groupIdx]?.[0]?.number === 1 ? "de bara" : currentProblems?.[groupIdx]?.[0]?.number === 3 ? "de linie" : currentProblems?.[groupIdx]?.[0]?.number === 4 ? "de tip C.L.P" : ""} de la ${group?.[0]?.getAttribute("id")}.`
                    };

                    setTargetElements(currTargetElements => {
                        return currTargetElements.map(currGroup => currGroup.filter((groupElem) => groupElem !== currElement));
                    });

                    if (!isOtherCellStarted) setCurrentProblems(currData => {
                        return currData.map((group, groupIdx2) => {
                            const newGroup = group.filter((elem, elemIdx2) => elemIdx2 !== elementIdx || groupIdx2 !== groupIdx);
                            return newGroup.map((element) => {
                                if (groupIdx === groupIdx2 && newGroup.length !== 0) return {...element, started: true};
                                return {...element, started: null};
                            });
                        });
                    });

                } 
            }); 
        });

        if (!result) result = {
            type: "warning",
            message: cookies?.language === "en" ? `The student maneuvered ${element.classList?.[0]} from ${element.getAttribute("id")} and he / she shouldn't have done it.` : `Elevul a manevrat ${element.classList[0]} de la ${element.getAttribute("id")} si nu ar fi trebuit maenvrat.`
        };

        console.log(result);

        if (!currentLogs.some(log => JSON.stringify(log) === JSON.stringify(result) && result.type === "correct")) 
            setCurrentLogs(currLogs => [...currLogs, result]);

        if (result.type === "correct") {
            console.log(currentProblemsSolved, element.classList?.[1], element.getAttribute("number"));
            setCurrentProblemsSolved(currProblemsSolved => {
                return currProblemsSolved.map((problemArr) => {
                    return problemArr.map((problem) => {

                        if (problem.name === `${element.classList?.[1]}` && ((problem?.number === null) || problem?.number?.toString() == (element?.getAttribute("number"))))
                            return {...problem, solved: true};
                        return problem;
                    });
                });
            });
        }

    };

    useEffect(() => {
        if (!targetElements) return;
        const elements = document.querySelectorAll("div[id]:not(.consumer):not(.cell):not(#root)");
        console.log(targetElements, problems);

        elements.forEach(element => {
            element.addEventListener("click", handleClick);
        });

        return () => {
            elements.forEach(element => {
                element.removeEventListener("click", handleClick);
            });
        };
        
    }, [targetElements, currentProblems]);

    useEffect(() => {
        console.log(schemeName);
        let noCookie = cookies?.["testing-CMIIA-cell"];
        if (schemeName === "SRS") {
            setCookie("testing-CMIIA-cell", 1);
            setCookie("testing-OE1-consumer", 1);
            setCookie("testing-CMIIB-cell", 1);
            setCookie('testing-PelicanuI-separator-2', 1);
            setCookie("testing-OE3-consumer", 1);
            setCookie("testing-OE3-intreruptor", 1);
            setCookie("testing-PelicanuI-generator", 1);
            setCookie("testing-LPGS2-consumer", 1);
            setCookie("testing-OE4-consumer", 1);
            setCookie("testing-3T-consumer", 1);
            setCookie("testing-2T-consumer", 1);
            setCookie("testing-CLII-separator-1", 1);
            setCookie("testing-OE4-separator-3", 1);
            setCookie("testing-PelicanuII-intreruptor", 1);
            setCookie("testing-OE3-separator-3", 1);
            setCookie("testing-CLI-separator-1", 1);
            setCookie("testing-OE3-separator-1", 1);
            setCookie("testing-OE1-intreruptor", 1);
            setCookie("testing-CMIA-cell", 1);
            setCookie("testing-CLII-separator-2", 1);
            setCookie("testing-CMIA-separator-3", 1);
            setCookie("testing-CMIA-cell", 1);
            setCookie("testing-CLII-separator-1", 1);
            setCookie("testing-OE4-separator-3", 1);
            setCookie("testing-PelicanuII-intreruptor", 1);
            setCookie("testing-OE3-separator-3", 1);
            setCookie("testing-CLI-separator-1", 1);
            setCookie("testing-OE3-separator-1", 1);
            setCookie("testing-OE4-consumer", 1);
            setCookie("testing-OE1-separator-2", 1);
            setCookie("testing-CMIIA-separator-3", 1);
            setCookie("testing-CMIIB-separator-3", 1);
            setCookie("testing-CMIB-separator-3", 1);
            setCookie("testing-OE2-intreruptor", 1);
            setCookie("testing-PelicanuI-intreruptor", 1);
            setCookie("testing-CLI-intreruptor", 1);
            setCookie("testing-OE2-consumer", 1);
            setCookie("testing-PelicanuII-separator-1", 1);
            setCookie("testing-LPGS2-separator-1", 1);
            setCookie("testing-CLI-separator-2", 1);
            setCookie("testing-3T-separator-1", 1);
            setCookie("testing-2T-separator-1", 1);
            setCookie("testing-LPGS2-intreruptor", 1);
            setCookie("testing-PelicanuII-generator", 1);
            setCookie("testing-3T-intreruptor", 1);
            setCookie("testing-PelicanuII-separator-3", 1);
            setCookie("testing-OE1-intreruptor", 1);
            setCookie("testing-PelicanuI-separator-3", 1);
            setCookie("testing-OE1-separator-3", 1);
            setCookie("testing-LPGS2-separator-3", 1);
            setCookie("testing-OE3-intreruptor", 1);
            setCookie("testing-OE2-separator-3", 1);
            setCookie("testing-OE4-intreruptor", 1);
            setCookie("testing-OE4-separator-1", 1);
            setCookie("testing-OE2-separator-2", 1);
            setCookie("testing-3T-consumer", 1);
            setCookie("testing-CLII-intreruptor", 1);
            setCookie("testing-CMIB-cell", 1);
            setCookie("testing-2T-intreruptor", 1);

        }
        // const timer = setTimeout(() => window.location.reload(), 500)
    }, []);

    

    useEffect(() => {
        const timer1 = setTimeout(() => {
            window.scrollTo( 0, 30 );
        }, 50);
    
        const timer2 = setTimeout(() => {
            
            cookies && Object.keys(cookies).forEach(cookiec => {

                if (cookiec?.includes("testing")) {
                    let cookie = cookiec.replaceAll("testing-", "");
                    if (cookie !== "user-id" && cookie.includes("-")) {
                        const cookieSplits = cookie.split("-");
                    
                        const cookieName = cookieSplits[0];
                        const cookieType = cookieSplits[1];
                        const cookieValue = Number(cookies[cookiec]) === 1;
                    
                        if (cookieValue) {

                            const elements = document.querySelectorAll(`.${cookieType}-${cookieName}`);
                            console.log(elements, cookieSplits)
                            if (elements && elements.length) {
                                if (cookieSplits.length === 3) {
                                    const cookieNumber = cookieSplits[2];
                                    const currentElement = [...elements].find(element => Number(element.getAttribute("number")) === Number(cookieNumber));
                                    if (currentElement) {
                                        currentElement.classList.add("active");
                                        currentElement.classList.remove("inactive");
                                    }
                                } else {
                                    elements[0].classList.add("active");
                                    elements[0].classList.remove("inactive");
                                    if (cookieType === "generator") {
                                        // alert("AA");
                                        const image = elements[0].querySelector("img");
                                        image.src = generatorInchis;
                                    } else if (cookieType === "cell") {
                                        try {
                                            const circle = elements[0].querySelector(".text-circle");
                                            const line = cookieName.slice(2);
                                            const barSeparatorIPelicanuI = document.querySelector(`.separator-PelicanuI[number="1"]`);
                                            const barSeparatorIIPelicanuI = document.querySelector(`.separator-PelicanuI[number="2"]`);
                                            const lineSeparatorPelicanuI = document.querySelector(`.separator-PelicanuI[number="3"]`);
                                            const intreruptorPelicanuI = document.querySelector(`.intreruptor-PelicanuI`);
                                            const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                                            
                                            const barSeparatorIPelicanuII = document.querySelector(`.separator-PelicanuII[number="1"]`);
                                            const barSeparatorIIPelicanuII = document.querySelector(`.separator-PelicanuII[number="2"]`);
                                            const lineSeparatorPelicanuII = document.querySelector(`.separator-PelicanuII[number="3"]`);
                                            const intreruptorPelicanuII = document.querySelector(`.intreruptor-PelicanuII`);
                                            const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                                    
                                    
                                            if (barSeparatorIPelicanuI.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                                                circle.innerText = 'P1';
                                            } 
                                            if (barSeparatorIIPelicanuI.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                                                circle.innerText = 'P1';
                                            }
                                            
                                            if (barSeparatorIPelicanuII.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                                                circle.innerText = 'P2';
                                            } 
                                            if (barSeparatorIIPelicanuII.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                                                circle.innerText = 'P2';
                                            }
                                        
                                        } catch (e) {
                                            alert(e);
                                        }
                                    }
                                }
                            }
                        
                        }
                    }
                }
            
            });
        
        }, 100);
    
    
        const separatorsOne = document.querySelectorAll('.separator[number="1"]');
        const separatorsTwo = document.querySelectorAll('.separator[number="2"]');
        const separators = [...separatorsOne, ...separatorsTwo];
    
        const lineSeparators = document.querySelectorAll('.separator[number="3"]');
        const clpSeparators = document.querySelectorAll('.separator[number="4"]');
        const intreruptors = document.querySelectorAll(".intreruptor");
        const messageContainer = document.querySelector('.message-content');
        const virIntreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
        const virIntreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
        const virCTVA = document.querySelector(".intreruptor-CTVA");
        const virCTVB = document.querySelector(".intreruptor-CTVB");
        const cellSeparators = [...lineSeparators].filter(separator => separator.getAttribute("id").includes("CM"));
    
        const refreshSeparator = (e) => {
            let lineSeparator = e?.target || e;
            while (lineSeparator && (!lineSeparator?.classList?.contains("separator") || !lineSeparator?.getAttribute("number")?.toString() === "3"))
                lineSeparator = lineSeparator.parentElement;

            console.log(lineSeparator);

            const separatorId = lineSeparator.getAttribute("id");
            // if (document.querySelector(`.separator[id="${separatorId}"][number="4"]`) && document.querySelector(`.separator[id="${separatorId}"][number="4"]`).classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `C.L.P de pe linia ${separatorId} trebuie sa fie deschis pentru a manevra separatorul de linie!`;
            //     messageContainer.classList.add("active");
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");
    
            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // } 
    
            if (lineSeparator.classList.contains("inactive")) {
                // if (document.querySelector(`.separator[id="${separatorId}"][number="1"]`) && document.querySelector(`.separator[id="${separatorId}"][number="1"]`).classList.contains('inactive') && document.querySelector(`.separator[id="${separatorId}"][number="2"]`) && document.querySelector(`.separator[id="${separatorId}"][number="2"]`).classList.contains('inactive')) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Un separator de bara de pe linia ${separatorId} trebuie sa fie inchis pentru a inchide separatorul de linie!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
    
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
                //     return;
                // }
    
                lineSeparator.classList.remove("inactive");
                lineSeparator.classList.add("active");
                try {
                    // setCookie(`testing-${separatorId}-separator-3`, '1');
                    
                } catch (e) {
                    alert(e);
                }
    
                if (separatorId.includes('CM')) {
                    const letterLine = separatorId.includes("A") ? "II" : "I";
                    const cellLine = separatorId.includes("II") ? "II" : "I";
                    const realLetterLine = separatorId.includes("A") ? "A" : "B";
    
                    const barSeparatorI = document.querySelector(`.separator-Pelicanu${letterLine}[number="1"]`);
                    const barSeparatorII = document.querySelector(`.separator-Pelicanu${letterLine}[number="2"]`);
                    const lineSeparator = document.querySelector(`.separator-Pelicanu${letterLine}[number="3"]`);
                    const generator = document.querySelector(`.generator-Pelicanu${letterLine}`);
                    const intreruptor = document.querySelector(`.intreruptor-Pelicanu${letterLine}`);
    
                    const otherLetterLine = letterLine === "I" ? "II" : "I";
    
                    const otherBarSeparatorI = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="1"]`);
                    const otherBarSeparatorII = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="2"]`);
                    const otherLineSeparator = document.querySelector(`.separator-Pelicanu${otherLetterLine}[number="3"]`);
                    const otherGenerator = document.querySelector(`.generator-Pelicanu${otherLetterLine}`);
                    const otherIntreruptor = document.querySelector(`.intreruptor-Pelicanu${otherLetterLine}`);
    
                    const ctvB = document.querySelector(`.intreruptor-CTVB`);
                    const ctvA = document.querySelector(`.intreruptor-CTVA`);
    
                    
                    const firstCondition = generator.classList.contains("inactive") || (cellLine === "II" && barSeparatorII.classList.contains("inactive") && ctvB.classList.contains("inactive")) || (cellLine === "I" && barSeparatorI.classList.contains("inactive") && ctvA.classList.contains("inactive")) || lineSeparator.classList.contains('inactive') || intreruptor.classList.contains('inactive'); 
                    const secondCondition = otherGenerator.classList.contains("inactive") || (cellLine === "II" && otherBarSeparatorII.classList.contains("inactive") && ctvB.classList.contains("inactive")) || (cellLine === "I" && otherBarSeparatorI.classList.contains("inactive") && ctvA.classList.contains("inactive")) || otherLineSeparator.classList.contains('inactive') || otherIntreruptor.classList.contains('inactive'); 
                    
                    // if (firstCondition && secondCondition) return;
                    
    
                    if (firstCondition && !secondCondition) {
                        const separatorsCL = [...document.querySelectorAll(`.separator-CL${cellLine}`)];
                        const intreruptorCL = document.querySelector(`.intreruptor-CL${cellLine}`);
    
                        if (intreruptorCL.classList.contains("active") && separatorsCL.every(separator => separator.classList.contains("active"))) {
        
                            const cell = document.querySelector(`.cell-${separatorId}`);
                            cell.classList.add("active");
                            cell.classList.remove("inactive");
                            enableCell(cell);
                        }
                        
                    } else {
                        const cell = document.querySelector(`.cell-${separatorId}`);
                        cell.classList.add("active");
                        cell.classList.remove("inactive");
                        enableCell(cell);
                    }
    
                    const oppositeLetterLine = realLetterLine === "A" ? "B" :"A";
                    const oppositeLine = cellLine + oppositeLetterLine; 
                    const separatorsCL = [...document.querySelectorAll(`.separator-CL${cellLine}`)];
                    const intreruptorCL = document.querySelector(`.intreruptor-CL${cellLine}`);
                    const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                    const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
    
    
                    if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                    if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                    if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
    
                    oppositeCM.classList.add("active");
                    oppositeCM.classList.remove("inactive");
                    enableCell(oppositeCM);
                }
    
    
                return;
            }
    
            const intreruptor = document.querySelector(`.intreruptor[id="${separatorId}"]`);
            // if (intreruptor && intreruptor.classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `Intreruptorul de pe linia ${separatorId} trebuie sa fie deconectat pentru a deschide separatorul de linie!`;
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     messageContainer.classList.add("active");
    
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");
    
            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // }
    
            lineSeparator.classList.remove("active");
            lineSeparator.classList.add("inactive");
    
    
            try {
                // setCookie(`testing-${separatorId}-separator-3`, '0');
    
            
            } catch (e) {
                alert(e);
            }
    
    
            if (separatorId.includes("CM")) {
                const cell = document.querySelector(`.cell-${separatorId}`);
                cell.classList.remove("active");
                cell.classList.add("inactive");
                
                disableCell(cell, true);
            } 
    
            
        };
    
        const refreshIntreruptor = (e, withoutColorChange = false, ctvTrigger = false, trafoWarning = true) => {
                
            let intreruptor = e?.target || e;
            console.log(intreruptor);
            while (intreruptor && !intreruptor?.classList?.contains("intreruptor")) {
                intreruptor = intreruptor.parentElement;
                console.log(intreruptor)
            }
            console.log(intreruptor);
                if (withoutColorChange && !ctvTrigger) {
                    refreshIntreruptor(virCTVA, false, true);
                    refreshIntreruptor(virCTVB, false, true);
                    refreshIntreruptor(virCTVA, false, true);
                    refreshIntreruptor(virCTVB, false, true);
                
                }
    
                let intreruptorId = intreruptor.getAttribute("id");
    
                // if (document.querySelector(`.separator[id="${intreruptorId}"][number="4"]`) && document.querySelector(`.separator[id="${intreruptorId}"][number="4"]`).classList.contains("active") && (!withoutColorChange && !ctvTrigger)) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `C.L.P de la linia ${intreruptorId} trebuie sa fie deschis pentru a manevra intreruptorul!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
    
                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
    
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
    
                //     return;
                // }
    
                // If intreruptor is active
                
                if (intreruptor.classList.contains("active")) {
                    if (!withoutColorChange) {
                        intreruptor.classList.remove("active");
                        intreruptor.classList.add("inactive");
                    }
    
                    try {
                      
                        // setCookie(`testing-${intreruptorId}-intreruptor`, '0');
    
                    } catch (e) {
                        alert(e);
                    }
    
                    if (intreruptorId.includes("Pelicanu")) {
                        
                        const letter = intreruptorId === "PelicanuI" ? "B" : "A";
                        const oppositeLetter = letter === "A" ? "B" : "A";
                        const separatorBarI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                        const separatorBarII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                        const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                        const number = separatorBarI.classList.contains("active") ? "I" : "II";
                        const oppositeNumber = number === "I" ? "II" : "I"; 
                        const oppositeNumberPelican = intreruptorId.includes("II") ? "I" : "II";
                        const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositeNumberPelican}`);
                        const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${number}${oppositeLetter}"]`);
                        const oppositeLineSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[number="3"]`);
                        const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositeNumberPelican}`);
                        const clSepartorI = document.querySelector(`.separator-CL${number}[number="1"]`);
                        const clSeparatorII = document.querySelector(`.separator-CL${number}[number="2"]`);
                        const clIntreruptor = document.querySelector(`.intreruptor-CL${number}`);
                        const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                        const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
                        const oppositeCTV = letter === "A" ? intreruptorCTVB : intreruptorCTVA;
                        const currentCTV = letter === "A" ? intreruptorCTVA : intreruptorCTVB;
                        const oppositeBarSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${oppositeNumber}${oppositeLetter}"]`);
                        
                        const oppositeCLIntreruptor = document.querySelector(`.intreruptor-CL${oppositeNumber}`);
                        const oppositeCellSeparator = document.querySelector(`.separator-CM${oppositeNumber}${letter}`);
                        const oppositeCell = document.querySelector(`.cell-CM${oppositeNumber}${letter}`);
                        
                        if (oppositeCLIntreruptor.classList.contains("inactive")) {
                            oppositeCell.classList.add("inactive");
                            oppositeCell.classList.remove("active");
                            disableCell(oppositeCell);
                        }
    
                        const disableCellCondition = 
                        (
                            oppositeCTV.classList.contains("active") && 
                            oppositeBarSeparator.classList.contains("active") &&
                            oppositeLineSeparator.classList.contains("active") && 
                            oppositeGenerator.classList.contains("active") && 
                            clIntreruptor.classList.contains("active")
                        ) ||
                        (
                            currentCTV.classList.contains("active") &&
                            oppositeBarSeparator.classList.contains("active") &&
                            oppositeLineSeparator.classList.contains("active") && 
                            oppositeGenerator.classList.contains("active") && 
                            clIntreruptor.classList.contains("active")
                        );
    
                        // const oppositeDisableCellCondition =  
                        // (
                        //     currentCTV.classList.contains("active") && 
                        //     number === "1" &&
                        //     lineSeparator.classList.contains("active") && 
                        //     oppositeGenerator.classList.contains("active") && 
                        //     clIntreruptor.classList.contains("active")
                        // ) ||
                        // (
                        //     currentCTV.classList.contains("active") &&
                        //     oppositeBarSeparator.classList.contains("active") &&
                        //     oppositeLineSeparator.classList.contains("active") && 
                        //     oppositeGenerator.classList.contains("active") && 
                        //     clIntreruptor.classList.contains("active") &&
                        //     clIntreruptor.classList.contains("active")
                        // );
    
    
                        
                        // if (
                        //     oppositeSeparator.classList.contains("active") && 
                        //     oppositeGenerator.classList.contains("active") && 
                        //     oppositeLineSeparator.classList.contains("active") && 
                        //     oppositeIntreruptor.classList.contains("active") && 
                        //     clSepartorI.classList.contains("active") && 
                        //     clSeparatorII.classList.contains("active") &&
                        //     clIntreruptor.classList.contains("active")
                        // ) return;
    
    
                        if (separatorBarI.classList.contains("inactive") && separatorBarII.classList.contains("inactive")) return;
                        
                        if ((letter === "A" && !disableCellCondition) || (letter === "B" && clSepartorI.classList.contains("active") && clSeparatorII.classList.contains("active") && clIntreruptor.classList.contains("active") && !disableCellCondition)) {
                            const cellI = document.querySelector(`.cell-CM${number}A`);
                            cellI.classList.remove("active");
                            cellI.classList.add("inactive");
                            disableCell(cellI);
                        }
    
                        if ((letter === "B" && !disableCellCondition) || (letter === "A" && clSepartorI.classList.contains("active") && clSeparatorII.classList.contains("active") && clIntreruptor.classList.contains("active") && !disableCellCondition)) {
                            const cellII = document.querySelector(`.cell-CM${number}B`);
                            cellII.classList.remove("active");
                            cellII.classList.add("inactive");
            
                            disableCell(cellII);
                        }
    
                        if (oppositeIntreruptor.classList.contains("inactive") || oppositeGenerator.classList.contains("inactive")) {
                            
                            const otherCellA = document.querySelector(`.cell-CM${oppositeNumber}A`);
                            const otherCellB = document.querySelector(`.cell-CM${oppositeNumber}B`);
                            const currentCellA = document.querySelector(`.cell-CM${number}A`);
                            const currentCellB = document.querySelector(`.cell-CM${number}B`);
                            otherCellA.classList.remove("active");
                            otherCellA.classList.add("inactive");
                            otherCellB.classList.add("inactive");
                            otherCellB.classList.remove("active");
                            currentCellA.classList.add("inactive");
                            currentCellA.classList.remove("active");
                            currentCellB.classList.add("inactive");
                            currentCellB.classList.remove("active");
                            disableCell(otherCellA);
                            disableCell(otherCellB);
                            disableCell(currentCellA);
                            disableCell(currentCellB);
                        }
            
                        
                    } else {
                        const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                        if (consumer) {
                            consumer.classList.add("inactive");
                            consumer.classList.remove("active");
                            disableConsumer(consumer);
                        }
            
                    }
    
                    if (intreruptorId.includes("CL")) {
                        const number = intreruptorId.includes("II") ? "II" : "I";
    
                        const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                        const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                        const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                        const generatorPelicanuI  = document.querySelector('.generator-PelicanuI');
                        const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                        const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                        const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                        const generatorPelicanuII  = document.querySelector('.generator-PelicanuII');    
                        const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                        const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
    
    
                        if (number === "I") {
                            if (
                                separatorPelicanuI1.classList.contains("active") && 
                                separatorPelicanuI2.classList.contains("inactive") && 
                                intreruptorPelicanuI.classList.contains("active") &&
                                generatorPelicanuI.classList.contains("active") &&
                                (
                                    separatorPelicanuII1.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuII2.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive") ||
                                    intreruptorCTVA.classList.contains("inactive")
                                )
                            ) {
                                const CMA = document.querySelector(".cell-CMIA");
                                CMA.classList.remove("active");
                                CMA.classList.add("inactive");
                                disableCell(CMA);
                            } else if (
                                separatorPelicanuII1.classList.contains("active") && 
                                separatorPelicanuII2.classList.contains("inactive") &&
                                intreruptorPelicanuII.classList.contains("active") && 
                                generatorPelicanuII.classList.contains("active") &&
                                (
                                    separatorPelicanuI1.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuI2.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive") ||
                                    intreruptorCTVB.classList.contains("inactive")
                                )
                            ) {
                                const CMB = document.querySelector(".cell-CMIB");
                                CMB.classList.remove("active");
                                CMB.classList.add("inactive");
                                disableCell(CMB);
                            }
                        } else if (number === "II") {
                            if (
                                separatorPelicanuI1.classList.contains("inactive") && 
                                separatorPelicanuI2.classList.contains("active") && 
                                intreruptorPelicanuI.classList.contains("active") &&
                                generatorPelicanuI.classList.contains("active") &&
                                (
                                    separatorPelicanuII2.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuII1.classList.contains("inactive") ||
                                    intreruptorPelicanuII.classList.contains("inactive") ||
                                    generatorPelicanuII.classList.contains("inactive") ||
                                    intreruptorCTVA.classList.contains("inactive")
                                )
                            ) {
                                const CMA = document.querySelector(".cell-CMIIA");
                                CMA.classList.remove("active");
                                CMA.classList.add("inactive");
                                disableCell(CMA);
                            } else if (
                                separatorPelicanuII1.classList.contains("inactive") && 
                                separatorPelicanuII2.classList.contains("active") && 
                                intreruptorPelicanuII.classList.contains("active") && 
                                generatorPelicanuII.classList.contains("active") &&
                                (
                                    separatorPelicanuI2.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive")
                                ) &&
                                (
                                    separatorPelicanuI1.classList.contains("inactive") ||
                                    intreruptorPelicanuI.classList.contains("inactive") ||
                                    generatorPelicanuI.classList.contains("inactive") ||
                                    intreruptorCTVB.classList.contains("inactive")
                                )
                            ) {
                                const CMB = document.querySelector(".cell-CMIIB");
                                CMB.classList.remove("active");
                                CMB.classList.add("inactive");
                                disableCell(CMB);
                            }
                        }
    
                        // refreshIntreruptor(virIntreruptorPelicanuI, true);
                        // refreshIntreruptor(virIntreruptorPelicanuII, true);
                        // refreshIntreruptor(virIntreruptorPelicanuI, true);
                        // refreshIntreruptor(virIntreruptorPelicanuII, true);
                        cellSeparators.forEach(cell => {
                    
                            refreshSeparator(cell);
                            refreshSeparator(cell);
                        });
                    }
    
                    if (intreruptorId.includes("CTV")) {
                        const activeLine = document.querySelector(`.separator-${intreruptorId}[number="1"]`).classList.contains("active") ? "1" : "2";
                        const oppositeLine = activeLine === "1" ? "2" : "1";
                        const oppositePelicanLine = intreruptorId.includes("II") ? "I" : "II";
                        const letter = intreruptorId.includes("II") ? "A" : "B";
                        const oppositeLetter = intreruptorId.includes("II") ? "A" : "B";
                        const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositePelicanLine}[number="${oppositeLine}"]`);
                        const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositePelicanLine}`);
                        const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositePelicanLine}`);
                        const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${oppositeLine === "1" ? "I" : "II"}`);
    
                        const oppositeLineCell = document.querySelector(`.cell-CM${oppositeLine === "2" ? "II" : "I"}${letter}`);
                        const oppositeLineCellSeparator =  document.querySelector(`.separator-CM${oppositeLine === "2" ? "II" : "I"}${letter}`);
                        const otherOppositeLineCell = document.querySelector(`.cell-CM${oppositeLine === "2" ? "II" : "I"}${oppositeLetter}`);
                        const otherOppositeLineCellSeparator = document.querySelector(`.separator-CM${oppositeLine === "2" ? "II" : "I"}${oppositeLetter}`);
    
                
    
                        if ((!oppositeSeparator.classList.contains("active") || !oppositeIntreruptor.classList.contains("active") || !oppositeGenerator.classList.contains("active")) && !oppositeIntreruptorCL.classList.contains("active")) {
                            
                            if (oppositeLineCellSeparator) {
                                oppositeLineCell.classList.add("inactive");
                                oppositeLineCell.classList.remove("active");
                            }
    
                            if (otherOppositeLineCellSeparator) {
                                otherOppositeLineCell.classList.add("inactive");
                                otherOppositeLineCell.classList.remove("active");
                            }
    
                            disableCell(oppositeLineCell);
                            disableCell(otherOppositeLineCell);
    
                        }
    
                        if (oppositeSeparator.classList.contains("active") && oppositeIntreruptor.classList.contains("active") && oppositeGenerator.classList.contains("active") && !oppositeIntreruptorCL.classList.contains("active")) {
                            if (oppositeLineCellSeparator) {
                                oppositeLineCell.classList.add("inactive");
                                oppositeLineCell.classList.remove("active");
                            }
    
                            disableCell(oppositeLineCell);
                        }
    
                        cellSeparators.forEach(cell => {
                            refreshSeparator(cell);
                            refreshSeparator(cell);
                        });
    
                    }
    
                    return;
                }
    
             
    
                    if ((intreruptorId.includes("2T") || intreruptorId.includes("3T")) && trafoWarning) {
                        const oppositeName = intreruptorId.includes("2T") ? "Trafo 3T" : "Trafo 2T";
                        const oppositeId = intreruptorId.includes("2T") ? "3T" : "2T";
                        const oppositeConsumer = document.querySelector(`.consumer-${oppositeId}`);
                        // if (oppositeConsumer.classList.contains("active")) {
                        //     const messageContainerText = document.querySelector(".message-content .message-container p");
                        //     messageContainerText.innerText = `Atentie! ${oppositeName} este conectat! Va rugam sa confirmati ca 0.4kV cupla longitudinala este deschisa. Ai inteles?`;
                        //     messageContainer.classList.add("active");
                        //     // const warningSound = new Audio("./sounds/warning.mp3");
                        //     warningSoundRef.current.currentTime = 0;
                        //     warningSoundRef.current.play();
                        //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                        //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                        //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                        //     negativeButton.classList.remove("inactive");
                        //     positiveButton.classList.remove("inactive");
                        //     confirmButton.classList.add("inactive");
                    
                        //     negativeButton.addEventListener("click", () => {
                        //         messageContainer.classList.remove("active");
                        //         return;
                        //     });
    
                        //     let counter = 0;
    
                        //     const positiveButtonEvent = () => {
                            
                        //         console.log("in event!");
                        //         if (counter) return;
                        //         counter++;
                        //         intreruptorArr.push(intreruptorId);
                        //         refreshIntreruptor(intreruptor, false, false, false);
                        //     };
    
                        //     positiveButton.addEventListener("click", () => positiveButtonEvent());
    
                        //     return;
                        // } 
                    } 
    
                    if ((intreruptorId.includes("Pelicanu") || intreruptorId.includes("CL") || intreruptorId.includes("CTV") || intreruptorId === "2T" || intreruptorId === "3T" || intreruptorId === "4T") && (!withoutColorChange && !ctvTrigger)) {
                        
                        intreruptorArr.push(intreruptor.getAttribute("id"));            
                        console.log(intreruptorArr);
                        messageContainer.classList.remove("active");

                        if (intreruptorId.includes("CTV") && (!withoutColorChange && !ctvTrigger)) {
                           
                            messageContainer.classList.remove("active");

                            intreruptorId = intreruptorArr.at(-1);
                            intreruptor = document.querySelector(`.intreruptor-${intreruptorId}`);
            
                            // Separators from current id & checking if there is an active separator
                            const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
            
                            // TODO: check tension on the line
                            // TODO: check if there is no potential differences from generators

                            if (!withoutColorChange) {
                                intreruptor.classList.remove("inactive");
                                intreruptor.classList.add("active");
                            }
                    
                            try {
                            
                                // setCookie(`testing-${intreruptorId}-intreruptor`, "1");

                            } catch (e) {
                                alert(e);
                            }

                            const letter = intreruptorId === "CTVB" ? "B" : "A";
                            const oppositeLetter = letter === "A" ? "B" : "A";
                            const currentPelicanNumber = letter === "A" ? "II" : "I";
                            const pelicanNumber = oppositeLetter === "A" ? "II" : "I";
                            const currentGenerator = document.querySelector(`.generator[line="${letter}"]`);
                            const oppositeGenerator = document.querySelector(`.generator[line="${oppositeLetter}"]`);
                            const currentIntreruptor = document.querySelector(`.intreruptor[line="${letter}"]`);
                            const oppositeInteruptor = document.querySelector(`.intreruptor[line="${oppositeLetter}"]`);  
                            const oppositeActiveLine = document.querySelector(`.separator-Pelicanu${pelicanNumber}[number="1"]`).classList.contains("active") ? "I" : "II";
                            const oppositeLineSeparator =  document.querySelector(`.separator-Pelicanu${currentPelicanNumber}[number="1"]`).classList.contains("active") ? "II" : "I";
                            const currentActiveLine = document.querySelector(`.separator-Pelicanu${currentPelicanNumber}[number="1"]`).classList.contains("active") ? "I" : "II";
                            const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${oppositeActiveLine}`);       
                            const intreruptorCL = document.querySelector(`.intreruptor-CL${currentActiveLine}`);  

                            if ((currentIntreruptor.classList.contains('active') && currentGenerator.classList.contains('active')) || (oppositeInteruptor.classList.contains('active') && oppositeGenerator.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active"))) {
                                const firstCellSeparator = document.querySelector(`.separator-CMI${letter}`);
                                const secondCellSeparator = document.querySelector(`.separator-CMII${letter}`);
                                const firstCell = document.querySelector(`.cell-CMI${letter}`);
                                const secondCell = document.querySelector(`.cell-CMII${letter}`);
                                if (firstCellSeparator.classList.contains("active")) {
                                    firstCell.classList.add("active");
                                    firstCell.classList.remove("inactive");
                                }

                                if (secondCellSeparator.classList.contains("active")) {
                                    secondCell.classList.add("active");
                                    secondCell.classList.remove("inactive");
                                }

                                const otherCell = document.querySelector(`.cell-CM${oppositeLineSeparator}${oppositeLetter}`);
                                const otherCellSeparator =  document.querySelector(`.separator-CM${oppositeLineSeparator}${oppositeLetter}`);
                                if (intreruptorCL.classList.contains("active")) {
                                    enableCell(otherCell);
                                    if (otherCellSeparator.classList.contains("active")) {
                                        otherCell.classList.add("active");
                                        otherCell.classList.remove("inactive");
                                    }
                                }

                                
                                enableCell(firstCell);
                                enableCell(secondCell);
                            }

                            cellSeparators.forEach(cell => {
                                refreshSeparator(cell);
                                refreshSeparator(cell);
                            });  
                            
                            } else {
                                
                                intreruptorId = intreruptorArr.at(-1);
                                intreruptor = document.querySelector(`.intreruptor-${intreruptorId}`);
                                if (!intreruptorId.includes("Pelicanu") && !intreruptorId.includes("CL") && !intreruptorId.includes("CTV")) {
                
                                    const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                    const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                    const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                    const line = activeBarSeparator.getAttribute("line");
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                   
                                }
                    
                    
                            // Separators from current id & checking if there is an active separator
                            const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
                      
                            
                            if (separators.length === 3) {
                              
                                const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4);
                                // if (restOfSeparators.every(separator => separator.classList.contains("inactive")) && (!withoutColorChange && !ctvTrigger)) {
                                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                                //     messageContainerText.innerText = `Trebuie sa fie inchis un separator de bara pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                                //     messageContainer.classList.add("active");
                                //     // const warningSound = new Audio("./sounds/warning.mp3");
                                //     warningSoundRef.current.currentTime = 0;
                                //     warningSoundRef.current.play();
                                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                                //     negativeButton.classList.add("inactive");
                                //     positiveButton.classList.add("inactive");
                                //     confirmButton.classList.remove("inactive");
                    
                                //     confirmButton.addEventListener("click", () => {
                                //         messageContainer.classList.remove("active");
                                //     });
                                //     return;
                                // }
                            }
                    
                            if (separators.length === 4) {
                                // if (separators.find(separator => Number(separator.getAttribute("number")) === 4).classList.contains("active") && (!withoutColorChange && !ctvTrigger)) {
                                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                                //     messageContainerText.innerText = `C.L.P trebuie sa fie deschis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                                //     messageContainer.classList.add("active");
                                //     // const warningSound = new Audio("./sounds/warning.mp3");
                                //     warningSoundRef.current.currentTime = 0;
                                //     warningSoundRef.current.play();
                                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                                //     negativeButton.classList.add("inactive");
                                //     positiveButton.classList.add("inactive");
                                //     confirmButton.classList.remove("inactive");
                    
                                //     confirmButton.addEventListener("click", () => {
                                //         messageContainer.classList.remove("active");
                                //     });
                                //     return;
                                // }
    
                                const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4 && Number(separator.getAttribute("number")) !== 3);
                                // if (restOfSeparators.every(separator => separator.classList.contains("inactive")) && (!withoutColorChange && !ctvTrigger)) {
                                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                                //     messageContainerText.innerText = `Un separator de bara de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                                //     messageContainer.classList.add("active");
                                //     // const warningSound = new Audio("./sounds/warning.mp3");
                                //     warningSoundRef.current.currentTime = 0;
                                //     warningSoundRef.current.play();
                                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                                //     negativeButton.classList.add("inactive");
                                //     positiveButton.classList.add("inactive");
                                //     confirmButton.classList.remove("inactive");
                    
                                //     confirmButton.addEventListener("click", () => {
                                //         messageContainer.classList.remove("active");
                                //     });
                                //     return;
                                // }
                    
                                // if (separators.find(separator => Number(separator.getAttribute("number")) === 3).classList.contains("inactive") && (!withoutColorChange && !ctvTrigger)) {
                                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                                //     messageContainerText.innerText = `Separatorul de linie de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                                //     messageContainer.classList.add("active");
                                //     // const warningSound = new Audio("./sounds/warning.mp3");
                                //     warningSoundRef.current.currentTime = 0;
                                //     warningSoundRef.current.play();
                                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                                //     negativeButton.classList.add("inactive");
                                //     positiveButton.classList.add("inactive");
                                //     confirmButton.classList.remove("inactive");
                    
                                //     confirmButton.addEventListener("click", () => {
                                //         messageContainer.classList.remove("active");
                                //     });
                                //     return;
                                // }

                            }
                    
                            // TODO: check tension on the line
                            // TODO: check if there is no potential differences from generators
                            if (!withoutColorChange) {
                                intreruptor.classList.remove("inactive");
                                intreruptor.classList.add("active");
                            }
                
                            try {
                                // setCookie(`testing-${intreruptorId}-intreruptor`, "1");
                            
                            } catch (e) {
                                alert(e);
                            }
                    
                            if (intreruptorId.includes("Pelicanu")) {
                                
                                const letterLine = intreruptorId === "PelicanuI" ? "B" : "A";
                                const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                                const generator = document.querySelector(`.generator-${intreruptorId}`);
                                
                                if (generator.classList.contains("inactive")) return; // no warning
                                if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return; // no warning
                                if (lineSeparator.classList.contains('inactive')) return; // no warning
                    
                                const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                                const line = numberLine + letterLine;
                                const oppositeNumberLine = (numberLine === "I" ? "II" : "I") + letterLine;
                                const otherCellSeparator = document.querySelector(`.separator-CM${oppositeNumberLine}[number="3"]`);
    
                                const otherOppositeLine = (numberLine === "I" ? "II" : "I") + (letterLine === "A" ? "B" : "A");
                                const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${numberLine === "I" ? "II" : "I"}`);
                                const otherOppositeLineSeparatorCM = document.querySelector(`.separator-CM${otherOppositeLine}`);
    
                                
                                const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                                const currentCTV = document.querySelector(`.intreruptor-CTV${letterLine}`);
                                
                                const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                                const oppositeLine = numberLine + oppositeLetterLine; 
                    
                                const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                                const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                                const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                            
                    
                                if (cellSeparator.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
                                
    
                                if (currentCTV.classList.contains("active") && otherCellSeparator.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${oppositeNumberLine}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
    
                                if (currentCTV.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active") && otherOppositeLineSeparatorCM.classList.contains("active")) {
                                    const cell = document.querySelector(`.cell-CM${otherOppositeLine}`);
                                    cell.classList.add("active");
                                    cell.classList.remove("inactive");
                                    enableCell(cell);
                                }
                    
                    
                                if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                                if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                                if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
                    
                    
                                const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                                oppositeCM.classList.add("active");
                                oppositeCM.classList.remove("inactive");
                    
                                enableCell(oppositeCM);
                    
                            } else {
                                const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                                const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                                const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                    
                                if (consumer) {
                                    if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) {
                                        consumer.classList.add("inactive");
                                        consumer.classList.remove("active");
                                        disableConsumer(consumer);
                                    } else {
                                        const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                        const line = activeBarSeparator.getAttribute("line");
                                        const cell = document.querySelector(`.cell-CM${line}`);
                                        if (cell.classList.contains("active")) {
                                            consumer.classList.add("active");
                                            consumer.classList.remove("inactive");
                                            enableConsumer(consumer);
                                        }
                                    }
                                }
                    
                            
                                
                            }
                    
                            if (intreruptorId.includes("CL")) {
                                const number = intreruptorId.includes("II") ? "II" : "I";
                                const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                                const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                    
                                const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                                const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                    
                                const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                                const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                    
                                const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                                const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                    
                                if (number === "I") {
                                    if (separatorPelicanuI1.classList.contains("active") && separatorPelicanuI2.classList.contains("inactive") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                        const CMA = document.querySelector(".cell-CMIA");
                                        const separatorCMA = document.querySelector('.separator-CMIA[number="3"]');
                                        if (separatorCMA.classList.contains("active")) {
                                            CMA.classList.add("active");
                                            CMA.classList.remove("inactive");
                                        }
                    
                                        enableCell(CMA, separatorCMA.classList.contains("active"));
                    
                                    
                                    } else if (separatorPelicanuII1.classList.contains("active") && separatorPelicanuII2.classList.contains("inactive") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                        const CMB = document.querySelector(".cell-CMIB");
                                        const separatorCMB = document.querySelector('.separator-CMIB[number="3"]');
                                        if (separatorCMB.classList.contains("active")) {
                                            CMB.classList.add("active");
                                            CMB.classList.remove("inactive");
                                        
                                        }
                                        enableCell(CMB, separatorCMB.classList.contains("active"));
                                    }
                                } else if (number === "II") {
                                    if (separatorPelicanuI1.classList.contains("inactive") && separatorPelicanuI2.classList.contains("active") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                        const CMA = document.querySelector(".cell-CMIIA");
                                        const separatorCMA = document.querySelector('.separator-CMIIA[number="3"]');
                                        if (separatorCMA.classList.contains("active")) {
                                            CMA.classList.add("active");
                                            CMA.classList.remove("inactive");
                                        
                                        }
                                        enableCell(CMA, separatorCMA.classList.contains("active"));
                                    
                                    } else if (separatorPelicanuII1.classList.contains("inactive") && separatorPelicanuII2.classList.contains("active") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                        const CMB = document.querySelector(".cell-CMIIB");
                                        const separatorCMB = document.querySelector('.separator-CMIIB');
                                        if (separatorCMB.classList.contains("active")) {
                                            CMB.classList.add("active");
                                            CMB.classList.remove("inactive");
                                        
                                        }
                    
                                        enableCell(CMB, separatorCMB.classList.contains("active"));
                                    }
                                }
    
                                // refreshIntreruptor(virIntreruptorPelicanuI, true);
                                // refreshIntreruptor(virIntreruptorPelicanuII, true);
                                // refreshIntreruptor(virIntreruptorPelicanuI, true);
                                // refreshIntreruptor(virIntreruptorPelicanuII, true);
                                cellSeparators.forEach(cell => {
                    
                                    refreshSeparator(cell);
                                    refreshSeparator(cell);
                                });
                                
                    
                            }   
                            }
            
                    }
            
                
            
                    // If intreruptor is inactive
            
                    // if (intreruptorId.includes("Pelicanu") || intreruptorId.includes("CL") || intreruptorId.includes("CTV") || intreruptorId === "2T" || intreruptorId === "3T" || intreruptorId === "4T") {
                    //     const messageContainerText = document.querySelector(".message-content .message-container p");
                    //     messageContainerText.innerText = `Sunt conditii de paralel intre Pelicanu 1 si Pelicanu 2?`;
                    //     messageContainer.classList.add("active");
                    //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                    //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                    //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
            
                    //     negativeButton.classList.remove("inactive");
                    //     positiveButton.classList.remove("inactive");
                    //     confirmButton.classList.add("inactive");
            
                    //     negativeButton.addEventListener("click", () => {
                    //         messageContainer.classList.remove("active");
                    //         return;
                    //     });
            
                    //     console.log("outside", intreruptorId);
                    //     intreruptorArr.push(intreruptorId);            
            
                    //     positiveButton.addEventListener("click", () => {
                    //         messageContainer.classList.remove("active");
                    //         intreruptorId = intreruptorArr.at(-2);
                    //         intreruptor = document.querySelector(`.intreruptor-${intreruptorArr.at(-1)}`);
            
                    //         if (!intreruptorId.includes("Pelicanu") && !intreruptorId.includes("CL") && !intreruptorId.includes("CTV")) {
            
                    //             const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                    //             const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                    //             const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                    //             const line = activeBarSeparator.getAttribute("line");
                    //             const cell = document.querySelector(`.cell-CM${line}`);
                    //             console.log(cell);
                    //             if (cell.classList.contains("inactive")) {
            
                    //                 messageContainerText.innerText = `Trebuie sa fie tensiune pe linia ${line} pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                    
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
            
                    //                 return;
                    //             }
                    //         }
                    
                    
                    //         // Separators from current id & checking if there is an active separator
                    //         const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
                    //         if (separators.length === 2) {
                    //             if (separators.every(separator => separator.classList.contains("inactive"))) {
                    //                 const messageContainerText = document.querySelector(".message-content .message-container p");
                    //                 messageContainerText.innerText = `Unul dintre separatoarele de bara trebuie sa fie inchis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                    //                 const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                    //                 const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                    //                 const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return; 
                    //             }
                    
                    //             if (separators.some(separator => separator.classList.contains("inactive")) && (intreruptorId.includes("CTV") || intreruptorId.includes("CL"))) {
                    //                 messageContainerText.innerText = `Trebuiesc pornite ambele separatoare de bara pe ${intreruptorId} pentru a manevra intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
                    //         }
                            
                    //         if (separators.length === 3) {
                    //             if (separators.find(separator => Number(separator.getAttribute("number")) === 4).classList.contains("active")) {
                    //                 messageContainerText.innerText = `C.L.P trebuie sa fie deschis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
                    //             const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4);
                    //             if (restOfSeparators.every(separator => separator.classList.contains("inactive"))) {
                    //                 messageContainerText.innerText = `Trebuie sa fie inchis un separator de bara pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
                    //         }
                    
                    //         if (separators.length === 4) {
                    //             if (separators.find(separator => Number(separator.getAttribute("number")) === 4).classList.contains("active")) {
                    //                 messageContainerText.innerText = `C.L.P trebuie sa fie deschis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
                    
                    //             if (separators.find(separator => Number(separator.getAttribute("number")) === 3).classList.contains("inactive")) {
                    //                 messageContainerText.innerText = `Separatorul de linie de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                    
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
            
                    //             const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4 && Number(separator.getAttribute("number")) !== 3);
                    //             if (restOfSeparators.every(separator => separator.classList.contains("inactive"))) {
                    //                 messageContainerText.innerText = `Un separator de bara de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                    //                 messageContainer.classList.add("active");
                                
                    //                 negativeButton.classList.add("inactive");
                    //                 positiveButton.classList.add("inactive");
                    //                 confirmButton.classList.remove("inactive");
                    
                    //                 confirmButton.addEventListener("click", () => {
                    //                     messageContainer.classList.remove("active");
                    //                 });
                    //                 return;
                    //             }
                    //         }
                    
                    //         // TODO: check tension on the line
                    //         // TODO: check if there is no potential differences from generators
                    
                    //         intreruptor.classList.remove("inactive");
                    //         intreruptor.classList.add("active");
                    
                    
                    //         try {
                            
                    //             ipcRenderer.send('set-cookie',  {
                    //                 name: `${intreruptorId}-intreruptor`,
                    //                 value: "1",
                    //                 session: false,
                    //                 url: 'https://www.github.com',
                    //                 expirationDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10
                    //             });
                            
                    //         } catch (e) {
                    //             alert(e);
                    //         }
                    
                    //         if (intreruptorId.includes("Pelicanu")) {
                                
                    //             const letterLine = intreruptorId === "PelicanuI" ? "B" : "A";
                    //             const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                    //             const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                    //             const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                    //             const generator = document.querySelector(`.generator-${intreruptorId}`);
                                
                    
                    //             if (generator.classList.contains("inactive")) return; // no warning
                    //             if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return; // no warning
                    //             if (lineSeparator.classList.contains('inactive')) return; // no warning
                    
                    //             const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                    //             const line = numberLine + letterLine;
                    
                                
                    //             const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                    
                    
                    //             const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                    //             const oppositeLine = numberLine + oppositeLetterLine; 
                    
                    //             const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                    //             const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                    //             const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                            
                    
                    //             if (cellSeparator.classList.contains("active")) {
                    //                 const cell = document.querySelector(`.cell-CM${line}`);
                    //                 cell.classList.add("active");
                    //                 cell.classList.remove("inactive");
                    //                 enableCell(cell);
                    //             }
                    
                    
                    //             if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                    //             if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                    //             if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
                    
                    
                    //             const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                    //             oppositeCM.classList.add("active");
                    //             oppositeCM.classList.remove("inactive");
                    
                    //             enableCell(oppositeCM);
                    
                    //         } else {
                    //             const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                    //             const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                    //             const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                    
                    //             if (consumer) {
                    //                 if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) {
                    //                     consumer.classList.add("inactive");
                    //                     consumer.classList.remove("active");
                    //                 } else {
                    //                     const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                    //                     const line = activeBarSeparator.getAttribute("line");
                    //                     const cell = document.querySelector(`.cell-CM${line}`);
                    //                     if (cell.classList.contains("active")) {
                    //                         consumer.classList.add("active");
                    //                         consumer.classList.remove("inactive");
                    //                     }
                    //                 }
                    //             }
                    
                            
                                
                    //         }
                    
                    //         if (intreruptorId.includes("CL")) {
                    //             const number = intreruptorId.includes("II") ? "II" : "I";
                    //             const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                    //             const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                    
                    //             const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                    //             const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                    
                    //             const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                    //             const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                    
                    //             const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                    //             const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                    
                    //             if (number === "I") {
                    //                 if (separatorPelicanuI1.classList.contains("active") && separatorPelicanuI2.classList.contains("inactive") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                    //                     const CMA = document.querySelector(".cell-CMIA");
                    //                     const separatorCMA = document.querySelector('.separator-CMIA[number="3"]');
                    //                     if (separatorCMA.classList.contains("active")) {
                    //                         CMA.classList.add("active");
                    //                         CMA.classList.remove("inactive");
                    //                     }
                    
                    //                     enableCell(CMA, separatorCMA.classList.contains("active"));
                    
                                    
                    //                 } else if (separatorPelicanuII1.classList.contains("active") && separatorPelicanuII2.classList.contains("inactive") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                    //                     const CMB = document.querySelector(".cell-CMIB");
                    //                     const separatorCMB = document.querySelector('.separator-CMIB[number="3"]');
                    //                     if (separatorCMB.classList.contains("active")) {
                    //                         CMB.classList.add("active");
                    //                         CMB.classList.remove("inactive");
                                        
                    //                     }
                    //                     enableCell(CMB, separatorCMB.classList.contains("active"));
                    //                 }
                    //             } else if (number === "II") {
                    //                 if (separatorPelicanuI1.classList.contains("inactive") && separatorPelicanuI2.classList.contains("active") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                    //                     const CMA = document.querySelector(".cell-CMIIA");
                    //                     const separatorCMA = document.querySelector('.separator-CMIIA[number="3"]');
                    //                     if (separatorCMA.classList.contains("active")) {
                    //                         CMA.classList.add("active");
                    //                         CMA.classList.remove("inactive");
                                        
                    //                     }
                    //                     enableCell(CMA, separatorCMA.classList.contains("active"));
                                    
                    //                 } else if (separatorPelicanuII1.classList.contains("inactive") && separatorPelicanuII2.classList.contains("active") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                    //                     const CMB = document.querySelector(".cell-CMIIB");
                    //                     const separatorCMB = document.querySelector('.separator-CMIIB');
                    //                     if (separatorCMB.classList.contains("active")) {
                    //                         CMB.classList.add("active");
                    //                         CMB.classList.remove("inactive");
                                        
                    //                     }
                    
                    //                     enableCell(CMB, separatorCMB.classList.contains("active"));
                    //                 }
                    //             }
                    
                    //         }
            
                            
            
                    
                    //     });
                    else {
                        
                        if (!intreruptorId.includes("Pelicanu") && !intreruptorId.includes("CL") && !intreruptorId.includes("CTV")) {
            
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                            const line = activeBarSeparator.getAttribute("line");
                            const cell = document.querySelector(`.cell-CM${line}`);
                            // if (cell.classList.contains("inactive") && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Trebuie sa fie tensiune pe linia ${line} pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                        }
                
                
                        // Separators from current id & checking if there is an active separator
                        const separators = [...document.querySelectorAll(`.separator[id="${intreruptorId}"]`)];
                        if (separators.length === 2) {
                            // if (separators.every(separator => separator.classList.contains("inactive")) && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Unul dintre separatoarele de bara trebuie sa fie inchis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return; 
                            // }
                
                            // if (separators.some(separator => separator.classList.contains("inactive")) && (intreruptorId.includes("CTV") || intreruptorId.includes("CL")) && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Trebuiesc pornite ambele separatoare de bara pe ${intreruptorId} pentru a manevra intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                        }
                        
                        if (separators.length === 3) {
                            // if (separators.find(separator => Number(separator.getAttribute("number")) === 4).classList.contains("active") && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `C.L.P trebuie sa fie deschis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                            const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4);
                            // if (restOfSeparators.every(separator => separator.classList.contains("inactive")) && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Trebuie sa fie inchis un separator de bara pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                        }
                
                        if (separators.length === 4) {
                            // if (separators.find(separator => Number(separator.getAttribute("number")) === 4).classList.contains("active") && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `C.L.P trebuie sa fie deschis pe linia ${intreruptorId} pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                
                        
                            const restOfSeparators = separators.filter(separator => Number(separator.getAttribute("number")) !== 4 && Number(separator.getAttribute("number")) !== 3);
                            // if (restOfSeparators.every(separator => separator.classList.contains("inactive")) && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Un separator de bara de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
    
                            // if (separators.find(separator => Number(separator.getAttribute("number")) === 3).classList.contains("inactive") && (!withoutColorChange && !ctvTrigger)) {
                            //     const messageContainerText = document.querySelector(".message-content .message-container p");
                            //     messageContainerText.innerText = `Separatorul de linie de pe linia ${intreruptorId} trebuie sa fie inchis pentru a conecta intreruptorul!`;
                            //     messageContainer.classList.add("active");
                            //     // const warningSound = new Audio("./sounds/warning.mp3");
                            //     warningSoundRef.current.currentTime = 0;
                            //     warningSoundRef.current.play();
                            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                
                            //     negativeButton.classList.add("inactive");
                            //     positiveButton.classList.add("inactive");
                            //     confirmButton.classList.remove("inactive");
                
                            //     confirmButton.addEventListener("click", () => {
                            //         messageContainer.classList.remove("active");
                            //     });
                            //     return;
                            // }
                        }
                
                        // TODO: check tension on the line
                        // TODO: check if there is no potential differences from generators
                        if (!withoutColorChange) {
                            intreruptor.classList.remove("inactive");
                            intreruptor.classList.add("active");
                        }
    
                
                
                        try {
                     
                            // setCookie(`testing-${intreruptorId}-intreruptor`, "1");
                        
                        } catch (e) {
                            alert(e);
                        }
                
                        if (intreruptorId.includes("Pelicanu")) {
                            
                            const letterLine = intreruptorId === "PelicanuI" ? "B" : "A";
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const lineSeparator = document.querySelector(`.separator-${intreruptorId}[number="3"]`);
                            const generator = document.querySelector(`.generator-${intreruptorId}`);
                
                
                            if (generator.classList.contains("inactive")) return; // no warning
                            if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return; // no warning
                            if (lineSeparator.classList.contains('inactive')) return; // no warning
                
                            const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                            const line = numberLine + letterLine;
                
                            
                            const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                
                
                            const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                            const oppositeLine = numberLine + oppositeLetterLine; 
                
                            const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                            const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                            const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                        
                
                            if (cellSeparator.classList.contains("active")) {
                                const cell = document.querySelector(`.cell-CM${line}`);
                                cell.classList.add("active");
                                cell.classList.remove("inactive");
                                enableCell(cell);
                            }
                
                
                            if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                            if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                            if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning
                
                
                            const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                            oppositeCM.classList.add("active");
                            oppositeCM.classList.remove("inactive");
                
                            enableCell(oppositeCM);
                
                        } else {
                            const barSeparatorI = document.querySelector(`.separator-${intreruptorId}[number="1"]`);
                            const barSeparatorII = document.querySelector(`.separator-${intreruptorId}[number="2"]`);
                            const consumer = document.querySelector(`.consumer-${intreruptorId}`);
                
                            if (consumer) {
                                if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) {
                                    consumer.classList.add("inactive");
                                    consumer.classList.remove("active");
                                    disableConsumer(consumer);
                                } else {
                                    const activeBarSeparator = barSeparatorI.classList.contains("active") ? barSeparatorI : barSeparatorII;
                                    const line = activeBarSeparator.getAttribute("line");
                                    const cell = document.querySelector(`.cell-CM${line}`);
                                    if (cell.classList.contains("active")) {
                                        consumer.classList.add("active");
                                        consumer.classList.remove("inactive");
                                        enableConsumer(consumer);
                                    }
                                }
                            }
                
                        
                            
                        }
                
                        if (intreruptorId.includes("CL")) {
                            const number = intreruptorId.includes("II") ? "II" : "I";
                            const separatorPelicanuI1 = document.querySelector('.separator-PelicanuI[number="1"]');
                            const separatorPelicanuI2 = document.querySelector('.separator-PelicanuI[number="2"]');
                
                            const separatorPelicanuII1 = document.querySelector('.separator-PelicanuII[number="1"]');
                            const separatorPelicanuII2 = document.querySelector('.separator-PelicanuII[number="2"');
                
                            const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                            const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
                
                            const intreruptorPelicanuI = document.querySelector('.intreruptor-PelicanuI');
                            const intreruptorPelicanuII = document.querySelector('.intreruptor-PelicanuII');
                
                            if (number === "I") {
                                if (separatorPelicanuI1.classList.contains("active") && separatorPelicanuI2.classList.contains("inactive") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                    const CMA = document.querySelector(".cell-CMIA");
                                    const separatorCMA = document.querySelector('.separator-CMIA[number="3"]');
                                    if (separatorCMA.classList.contains("active")) {
                                        CMA.classList.add("active");
                                        CMA.classList.remove("inactive");
                                    }
                
                                    enableCell(CMA, separatorCMA.classList.contains("active"));
                
                                
                                } else if (separatorPelicanuII1.classList.contains("active") && separatorPelicanuII2.classList.contains("inactive") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                    const CMB = document.querySelector(".cell-CMIB");
                                    const separatorCMB = document.querySelector('.separator-CMIB[number="3"]');
                                    if (separatorCMB.classList.contains("active")) {
                                        CMB.classList.add("active");
                                        CMB.classList.remove("inactive");
                                    
                                    }
                                    enableCell(CMB, separatorCMB.classList.contains("active"));
                                }
                            } else if (number === "II") {
                                if (separatorPelicanuI1.classList.contains("inactive") && separatorPelicanuI2.classList.contains("active") && generatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active")) {
                                    const CMA = document.querySelector(".cell-CMIIA");
                                    const separatorCMA = document.querySelector('.separator-CMIIA[number="3"]');
                                    if (separatorCMA.classList.contains("active")) {
                                        CMA.classList.add("active");
                                        CMA.classList.remove("inactive");
                                    
                                    }
                                    enableCell(CMA, separatorCMA.classList.contains("active"));
                                
                                } else if (separatorPelicanuII1.classList.contains("inactive") && separatorPelicanuII2.classList.contains("active") && generatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active")) {
                                    const CMB = document.querySelector(".cell-CMIIB");
                                    const separatorCMB = document.querySelector('.separator-CMIIB');
                                    if (separatorCMB.classList.contains("active")) {
                                        CMB.classList.add("active");
                                        CMB.classList.remove("inactive");
                                    
                                    }
                
                                    enableCell(CMB, separatorCMB.classList.contains("active"));
                                }
                            }
                
                        }
                
                    }
            };
    
    
        intreruptors.forEach(intreruptor1 => {
    
            let intreruptor = intreruptor1;
                
            
            intreruptor.addEventListener("click", refreshIntreruptor);
        });

        const separatorFunctionality = async e => {
            let separator = e.target;
            while (separator && !separator?.classList?.contains("separator")) separator = separator.parentElement;
            console.log(separator);
            const separatorId = separator.getAttribute("id");
            // if (document.querySelector(`.separator[id="${separatorId}"][number="4"]`) && document.querySelector(`.separator[id="${separatorId}"][number="4"]`).classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `C.L.P de pe linia ${separatorId} trebuie sa fie deschis pentru a manevra separatorul de bara!`;
            //     messageContainer.classList.add("active");
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");

            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");

            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // }

            const currIntreruptor = document.querySelector(`.intreruptor[id="${separatorId}"]`);
            const oppositeNumber = Number(separator.getAttribute("number")) === 1 ? 2 : 1;
            const oppositeSeparator = document.querySelector(`.separator[number="${oppositeNumber}"][id="${separatorId}"]`);

            // if (currIntreruptor.classList.contains("active")) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `Separatorul de bara de pe linia ${separatorId} nu poate fi manevrat cu intreruptorul conectat!`;
            //     messageContainer.classList.add("active");
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");

            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");

            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
            //     return;
            // }

            if (separator.classList.contains("inactive")) {

                // Check for the clp separator from the current line to be inactive
                if (separator.getAttribute("line")) {
                    const clpCMSeparator = document.querySelector(`.separator-CM${separator.getAttribute("line")}[number="4"]`);
                    // if (clpCMSeparator.classList.contains("active")) {
                    //     const messageContainerText = document.querySelector(".message-content .message-container p");
                    //     messageContainerText.innerText = `C.L.P de pe linia ${separatorId} trebuie sa fie deschis pentru a inchide separatorul de bara!`;
                    //     messageContainer.classList.add("active");
                    //     // const warningSound = new Audio("./sounds/warning.mp3");
                    //     warningSoundRef.current.currentTime = 0;
                    //     warningSoundRef.current.play();
                    //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                    //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                    //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
            
                    //     negativeButton.classList.add("inactive");
                    //     positiveButton.classList.add("inactive");
                    //     confirmButton.classList.remove("inactive");
            
                    //     confirmButton.addEventListener("click", () => {
                    //         messageContainer.classList.remove("active");
                    //     });
                    //     return;
                    // }
                }

                // if (oppositeSeparator.classList.contains("active") && !separatorId.includes("CTV") && !separatorId.includes("CL")) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Nu se poate inchide separatorul de bara de pe linia ${separatorId} daca celalalt separator de bara este inchis!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
        
                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
        
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
                //     return;
                // }

                // if ((separatorId === "PelicanuI" && oppositeNumber === 2) || (separatorId === "PelicanuII" && oppositeNumber === 1)) {
                //     const systemNumber = `${oppositeNumber === 2 ? "I" : "II"}${separatorId === "PelicanuI" ? "B" : "A"}`;
                //     const oppositeSystemNumber = `${oppositeNumber === 1 ? "I" : "II"}${separatorId === "PelicanuI" ? "B" : "A"}`;                const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Atentie! Se recomanda ca ${separatorId} sa alimenteze sistemul de bare ${oppositeSystemNumber}! Aveti aprobare de la dispecer de a alimenta cu ${separatorId} sistemul ${systemNumber}?`;
                

                //     // const errorSound = new Audio("./sounds/real_error.mp3");
                //     errorSoundRef.current.currentTime = 0;
                //     errorSoundRef.current.play();

                //     messageContainer.classList.add("active");
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
                    
                //     negativeButton.classList.remove("inactive");
                //     positiveButton.classList.remove("inactive");
                //     confirmButton.classList.add("inactive");
            
                //     negativeButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //         return;
                //     });

                

                //     positiveButton.addEventListener("click", () => {
                    
                //         messageContainer.classList.remove("active");

                //         separator.classList.remove("inactive");
                //         separator.classList.add("active");
                //         try {
                //             // console.log("cookie set", separator.classList.contains("active"));
                //             // setCookie(`testing-${separatorId}-separator-${Number(separator.getAttribute("number"))}`, separator.classList.contains("active") ? "1" : "0");
                        
                //         } catch (e) {
                //             alert(e);
                //         }
                //     });

                //     return;
                    
                // } 

                separator.classList.remove("inactive");
                separator.classList.add("active");
            } else {
                const lineSeparator = document.querySelector(`.separator[id="${separatorId}"][number="3"]`);
                // if (lineSeparator && lineSeparator.classList.contains("active")) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Separatorul de linie de pe linia ${separatorId} trebuie sa fie deschis pentru a deschide separatorul de bara!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");
        
                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
        
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
                //     return;
                // }
                separator.classList.remove("active");
                separator.classList.add("inactive");
            }

            try {
                console.log("cookie set", separator.classList.contains("active"));
                // setCookie(`testing-${separatorId}-separator-${Number(separator.getAttribute("number"))}`, separator.classList.contains("active") ? "1" : "0");
            
            } catch (e) {
                alert(e);
            }
        };
    
        // Separatoare de bara
    
        separators.forEach(separator => {
            separator.addEventListener("click", separatorFunctionality);
        });
    
        lineSeparators.forEach(lineSeparator => {
            lineSeparator.addEventListener("click", refreshSeparator);
        });

        const clpSeparatorEvent = async (e) => {

            let separator = e.target;
            while (!separator.classList.contains("separator")) separator = separator.parentElement;
       
            const separatorId = separator.getAttribute("id");
            const otherElements = [...document.querySelectorAll(`div[id="${separatorId}"]:not([number="4"])`)];

            if (separatorId.includes("CM")) {
                const separatorLine = separatorId.slice(2);
                const barSeparators = document.querySelectorAll(`.separator[line="${separatorLine}"]`);
                const barSeparatorActive = [...barSeparators].some(separator => {
                    return separator.classList.contains("active")
                });

                // if (barSeparatorActive) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `Separatoarele de bara de pe linia ${separatorId} trebuie sa fie deschise pentru a manevra C.L.P-ul!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");

                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
            
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });
                //     return;
                // }
            }
            
            // if (otherElements.some(element => element.classList.contains("active") && !element.classList.contains("consumer"))) {
            //     const messageContainerText = document.querySelector(".message-content .message-container p");
            //     messageContainerText.innerText = `Totul de pe linia ${separatorId} trebuie sa fie deschis pentru a manevra C.L.P-ul!`;
            //     messageContainer.classList.add("active");
            //     // const warningSound = new Audio("./sounds/warning.mp3");
            //     warningSoundRef.current.currentTime = 0;
            //     warningSoundRef.current.play();
            //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
            //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
            //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");

            //     negativeButton.classList.add("inactive");
            //     positiveButton.classList.add("inactive");
            //     confirmButton.classList.remove("inactive");
        
            //     confirmButton.addEventListener("click", () => {
            //         messageContainer.classList.remove("active");
            //     });
                
            //     return;
            // }

            if (separator.classList.contains("inactive")) {
                separator.classList.remove("inactive");
                separator.classList.add("active");
                try {
                    
                    // setCookie(`testing-${separatorId}-separator-4`, '1');
                
                } catch (error) {
                    alert(error);
                }
                return;
            }

            separator.classList.remove("active");
            separator.classList.add("inactive");
            try {
                // setCookie(`testing-${separatorId}-separator-4`, '0');
            
            } catch (e) {
                alert(e);
            }
            
        };
    
        clpSeparators.forEach(separator => {
            separator.addEventListener("click", clpSeparatorEvent);
        });

        const generatorFunctionality = async (e) => {
            const generator = e.target?.classList?.contains("generator") ? e.target : e.target.parentElement;
            console.log(generator);
            const image = generator.querySelector("img");
            const generatorId = generator.getAttribute("id");
            console.log("CLick");
            try {
                // setCookie(`testing-${generatorId}-generator`, generator.classList.contains('active') ? "0" : "1");            
            } catch (e) {
                alert(e);
            }

            if (generator.classList.contains("active")) {
                console.log("A");
                generator.classList.remove("active");
                generator.classList.add("inactive");
                image.src = generatorImage;
                
                const barSeparatorI = document.querySelector(`.separator-${generatorId}[number="1"]`);
                const barSeparatorII = document.querySelector(`.separator-${generatorId}[number="2"]`);
                const number = barSeparatorI.classList.contains("active") ? "I" : "II";
                const oppositeNumber = number === "I" ? "II" : "I"; 
                // if (barSeparatorI.classList.contains("inactive") && barSeparatorII.classList.contains("inactive")) return;
                const currentNumber = barSeparatorI.classList.contains("active") ? "I" : "II";
                const letter = generatorId === "PelicanuI" ? "B" : "A";
                const oppositeLetter = letter === "B" ? "A" : "B";
                const oppositeNumberPelican = generatorId.includes("II") ? "I" : "II";
                const oppositeIntreruptor = document.querySelector(`.intreruptor-Pelicanu${oppositeNumberPelican}`);
                const oppositeSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${number}${oppositeLetter}"]`);
                const oppositeLineSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[number="3"]`);
                const oppositeGenerator = document.querySelector(`.generator-Pelicanu${oppositeNumberPelican}`);
                const clSepartorI = document.querySelector(`.separator-CL${number}[number="1"]`);
                const clSeparatorII = document.querySelector(`.separator-CL${number}[number="2"]`);
                const clIntreruptor = document.querySelector(`.intreruptor-CL${number}`);
                const intreruptorCTVA = document.querySelector('.intreruptor-CTVA');
                const intreruptorCTVB = document.querySelector('.intreruptor-CTVB');
                const oppositeCTV = letter === "A" ? intreruptorCTVB : intreruptorCTVA;
                const currentCTV = letter === "A" ? intreruptorCTVA : intreruptorCTVB;
                const oppositeBarSeparator = document.querySelector(`.separator-Pelicanu${oppositeNumberPelican}[line="${oppositeNumber}${oppositeLetter}"]`);

                    
                const oppositeCLIntreruptor = document.querySelector(`.intreruptor-CL${oppositeNumber}`);
                const oppositeCell = document.querySelector(`.cell-CM${oppositeNumber}${letter}`);
                
                if (oppositeCLIntreruptor.classList.contains("inactive")) {
                    oppositeCell.classList.add("inactive");
                    oppositeCell.classList.remove("active");
                    disableCell(oppositeCell);
                }

                const disableCellCondition = 
                (
                    oppositeCTV.classList.contains("active") && 
                    oppositeBarSeparator.classList.contains("active") &&
                    oppositeLineSeparator.classList.contains("active") && 
                    oppositeGenerator.classList.contains("active") && 
                    oppositeIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active")
                ) ||
                (
                    currentCTV.classList.contains("active") &&
                    oppositeBarSeparator.classList.contains("active") &&
                    oppositeLineSeparator.classList.contains("active") && 
                    oppositeGenerator.classList.contains("active") &&  
                    oppositeIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active") &&
                    clIntreruptor.classList.contains("active")
                );

                
                // if (
                //     oppositeSeparator.classList.contains("active") && 
                //     oppositeGenerator.classList.contains("active") && 
                //     oppositeLineSeparator.classList.contains("active") && 
                //     oppositeIntreruptor.classList.contains("active") && 
                //     clSepartorI.classList.contains("active") && 
                //     clSeparatorII.classList.contains("active") &&
                //     clIntreruptor.classList.contains("active")
                // ) return;
                
                if (
                    (letter === "A" && !disableCellCondition) ||
                    (  
                        letter === "B" && 
                        clSepartorI.classList.contains("active") && 
                        clSeparatorII.classList.contains("active") &&
                        clIntreruptor.classList.contains("active") &&
                        !disableCellCondition
                    )
                ) {
                    const cellI = document.querySelector(`.cell-CM${currentNumber}A`);
                    cellI.classList.remove("active");
                    cellI.classList.add("inactive");
                    disableCell(cellI);
                }

                if (
                    (letter === "B" && !disableCellCondition) ||
                    (  
                        letter === "A" && 
                        clSepartorI.classList.contains("active") && 
                        clSeparatorII.classList.contains("active") &&
                        clIntreruptor.classList.contains("active") && 
                        !disableCellCondition
                    )
                ) {
                    const cellII = document.querySelector(`.cell-CM${currentNumber}B`);
                    cellII.classList.remove("active");
                    cellII.classList.add("inactive");
                    disableCell(cellII);
                }

                if (oppositeIntreruptor.classList.contains("inactive") || oppositeGenerator.classList.contains("inactive")) {
                        
                    const otherCellA = document.querySelector(`.cell-CM${oppositeNumber}A`);
                    const otherCellB = document.querySelector(`.cell-CM${oppositeNumber}B`);
                    const currentCellA = document.querySelector(`.cell-CM${number}A`);
                    const currentCellB = document.querySelector(`.cell-CM${number}B`);
                    otherCellA.classList.remove("active");
                    otherCellA.classList.add("inactive");
                    otherCellB.classList.add("inactive");
                    otherCellB.classList.remove("active");
                    currentCellA.classList.add("inactive");
                    currentCellA.classList.remove("active");
                    currentCellB.classList.add("inactive");
                    currentCellB.classList.remove("active");
                    disableCell(otherCellA);
                    disableCell(otherCellB);
                    disableCell(currentCellA);
                    disableCell(currentCellB);
                }
                
            } else {
                const clpSeparator = document.querySelector(`.separator-${generator.getAttribute("id")}[number="4"]`)
                // if (clpSeparator.classList.contains("active")) {
                //     const messageContainerText = document.querySelector(".message-content .message-container p");
                //     messageContainerText.innerText = `C.L.P-ul de pe linia ${generatorId} trebuie sa fie deschis pentru a conecta generatorul!`;
                //     messageContainer.classList.add("active");
                //     // const warningSound = new Audio("./sounds/warning.mp3");
                //     warningSoundRef.current.currentTime = 0;
                //     warningSoundRef.current.play();
                //     const negativeButton = document.querySelector(".message-content .message-container button.negative-btn");
                //     const positiveButton = document.querySelector(".message-content .message-container button.positive-btn");
                //     const confirmButton = document.querySelector(".message-content .message-container button.confirm-btn");

                //     negativeButton.classList.add("inactive");
                //     positiveButton.classList.add("inactive");
                //     confirmButton.classList.remove("inactive");
            
                //     confirmButton.addEventListener("click", () => {
                //         messageContainer.classList.remove("active");
                //     });

                //     return;
                // }
                
                generator.classList.add("active");
                generator.classList.remove("inactive");
                console.log(generatorInchis);

                image.src = generatorInchis;

                const letterLine = generatorId === "PelicanuI" ? "B" : "A";
                const barSeparatorI = document.querySelector(`.separator-${generatorId}[number="1"]`);
                const barSeparatorII = document.querySelector(`.separator-${generatorId}[number="2"]`);
                const lineSeparator = document.querySelector(`.separator-${generatorId}[number="3"]`);
                const intreruptor = document.querySelector(`.intreruptor-${generatorId}`);

                // if (barSeparatorI.classList.contains("inactve") && barSeparatorII.classList.contains("inactve")) return;
                // if (lineSeparator.classList.contains('inactive')) return;
                // if (intreruptor.classList.contains('inactive')) return;

                const numberLine = barSeparatorI.classList.contains("active") ? "I" : "II";
                const oppositeNumberLine = (numberLine === "I" ? "II" : "I") + letterLine;

                const line = numberLine + letterLine;
                
                const cellSeparator = document.querySelector(`.separator-CM${line}[number="3"]`); 
                if (cellSeparator.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${line}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                const oppositeLetterLine = letterLine === "A" ? "B" :"A";
                const oppositeLine = numberLine + oppositeLetterLine; 

                const separatorsCL = [...document.querySelectorAll(`.separator-CL${numberLine}`)];
                const intreruptorCL = document.querySelector(`.intreruptor-CL${numberLine}`);
                const oppositeLineSeparatorCM = document.querySelector(`.separator-CM${oppositeLine}[number="3"]`);
                const oppositeCM = document.querySelector(`.cell-CM${oppositeLine}`);
                const otherOppositeLine = (numberLine === "I" ? "II" : "I") + (letterLine === "A" ? "B" : "A");
                const oppositeIntreruptorCL = document.querySelector(`.intreruptor-CL${numberLine === "I" ? "II" : "I"}`);
                const otherOppositeLineSeparatorCM = document.querySelector(`.separator-CM${otherOppositeLine}`);
                const otherCellSeparator = document.querySelector(`.separator-CM${oppositeNumberLine}[number="3"]`);

                
                const currentCTV = document.querySelector(`.intreruptor-CTV${letterLine}`);

                if (currentCTV.classList.contains("active") && otherCellSeparator.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${oppositeNumberLine}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                if (currentCTV.classList.contains("active") && oppositeIntreruptorCL.classList.contains("active") && otherOppositeLineSeparatorCM.classList.contains("active")) {
                    const cell = document.querySelector(`.cell-CM${otherOppositeLine}`);
                    cell.classList.add("active");
                    cell.classList.remove("inactive");
                    enableCell(cell);
                }

                if (intreruptorCL.classList.contains("inactive")) return; // Not warning
                if (separatorsCL.some(separator => separator.classList.contains("inactive"))) return; // No warning
                if (oppositeLineSeparatorCM.classList.contains("inactive")) return; // No warning

                oppositeCM.classList.add("active");
                oppositeCM.classList.remove("inactive");
                enableCell(oppositeCM);
            }

        

        };
    
        const generators = document.querySelectorAll(".generator");
        generators.forEach(generator => {
            generator.addEventListener("click", generatorFunctionality);
        });
    
        // Cells turning red 
    
        // const cellIA = document.querySelector('.cell-CMIA');
        // const cellIB = document.querySelector('.cell-CMIB');
        // const cellIIB = document.querySelector('.cell-CMIIB');
        // const cellIIA = document.querySelector('.cell-CMIIA');
    
        // const classAddIA = () => {
        //     alert("ohoo");
        
    
        //     const cells = document.querySelectorAll('.separator[number="2"][line="IA"]');
        //     cells.forEach(cell => {
        //         if (cellIA.classList.contains("active")) {
        //             cell.classList.add("active"); 
        //             cell.classList.remove("inactive");
        //         } else {
        //             cell.classList.add("inactive");
        //             cell.classList.remove("active");
        //         }
        //     });
        // };
    
        // const classAddIIA = ()  => {
    
        // };
    
        // const classAddIB = () => {
    
        // };
    
        // const classAddIIB = () => {
    
        // };
    
        // const classRemove = () => {
    
        // }
    
        // const classWatcherIACell = new ClassWatcher(cellIA, 'trigger', classAddIA, classRemove);
        // const classWatcherIIACell = new ClassWatcher(cellIIA, 'trigger', classAddIIA, classRemove);
        // const classWatcherIBCell = new ClassWatcher(cellIB, 'trigger', classAddIB, classRemove);
        // const classWatcherIIBCell = new ClassWatcher(cellIIB, 'trigger', classAddIIB, classRemove);
    
        const disableCell = (cell, fromLineSeparatorCM = false) => {
            const line = cell.getAttribute("id").slice(2);
            const barSeparators = document.querySelectorAll(`.separator[line="${line}"]`);
            
            if (!fromLineSeparatorCM) {
                barSeparators.forEach(barSeparator => {
                    const barSeparatorId = barSeparator.getAttribute("id");
            
                    if (barSeparator.classList.contains("active")) {
                        const consumer = document.querySelector(`.consumer-${barSeparatorId}`);
                        if (consumer) {
                            consumer.classList.add("inactive");
                            consumer.classList.remove("active");
                            disableConsumer(consumer);
                        }
                    
                    }
                });
            }
    
        
    
            const circleText = cell.querySelector('.text-circle');
            circleText.innerText = '';
    
        
            try {
                // setCookie(`testing-${cell.getAttribute("id")}-cell`, "0");
            } catch (e) {
                alert(e);
            }
            
        
        };
    
        const enableCell = (cell, textAppearing = true) => {
            const line = cell.getAttribute("id").slice(2);
            const barSeparators = document.querySelectorAll(`.separator[line="${line}"]`);
    
            barSeparators.forEach(barSeparator => {
                const barSeparatorId = barSeparator.getAttribute("id");
                
                const number = Number(barSeparator.getAttribute("number"));
                const otherBarSeparator = document.querySelector(`.separator-${barSeparatorId}[number="${number === 1 ? 2 : 1}"]`);
                const lineSeparator = document.querySelector(`.separator-${barSeparatorId}[number="3"]`);
                const intreruptor = document.querySelector(`.intreruptor-${barSeparatorId}`);
                
                if (barSeparator.classList.contains("active") && (lineSeparator ? lineSeparator.classList.contains("active") : true) && intreruptor.classList.contains("active") && otherBarSeparator.classList.contains("inactive")) {
                    const consumer = document.querySelector(`.consumer-${barSeparatorId}`);
                    if (consumer) {
                        consumer.classList.add("active");
                        consumer.classList.remove("inactive");
                        enableConsumer(consumer);
                    }
        
                }
                
            });
    
            if (textAppearing) {
    
                try {
                    const circle = cell.querySelector(".text-circle");
                    const barSeparatorIPelicanuI = document.querySelector(`.separator-PelicanuI[number="1"]`);
                    const barSeparatorIIPelicanuI = document.querySelector(`.separator-PelicanuI[number="2"]`);
                    const lineSeparatorPelicanuI = document.querySelector(`.separator-PelicanuI[number="3"]`);
                    const intreruptorPelicanuI = document.querySelector(`.intreruptor-PelicanuI`);
                    const generatorPelicanuI = document.querySelector('.generator-PelicanuI');
                    
                    const barSeparatorIPelicanuII = document.querySelector(`.separator-PelicanuII[number="1"]`);
                    const barSeparatorIIPelicanuII = document.querySelector(`.separator-PelicanuII[number="2"]`);
                    const lineSeparatorPelicanuII = document.querySelector(`.separator-PelicanuII[number="3"]`);
                    const intreruptorPelicanuII = document.querySelector(`.intreruptor-PelicanuII`);
                    const generatorPelicanuII = document.querySelector('.generator-PelicanuII');
            
            
                    if (barSeparatorIPelicanuI.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                        circle.innerText = 'P1';
                    } 
                    if (barSeparatorIIPelicanuI.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuI.classList.contains("active") && intreruptorPelicanuI.classList.contains("active") && generatorPelicanuI.classList.contains("active")) {
                        circle.innerText = 'P1';
                    }
                    
                    if (barSeparatorIPelicanuII.classList.contains("active") && (line === "IA" || line === "IB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                        circle.innerText = 'P2';
                    } 
                    if (barSeparatorIIPelicanuII.classList.contains("active") && (line === "IIA" || line === "IIB") && lineSeparatorPelicanuII.classList.contains("active") && intreruptorPelicanuII.classList.contains("active") && generatorPelicanuII.classList.contains("active")) {
                        circle.innerText = 'P2';
                    }
                
                } catch (e) {
                    alert(e);
                }
            
            
            }
    
            try {
                // setCookie(`testing-${cell.getAttribute("id")}-cell`, "1");
            } catch (e) {
                alert(e);
            }
            
            
        };
    
        const disableConsumer = (consumer) => {
            try {
                // setCookie(`testing-${consumer.getAttribute("id")}-consumer`, "0");
            } catch (e) {
                alert(e);
            }
            
        };
    
        const enableConsumer = (consumer) => {
            try {
                // setCookie(`testing-${consumer.getAttribute("id")}-consumer`, "1");
            } catch (e) {
                alert(e);
            }
        };
    
        const consumers = document.querySelectorAll(".consumer");
        consumers.forEach(consumer => {
            consumer.style.left = `${consumer.getBoundingClientRect().left + 2.5}px`; 
        });

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            generators.forEach(generator => {
                generator.removeEventListener("click", generatorFunctionality);
            });

            intreruptors.forEach(intreruptor1 => {
                let intreruptor = intreruptor1;
                intreruptor.removeEventListener("click", refreshIntreruptor);
            });

            separators.forEach(separator => {
                separator.removeEventListener("click", separatorFunctionality);
            });

            lineSeparators.forEach(lineSeparator => {
                lineSeparator.removeEventListener("click", refreshSeparator);
            });

            clpSeparators.forEach(separator => {
                separator.removeEventListener("click", clpSeparatorEvent);
            });
        
        };
    
    }, [cookies]);

    const scenarioPaperRef = useRef();

    useEffect(() => {
        if (!scenarioPaperRef?.current) return;
        const timer = setTimeout(() => {
            scenarioPaperRef?.current?.classList.add("active");
        }, 400);

        return () => {
            clearTimeout(timer);
        };
    }, [scenarioPaperRef]);

    const finishSessionStudent = async () => {

        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                sessionId: sessionId,
                teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
                studentId: data.userData.userId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session-testing/finish-session-student-testing`, options);
            const response = await request.json();
            if (response.success) window.location.replace(`/session-testing/${sessionId}/leaderboard`);

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="scheme-container">
            <SRS data={data} />

            <div className="project-name">
                <h2>{sessionName || ""}</h2>
            </div>

            <div className="scenario-button" onClick={() => scenarioPaperRef.current.classList.toggle("active")}>
                <FontAwesomeIcon icon={faBookOpen} className="scenario-icon" />
            </div>

            <div className="scenario-paper" ref={scenarioPaperRef}>
                {/* <h2>{cookies?.language === "en" ? "" : ""}</h2> */}
                {scenario === "shock" ? 
                    (cookies?.language === "en" ? <p>You are in a critical situation. One of your colleagues was electro-shocked in  <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. You will need to execute the necesarry maneuveres in order to get him out of that situation. <br /> Good luck! </p> : <p>Te afli intr-o situatie critica. Unul dintre colegii tai a fost electrocutat in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. Tu va trebui sa executi manevrele necesare pentru a-l scoate din acea situatie. <br /> Mult succes! </p>) :
                    (cookies?.language === "en" ? <p>You are in a critical situation. It will be a future fire in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. You will need to execute the necesarry maneuveres in order to avoid the future fire spread in our electrical system. <br /> Good luck! </p> : <p>Te afli intr-o situatie critica. Urmeaza sa fie un viitor incendiu in <b>{schemeName} - {problems.length > 1 ? `linia ${semiStation}` : cell}</b>. Tu va trebui sa executi manevrele necesare pentru a preveni viitorul incendiu. <br /> Mult succes! </p>)
                }

                <div className="close-button" onClick={() => scenarioPaperRef.current.classList.remove("active")}>
                    <FontAwesomeIcon className="close-icon" icon={faTimes}/>
                </div>

                {scenario === "shock" ? <img src={electrocutareColeg} alt="electrocutare coleg imagine" /> : null}
            </div>

            <div className="start-session-button high-tension" onClick={async () => await finishSessionStudent()}>
                {<p>{cookies?.language === "en" ? "Finish session" : "Termina sesiunea"}</p>}
                <FontAwesomeIcon icon={faFlagCheckered} className="button-icon" />
            </div>
        </div>
    );
};

export default Testing;