import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeaderboardDebugSession from "../LeaderboardDebugSession/LeaderboardDebugSession";
import PredefinedScheme from "../PredefinedScheme/PredefinedScheme";
import PredefinedSchemeDebug from "../PredefinedSchemeDebug/PredefinedSchemeDebug";
import "./DebugSession.css";

const DebugSession = ({ data, socket, useStore } : { data: any, socket: any, useStore: any }) => {
    const { sessionId, join } = useParams();
    const joinNumber = useMemo(() => Number(join), []);
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [notes, setNotes] = useState<string>("");
    const [leaderboard, setLeaderboard] = useState([]);
    const [totalProblems, setTotalProblems] = useState([]);
    const [problemsSolved, setProblemsSolved] = useState([]);
    const [endSessionAnimation, setEndSessionAnimation] = useState<boolean>(false);
    const [animationTextSize, setAnimationTextSize] = useState<number>(50);
    const animationRef = useRef<any>();
    const [startAnimation, setStartAnimation] = useState<boolean>(false);

    let times = useMemo(() => 0, []);

    const getJoinedSession = async () => {
        try {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/join-session-student/${data.userData.teacherId}/${data.userData.userId}/${sessionId}`, options);
            const response = await request.json();
            
            if (response?.alreadyEntered) {
                setStartAnimation(false);
                setEndSessionAnimation(true);
            }
            else setStartAnimation(true);
            times++;

        } catch (e) {
            console.log(e);
        }
    };

    useLayoutEffect(() => {
        if (times > 0) return;
        if (data.userData.type !== "student") return;
        getJoinedSession().catch(err => console.log(err));
    }, []);
    
    useLayoutEffect(() => {
        if (!animationRef?.current || data.userData.type !== "student" || !startAnimation) return;
        // setAnimationTextSize(70);
        const timer = setTimeout(() => {
            console.log(animationRef?.current);
            if (!animationRef?.current || data.userData.type !== "student" || !startAnimation) return;
            // setAnimationTextSize(1);
            animationRef.current.innerText = '2';
            setAnimationTextSize(50);
            setTimeout(() => {
                // setAnimationTextSize(1);
                animationRef.current.innerText = '1';
                setAnimationTextSize(50);
                setTimeout(() => {
                    // setAnimationTextSize(1)
                    setAnimationTextSize(20);
                    animationRef.current.innerText = 'Start';
                    setTimeout(() => {
                        setEndSessionAnimation(true);
                        setTimeout(() => window.location.reload(), 500);
                    }, 750);
                }, 500);
            }, 500);
        }, 500);

        return () => {
            clearTimeout(timer);
        };

    }, [startAnimation]);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/check-id/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();
                if (!response.success) return navigate(-1);
                if (data.userData.type === "student") {
                    console.log(response);
                    const currResResults = response.results;
                    const currResults = JSON.parse(currResResults.results);
                    const userResults = currResults.find((result : { userId: string }) => result.userId === data.userData.userId);
                    if (!userResults) return;
                    setProblemsSolved(userResults.problemsSolved);
                    setProblems(JSON.parse(currResResults.problems).filter((problem : any) => !userResults.problemsSolved.includes(problem.point)));
                    setTotalProblems(JSON.parse(currResResults.problems));
                    setNotes(userResults.notes);
                    return;
                } 

            } catch (e) {
                console.log(e);
            }
        })(); 

        
    }, []);

    useEffect(() => {
        if (data.userData.type !== "teacher") return;
        socket.on("update-results", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-results-of-session/${data.userData.userId}/${sessionId}`, options);
                const response = await request.json();

                if (response.success) setLeaderboard(response.results);
            } catch (e) {
                console.log(e);
            }
        });

    }, []);

    return data.userData.type === "teacher" && !joinNumber ? (
        <LeaderboardDebugSession data={data} socket={socket} />
    ) : endSessionAnimation || joinNumber ? (
        <PredefinedSchemeDebug data={data} useStore={useStore} problemsSolved={problemsSolved} problems={problems} totalProblems={totalProblems} notes={notes} isTeacher={data.userData.type === "teacher" ? !joinNumber : false} />
    ) : (
        <div className="animation-container">
            <h1 ref={animationRef} style={{ fontSize: `${animationTextSize}vmax` }}>3</h1>
        </div>
    );
};

export default DebugSession;