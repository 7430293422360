import { lazy, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./Grid.css";
import SCHEME_ELEMENTS from "../../Functions/getElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faBoltLightning, faBookOpen, faBug, faCheck, faFlagCheckered, faMinus, faPlug, faPlus, faRotateLeft, faRotateRight, faScrewdriver, faTimes } from "@fortawesome/free-solid-svg-icons";
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import SignsMenu from "../SignsMenu/SignsMenu";
import zoomFunc from "../../Functions/zoomFunction";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import contactNormalDeschis from "../../imgs/contact_normal_deschis.svg";
import derivatie_din_conductor_punctat from "../../imgs/derivatie_din_conductor.svg";
import bobinaReleu from "../../imgs/bobina_releu.svg";
import buton_cu_revenire from "../../imgs/buton_cu_revenire.svg";
import motor_asincron_trifazat_cu_rotor_cu_inele from "../../imgs/motor_asincron_trifazat_cu_rotor_cu_inele.svg";
import sirDeClame from "../../imgs/sir_de_clame.svg";
import { faLightbulb, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import aparatDeMasura from "../../imgs/aparat_de_masura.svg";
import screwDriver from "../../imgs/screw-driver.png";
import useContextMenu from "../../Hooks/useContextMenu";
import rightClickData from "./rightClickData";
import GridElement from "./GridElement";


const buzzerSound = require("../../sounds/buzzer.mp3");
const screwSound = require("../../sounds/screw.mp3");

const CATEGORIES = ["contacts", "conductors", "bobines", "buttons", "engines", "others"];

// console.log = () => {};

const PrintColumns = memo((props : any) => {

    const COLUMNS = useMemo(() => props?.columns || 28, []);
    const rowIdx = useMemo(() => props?.rowIdx || 0, []);
    const page = useMemo(() => props?.page || 1, []);
    const columnsArr = useMemo(() => new Array(COLUMNS).fill(0), []);
  
    return (
        <>
            {columnsArr.map((_ : number, idx : any) => {
                const otherProps = {
                    row: idx,
                    column: rowIdx
                };

                let electricityLine = null;
                let hasCurrent = false;

                Object.keys(props?.currentElectricity || {}).forEach((key : string) => {
                    const [ keyIdx, keyRowIdx, keyPage, keyCurrLine ] = key.split("_");
                    if (Number(keyIdx) === idx && Number(keyRowIdx) === rowIdx && Number(keyPage) === page) {
                        const value = props?.currentElectricity?.[key];
                        if (value) {
                            electricityLine = keyCurrLine;
                            hasCurrent = true;
                        }
                    }
                });

                // console.log(electricityLine);


                return <GridElement 
                    {...props}
                    idx={idx}
                    otherProps={otherProps}
                    hasCurrent={hasCurrent}
                    electricityLine={electricityLine}
                    key={idx}
                />;
            })}
        </>
    );
});

const PrintRows = memo((props : any ) => {
    console.log(props);
    const { rowRef, ROWS } = props;
    const rowsElements = useMemo(() => new Array(ROWS).fill(0), []);
    return (
        <>
            {rowsElements.map((_ : number, idx: number) => (
                <div className="row" key={idx} ref={ref => rowRef.current[idx] = ref}>
                    <PrintColumns
                        {...props}
                        rowIdx={idx}
                        idx={idx}
                    />
                </div>
            ))}
        </>
    );

});

const Grid = ({ useStore, data, page, removePage, printSchemes, totalPages, projectId, category, format, projectName, debugSchemeNumber, teacherVar }: { useStore: any, debugSchemeNumber: any, data: any, totalPages: number | null, page: number, projectId: string | null, category: string, format: string, projectName: string, removePage: ((pg: number) => void) | null, printSchemes: (() => void) | null, teacherVar: boolean }) => {

    const buzzerSoundRef = useRef<HTMLAudioElement>(null);
    const screwSoundRef = useRef<HTMLAudioElement>(null);
    const isTeacher = useMemo(() => data.userData.type === "teacher" && teacherVar, []);
    const ROWS = format === "A4" ? 20 : format === "A3" ? 28 : 20;
    const COLUMNS = format === "A4" ? 28 : format === "A3" ? 40 : 28;
    const LONG_PRESS_DELAY = 1500;
    const currParams = useParams();
    const sessionId = currParams?.sessionId;
    const schemeNumber = currParams?.schemeNumber;
    const location = useLocation();
    const navigate = useNavigate();

    const pageRef = useRef<HTMLDivElement>(null);
    const cellRef = useRef<Array<HTMLDivElement | null>>([]);
    const dotsRef = useRef<Array<HTMLDivElement>>([]);
    const rowRef = useRef<Array<HTMLDivElement | null>>([]);
    const printRef = useRef<HTMLDivElement>(null);
    const deviceScreenRef = useRef<any>(null);
    const projectNameRef = useRef<HTMLDivElement>(null);
    const pageNumberRef = useRef<HTMLDivElement>(null);
    const nameContainerRef = useRef<HTMLDivElement>(null);
    const addElementsRef = useRef<HTMLDivElement>(null);

    const [numberPages, setNumberPages] = useState(1);
    const [playAudio, setPlayAudio] = useState<boolean>(false);

    const [cookies, setCookie] = useCookies();
    

    const [currElements, setCurrentElements] = useState<Array<{ row: number, col: number, type: 'string' }> | Array<null>>([]);
    const [dragElement, setDragElement] = useState<HTMLDivElement | null>();
    const [lastDrag, setLastDrag] = useState<HTMLDivElement | null>();
    const [firstElementPos, setFirstElementPos] = useState<number | null>();
    const [lastElementPos, setLastElementPos] = useState<number | null>();
    const [firstEmptyCell, setFirstEmptyCell] = useState<{ x: number, y: number, page: number } | null>(null);

 
    const [dragElementRow, setDragElementRow] = useState<number | null>(null);
    const [dragElementColumn, setDragElementColumn] = useState<number | null>(null);
    const [columns, setColumns] = useState<Array<number>>(new Array(COLUMNS).fill(0));
    const [signTextAlignment, setSignTextAlignment] = useState<string>("");
    const [nameSignMenu, setNameSignMenu] = useState<boolean>(false);
    const [activeDots, setActiveDots] = useState<any>(null);
    const [defectsButton, setDefectsButton] = useState<boolean>(false);
    const [activeDot, setActiveDot] = useState<any>(null);
    const [defects, setDefects] = useState<Array<{ element: any, type: string, screw: number | null }>>([]);
    const [elementsWindowScrollX, setElementsWindowScrollX] = useState<number>(0);

    const [signs, addSign, removeSign, replaceSign] = [useStore((state: any) => state.elements), useStore((state: any) => state.addSign), useStore((state: any) => state.removeSign), useStore((state: any) => state.replaceSign)];
 
    const changeIsLockedSign = useStore((state: any) => state.changeIsLockedSign);
    const changeIsSelectedSign = useStore((state: any) => state.changeIsSelectedSign);
    const changeSignPosition = useStore((state: any) => state.changeSignPosition);
    const addSignMenu = useStore((state: any) => state.addSignMenu);
    const changeAddSignMenu = useStore((state: any) => state.changeAddSignMenu);
    const removeSelectedSign = useStore((state: any) => state.removeSelectedSign);
    const changeSelectedCell = useStore((state: any) => state.changeSelectedCell);
    const changeCellName = useStore((state: any) => state.changeSignName);
    const isImageLocked = useStore((state: any) => state.lockImage);
    const [singleElementDrag, setSingleElementDrag] = useState<boolean>(false);
    const printCall = useStore((state: any) => state.printCall);
    const changeGridActivated = useStore((state: any) => state.changeGrid);
    const isGridActivated = useStore((state: any) => state.isGrid);
    const isSelected = useStore((state: any) => state.elements.find((element: any) => element.isSelectedSign));
    const language = useStore((state: any) => state.language);
    const changeEditMenu = useStore((state: any) => state.changeEditMenu);
    const changeOpenMenu = useStore((state: any) => state.changeOpenMenu);
    const schemeStructure = useStore((state: any) => state.schemeStructure);
    const changeSchemeStructure = useStore((state: any) => state.changeSchemeStructure);
    const changeOpenSchemeStructure = useStore((state: any) => state.changeOpenSchemeStructure);
    const changeScrewSchemeStructure = useStore((state: any) => state.changeScrewSchemeStructure);
    const changeSolvedSchemeStructure = useStore((state: any) => state.changeSolvedSchemeStructure);
    const changeSignTextPosition = useStore((state: any) => state.changeSignTextPosition);
    const flipSign = useStore((state: any) => state.flipSign);
    const [deviceDrag, setDeviceDrag] = useState<boolean>(false);
    const [deviceDragX, setDeviceDragX] = useState<number | null>(null);
    const [deviceDragY, setDeviceDragY] = useState<number | null>(null);
    const isCurrentOnScheme = useStore((state : any) => state.current);
    const changeCurrent = useStore((state: any) => state.changeCurrent);

    const [screw, setScrew] = useState<any>(0);
    const [unscrew, setUnscrew] = useState<any>(0);

    const activeEditMenu = useStore((state: any) => state.activeEditMenu);
    const isGrid = useStore((state: any) => state.isGrid );

    useEffect(() => {
        if (category !== "0") return;
        // changeGridActivated(window.history.state?.isGrid !== null && window.history.state?.isGrid !== isGrid ? !isGrid : isGrid);
    }, []);

    // const alignmentOptions = language === "ro" ? ["Stanga-Sus", "Centru-Sus", "Dreapta-Sus", "Stanga-Centru", "Centru-Centru", "Dreapta-Centru", "Stanga-Jos", "Centru-Jos", "Dreapta-Jos"] : ["Top-Left", "Top-Center", "Top-Right", "Center-Left", "Center-Center", "Center-Right", "Bottom-Left", "Bottom-Center", "Bottom-Right"];
    // const alignmentClassList = ["top-left", "top-center", "top-right", "center-left", "center-center", "center-right", "bottom-left", "bottom-center", "bottom-right"];
    // const [alignmentIdx, setAlignmentIdx] = useState(isSelected ? alignmentOptions.indexOf(isSelected.textAlignment) || 0 : 0);
    const [texts, setTexts] = useState<any>(isSelected ? isSelected.texts || [] : "");
    const [signFontSize, setSignFontSize] = useState("14");

    const defectsOptions = cookies.language === "en" ? ["Lack of tension", "Short circuit"] : ["Lipsa tensiune", "Scurt circuit"];
    const [activeDefectsOption, setActiveDefectsOption] = useState<boolean>(false);
    const [activeDefectsOptionType, setActiveDefectsOptionType] = useState<string>(cookies?.language === "en" ? "Lack of tension" : "Lipsa tensiune");
    const [defectsResponse, setDefectsResponse] = useState<any>(null);
    const [problemsSolved, setProblemsSolved] = useState<any>();
    const [notes, setNotes] = useState<string>("");
    const [logs, setLogs] = useState<Array<any>>([]);
    const [scrollX, setScrollX] = useState<number>(0);
    const [scrollY, setScrollY] = useState<number>(0);
    const [schemeMap, setSchemeMap] = useState<Array<{ point: number, sign: string, line: string, problem: string | null }>>([]);
    const [sessionData, setSessionData] = useState<{ problems: any } | null>(null);
    const [currentProjectName, setCurrentProjectName] = useState<string>(projectName);
    const [projectData, setProjectData] = useState<any>(null);
    const [projectDataSaved, setProjectDataSaved] = useState<boolean>(false);
    const [copiedElement, setCopiedElement] = useState<{ x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } | null>(null);
    const [cuttedElement, setCuttedElement] = useState<{ x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } | null>(null);

    // Measurment device state
    const [showMeasurmentDevice, setShowMeasurmentDevice] = useState<boolean>(false);
    const [deviceValue, setDeviceValue] = useState<number | string>("");
    const [activeOptionAdd, setActiveOptionAdd] = useState<number | null>(null);
    const [deviceActiveOption, setDeviceActiveOption] = useState<string>("off");
    const [currentElectricity, setCurrentElectricity] = useState<any>({});

    const markedMap : any = useMemo(() => new Object(), []);
    let electricityArr : any = useMemo(() => new Object(), []);

    // useEffect(() => {
    //     localStorage.setItem(`signs-${projectId}`, JSON.stringify(signs));
    // }, [signs]);

    useEffect(() => {
        console.log("SIGNS UPDATE");
    }, [signs]);

    const getTopNeighbour = (x : number, y : number, page : number, current: boolean, line: string) => {
        const element = signs.find((sign : any) => sign.x === x && sign.y === y - 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, "top");    
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, "top");
                }
            }
        }
    };

    const getBottomNeighbour = (x : number, y : number, page : number, current: boolean, line: string) => {
        const element = signs.find((sign: any) => sign.x === x && sign.y === y + 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, "bottom");
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, "bottom");
                }
            }
        }
    };

    const getLeftNeighbour = (x: number, y: number, page: number, current: boolean, line: string) => {
        const element = signs.find((sign : any) => sign.x === x - 1 && sign.y === y && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, "left");
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, "left");
                }
            }
        }
    };

    const getRightNeighbour = (x: number, y: number, page: number, current: boolean, line: string) => {
        const element = signs.find((sign: any) => sign.x === x + 1 && sign.y === y && sign.page === page);
        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, "right");
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, "right");
                }
            }
        }
       
    };

    const getCornerTopLeftNeighbour = (x: number, y: number, page: number, current: boolean, line: string, fromAutomention : boolean = false) => {
        const element = signs.find((sign: any) => sign.x === x - 1 && sign.y === y - 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, fromAutomention ? "top" : "top-left");
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, fromAutomention ? "top" : "top-left");
                }
            }
        }
    };

    const getCornerTopRightNeighbour = (x: number, y: number, page: number, current: boolean, line: string, fromAutomention : boolean = false) => {
        const element = signs.find((sign: any) => sign.x === x + 1 && sign.y === y - 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, fromAutomention ? "top" : "top-right");
            } else {
                if (electricityArr?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, fromAutomention ? "top" : "top-right");
                }
            }
        }
    };

    const getCornerBottomLeftNeighbour = (x: number, y: number, page: number, current: boolean, line: string, fromAutomention: boolean = false) => {
        const element = signs.find((sign: any) => sign.x === x - 1 && sign.y === y + 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, fromAutomention ? "bottom" : "bottom-left");
            } else {
                if (currentElectricity?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, fromAutomention ? "bottom" : "bottom-left");
                }
            }
        }
    };

    const getCornerBottomRightNeighbour = (x: number, y: number, page: number, current: boolean, line: string, fromAutomention : boolean = false) => {
        const element = signs.find((sign: any) => sign.x === x + 1 && sign.y === y + 1 && sign.page === page);

        if (element) {
            if (!markedMap?.[`${element.x}_${element.y}_${element.page}_${line}`]) {
                Object.assign(markedMap, {[`${element.x}_${element.y}_${element.page}_${line}`]: true});
    
                getNeighbours(element, current, line, fromAutomention ? "bottom" : "bottom-right");
            } else {
                if (currentElectricity?.[`${element.x}_${element.y}_${element.page}_${line}`] === false && current) {
                    getNeighbours(element, true, line, fromAutomention ? "bottom" : "bottom-right");
                }
            }
        }
    };

    // Get Electricity in scheme

    const getNeighbours = (line : {x : number, y : number, page : number, flip : number, name : string, signPosition : string}, isCurrent: boolean, electricLine: string, direction : string) => {
        const { x, y, page, flip, name, signPosition } = line;

        if (name.includes("conductor_lung_sageata_final")) {
            Object.assign(electricityArr, {[`${x}_${y}_${page}_${electricLine}`]: isCurrent});
        }

        if (name.includes("contur") || name.includes("indicator_luminos") || name.includes("bobina_releu") || name.includes("motor") || name.includes("conductor_lung_sageata_final") || name.includes("actionare_electromecanica_cu_temporizare")) return;

        let current = isCurrent;

        console.log(line);
        
        if (name.includes("deschis")) {

            // changeHasCurrent(x, y, page, false);
            Object.assign(electricityArr, {[`${x}_${y}_${page}_${electricLine}`]: false});
            current = false;

        } else {
            // setElectricityMap(curr => {...curr, []});
            // changeHasCurrent(x, y, page, true);
            Object.assign(electricityArr, {[`${x}_${y}_${page}_${electricLine}`]: isCurrent});
        }

        if (name.includes("actionare_prin_apasare_automentinere")) {

            if (Number(flip) === 0) {

                if (direction === "top") getTopNeighbour(x, y, page, current, electricLine);
                getRightNeighbour(x, y, page, isCurrent, electricLine);
                if (direction === "bottom") getBottomNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {

                if (direction === "left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getRightNeighbour(x, y, page, current, electricLine);
                getBottomNeighbour(x, y, page, isCurrent, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "top") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getBottomNeighbour(x, y, page, current, electricLine);
                getLeftNeighbour(x, y, page, isCurrent, electricLine);
            
            } else if (Number(flip) === 270) {

                if (direction === "left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getRightNeighbour(x, y, page, current, electricLine);
                getTopNeighbour(x, y, page, isCurrent, electricLine);
            
            }

        } else if (name.includes("automentinere")) { 

            if (Number(flip) === 0) {

                getTopNeighbour(x, y, page, current, electricLine);
                getBottomNeighbour(x, y, page, current, electricLine);
                getCornerBottomLeftNeighbour(x, y, page, current, electricLine, true);
                getCornerTopLeftNeighbour(x, y, page, current, electricLine, true);

            } else if (Number(flip) === 90) {

                getLeftNeighbour(x, y, page, current, electricLine);
                getRightNeighbour(x, y, page, current, electricLine);
                getCornerTopRightNeighbour(x, y, page, current, electricLine, true);
                getCornerTopLeftNeighbour(x, y, page, current, electricLine, true);

            } else if (Number(flip) === 180) {
                
                getTopNeighbour(x, y, page, current, electricLine);
                getBottomNeighbour(x, y, page, current, electricLine);
                getCornerBottomRightNeighbour(x, y, page, current, electricLine, true);
                getCornerTopRightNeighbour(x, y, page, current, electricLine, true);

            } else if (Number(flip) === 270) {

                getLeftNeighbour(x, y, page, current, electricLine);
                getRightNeighbour(x, y, page, current, electricLine);
                getCornerBottomRightNeighbour(x, y, page, current, electricLine, true);
                getCornerBottomLeftNeighbour(x, y, page, current, electricLine, true);
           
            }

        } else if (name.includes("conductor_lung")) {

            if (Number(flip) === 90 || Number(flip) === 270) {

                if (direction === "top") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getBottomNeighbour(x, y, page, current, electricLine);

            } else {

                if (direction === "left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getRightNeighbour(x, y, page, current, electricLine);

            }

        } else if (name.includes("conductor_diagonal_45")) {
            
            if (Number(flip) === 90 || Number(flip) === 270) {

                if (direction === "top-left") getCornerTopLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom-right") getCornerBottomRightNeighbour(x, y, page, current, electricLine);
            
            } else {

                if (direction === "top-right") getCornerTopRightNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom-left") getCornerBottomLeftNeighbour(x, y, page, current, electricLine);
           
            }

        } else if (name.includes("conductor_diagonal_135")) {

            if (Number(flip) === 90 || Number(flip) === 270) {

                if (direction === "top-right") getCornerTopRightNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom-left") getCornerBottomLeftNeighbour(x, y, page, current, electricLine);
            
            } else {

                if (direction === "top-left") getCornerTopLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom-right") getCornerBottomRightNeighbour(x, y, page, current, electricLine);
            
            }

        } else if (name.includes("conductor_oblic_45")) {

            if (Number(flip) === 0) {
                
                if (direction === "top-right") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getCornerBottomLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {

                if (direction === "bottom-right") getRightNeighbour(x, y, page, current, electricLine);
                if (direction === "left") getCornerTopLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "bottom-left") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getCornerTopRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 270) {

                if (direction === "top-left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getCornerBottomRightNeighbour(x, y, page, current, electricLine);
            
            }

        } else if (name.includes("conductor_oblic_135")) {

            if (Number(flip) === 0) {

                if (direction === "top-left") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getCornerBottomRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {

                if (direction === "top-right") getRightNeighbour(x, y, page, current, electricLine);
                if (direction === "left") getCornerBottomLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "bottom-right") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getCornerTopLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 270) {

                if (direction === "bottom-left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getCornerTopRightNeighbour(x, y, page, current, electricLine);
            
            }

        } else if (name.includes("colt_stanga_sus")) {

            if (Number(flip) === 0) {

                if (direction === "left") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {

                if (direction === "right") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "right") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 270) {

                if (direction === "left") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getRightNeighbour(x, y, page, current, electricLine);
            
            }

        } else if (name.includes("colt_stanga_jos")) {

            if (Number(flip) === 0) {

                if (direction === "left") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {

                if (direction === "left") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "right") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getLeftNeighbour(x, y, page, current, electricLine);    
            
            } else if (Number(flip) === 270) {

                if (direction === "right") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getLeftNeighbour(x, y, page, current, electricLine);
            
            }
 
        } else if (name.includes("colt_dreapta_sus")) {

            if (Number(flip) === 0) {

                if (direction === "right") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getLeftNeighbour(x, y, page, current, electricLine);    
            
            } else if (Number(flip) === 90) {

                if (direction === "right") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getLeftNeighbour(x, y, page, current, electricLine);
           
            } else if (Number(flip) === 180) {

                if (direction === "left") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getRightNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 270) {

                if (direction === "left") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getRightNeighbour(x, y, page, current, electricLine);
            
            }

        } else if (name.includes("colt_dreapta_jos")) {

            if (Number(flip) === 0) {

                if (direction === "right") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getLeftNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 90) {
                
                if (direction === "left") getTopNeighbour(x, y, page, current, electricLine);
                if (direction === "bottom") getRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 180) {

                if (direction === "left") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getRightNeighbour(x, y, page, current, electricLine);
            
            } else if (Number(flip) === 270) {

                if (direction === "right") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getLeftNeighbour(x, y, page, current, electricLine);    
            
            }

        } else if (name.includes("derivatie_din_conductor")) {
            
            if (Number(flip) === 0) {

                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 90) {
                
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 180) {
                
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 270) {

                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            }

        } else if (name.includes("semi_cruce")) {

            if (Number(flip) === 0) {

                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 90) {

                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 180) {
                
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "top") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            } else if (Number(flip) === 270) {
                
                if (direction !== "right") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction !== "left") getRightNeighbour(x, y, page, current, electricLine);
                if (direction !== "bottom") getTopNeighbour(x, y, page, current, electricLine);

            }

        } else if (name.includes("cruce")) {

            if (direction === "left") getLeftNeighbour(x, y, page, current, electricLine);
            if (direction === "right") getRightNeighbour(x, y, page, current, electricLine);
            if (direction === "top") getTopNeighbour(x, y, page, current, electricLine);
            if (direction === "bottom") getBottomNeighbour(x, y, page, current, electricLine);
            
        } else {
            if (Number(flip) === 90 || Number(flip) === 270) {
                
                if (direction === "left") getLeftNeighbour(x, y, page, current, electricLine);
                if (direction === "right") getRightNeighbour(x, y, page, current, electricLine);
            
            } else {

                if (direction === "bottom") getBottomNeighbour(x, y, page, current, electricLine);
                if (direction === "top") getTopNeighbour(x, y, page, current, electricLine);
            
            }
        }
    };


    const getElectricity = () => {
        console.log(signs);
        
        // electricityArr = {};
        Object.keys(electricityArr).forEach(key => {
            delete electricityArr[key];
        });

        Object.keys(markedMap).forEach(key => {
            delete markedMap[key];
        });

        console.log(markedMap);

        console.log(electricityArr);
        
        const currentLines = signs.filter((sign : { name: string }) => sign.name.includes("conductor_lung_sageata") && !sign.name.includes("conductor_lung_sageata_final"));
        console.log(currentLines);
        
        currentLines.forEach((line : { x: number, y: number, page: number, flip: number, name: string, signPosition: string }, lineIdx : number) => {
            const leftElement = signs.find((sign : any) => sign.x === line.x - 1 && sign.y === line.y && sign.page === line.page);
            if (leftElement && leftElement?.texts?.some((text : { name: string }) => text.name.toLowerCase() === "nul" || text.name.toLowerCase() === "n")) {
                getNeighbours(line, true, "N", line.flip === 0 ? "right" : line.flip === 90 ? "bottom" : line.flip === 180 ? "left" : "top");
            } else {
                getNeighbours(line, true, lineIdx === 0 ? "L1" : lineIdx === 1 ? "L2" : lineIdx === 2 ? "L3" : "PE", line.flip === 0 ? "right" : line.flip === 90 ? "bottom" : line.flip === 180 ? "left" : "top");
            }
        });

        Object.keys(electricityArr).forEach((key : string) => {
            const value = electricityArr[key];
            const [ x, y, page, electricLine ] = key.split("_");
            console.log(x, y, page, value, electricLine);
        }); 

        console.log(electricityArr);

        // for (let i = 0; i < Object.keys(electricityArr).length; i++) {
        //     const key = Object.keys(electricityArr)[i];
        //     const value = electricityArr[key];

        //     setTimeout(() => {
        //         setCurrentElectricity((curr : any) => {
        //             return Object.assign(curr, {[key] : value});
        //         });
        //     }, i * 30);
        // }

        setCurrentElectricity(electricityArr);

    };

    useEffect(() => {
        console.log(currentElectricity);
    }, [currentElectricity]);


    useEffect(() => {
        console.log(markedMap);
    }, [markedMap]);

    let beenHere = useMemo(() => false, []);

    useEffect(() => {
        if (!signs?.length) return;

        if (!beenHere) { 
            getElectricity();
            beenHere = true;
        }
    }, [signs, isCurrentOnScheme]);
    

    let currPoint = useMemo(() => 1, []);

    useLayoutEffect(() => {
        setTexts(isSelected?.texts || []);
    }, [isSelected]);

    // Students function to get the defects

    const getSessionData = useCallback(async () => {
        if (isTeacher || !sessionId || category !== "2") return;
        console.log(sessionData);
        try {
            console.log("FIRST!");

            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/check-id/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
            const response = await request.json();
            console.log(response);
            if (!response.success) return null;
            const results = JSON.parse(response.results.results).find((user: { userId: string }) => user.userId === data.userData.userId);
            console.log(results);
            results.problemsSolved.forEach((problemSolved: { type: string, name: number }) => {
                changeSolvedSchemeStructure(problemSolved.name, true);
            });

            setProblemsSolved(results.problemsSolved);
            setNotes(results.notes);
            setLogs(results.logs);
            setDefectsResponse(results.currentResults);
            setSessionData(response.results);
            return response.results;

        } catch (e) {
            console.log(e);
        }

    }, []);


    const [problems, setProblems] = useState<Array<any>>([]);
    const [sessionDataExtracted, setSessionDataExtracted] = useState<boolean>(false);

    useEffect(() => {
        if (!sessionData) return;
        const currProblems = JSON.parse(sessionData?.problems || "[]");
        console.log(currProblems, schemeStructure);
        if (!currProblems) return;

        setProblems(currProblems);
        const newSchemeStructure = schemeStructure.map((dot: { dotNumber: number, line: string }) => {
            const currentProblem = currProblems.find((problem : any) => problem.point === dot.dotNumber);
            if (currentProblem) {
                console.log(dot, currentProblem);
                return {...dot,
                    defect: currentProblem.type,
                    screw: currentProblem.screw === null ? 1 : currentProblem.screw,
                    open: false,
                };
            }

            return dot; 
        });

        console.log(newSchemeStructure);

        changeSchemeStructure(newSchemeStructure);

        setSessionDataExtracted(true);
        
        // problemsRead = true;

    }, [sessionData, location.state]);


    let shortCircuitFinished = useMemo(() => false, []);
    let [initialSchemeStructure, setInitialSchemeStructure] = useState<any>(false);

    useEffect(() => {
        if (isTeacher || !sessionDataExtracted) return;
        console.log(initialSchemeStructure, schemeStructure);
        if (!initialSchemeStructure) setInitialSchemeStructure(schemeStructure);
        let currentInitialSchemeStructure = initialSchemeStructure ? initialSchemeStructure.map((element : any, idx : number) => {
            return {...element, screw: schemeStructure.find((currElement: any) => currElement.dotNumber === element.dotNumber)?.screw};
        }) : schemeStructure;

        if(!currentInitialSchemeStructure.some((element: { defect: string | null, dotNumber: number, solved: boolean }) => (element.defect === "Scurt circuit" && !element.solved) || problems.some((problem: { type: string | null, point: number }) => problem.type === "Scurt circuit" && problem.point.toString() === element.dotNumber.toString()))) return;
        
        console.log(problems);
        const shortCircuitsElements = currentInitialSchemeStructure.filter((element: { dotNumber: number, defect: string | null, solved: boolean }) => {
            const isShortcircuitDefect : boolean = problems.some((problem: { type: string | null, point: number }) => problem.type === "Scurt circuit" && problem.point === element.dotNumber);
            return ((element.defect === "Scurt circuit" || isShortcircuitDefect) && !schemeStructure?.find((currElement: any) => currElement.dotNumber === element.dotNumber)?.solved);
        });

        console.log(shortCircuitsElements);

        if (!shortCircuitsElements?.length) return;

        let copySchemeStructure : any = [], aparitionOfShortCircuit = 0;

        for (let idx = currentInitialSchemeStructure.length - 1; idx >= 0; idx--) {
            if (!(aparitionOfShortCircuit > 0 && currentInitialSchemeStructure[idx].dotNumber === 500)) {
                if (currentInitialSchemeStructure[idx].dotNumber === 500) aparitionOfShortCircuit++;
                copySchemeStructure.unshift(currentInitialSchemeStructure[idx]);
            } 
        }
        
        shortCircuitsElements.forEach(async (element: { dotNumber: number, line:  string | null }) => {

            const dotNumber = element.dotNumber;
            // const line = element.line;
            let currIdx : number = -1;
            const newSchemeStructure = [];

            copySchemeStructure.forEach((element: { dotNumber: number }, idx: number) => dotNumber === element.dotNumber ? currIdx = idx : null);
            
            if (currIdx === -1) return;

            let leftIdx = currIdx - 1, rightIdx = currIdx + 1, initialIdx : number = -1, initialDot : any;
            const initialLine = element.line;
            const totalCells : any = document.querySelectorAll(".cell");

            const initialCell = [...totalCells].find(cell => {
                const dots : any = cell.querySelectorAll(".dot p");
                if ([...dots].some((dot : any) => dot.innerText.toString() === element.dotNumber.toString())) {
                    dots.forEach((dot : any, idx : number) => {
                        if (dot.innerText.toString() === element.dotNumber.toString()) {
                            initialIdx = idx;
                            initialDot = dot.parentElement;
                        } 
                    });                    
                    return true;
                }
                return false;
            });
            console.log(initialLine);

            while (leftIdx >= 0) {
                const currentDotNumber = copySchemeStructure[leftIdx].dotNumber;

                let currentDotIdx : number = -1, currentDot : any;
                const currentCell = [...totalCells].find(cell => {
                    const dots : any = cell.querySelectorAll(".dot p");
                    if ([...dots].some((dot : any) => dot.innerText.toString() === currentDotNumber.toString())) {
                        dots.forEach((dot : any, idx : number) => {
                            if (dot.innerText.toString() === currentDotNumber.toString()) {
                                currentDotIdx = idx;
                                currentDot = dot.parentElement;
                            } 
                        });
                        return true;
                    }
                    else return false;
                });
                const isConsumer = currentCell && (currentCell?.querySelector("img")?.src?.includes("indicator_luminos") || currentCell?.querySelector("img")?.src?.includes("motor") || currentCell?.querySelector("img")?.src?.includes("bobina_releu"));
                const isOpen = currentCell?.querySelector("img")?.src?.includes("deschis");
                if (isOpen) console.log(currentCell, currentDot?.classList, initialDot?.classList, copySchemeStructure);
                if (copySchemeStructure[leftIdx].screw < 1 || copySchemeStructure[leftIdx].defect === "Lipsa tensiune" || (isOpen && ([...currentDot?.classList]?.some((currClass : string) => currClass.includes("left") || currClass.includes("top"))) && (initialCell !== currentCell || ([...initialDot?.classList]?.some((currClass : string) => currClass.includes("right"))))) || (isConsumer && (([...currentDot?.classList]?.some((currClass : string) => currClass.includes("left") || currClass.includes("top")))) && (initialCell !== currentCell || ([...initialDot?.classList]?.some((currClass : string) => currClass.includes("right"))))) || (initialLine !== copySchemeStructure[leftIdx].line && !isBranchLine(initialLine || "", copySchemeStructure[leftIdx].line))) {
                    break;
                }
                leftIdx--;
            }

            leftIdx++;

            console.log(leftIdx);

            while (rightIdx < copySchemeStructure.length) {
                let currentDotIdx : number = -1, currentDot: any;

                const currentDotNumber = copySchemeStructure[rightIdx].dotNumber;
                const currentCell = [...totalCells].find(cell => {
                    const dots : any = cell.querySelectorAll(".dot p");
                    if ([...dots].some((dot : any) => dot.innerText.toString() === currentDotNumber.toString())) {
                        dots.forEach((dot : any, idx : number) => {
                            if (dot.innerText.toString() === currentDotNumber.toString()) {
                                currentDotIdx = idx;
                                currentDot = dot.parentElement;
                            } 
                        });                        
                        return true;
                    }
                    else return false;
                });
                
                const isConsumer = currentCell && (currentCell?.querySelector("img")?.src?.includes("indicator_luminos") || currentCell?.querySelector("img")?.src?.includes("motor") || currentCell?.querySelector("img")?.src?.includes("bobina_releu"));
                const isOpen = currentCell?.querySelector("img")?.src?.includes("deschis");
                if (copySchemeStructure[rightIdx].screw < 1 || copySchemeStructure[rightIdx].defect === "Lipsa tensiune" || (isOpen && ([...currentDot?.classList]?.some((currClass : string) => currClass.includes("right"))) && !isBranchLine(copySchemeStructure[rightIdx].line, copySchemeStructure?.[rightIdx + 1]?.line) && (initialCell !== currentCell || ([...initialDot?.classList]?.some((currClass : string) => currClass.includes("left") || currClass.includes("top"))))) || (isConsumer && ([...currentDot?.classList]?.some((currClass : string) => currClass.includes("right"))) && (initialCell !== currentCell || ([...initialDot?.classList]?.some((currClass : string) => currClass.includes("left") || currClass.includes("top") )))) || (initialLine !== copySchemeStructure[rightIdx].line && !isBranchLine(initialLine || "", copySchemeStructure[rightIdx].line))) break;
                rightIdx++;
            }

            rightIdx--;

            for (let idx = 0; idx < leftIdx; idx++) {
                if (idx < leftIdx) newSchemeStructure.push(copySchemeStructure[idx]);
            }

            newSchemeStructure.push({
                dotNumber: 500,
                line: "Nul protectie",
                defect: null,
                screw: 1,
                solved: false,
                potential: Number(schemeNumber) === 1 ? 3 : 173,
                open: false
            });


            for (let idx = leftIdx; idx <= rightIdx; idx++) {
                const element = copySchemeStructure[idx];
                newSchemeStructure.push({...element,
                    line: "Nul protectie",
                    potential: Number(schemeNumber) === 1 ? 3 : 173,
                });
            }

            for (let idx = rightIdx + 1; idx < copySchemeStructure.length; idx++) {
                newSchemeStructure.push(copySchemeStructure[idx]);
            }
        
            changeSchemeStructure(newSchemeStructure);

        });

        setTimeout(() => changeOpenStructure(), 200);

        shortCircuitFinished = true;
    }, [sessionDataExtracted, signs, screw, unscrew]);


    const changeOpenStructure = () => {
        if (category !== "2") return;
        schemeStructure.forEach((element: { dotNumber: number }) => element.dotNumber);

        const initialSchemeStructure = schemeStructure.map((element : { dotNumber: number }) => {
            changeOpenSchemeStructure(false, element.dotNumber);
            return {...element, open: false };
        });

        
        let currLine : string | null = null;
    
        setTimeout(() => {
            initialSchemeStructure.forEach((element: { dotNumber: number, line: string, defect: string, solved: boolean }, idx : number) => {
                const currentDot = Array.from(document.querySelectorAll('.dot')).find((dot : any) => Number(dot?.querySelector("p")?.innerText || "-1") === element.dotNumber);
                if (element.dotNumber !== 500 && (!currentDot?.parentElement?.querySelector("img")?.src.includes("deschis") || initialSchemeStructure?.[idx - 1]?.defect !== "Scurt circuit") && !currentDot?.parentElement?.querySelector("img")?.src.includes("actionare_prin_apasare_automentinere") && ((currentDot?.parentElement?.querySelector("img")?.src.includes("deschis") &&  (currentDot.classList.contains("right") || currentDot.classList.contains("max-right")) && (element.defect !== "Scurt circuit" || element.solved || Number(schemeNumber) !== 1)) || currLine === element.line)) {
                    
                    currLine = element.line;
                    changeOpenSchemeStructure(true, element.dotNumber);
                } else if (currLine !== element.line) currLine = null;
            });
        }, 250);
     
    };

    useEffect(() => {
        if (!schemeStructure?.length) return;
        // console.log(schemeStructure);
        changeOpenStructure();
    }, [signs]);

    useEffect(() => {
        console.log(schemeStructure);
    }, [schemeStructure]);

    // useEffect(() => {
        
    //     if (playAudio) buzzerSound.play();
    //     else buzzerSound.pause();
    //     buzzerSound.loop = true;
    //     setPlayAudio(currPlay => !currPlay);
    // }, [playAudio]);

    const updateProjectData = async () => {
        try {
            const options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    grid: isGrid,
                    zoom: Number(document.body.getAttribute("zoom")) || 1,
                    xScroll: scrollX,
                    yScroll: scrollY,
                    userId: data.userData.userId,
                    projectId
                })
            };

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/project/update-project-data`, options);
            const response = await request.json();
            if (!response.success) console.log(response);
            
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        if (!projectData && projectId?.length) return;
        document.body.classList.add("grid-body");
        document.querySelector("html")?.classList.add("grid-body");
        const scrollVisible = document.body.scrollHeight > document.body.clientHeight || document.body.scrollWidth > document.body.clientWidth;

        if (scrollVisible) document.body.classList.remove("grid-scroll");
        else document.body.classList.add("grid-scroll");
    }, [projectData, projectDataSaved, location]);

    useEffect(() => {
        if (category !== "2" || !sessionId || isTeacher) return;

        (async () => {
            const currSessionData = await getSessionData();
           
            if (!currSessionData) {
                console.log("Error with session data!");
                return;
            }

            if (debugSchemeNumber === 1) {
                const currentDots: any = document.querySelectorAll(".dot");
                setSchemeMap([...currentDots].map(dot => {
                    return {
                        point: Number(dot.getAttribute("point")) || 0,
                        sign: dot.parentElement?.querySelector("img")?.src || "",
                        line: Number(dot.getAttribute("point")) <= 18 ? "F" : "N",
                        problem: JSON.parse(currSessionData.problems).find((problem: any) => problem.point === Number(dot.getAttribute("point")))?.type || null
                    }
                }));
            } else if (debugSchemeNumber === 2) {
                
            }
        })();

    }, []);

    const actuationAlgorithm = (currIdx : number, currRowIdx : number, open : boolean | null = null, callback : boolean | null = true) => {
        const currSignObj: any = findSignObject(currIdx, currRowIdx);
        const currSign: any = findSign(currIdx, currRowIdx);
        
        if (!currSign) return;
        const source = currSign?.props?.children?.[0]?.props?.src;
        if (!(source.includes("siguranta") && !source.includes("siguranta_automata")) && !(source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis"))) return;

        if (source.includes("siguranta") && !source.includes("siguranta_automata")) {
            const isOpen = source.includes("/siguranta_deschisa");
            removeSign(currIdx, currRowIdx, page);
            if (isOpen && (open !== null ? open : true)) addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            else addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta_deschisa"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        } else if (source.includes("actionare_prin_apasare") && Number(schemeNumber) !== 1) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = isOpen && (open !== null ? open : true) ? source.replaceAll("deschis", "inchis").slice(14) : source.replaceAll("inchis", "deschis").slice(14);
            const realNewSource = newSource.split(".")[0];

            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[realNewSource].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");
            const selfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");
            const interlockQ1 = findSignObjectByName("C.i. Q11.1");
            const interlockQ2 = findSignObjectByName("C.i. Q11.2");
            const BP1 = findSignObjectByName(Number(schemeNumber) === 2 ? "B.p." : "B.p.");


            // const selfMaintananceQ1 = findSignObject(selfMaintananceQ1Obj?.x || (currIdx + 1), selfMaintananceQ1Obj?.y || currRowIdx);

            setTimeout(() => {
                if (Number(schemeNumber) === 1) return;
                if (Number(schemeNumber) === 2 || currSignObj.texts.some((text: { name: string }) => text.name === "S1")) {
                    if ((currSignObj.name.includes("automentinere") && !selfMaintananceQ1.name.includes("inchis")) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ1.name.includes("inchis"))) {
                        // alert("Open")
                        actuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx);
                        // if (callback) setTimeout(() => actuationAlgorithm(BP1?.x || 0, BP1?.y || 0, false, false), 2000);

                        if (interlockQ1?.x !== null) actuationAlgorithm(interlockQ1?.x || (currIdx + 1), interlockQ1?.y || currRowIdx);

                        // For the three parallel contacts
                            // actuationAlgorithm(4, 7);
                            // actuationAlgorithm(5, 7);
                            // actuationAlgorithm(6, 7);
                    }
                }

                if (currSignObj.texts.some((text: { name: string }) => text.name === "S2")) {
                    if ((currSignObj.name.includes("automentinere") && !selfMaintananceQ2.name.includes("inchis")) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ2.name.includes("inchis"))) {

                        actuationAlgorithm(selfMaintananceQ2?.x || (currIdx + 1), selfMaintananceQ2?.y || currRowIdx);
                        if (interlockQ2?.x !== null) actuationAlgorithm(interlockQ2?.x || (currIdx + 1), interlockQ2?.y || currRowIdx);

                        // For the three parallel contacts
                            // actuationAlgorithm(4, 7);
                            // actuationAlgorithm(5, 7);
                            // actuationAlgorithm(6, 7);
                    }
                }
               
            }, 500);

            setTimeout(() => {
                removeSign(currIdx, currRowIdx, page);
                addSign(currIdx, currRowIdx, page, currSignObj.name, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            }, 650);

        }
        else if (source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis")) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = open === true ? source.replaceAll("inchis", "deschis").slice(14) : open === false ? source.replaceAll("deschis", "inchis").slice(14) : isOpen ? source.replaceAll("deschis", "inchis").slice(14) : source.replaceAll("inchis", "deschis").slice(14);
            
            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[newSource.split(".")[0]].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        }
 
        setTimeout(() => document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected")), 200);
    };

    useEffect(() => {
        const projects = data.userData.projects;
        if (!projects || !projects.length) return;
        const currProject = projects.find((project : { projectId: string }) => projectId === project.projectId);
        if (currProject) setProjectData(currProject);
    }, []);


    useEffect(() => {
        if (!projectData) return;
   
        document.body.setAttribute("zoom", projectData?.zoom || 1);
        setTimeout(() => window.scrollTo({
            top: projectData?.yScroll || 0,
            left: projectData?.xScroll || 0,
            behavior: 'smooth'
        }), 0);

        // setScrollX(projectData?.xScroll || 0);
        // setScrollY(projectData?.yScroll || 0);
        // if (Number(projectData?.grid) == 0) changeGridActivated();
        setProjectDataSaved(true);
    }, [projectData]);

    useEffect(() => {
        if (!problems?.length) return;
        const timer = setTimeout(() => {
            const S1 = findSignObjectByName("F1");
            const S2 = findSignObjectByName("F2");
            const S3 = findSignObjectByName("F3");

            const S4 = findSignObjectByName("F4");
            const S5 = findSignObjectByName("F5");
            const contact = findSignObjectByName("I");

            if (Number(schemeNumber) === 1) {
         

                const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 18 && element.type === "Scurt circuit")?.point || -1;
                
                if (schemeStructure.some((element : { dotNumber: number, screw: number }) => {
                    // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                    return element.dotNumber < firstShortcircuitDefect && element.screw <= .25;
                })) return;
    
                console.log(problems, S1, contact);
                if (S1 && problems.some((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 10 && element.type === "Scurt circuit")) {
                    actuationAlgorithm(S1.x, S1.y, true);
                } else if (S1 && problems.some((element: { point: number, type: string | null }) => element.point >= 11 && element.point <= 18 && element.type === "Scurt circuit")) {
                    if (contact.name.includes("inchis")) {
                        actuationAlgorithm(S1.x, S1.y, true);
                    }
                }
    
                // if (schemeStructure.some((element: { dotNumber: number, defect: string | null }) => element.dotNumber >= 19 && element.dotNumber <= 32 && element.defect === "Scurt circuit")) {
                    
                // }
    
    
            } else if (Number(schemeNumber) === 2) {
                const contactS1 = findSignObjectByName("Q1");
                const contactS3 = findSignObjectByName("Q1S3");
                const contactS5 = findSignObjectByName("C.a. Q1");
                const firstTotalLinePoints = [8, 9, 10, 26, 27, 28, 29, 52, 53, 54, 55, 70, 71];
                const firstRestLinePoints = [28, 29, 52, 53, 54, 55, 70, 71];
                const secondTotalLinePoints = [13, 14, 31, 32, 33, 34, 57, 58, 59, 60, 69, 70];
                const secondRestLinePoints = [33, 34, 57, 58, 59, 60, 69, 70];
                const thirdTotalLinePoints = [16, 17, 34, 35, 36, 37, 60, 61, 62, 63, 72, 73];
                const thirdRestLinePoints = [36, 37, 60, 61, 62, 63, 72, 73];
                const fifthTotalLinePoints = [24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75];
                const fifthRestLinePoints = [44, 45,  47, 49, 51, 64, 65, 66, 67, 74, 75];

                let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
                const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                    if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                        firstShortcircuitDefectIdx = idx;
                        return true;
                    }

                    return false;
                });

                const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                    if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                        thirdShortcircuitDefectIdx = idx;
                        return true;
                    }

                    return false;
                });

                const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                    if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                        fifthShortcircuitDefectIdx = idx;
                        return true;
                    }
                    return false;
                });


                // For S1

                if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                    // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                    return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
                })) {
                    if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {

                        actuationAlgorithm(S1.x, S1.y, true);
                    } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        if (contactS1.name.includes("inchis")) {
                            actuationAlgorithm(S1.x, S1.y, true);
                        }
                    }
                }


                // For S3
                if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                    // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                    return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
                })) {

                    if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                        actuationAlgorithm(S3.x, S3.y, true);
                    } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        if (contactS3.name.includes("inchis")) {

                            actuationAlgorithm(S3.x, S3.y, true);
                        }
                    }
                }


                // For S5
                if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                    return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
                })) {
                    if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                        
                        actuationAlgorithm(S5.x, S5.y, true);
                    } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        if (contactS5.name.includes("inchis")) {
                            actuationAlgorithm(S5.x, S5.y, true);
                        }
                    }
                }
            } else if (Number(schemeNumber) === 3) {
                
                const secondContactS1 = findSignObjectByName("Q11.2");
                const secondContactS3 = findSignObjectByName("Q11.2S3");
                const selfMaintananceQ1 = findSignObjectByName("C.i. Q11.1");
                const selfMaintananceQ2 = findSignObjectByName("C.i. Q11.2");

                let currentSecondContact : any, currentSecondContactS3;
                document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === secondContactS1?.y && Number(cell.getAttribute("row")) === secondContactS1?.x ? currentSecondContact = cell?.querySelector("img")?.src?.includes("deschis") : null);
                // document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === secondContactS3?.y && Number(cell.getAttribute("row")) === secondContactS3?.x ? currentSecondContactS3 = cell?.querySelector("img")?.src?.includes("deschis") : null);

                const contactS1 = currentSecondContact ? findSignObjectByName("Q11.1") : secondContactS1;
                const contactS3 = currentSecondContact ? findSignObjectByName("Q11.1S3") : secondContactS3;

                const contactS5 = findSignObjectByName("C.a. Q1");

                const firstTotalLinePoints = currentSecondContact ? [6, 7, 8, 9, 50, 51, 30, 31, 32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [6, 7, 8, 9, 30, 31, 50, 51, 52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
                const firstRestLinePoints = currentSecondContact ?  [32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
                const secondTotalLinePoints = [10, 11, 12, 13, 46, 47, 34, 35, 36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
                const secondRestLinePoints = [36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
                const thirdTotalLinePoints = currentSecondContact ? [14, 15, 16, 17, 38, 39, 42, 43, 40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [14, 15, 16, 17, 38, 39, 42, 43, 44, 45, 32, 33, 82, 83, 84, 85, 104, 105];
                const thirdRestLinePoints = currentSecondContact ? [40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [44, 45, 32, 33, 82, 83, 84, 85, 104, 105];
                // const fifthTotalLinePoints = [24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75];
                // const fifthRestLinePoints = [44, 45,  47, 49, 51, 64, 65, 66, 67, 74, 75];

                let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
                const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                    if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                        firstShortcircuitDefectIdx = idx;
                        return true;
                    }

                    return false;
                });

                const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                    if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                        thirdShortcircuitDefectIdx = idx;
                        return true;
                    }

                    return false;
                });

                // const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                //     if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                //         fifthShortcircuitDefectIdx = idx;
                //         return true;
                //     }
                //     return false;
                // });


                // For S1

                if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                    // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                    return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
                })) {
                    if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                        actuationAlgorithm(S1.x, S1.y, true);
                    } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        let currentContact;
                        document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS1?.y && Number(cell.getAttribute("row")) === contactS1?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                        if (currentContact) {
                            actuationAlgorithm(S1.x, S1.y, true);
                        }
                    }
                }


                // For S3
                if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                    // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                    return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
                })) {
                    if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                        actuationAlgorithm(S3.x, S3.y, true);
                    } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        let currentContact;
                        document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                        if (currentContact) {
                            actuationAlgorithm(S3.x, S3.y, true);
                        }
                    }
                }


                // For S5
                // if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                //     return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
                // })) {
                //     if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                        
                //         actuationAlgorithm(S5.x, S5.y, false);
                //     } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                //         if (foundSignObject?.name?.includes("actionare_prin_apasare_automentinere")) {
                //             setTimeout(() => {
                //             // alert("PASS 2");

                //                 let currentContact;
                //                 document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS5?.y && Number(cell.getAttribute("row")) === contactS5?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                //                 if (currentContact) {
                //                     actuationAlgorithm(S5.x, S5.y, false);
                //                     setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                //                     setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                //                 }
                //             }, 1000);
                            
                //         }
                            
                        
                //     }
                // }
            
            }
        }, 250);

        return () => clearTimeout(timer);
    
    }, [problems]);

    // useLayoutEffect(() => {
    //     if (projectName === "Circuit simplu") {
    //         const S1 = findSignObjectByName("F1");
    //         const S2 = findSignObjectByName("F2");
    //         const contact = findSignObjectByName("I");

    //         const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 18 && element.type === "Scurt circuit")?.point || -1;
            
    //         if (schemeStructure.some((element : { dotNumber: number, screw: number }) => {
    //             // if (element.dotNumber < 3 || element.dotNumber > 18) return;
    //             return element.dotNumber < firstShortcircuitDefect && element.screw <= .25;
    //         })) return;

    //         console.log(problems, S1, contact);
    //         if (problems.some((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 10 && element.type === "Scurt circuit")) {
    //             actuationAlgorithm(S1.x, S1.y, true);
    //         } else if (problems.some((element: { point: number, type: string | null }) => element.point >= 11 && element.point <= 18 && element.type === "Scurt circuit")) {
    //             if (contact.name.includes("inchis")) {
    //                 actuationAlgorithm(S1.x, S1.y, true);
    //             }
    //         }

    //         // if (schemeStructure.some((element: { dotNumber: number, defect: string | null }) => element.dotNumber >= 19 && element.dotNumber <= 32 && element.defect === "Scurt circuit")) {
                
    //         // }


    //     }
    // }, [schemeStructure]);

    // Disable right click

    // useEffect(() => {
    //     if (!pageRef || !pageRef.current) return;
    //     pageRef.current.addEventListener('contextmenu', event => event.preventDefault());
    // }, [pageRef]);

    useEffect(() => {
        document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected"));
    }, []);

    useEffect(() => {
        zoomFunc(Number(document.body.getAttribute("zoom")) || 1, isGrid, format);
    }, [signs, isGrid, location]);

    useLayoutEffect(() => {
        zoomFunc(Number(document.body.getAttribute("zoom")) || 1, isGrid, format);
    }, [projectData, projectDataSaved]);

    useLayoutEffect(() => {
        if (!projectData) return;
        (async () => {
            await updateProjectData();
        })();

    }, [signs, isGrid, scrollX, scrollY]);

   

    const [times, setTimes] = useState(0);


    // useEffect(() => {
    //     if (category !== "0") return;
    //     console.log(isGrid);
    //     if (window.history.state.isGrid === false && isGrid) {
    //         changeGridActivated(false);
    //     } else if (window.history.state.isGrid !== false && !isGrid) changeGridActivated(true);


   
    //     // window.history.replce
    // }, []);

    useEffect(() => {
        if (category !== "0") return;
        if (times <= 2) {
            setTimes(curr => curr + 1);
            return;
        }

        (async () => {
            const currentProject = data.userData.projects.find((project: { projectId: string }) => project.projectId === projectId);
            const options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: data.userData.userId,
                    projectId,
                    historic: JSON.parse(currentProject.historic) || [],
                    signs,
                    projectName: currentProjectName
                })
            };

            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/project/update-project`, options);
            const response = await request.json();
            if (!response.success) console.log(response);
        })();
    }, [signs, currentProjectName]);

    const isInViewPort = (element: HTMLDivElement) => {
        const rect = element.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const gotoFirstEmptyCell = (): ({ x: number, y: number, page: number } | null) => {
        if (!cellRef.current) return null;
        for (let idx = 0; idx < cellRef.current.length; idx++) {
            const cell = cellRef.current[idx];
            if (cell) {
                if (isInViewPort(cell) && !cell?.firstChild) return {
                    x: idx % COLUMNS,
                    y: Math.floor(idx / COLUMNS),
                    page: page
                }
            }
        }


        for (let idx = 0; idx < cellRef.current.length; idx++) {
            const cell = cellRef.current[idx];

            if (cell) {
                if (!cell?.firstChild) return {
                    x: idx % COLUMNS,
                    y: Math.floor(idx / COLUMNS),
                    page: page
                }
            }
        }

        return null;
    };

    const getCenterCell = () => {

    };

 

    const initiateElement = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: HTMLDivElement | null, row: number, col: number) => {
        if (dragElement || isImageLocked) return;


        const foundSign = signs.find((sign: any) => sign.x === row && sign.y == col);
        if (foundSign && foundSign?.isLockedSign) return;

        setDragElement(element);
        setDragElementRow(row);
        setDragElementColumn(col);
        // setDragElementX(event.clientX);
        // setDragElementY(event.clientY);

        if (element?.firstChild) {
            const elementImage = element.querySelector("img");
            if (elementImage) {
                elementImage.style.position = 'absolute';
                element.style.position = 'initial';
                // elementImage.style.top = `${event.clientY - (elementImage.clientHeight / 2) + window.scrollY}px`;
                // elementImage.style.left = `${event.clientX - (elementImage.clientWidth / 2) + window.scrollX}px`;
            }

        }

        // requestAnimationFrame(() => moveElement(event));
    }, [signs, dragElement, isImageLocked]);

    const touchInitiateElement = (event: React.TouchEvent<HTMLDivElement>, element: HTMLDivElement | null, row: number, col: number) => {
        if (dragElement || event.touches.length > 1 || isImageLocked) return;
        const foundSign = signs.find((sign: any) => sign.x === row && sign.y == col);
        if (foundSign && foundSign?.isLockedSign) return;
        setDragElementRow(row);
        setDragElementColumn(col);
        setDragElement(element);
        // setDragElementX(event.touches[0].clientX);
        // setDragElementY(event.touches[0].clientY);
        if (element?.firstChild) {
            const elementImage = element.querySelector("img");
            if (elementImage) {
                elementImage.style.position = 'absolute';
                element.style.position = 'initial';
                document.body.style.overflow = 'hidden';
                // elementImage.style.top = `${event.clientY - (elementImage.clientHeight / 2) + window.scrollY}px`;
                // elementImage.style.left = `${event.clientX - (elementImage.clientWidth / 2) + window.scrollX}px`;
            }

        }
    };

    const moveElement = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (!dragElement || !pageRef.current) return;

        event.preventDefault();

        const elementImage = dragElement.querySelector("img");

        if (elementImage) {
            elementImage.style.top = `${event.clientY - ((elementImage.clientHeight)) - pageRef.current?.getBoundingClientRect().top}px`;
            elementImage.style.left = `${event.clientX - ((elementImage.clientWidth)) - pageRef.current?.getBoundingClientRect().left}px`;
        }

        // setDragElementX(event.clientX);
        // setDragElementY(event.clientY);

        if (cellRef?.current) {
            const cellElement = cellRef?.current?.find((cell, idx) => {
                if (cell?.getBoundingClientRect())
                    return cell?.getBoundingClientRect()?.top <= event.clientY && cell?.getBoundingClientRect()?.left <= event.clientX && cell?.getBoundingClientRect()?.right >= event.clientX && cell?.getBoundingClientRect()?.bottom >= event.clientY;
                return false;
            });

            if (cellElement && lastDrag !== cellElement) setLastDrag(cellElement);
        }

  

        // requestAnimationFrame(() => moveElement(event));
    }, [dragElement, isImageLocked, pageRef, cellRef, lastDrag]);

    const touchMoveElement = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        if (!dragElement || event.touches.length >= 2 || !pageRef.current) return;
        const elementImage = dragElement.querySelector("img");
        if (elementImage) {
            elementImage.style.top = `${event.touches[0].clientY - ((elementImage.clientHeight)) + window.scrollY}px`;
            elementImage.style.left = `${event.touches[0].clientX - ((elementImage.clientWidth) + pageRef.current?.getBoundingClientRect().left)}px`;
        }

        // setDragElementX(event.touches[0].clientX);
        // setDragElementY(event.touches[0].clientY);

        if (cellRef?.current) {
            const cellElement = cellRef?.current?.find((cell, idx) => {
                if (cell?.getBoundingClientRect())
                    return cell?.getBoundingClientRect()?.top <= event.touches[0].clientY && cell?.getBoundingClientRect()?.left <= event.touches[0].clientX && cell?.getBoundingClientRect()?.right >= event.touches[0].clientX && cell?.getBoundingClientRect()?.bottom >= event.touches[0].clientY;
                return false;
            });
            if (cellElement) setLastDrag(cellElement);
        }
    }, [dragElement, isImageLocked, cellRef, pageRef]);

    const stopElement = useCallback((touchElement = false) => {
        if (!dragElement || !lastDrag) return;
        const elementImage = dragElement.querySelector("img");

        if (touchElement) document.body.style.overflow = 'initial';

        if (elementImage) {
            elementImage.style.position = 'initial';
            elementImage.style.top = 'initial';
            elementImage.style.left = 'initial';
            dragElement.style.position = 'relative';
        }

        if (lastDrag?.firstChild) {
            setLastDrag(null);
            setDragElement(null);
            // setDragElementX(0);
            // setDragElementY(0);
            return;
        }


        changeSignPosition(dragElementRow, dragElementColumn, page, Number(lastDrag.getAttribute("row")), Number(lastDrag.getAttribute("column")), page);


        setLastDrag(null);
        setDragElement(null);
        // setDragElementX(0);
        // setDragElementY(0);
        setDragElementRow(null);
        setDragElementColumn(null);
    }, [dragElement, lastDrag, dragElementRow, dragElementColumn]);

       // Throttle function 

 
    const handleDotTouchStart = (e: any, currentDot: HTMLDivElement) => {
        const dots = Array.from(document.querySelectorAll(".dot"));
        if (!dots || !dots.length) return;
        if (dots.filter((currentDot: any) => currentDot.classList.contains("activated")).length >= 2) return;
        currentDot.classList.add("activated");

        // document.body.style.overflow = 'hidden';
        const currentActiveDots : any = dots.filter((currentDot: any) => currentDot.classList.contains("activated"));
        if (currentActiveDots.length === 2 && currentActiveDots) {
            currentActiveDots.forEach((activeDot: any) => activeDot.classList.add("sync-activated"));
            console.log("activationDots", currentActiveDots);
            setActiveDots(currentActiveDots);
            setDefectsButton(false);
        } else setDefectsButton(true);

        console.log(currentActiveDots);

        if (currentActiveDots.length === 1) {
            const problemButton : any = document.querySelector(".problem-button");
            const screwButton : any = document.querySelector(".screw-button");
            
            const currX = e.clientX;
            const currY = e.clientY;

            if (screwButton) {
                // screwButton.style.transition = 'none';
                screwButton.style.left = `${currX}px`;
                screwButton.style.top = `${currY}px`;

                setTimeout(() => {
                    // screwButton.style.transition = `all 350ms ease-in-out`;
                    screwButton.style.left = '100px';
                    screwButton.style.top = '25px';
                    screwButton.style.transition = 'none';
                }, 250);
            }

            if (problemButton) {
                problemButton.style.transition = 'none';
                problemButton.style.left = `${currX}px`;
                problemButton.style.top = `${currY}px`;

                setTimeout(() => {
                    problemButton.style.transition = `all 350ms ease-in-out`;
                    problemButton.style.left = 'initial';
                    problemButton.style.right = '20px';
                    problemButton.style.top = '20px';
                    problemButton.style.transition = 'none';
                }, 250);
            }
        }
     
    };

    const handleDotTouchEnd = (e: any, currentDot: HTMLDivElement) => {
        const dots = Array.from(document.querySelectorAll(".dot"));

        const currentActiveDots = dots.filter((currentDot: any) => currentDot.classList.contains("activated"));
        if (currentActiveDots.length === 2) {
            currentActiveDots.forEach((activeDot: any) => {
                // activeDot.classList.remove("activated");
                activeDot.classList.remove("sync-activated");
            });
            console.log("deactivating dots");
            setActiveDots(null);
        }

        setDefectsButton(false);

        currentDot.classList.remove("activated");
        const newActiveDots = dots.filter((currentDot: any) => currentDot.classList.contains("activated"));

        if (newActiveDots.length === 0)
            document.body.style.overflow = 'scroll';

    };

    
    let lastX = useMemo(() => -1, []);
    let lastY = useMemo(() => -1, []);
    let inHere = useMemo(() => false, []);

    const screwDot = (dotNumber : number, dotElement : any) => {
        const element = schemeStructure.find((element: { dotNumber: number, screw: number }) => element.dotNumber === Number(dotNumber));
        console.log(element, dotNumber, dotElement);
        if (!element) return;
        if (element?.screw === 1) return;
        changeScrewSchemeStructure(dotNumber, element.screw + .25);

        const screwLine = dotElement.querySelector(".screw-driver-container .screw-line");
        const newScrew = element.screw + .25;
        screwLine.style.transform = `rotateZ(${newScrew === .25 ? 90 : newScrew === .5 ? 180 : newScrew === .75 ? 270 : 360}deg) translateY(-50%)`;

    };


    let notFinished = useMemo(() => false, []);

    useEffect(() => {
        if (category.toString() !== "2") return;
        const timer = setTimeout(() => {
            let dotPoint : number = 1;
            console.log(process.env.REACT_APP_THIRD_SCHEME_PROTECTION_POINT);
            document.querySelectorAll(".dot").forEach((dot : any, idx: number) => {
                const currDotNumber = Number(dot.querySelector("p")?.innerText);
                if (currDotNumber !== 500) {
                    const paragraph = dot.querySelector("p");
                    if ((Number(schemeNumber) === 2 && idx === Number(process.env.REACT_APP_SECOND_SCHEME_PROTECTION_POINT)) || (Number(schemeNumber) === 3 && idx === Number(process.env.REACT_APP_THIRD_SCHEME_PROTECTION_POINT))) paragraph.innerText = '500';
                    else {
                        paragraph.innerText = dotPoint.toString();
                        dotPoint++;
                    }
                 
                }
            });
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const getClosestElement = (xPos: number, yPos: number, elements : any) => {

        // Initialize variables to keep track of the closest element and its distance
        let closestElement = null;
        let closestDistance = Number.MAX_SAFE_INTEGER;
    
        // Loop through the elements and calculate the distance from the click coordinates
        elements.forEach((element : any) => {
          const rect = element.getBoundingClientRect();
          const elementX = rect.left + window.scrollX + rect.width / 2;
          const elementY = rect.top + window.scrollY + rect.height / 2;
    
          const distance = Math.sqrt(
            Math.pow(elementX - xPos, 2) + Math.pow(elementY - yPos, 2)
          );
    
          if (distance < closestDistance) {
            closestDistance = distance;
            closestElement = element;
          }
        });
        
        if (closestDistance > Math.max(window.innerWidth, window.innerHeight) / 10) return null;

        return closestElement;
    };

    const getClosestElementIdx = (xPos: number, yPos: number, elements : any) => {

        // Initialize variables to keep track of the closest element and its distance
        let closestElement = null;
        let closestDistance = Number.MAX_SAFE_INTEGER;
    
        // Loop through the elements and calculate the distance from the click coordinates
        elements.forEach((element : any, idx: number) => {
          const rect = element.getBoundingClientRect();
          const elementX = rect.left + window.scrollX + rect.width / 2;
          const elementY = rect.top + window.scrollY + rect.height / 2;
    
          const distance = Math.sqrt(
            Math.pow(elementX - xPos, 2) + Math.pow(elementY - yPos, 2)
          );
    
          if (distance < closestDistance) {
            closestDistance = distance;
            closestElement = idx;
          }
        });
        
        return closestElement;
    };


    useEffect(() => {
        const clickEvent = (e : any) => {
            let currentTarget = e.target;
            while (!currentTarget?.classList?.contains("page-container")) {
                if (currentTarget?.classList?.contains("screw-driver-container")) return;
                currentTarget = currentTarget.parentElement;
            }

            if (!e.target.classList.contains("dot") && !e?.target?.src?.includes("actionare_prin_apasare") && !e?.target?.src?.includes("contact_normal") && !e.target?.src?.includes("siguranta")) {
                const target : any = getClosestElement(e.clientX, e.clientY, document.querySelectorAll(".dot"));
                if (!target) return;
                if (!target.classList.contains("activated")) setDefectsButton(true);
                else setDefectsButton(false);

                if (isTeacher) {
                    document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                    setActiveDot(null);
                }
                
                if (isTeacher) setActiveDot(target);
                if (!isTeacher) {
                    if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                    else handleDotTouchEnd(e, target);
                }

                if (isTeacher) target.classList.toggle("activated");
            }
            console.log(getClosestElement(e.clientX, e.clientY, document.querySelectorAll(".dot")));
        };

        const dotImgs : any = document.querySelectorAll(".cell");
        dotImgs.forEach((dot : any) => {
            dot.addEventListener("click", clickEvent);
        });
        
        return () => {
            dotImgs.forEach((dot : any) => {
                dot.removeEventListener("click", clickEvent);
            });
        };
    }, []);



    useEffect(() => {

        if (screw === 0 || !screwSoundRef?.current) return;
        
        const [dotNumber, dotElement] = screw;
        console.log(dotNumber, dotElement);
        if (!dotElement.classList.contains("left") && !dotElement.classList.contains("right")) return;
        // if (dotNumber % 4 !== 2 && dotNumber % 4 !== 3) return;
        const element = schemeStructure.find((element: { dotNumber: number, screw: number }) => element.dotNumber.toString() === Number(dotNumber).toString());
        console.log(element, dotNumber, dotElement);
        if (!element) return;
        if (element?.screw === 1) return;
        if (isTeacher) {
            if (element.screw === .75) removeDefect(dotElement);
            else {
                removeDefect(dotElement);
                createDefect(dotElement, "Lipsa tensiune", element.screw + .25);   
            } 
        }
        

        if (!isTeacher && problems.some((problem: any) => problem.point === dotNumber && problem.screw != null) && !schemeStructure.find((element : any) => element.dotNumber === dotNumber)?.solved) {
            if (element.screw === .75) solveDefect(dotNumber, "Lipsa tensiune");
        }
        console.log(element.screw, dotNumber);
        changeScrewSchemeStructure(dotNumber, element.screw + .25);


        const screwLine = dotElement.querySelector(".screw-driver-container .screw-line");
        const screwPercentage = dotElement.querySelectorAll("h3")[1];
        const initialScrewPercentage = parseInt(screwPercentage.innerText);
        setTimeout(() => {
            setTimeout(() => notFinished = true, 25);
            for (let i = 0; i <= 25; i++) {
                setTimeout(() => {
                    if (i === 25) notFinished = false;
                    screwPercentage.innerText = `${initialScrewPercentage + i}%`;
                }, i * 10);
            }
        }, notFinished ? 350 : 0);
       
        const unScrewPercentage = dotElement.querySelectorAll("h3")[3];
        const initialUnscrewPercentage = parseInt(unScrewPercentage.innerText);
        setTimeout(() => {
            for (let i = 0; i <= 25; i++) {
                setTimeout(() => {
                    if (i === 25) notFinished = false;
                    unScrewPercentage.innerText = `${initialUnscrewPercentage - i}%`;
                }, i * 10);
            }
    
        }, notFinished ? 350 : 0);
      
        const newScrew = element.screw + .25;
        screwLine.style.transform = `rotateZ(${newScrew === .25 ? 90 : newScrew === .5 ? 180 : newScrew === .75 ? 270 : 360}deg) translateY(-50%)`;

        screwSoundRef.current.currentTime = 0;
        screwSoundRef.current.volume = 0.5;
        screwSoundRef.current.play();

        const S1 = findSignObjectByName("F1");
        const S5 = findSignObjectByName("F5");
        const S3 = findSignObjectByName("F3");
        const contact = findSignObjectByName("I");

        if (Number(schemeNumber) === 1) {
            const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 18 && element.type === "Scurt circuit")?.point || -1;
            
            if (schemeStructure.some((element : { dotNumber: number, screw: number }) => {
                // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                return element.dotNumber < firstShortcircuitDefect && element.screw <= 0;
            })) return;
    
            console.log(problems, S1, contact);
            if (problems.some((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 10 && element.type === "Scurt circuit")) {
                actuationAlgorithm(S1.x, S1.y, false);
            } else if (problems.some((element: { point: number, type: string | null }) => element.point >= 11 && element.point <= 18 && element.type === "Scurt circuit")) {
                if (contact.name.includes("inchis")) {
                    actuationAlgorithm(S1.x, S1.y, false);
                }
            }
        } else if (Number(schemeNumber) === 2) {
            const contactS1 = findSignObjectByName("Q1");
            const contactS3 = findSignObjectByName("Q1S3");
            const contactS5 = findSignObjectByName("C.a. Q1");
            const firstTotalLinePoints = [8, 9, 10, 26, 27, 28, 29, 52, 53, 54, 55, 70, 71];
            const firstRestLinePoints = [28, 29, 52, 53, 54, 55, 70, 71];
            const secondTotalLinePoints = [13, 14, 31, 32, 33, 34, 57, 58, 59, 60, 69, 70];
            const secondRestLinePoints = [33, 34, 57, 58, 59, 60, 69, 70];
            const thirdTotalLinePoints = [16, 17, 34, 35, 36, 37, 60, 61, 62, 63, 72, 73];
            const thirdRestLinePoints = [36, 37, 60, 61, 62, 63, 72, 73];
            const fifthTotalLinePoints = [24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75];
            const fifthRestLinePoints = [44, 45,  47, 49, 51, 64, 65, 66, 67, 74, 75];

            let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
            const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                    firstShortcircuitDefectIdx = idx;
                    return true;
                }

                return false;
            });

            const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                    thirdShortcircuitDefectIdx = idx;
                    return true;
                }

                return false;
            });

            const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                    fifthShortcircuitDefectIdx = idx;
                    return true;
                }
                return false;
            });


            // For S1

            if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
            })) {
                if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {

                    actuationAlgorithm(S1.x, S1.y, false);
                } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                    if (contactS1.name.includes("inchis")) {
                        actuationAlgorithm(S1.x, S1.y, false);
                    }
                }
            }


            // For S3
            if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
            })) {
                if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                    
                    actuationAlgorithm(S3.x, S3.y, false);
                } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                    if (contactS3.name.includes("inchis")) {
                        actuationAlgorithm(S3.x, S3.y, false);
                    }
                }
            }


            // For S5
            if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
            })) {
                if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                    
                    actuationAlgorithm(S5.x, S5.y, false);
                } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                    if (contactS5.name.includes("inchis")) {
                        actuationAlgorithm(S5.x, S5.y, false);
                    }
                }
            }
        } else if (Number(schemeNumber) === 3) {
             
            const secondContactS1 = findSignObjectByName("Q11.2");
            const secondContactS3 = findSignObjectByName("Q11.2S3");
            const selfMaintananceQ1 = findSignObjectByName("C.i. Q11.1");
            const selfMaintananceQ2 = findSignObjectByName("C.i. Q11.2");

            let currentSecondContact : any, currentSecondContactS3;
            document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === secondContactS1?.y && Number(cell.getAttribute("row")) === secondContactS1?.x ? currentSecondContact = cell?.querySelector("img")?.src?.includes("deschis") : null);
            // document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === secondContactS3?.y && Number(cell.getAttribute("row")) === secondContactS3?.x ? currentSecondContactS3 = cell?.querySelector("img")?.src?.includes("deschis") : null);

            const contactS1 = currentSecondContact ? findSignObjectByName("Q11.1") : secondContactS1;
            const contactS3 = currentSecondContact ? findSignObjectByName("Q11.1S3") : secondContactS3;

            const contactS5 = findSignObjectByName("C.a. Q1");

            const firstTotalLinePoints = currentSecondContact ? [6, 7, 8, 9, 50, 51, 30, 31, 32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [6, 7, 8, 9, 30, 31, 50, 51, 52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
            const firstRestLinePoints = currentSecondContact ?  [32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
            const secondTotalLinePoints = [10, 11, 12, 13, 46, 47, 34, 35, 36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
            const secondRestLinePoints = [36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
            const thirdTotalLinePoints = currentSecondContact ? [14, 15, 16, 17, 38, 39, 42, 43, 40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [14, 15, 16, 17, 38, 39, 42, 43, 44, 45, 32, 33, 82, 83, 84, 85, 104, 105];
            const thirdRestLinePoints = currentSecondContact ? [40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [44, 45, 32, 33, 82, 83, 84, 85, 104, 105];
            
            // const fifthTotalLinePoints = [24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75];
            // const fifthRestLinePoints = [44, 45,  47, 49, 51, 64, 65, 66, 67, 74, 75];

            let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
            const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                    firstShortcircuitDefectIdx = idx;
                    return true;
                }

                return false;
            });

            const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                    thirdShortcircuitDefectIdx = idx;
                    return true;
                }

                return false;
            });

            // const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
            //     if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
            //         fifthShortcircuitDefectIdx = idx;
            //         return true;
            //     }
            //     return false;
            // });


            // For S1

            if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
            })) {
                if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {

                    actuationAlgorithm(S1.x, S1.y, false);
                } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                        
                        setTimeout(() => {
                            // alert("PASS 2");
                            let currentContact;
                            document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS1?.y && Number(cell.getAttribute("row")) === contactS1?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                            console.log(currentContact);

                            if (currentContact) {
                                actuationAlgorithm(S1.x, S1.y, false);
                                
                            } 
                        }, 1000);
                    
                }
            }


            // For S3
            if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
            })) {
                if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                    actuationAlgorithm(S3.x, S3.y, false);
                } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                    console.log(schemeStructure);
                   
                    setTimeout(() => {
                    // alert("PASS 2");

                        let currentContact;
                        document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                        if (currentContact) {
                            actuationAlgorithm(S3.x, S3.y, false);
                            
                        }
                    }, 1000);
                        
                
                }
            }


            // For S5
            // if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
            //     return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
            // })) {
            //     if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                    
            //         actuationAlgorithm(S5.x, S5.y, false);
            //     } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
            //         if (foundSignObject?.name?.includes("actionare_prin_apasare_automentinere")) {
            //             setTimeout(() => {
            //             // alert("PASS 2");

            //                 let currentContact;
            //                 document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS5?.y && Number(cell.getAttribute("row")) === contactS5?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
            //                 if (currentContact) {
            //                     actuationAlgorithm(S5.x, S5.y, false);
            //                     setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
            //                     setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
            //                 }
            //             }, 1000);
                        
            //         }
                        
                    
            //     }
            // }
                                
    
        }

     
    }, [screw]);

    useEffect(() => {
        if (schemeStructure.some((element : any) => element.screw < 1)) console.log(schemeStructure);
    }, [schemeStructure]);


    useEffect(() => {
        console.log(unscrew)
        if (unscrew === 0 || !screwSoundRef?.current) return;
        const [dotNumber, dotElement] = unscrew;
        if (!dotElement.classList.contains("left") && !dotElement.classList.contains("right")) return;

        const element = schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === dotNumber);
        console.log(element, schemeStructure, dotNumber, dotElement);
        if (!element) return;
        console.log(element.screw, dotNumber, dotElement);
        if (element.screw === 0) return;

        if (isTeacher) {
            removeDefect(dotElement);
            createDefect(dotElement, "Lipsa tensiune", element.screw - .25);
        }

        changeScrewSchemeStructure(dotNumber, element.screw - .25);
        const screwPercentage = dotElement.querySelectorAll("h3")[1];
        const initialScrewPercentage = parseInt(screwPercentage.innerText);
        setTimeout(() => {
            setTimeout(() => notFinished = true, 25);
            for (let i = 0; i <= 25; i++) {
                setTimeout(() => {
                    if (i === 25) notFinished = false;
                    screwPercentage.innerText = `${initialScrewPercentage - i}%`;
                }, i * 10);
            }
        }, notFinished ? 350 : 0);
       
        const unScrewPercentage = dotElement.querySelectorAll("h3")[3];
        const initialUnscrewPercentage = parseInt(unScrewPercentage.innerText);
        setTimeout(() => {
            for (let i = 0; i <= 25; i++) {
                setTimeout(() => {
                    if (i === 25) notFinished = false;
                    unScrewPercentage.innerText = `${initialUnscrewPercentage + i}%`;
                }, i * 10);
            }
    
        }, notFinished ? 350 : 0);
        
        const screwLine = dotElement.querySelector(".screw-driver-container .screw-line");
        const newScrew = element.screw - .25;
        screwLine.style.transform = `rotateZ(${newScrew === 0 ? 0 : newScrew === .25 ? 90 : newScrew === .5 ? 180 : 270}deg) translateY(-50%)`;

        screwSoundRef.current.currentTime = 0;
        screwSoundRef.current.volume = 0.5;
        screwSoundRef.current.play();
    }, [unscrew]);

    useEffect(() => {
        const dots : any = document.querySelectorAll(".dot");

        const event =  (e : any) => {
            e.preventDefault();
            let target = e.target;
            if (target.tagName.toLowerCase() === "p") target = target.parentElement;
            
            if (!target.classList.contains("activated")) setDefectsButton(true);
            else setDefectsButton(false);

            if (isTeacher) {
                document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                setActiveDot(null);
            }
            
            if (isTeacher) setActiveDot(target);
          
            // else handleDotTouchEnd(null, target);

            document.querySelectorAll(".dot").forEach((dot : any) => {
                if (dot !== target && dot.classList.contains("activated")) {
                    console.log(dot);
                    handleDotTouchEnd(null, dot);
                }
            });
            
            console.log(target);
            handleDotTouchStart(e, target);
            

            target.classList.add("activated");
        };

        dots.forEach((dot : any) => {
            dot.addEventListener("contextmenu", event);
        });

        return () => {
            dots.forEach((dot: any) => {
                dot.removeEventListener("contextmenu", event);
            });
        }

    }, [document.querySelectorAll(".dot")]);

    // useEffect(() => console.log(schemeStructure), [schemeStructure]);

    const [startDragText, setStartDragText] = useState<boolean>(false);
    const findSign = (x: number, y: number, idx: number = 0) => {
        // if (dragElement) return;
        const currSign: { x: number, y: number, name: string, page: number, flip: number, texts: any, signColor: string | null, signPosition: string } = signs.find((sign: { page: number, x: number, y: number, name: string, flip: number }) => sign.x === x && sign.y === y && sign.page === page);

        if (!currSign) return null;

        const leftMaxPoint = currPoint, leftPoint = currPoint + 1, rightPoint = currPoint + 2, rightMaxPoint = currPoint + 3, topPoint = currPoint, fifthPoint = currPoint + 4, sixthPoint = currPoint + 5;
        
        const dotLeftMaxProp = {
            point: leftMaxPoint
        };

        const dotLeftProp = {
            point: leftPoint
        };

        const dotRightProp = {
            point: rightPoint
        };

        const dotRightMaxProp = {
            point: rightMaxPoint
        };

        const dotTopProp = {
            point: Number(schemeNumber) === 1 ? 500 : Number(schemeNumber) === 2 && topPoint === Number(process.env.REACT_APP_SECOND_SCHEME_PROTECTION_POINT) ? 500 : topPoint
        };

        const dotFifthProp = {
            point: fifthPoint
        };

        const dotSixthProp = {
            point: sixthPoint
        };


        // if (currSign.name.includes("derivatie_din_conductor") && Number(schemeNumber) === 1) {
        //     currPoint += 0.5;
        // }
        
        if (!currSign.name.includes("conductor") && !currSign.name.includes("colt") && !currSign.name.includes("contur") && !currSign.name.includes("cruce") && !currSign.name.includes("priza") && category === "2") {
            // console.log(currSign);
            // currPoint = currPoint + (inHere ? 2 : 4);
            if (!inHere) inHere = true;
        }

        currPoint++;
        // console.log(currSign?.signPosition);

        // if (currSign?.signPosition && currSign.signPosition.includes("engine")) currPoint += 2;



        return (
            <>
                <img 
                    draggable={false} 
                    src={currSign.name} 
                    onLoad={() => {
                        console.log("load");
                        
                    }} 
                    style={{ transform: `rotateZ(${currSign.flip || 0}deg) ${currSign.name.includes("conductor_lung") || currSign.name.includes("derivatie_din_conductor") || currSign.name.includes("cruce") || currSign.name.includes("conductor_diagonal") ? "scaleX(1.1)" : currSign.name.includes("motor") ? "scale(1.5)" : ""}`, filter: currSign?.signColor || "initial", zIndex: currSign.name.includes("motor") ? 1000 : 1 }} 
                />
                {currSign.texts.length ? currSign.texts.map((text: any, idx: number) => {
                    const textElementAttr = {
                        fontSize: text.fontSize || 14,
                        positionx: text.positionX || 0,
                        positiony: text.positionY || 0,
                    };
                    return <h2 
                        key={idx}
                        className={text.textAlignment} 
                        style={{ transform: `rotateZ(${text?.flip || 0}deg)`, fontWeight: text.fontWeight || 600, fontSize: `${Number(text?.fontSize) > 14 ? text?.fontSize : 14}px`, left: 0, top: 0}} 
                        {...textElementAttr}
                        onMouseDown={(e : any) => {
                            // if (!e.target.parentElement.classList.contains("selected")) return;
                            // setStartDragText(true);
                            
                        }}
                        onMouseMove={(e : any) => {
                            // if (!startDragText) return;
                            // const currZoom = Number(document.body.getAttribute("zoom")) || 1;
                            // const newXValue = (e.clientX - (e.target.parentElement.getBoundingClientRect()?.x + window.scrollX)) / 3.779527559055;
                            // const newYValue = (e.clientY - (e.target.parentElement.getBoundingClientRect()?.y + window.scrollY)) / 3.779527559055;
                            // console.log(newXValue, newYValue, e.clientX, e.clientY);
                            // // changeSignTextPosition(currSign?.x, currSign?.y, currSign?.page, idx, newXValue, newYValue);
                            // e.target.style.left = `${newXValue}mm`;
                            // e.target.style.top = `${newYValue}mm`;

                        }}
                        onMouseUp={(e : any) => {
                            // alert("AA");
                            // setStartDragText(false);
                        }}
                        >
                            {text.name}
                        </h2>;
                }) : null}

                {!currSign.name.includes("conductor") && !currSign.name.includes("colt") && !currSign.name.includes("contur") && !currSign.name.includes("priza") && !currSign.name.includes("cruce") && category == "2" ? (
                    <>
                        {!currSign.signPosition.includes("right-automention") ? <div
                            className="dot max-left"
                            onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                            onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                            onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                            onClick={(e: any) => {
                                let target = e.target;
                                if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                if (!target.classList.contains("activated")) setDefectsButton(true);
                                else setDefectsButton(false);

                                if (isTeacher) {
                                    document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                    setActiveDot(null);
                                }
                                
                                if (isTeacher) setActiveDot(target);
                                if (!isTeacher) {
                                    if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                    else handleDotTouchEnd(null, target);
                                }

                                if (isTeacher) target.classList.toggle("activated");
                            }}
                            
                            
                            {...dotLeftMaxProp}
                        >
                            <p>{dotLeftMaxProp.point - 4}</p>
                        </div> : null}

                        <div
                            className={`dot left`}
                            onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                            onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                            onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                            onClick={(e: any) => {
                                let target = e.target;
                                if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                if (target.classList.contains("screw-driver") || target.classList.contains("screw-driver-container") || target.tagName.toLowerCase() === "h3") return;
                                if (!target.classList.contains("activated")) setDefectsButton(true);
                                else setDefectsButton(false);

                                if (isTeacher) {
                                    document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                    setActiveDot(null);
                                }
                                
                                if (isTeacher) setActiveDot(target);
                                if (!isTeacher) {
                                    if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                    else handleDotTouchEnd(e, target);
                                }

                                if (isTeacher) target.classList.toggle("activated");
                            }}
                            {...dotLeftProp}
                        >
                            <p>{dotLeftProp.point}</p>
                           
                            <div className="screw-driver-container">
                                <img className="screw-driver" width={150} src={screwDriver} onClick={(e : any) => {
                                    e.target.nextElementSibling.classList.toggle("show");
                                }}/>

                                <div className="screw-driver-option">
                                    <div className="option" onClick={(e : any) => {
                                        // const dot = Array.from(document.querySelectorAll(".dot")).find((dot : any) => dot.querySelector("p").innerText === (dotLeftProp.point - 4).toString());
                                        let dot = e.target;
                                        while (!dot.classList.contains("dot")) dot = dot.parentElement;
                                        if (dot?.querySelector(".screw-driver-option:not(.show)")) return;

                                        setScrew([Number(dot?.querySelector("p")?.innerText || ""), dot]);
                                    }}>
                                        <h3>{cookies?.language === "en" ? "Screw" : "Înșurubează"}</h3>
                                        <FontAwesomeIcon className="screw-driver-option-icon" icon={faRotateRight} />
                                        <h3>{(getDotDataAlteredPoints(dotLeftProp.point)?.screw == null ? 1 : getDotDataAlteredPoints(dotLeftProp.point)?.screw) * 100}%</h3>
                                    </div>

                                    <div className="option" onClick={(e : any) => {
                                        let dot = e.target;
                                        while (!dot.classList.contains("dot")) dot = dot.parentElement;
                                        if (dot?.querySelector(".screw-driver-option:not(.show)")) return;
                                        // console.log(schemeStructure.find((element : any) => element.dotNumber === dotLeftProp.point - 4)?.screw);
                                        setUnscrew([Number(dot?.querySelector("p")?.innerText || ""), dot]);
                                    }}>
                                        <h3>{cookies?.language === "en" ? "Unscrew" : "Deșurubează"}</h3>
                                        <FontAwesomeIcon className="screw-driver-option-icon" icon={faRotateLeft} />
                                        <h3>{(1 - (getDotDataAlteredPoints(dotLeftProp.point)?.screw == null ? 1 : getDotDataAlteredPoints(dotLeftProp.point)?.screw)) * 100}%</h3>

                                    </div>
                                </div>

                                <div className="screw-line" />
                            </div>
                       
                        </div>

                        <div
                            className={`dot ${currSign?.signPosition && currSign.signPosition.includes("engine") ? "branch-left" : "right"}`}
                            onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                            onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                            onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                            onClick={(e: any) => {
                                let target = e.target;
                                if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                if (target.classList.contains("screw-driver") || target.classList.contains("screw-driver-container") || target.tagName.toLowerCase() === "h3") return;
                                if (!target.classList.contains("activated")) setDefectsButton(true);
                                else setDefectsButton(false);

                                if (isTeacher) {
                                    document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                    setActiveDot(null);
                                }
                                
                                if (isTeacher) setActiveDot(target);
                                if (!isTeacher) {
                                    if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                    else handleDotTouchEnd(e, target);
                                }

                                if (isTeacher) target.classList.toggle("activated");
                            }}
                           
                            {...dotRightProp}
                        >
                            <p>{dotRightProp.point}</p>
                            
                            <div className="screw-driver-container">
                                <img className="screw-driver" width={150} src={screwDriver} onClick={(e : any) => {
                                    e.target.nextElementSibling.classList.toggle("show");
                                }}/>

                                <div className="screw-driver-option">
                                    <div className="option" onClick={(e : any) => {
                                        let dot = e.target;
                                        while (!dot.classList.contains("dot")) dot = dot.parentElement;
                                        if (dot?.querySelector(".screw-driver-option:not(.show)")) return;

                                        setScrew([Number(dot?.querySelector("p")?.innerText || ""), dot]);
                                    }}>
                                        <h3>{cookies?.language === "en" ? "Screw" : "Înșurubează"}</h3>
                                        <FontAwesomeIcon className="screw-driver-option-icon" icon={faRotateRight} />
                                        <h3>{(getDotDataAlteredPoints(dotRightProp.point)?.screw == null ? 1 : getDotDataAlteredPoints(dotRightProp.point)?.screw) * 100}%</h3>
                                    </div>

                                    <div className="option" onClick={(e : any) => {
                                        let dot = e.target;
                                        while (!dot.classList.contains("dot")) dot = dot.parentElement;
                                        if (dot?.querySelector(".screw-driver-option:not(.show)")) return;
                                        console.log(Number(dot?.querySelector("p")?.innerText || ""));
                                        setUnscrew([Number(dot?.querySelector("p")?.innerText || ""), dot]);
                                    }}>
                                        <h3>{cookies?.language === "en" ? "Unscrew" : "Deșurubează"}</h3>
                                        <FontAwesomeIcon className="screw-driver-option-icon" icon={faRotateLeft} />
                                        <h3>{(1 - (getDotDataAlteredPoints(dotRightProp.point)?.screw == null ? 1 : getDotDataAlteredPoints(dotRightProp.point)?.screw)) * 100}%</h3>

                                    </div>
                                </div>

                                <div className="screw-line" />
                            </div>
                           
                        </div>

                        {!currSign.signPosition.includes("right-automention") ? <div
                            className={`dot ${currSign?.signPosition && currSign.signPosition.includes("engine") ? "branch-left-max" : "max-right"}`}
                            onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                            onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                            onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                            onClick={(e: any) => {
                                let target = e.target;
                                if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                if (!target.classList.contains("activated")) setDefectsButton(true);
                                else setDefectsButton(false);

                                if (isTeacher) {
                                    document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                    setActiveDot(null);
                                }
                                
                                if (isTeacher) setActiveDot(target);
                                if (!isTeacher) {
                                    if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                    else handleDotTouchEnd(e, target);
                                }

                                if (isTeacher) target.classList.toggle("activated");
                            }}
                            {...dotRightMaxProp}
                        >
                            <p>{dotRightMaxProp.point - 4}</p>
                        </div> : null}

                        {currSign?.signPosition && currSign.signPosition.includes("-automention") ? (
                            <>
                                <div
                                className={`dot max-left-automention`}
                                onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                                onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                                onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                                onClick={(e: any) => {
                                    let target = e.target;
                                    if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                    if (!target.classList.contains("activated")) setDefectsButton(true);
                                    else setDefectsButton(false);

                                    if (isTeacher) {
                                        document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                        setActiveDot(null);
                                    }
                                    
                                    if (isTeacher) setActiveDot(target);
                                    if (!isTeacher) {
                                        if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                        else handleDotTouchEnd(e, target);
                                    }

                                    if (isTeacher) target.classList.toggle("activated");
                                }}
                                style={currSign.name.includes("indicator_luminos") ? { left: "8.5px" } : {}}
                                {...dotFifthProp}
                                >
                                  <p>{dotFifthProp.point - 4}</p>
                                </div>

                                <div
                                    className={`dot max-right-automention`}
                                    onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                                    onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                                    onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                                    onClick={(e: any) => {
                                        let target = e.target;
                                        if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                        if (!target.classList.contains("activated")) setDefectsButton(true);
                                        else setDefectsButton(false);

                                        if (isTeacher) {
                                            document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                            setActiveDot(null);
                                        }
                                        
                                        if (isTeacher) setActiveDot(target);
                                        if (!isTeacher) {
                                            if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                            else handleDotTouchEnd(e, target);
                                        }

                                        if (isTeacher) target.classList.toggle("activated");
                                    }}
                                    style={currSign.name.includes("indicator_luminos") ? { right: "5px", left: "initial", transform: "initial" } : {}}
                                    {...dotSixthProp}
                                >
                                    <p>{dotSixthProp.point - 4}</p>
                                </div>
                            </>
                        ) : null}

                        {currSign?.signPosition && currSign.signPosition.includes("engine") ? (
                            <>
                              <div
                                className={`dot branch-right-max`}
                                onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                                onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                                onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                                onClick={(e: any) => {
                                    let target = e.target;
                                    if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                    if (!target.classList.contains("activated")) setDefectsButton(true);
                                    else setDefectsButton(false);
    
                                    if (activeDot && isTeacher) {
                                        document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                        setActiveDot(null);
                                    }
                                    
                                    if (isTeacher) setActiveDot(target);
                                    if (!isTeacher) {
                                        if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                        else handleDotTouchEnd(e, target);
                                    }
    
                                    if (isTeacher) target.classList.toggle("activated");
                                }}
                                style={currSign.name.includes("indicator_luminos") ? { left: "8.5px" } : {}}
                                {...dotFifthProp}
                                >
                                    <p>{dotFifthProp.point - 4}</p>
                                </div>

                                <div
                                    className={`dot branch-right`}
                                    onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                                    onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                                    onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                                    onClick={(e: any) => {
                                        let target = e.target;
                                        if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                                        if (!target.classList.contains("activated")) setDefectsButton(true);
                                        else setDefectsButton(false);
        
                                        if (activeDot && isTeacher) {
                                            document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                                            setActiveDot(null);
                                        }
                                        
                                        if (isTeacher) setActiveDot(target);
                                        if (!isTeacher) {
                                            if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                                            else handleDotTouchEnd(e, target);
                                        }
        
                                        if (isTeacher) target.classList.toggle("activated");
                                    }}
                                    style={currSign.name.includes("indicator_luminos") ? { right: "5px", left: "initial", transform: "initial" } : {}}
                                    {...dotSixthProp}
                                >
                                    <p>{dotSixthProp.point - 4}</p>
                                </div>
                            </>
                        ) : null}


                    </>
                ) : (currSign.name.includes("derivatie_din_conductor")) && category == "2" ? (
                    <div
                    className="dot top"
                    onTouchStart={(e: any) => handleDotTouchStart(e, e.target)}
                    onTouchEnd={(e: any) => handleDotTouchEnd(e, e.target)}
                    onTouchCancel={(e: any) => handleDotTouchEnd(e, e.target)}
                    onClick={(e: any) => {
                        let target = e.target;
                        if (target.tagName.toLowerCase() === "p") target = target.parentElement;
                        console.log(target);
                        if (!target.classList.contains("activated")) setDefectsButton(true);
                        else setDefectsButton(false);

                        if (activeDot && isTeacher) {
                            document.querySelectorAll(".dot").forEach(dot => dot !== target ? dot.classList.remove("activated") : null);
                            setActiveDot(null);
                        }
                        
                        if (!target.classList.contains("activated") && isTeacher) setActiveDot(target);
                        if (!isTeacher) {
                            if (!target.classList.contains("activated")) handleDotTouchStart(e, target);
                            else handleDotTouchEnd(e, target);
                        }

                        if (isTeacher) target.classList.toggle("activated");
                    }}
                    {...dotTopProp}
                >
                    <p>{dotTopProp.point - (Number(schemeNumber) === 1 || dotTopProp.point === 500 ? 0 : 0.5)}</p>
                </div> 
                ) : null}

            </> 
        );
    };

    const findSignObject = (x: number, y: number, idx: number = 0) => {
        const currSign: { x: number, y: number, name: string, flip: number, texts: any } = signs.find((sign: { x: number, y: number, name: string, flip: number }) => sign.x === x && sign.y === y);
        return currSign;
    };

    const findSignObjectByName = (name : string) => {
        return signs.find((sign : any) => sign.texts.some((text: { name: string }) => text.name === name));
    };

    const selectedCell = useStore((state: any) => state.selectedCell);

    const isSelectedCell = (x: number, y: number) => {
        const currSign: { x: number, y: number, name: string, page: number, flip: number, isSelectedSign: boolean } = signs.find((sign: { x: number, y: number, name: string, flip: number, isSelected: boolean, page: number }) => sign.x === x && sign.y === y && sign.page === page);

        return currSign?.isSelectedSign || (selectedCell?.y === y && selectedCell?.x === x && selectedCell?.page === page);
    };

    const getSelectedCell = useCallback(() => {
        const element = Array.from(document.querySelectorAll(".cell")).find((cell: any) => cell.classList.contains("selected"));
        if (!element) return null;
        return { x: Number(element.getAttribute("row")), y: Number(element.getAttribute("column")), element };
    }, [changeSelectedCell]);

    const changeName = () => {
        console.log(isSelected, texts);
        const selectedCell : any = getSelectedCell();
        if (selectedCell?.element?.firstChild) changeCellName(selectedCell.x, selectedCell.y, page, texts);
        else if (selectedCell) addSign(selectedCell?.x, selectedCell?.y, page, SCHEME_ELEMENTS["contur"].imageSource, 0, texts);
        
        // setSignName("");
        // setSignTextAlignment("");
        setNameSignMenu(false);
    };

    const [lastTime, setLastTime] = useState<number | null>(null);

    // useEffect(() => {
    //     if (!document.querySelectorAll(".dot").length) return;
    //     if (isTeacher) return;
    //     Array.from(document.querySelectorAll(".dot")).forEach((dot : any) => {
    //         const dotNumber = Number(dot.querySelector("p")?.innerText || 1);
    //         if ((dotNumber % 4 === 2 || dotNumber % 4 === 3) && !dot.querySelector("img")) {
    //             const newImage = document.createElement("img");
    //             newImage.src = screwDriver;
    //             newImage.classList.add("screw-driver");
    //             // newImage.style.width = '150px';

    //             const screwDriverOptionI = document.createElement("div");
    //             screwDriverOptionI.classList.add("screw-driver-option");

    //             // const screwDriverIconOptionI = document.createElement("");

    //             newImage.addEventListener("click", (e : any) => {
    //                 if (e.target !== newImage) return;
    //                 newImage.classList.add("show-options");
    //             });
                
    //             dot.appendChild(newImage);

    //             const screwLine = document.createElement("div");
    //             screwLine.classList.add("screw-line");
    //             // newImage.style.width = '150px';
                
    //             dot.appendChild(screwLine);
    //         }
    //     });
    // }, [signs]);

    // useEffect(() => {
    //     if (activeDots?.length !== 1) return;
    //     const dot = activeDots[0];
    //     const image = dot.querySelector("img");

    //     Array.from(document.querySelectorAll(".dot img")).forEach((img : any) => img === image ? img.classList.toggle("show") : img.classList.remove("show"));
    // }, [activeDots]);

    // useEffect(() => {
    //     console.log(signs);
    //     if (!lastTime) return;
    //     if (!copiedElement) return setLastTime(null);
    //     const currTime = new Date().getTime();
    //     if (lastTime && currTime - lastTime >= 1000) {
    //         setCopiedElement(null);
    //         // cellRef.current.forEach(cell => {
    //         //     cell?.classList.remove("selected");
    //         // });
    //         removeSelectedSign();
    //     }
    //     setLastTime(currTime);
    // }, [signs]);

    // Update element's columns
    useEffect(() => {
        if (!cellRef?.current) return;
        setColumns(currColumns => {
            let currNumber = 0;
            return currColumns.map((column, columnIdx) => {
                let elementOnThisColumn = false;
                for (let row = 0; row < ROWS; row++) {
                    if (cellRef.current[row * COLUMNS + columnIdx]?.firstElementChild) elementOnThisColumn = true;

                }

                if (elementOnThisColumn) return ++currNumber;
                return 0;
            });

        });

    }, [signs, cellRef]);



    useEffect(() => {
        if (!pageRef || !pageRef.current) return;
        pageRef.current.setAttribute("format", format);
    }, [pageRef]);

    // const [currentKeys, setCurrentKeys] = useState<Array<string>>([]);

    useEffect(() => {
        console.log(signs);
    }, [signs]);

    useEffect(() => {
        const keyDownEvent = (e : any) => {
            const selectedCell = getSelectedCell();

            if (e.ctrlKey || e.metaKey) {
                if (e.key.toLowerCase() === "c") {
                  // Ctrl+C was pressed
                    if (selectedCell) {
                        const currentSign : { x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page:number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                        setCopiedElement(currentSign);
                        removeSelectedSign();
                        removeSelectedCell();
                    }
                } else if (e.key.toLowerCase() === "v") {
                    // Ctrl+V was pressed
                    const selectedCells = cellRef.current.filter(cell => cell?.classList.contains("selected"));
                    if (cuttedElement) {
                        selectedCells.forEach(cell => {
                            // const currSign: { x: number, y: number, name: string, flip: number, textName: string, textAlignment: string, fontSize: number, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number }) => sign.x === Number(cell?.getAttribute("column")) && sign.y === Number(cell?.getAttribute("row")));
                            addSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page, cuttedElement.name, cuttedElement.flip, cuttedElement.texts, cuttedElement.signColor);
                        });
                    } else if (copiedElement) {
                        selectedCells.forEach(cell => {
                            // const currSign: { x: number, y: number, name: string, flip: number, textName: string, textAlignment: string, fontSize: number, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number }) => sign.x === Number(cell?.getAttribute("column")) && sign.y === Number(cell?.getAttribute("row")));
                            addSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page, copiedElement.name, copiedElement.flip, copiedElement.texts, copiedElement.signColor);
                        });
                    }
                  
                    
                    if (copiedElement) setCopiedElement(null);
                    else if (cuttedElement) setCuttedElement(null);
                } else if (e.key.toLowerCase() === "x") {
                    if (selectedCell) {
                        const currentSign : { x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page: number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                        setCuttedElement(currentSign);
                        removeSign(currentSign.x, currentSign?.y, page);
                        removeSelectedSign();
                        removeSelectedCell();
                    }
                }
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                if (selectedCell && !nameSignMenu) {
                    const currentSign : { x: number, y: number, name: string, page: number, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page: number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                    
                    removeSign(currentSign?.x, currentSign?.y, page);
                }
            }
        
        };

    
        window.addEventListener("keydown", keyDownEvent);

        return () => {
            window.removeEventListener("keydown", keyDownEvent);
        };
    }, [copiedElement, cuttedElement, nameSignMenu]);

    useEffect(() => {
        console.log(copiedElement)
    }, [copiedElement]);


    const addSignPressed = () => {
        setFirstEmptyCell(gotoFirstEmptyCell());
        changeEditMenu(false);
        changeOpenMenu(false);
        changeAddSignMenu(true);
    };

    const currentSelectedCell = useStore((state: any) => state.selectedCell);
    const removeSelectedCell = useStore((state: any) => state.removeSelectedCell);

    const [lastElementX, setLastElementPosX] = useState(0);
    const [lastElementY, setLastElementPosY] = useState(0);
    const [lastElementPage, setLastElementPage] = useState(1);


    const addedSign = (element : { realSource: Array<string>, align: string | null }) => {

        const currX = isSelected?.x != null ? isSelected?.x : currentSelectedCell?.x != null ? currentSelectedCell?.x : (lastElementX + 1 >= COLUMNS && lastElementY + 1 >= ROWS ? -1 : lastElementX + 1 >= COLUMNS ? 0 : lastElementX + 1);
        const currY = isSelected?.y != null ? isSelected?.y : currentSelectedCell?.y != null ? currentSelectedCell?.y : (lastElementX + 1 >= COLUMNS && lastElementY + 1 >= ROWS ? -1 : lastElementX + 1 >= COLUMNS ? lastElementY + 1 : lastElementY);
        const currPage = isSelected?.page ? isSelected?.page : currentSelectedCell?.page ? currentSelectedCell?.page : lastElementPage;
        console.log(isSelected, currX, currY, currPage);
        if (currX !== -1 && currY !== -1) {
            removeSign(currX, currY, currPage);

            console.log(element?.align);
                        
            element.realSource.forEach((source : any, idx : number) => {
                if (element?.align === "vertical") addSign(currX, currY + idx, currPage, source, 0, []);
                else addSign(currX - idx, currY, currPage, source, 0, []);
            });

            setLastElementPosX(currX);
            setLastElementPosY(currY);
            setLastElementPage(currPage);
        }

        // if (currentSelectedCell) {
        //     element.realSource.forEach((source : any, idx : number) => {
        //         addSign(currentSelectedCell.x - idx, currentSelectedCell.y, currentSelectedCell.page, source, 0, []);
        //     });
        // } else if (firstEmptyCell) element.realSource.forEach((source : any, idx : number) => {
        //     addSign(firstEmptyCell.x - idx, firstEmptyCell.y, firstEmptyCell.page, source, 0, []);
        // });

        document.body.classList.add("grid-body");
        document.querySelector("html")?.classList.add("grid-body");

        removeSelectedCell();
        removeSelectedSign();
        // changeAddSignMenu();
        const newPageElement : any = document.querySelector(".new-page");
        if (newPageElement) newPageElement.style.display = 'flex';
    };

    const activateEditName = () => {
        if (!texts.length) setTexts((currTexts : any) => [...currTexts, {}]);
        setNameSignMenu(true);
    };

    const removeTextRow = (idx : number) => {
        setTexts((currTexts : any) => currTexts.filter((text : any, index : number) => index !== idx));
    };

    const replaceSignPressed = (x: number, y: number) => {

    };

    const removeDefect = (target: any) => {
        setDefects(currDefects => currDefects.filter(defect => defect.element !== target));
        target.classList.remove("defect");
    };

    const createDefect = (target: any, type: string, screwPerc : number | null = null) => {
        setDefects(currDefects => [...currDefects, { element: target, type, screw: screwPerc }]);
        target.classList.add("defect");
    };

    const screwDefect = (target: any, type: string, screw: number) => {
        if (!defects.find(defect => defect.element === target)) setDefects(currDefects => [...currDefects, { element: target, type, screw }]);
        else setDefects(currDefects => currDefects.map(defect => {
            if (defect.element) return {...defect, screw };
            return defect;
        }));
    }

    const startSessionTeacher = async () => {


        const currentDefects = defects.map((defect) => {
            return {
                type: defect.type,
                point: Number(defect.element.querySelector("p").innerText || '1'),
                problem: true,
                screw: defect?.screw
            }
        });

        try {
            console.log({
                userId: data.userData.userId,
                number: debugSchemeNumber,
                problems: currentDefects
            });

            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: data.userData.userId,
                    number: debugSchemeNumber,
                    problems: currentDefects
                })
            };


            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/create-session`, options);
            const response = await request.json();
            
            if (response.success) window.location.replace(`/session/${response.sessionId}/${debugSchemeNumber}/0`);


        } catch (e) {
            console.log(e);
        }
    };

    const finishSessionStudent = async () => {

        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                sessionId: sessionId,
                teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
                studentId: data.userData.userId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/finish-session-student`, options);
            const response = await request.json();
            if (response.success) window.location.replace(`/session/${sessionId}/leaderboard`);

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isTeacher || !sessionId || category !== "2" || !sessionData) return;
        (async () => {
            if (isTeacher || !sessionId || category !== "2" || !sessionData) return;
            console.log("SECOND!");
            const options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teacherId: data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId,
                    studentId: data.userData.userId,
                    currResults: defectsResponse,
                    currProblemsSolved: problemsSolved,
                    currNotes: notes,
                    currLogs: logs,
                    sessionId
                })
            };
    
            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/edit-session-student`, options);
                const response = await request.json();
    
                if (!response.success) {
                    console.log(response);
                    // Something to happen when update fails
                } else console.log("Updated data!");
            } catch (e) {
                console.log(e);
            }
        })();
    }, [defectsResponse, problemsSolved, notes, logs]);

    useEffect(() => console.log(defects), [defects]);

    useEffect(() => {

        const currSolved = schemeStructure.filter((element : { solved: boolean }) => element?.solved);
        setProblemsSolved(currSolved.map((element: { dotNumber: number, defect: string }) => {
            return {
                name: element.dotNumber,
                type: element.defect
            };
        }));

        const currDefects = schemeStructure.filter((element : { defect: string | null }) => element?.defect);
        setDefectsResponse(currDefects.map((element: { dotNumber: number, defect: string }) => {
            return {
                name: element.dotNumber,
                type: element.defect
            };
        }));

    }, [schemeStructure]);

    const getDotData = (dot : any) => {
        const dotNumber = typeof dot === "number" ? "1" : Number(dot.querySelector("p")?.innerText || "1");
        return schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === dotNumber || element.dotNumber === dot);
    };

    const getDotDataAlteredPoints = (currDot: any) => {
        let dotNumber = -1;
     
        document.querySelectorAll(".dot").forEach((dot : any) => {
            if (Number(dot.getAttribute("point")) === Number(currDot)) dotNumber = Number(dot.querySelector("p").innerText || "-1") 
        });
        console.log(dotNumber);
        return schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === dotNumber);
    };

    useLayoutEffect(() => {
        if (isTeacher) return;
        if (!buzzerSoundRef.current) return;
        if (activeDots?.length !== 2) {
            if (deviceActiveOption !== "off") setDeviceValue("ON");
            else setDeviceValue("");
            buzzerSoundRef.current.pause();
            buzzerSoundRef.current.currentTime = 0;
            return;
        }
        const tension = checkTension();
        const continuity = checkContinuity();
        console.log(continuity);
        if (deviceActiveOption === "acv-200" && tension) {
            // Somehow animation for burning measurement device
            setDeviceValue("ERROR!");
        }

        else if (deviceActiveOption === "acv-750") {
            const activeDotIData = getDotData(activeDots[0]);
            const activeDotIIData = getDotData(activeDots[1]);
            console.log(`${activeDotIData?.potential} ${activeDotIIData?.potential}`);
            if (tension) {
                setDeviceValue(`${Math.abs((activeDotIData?.potential !== null ? activeDotIData?.potential : 230) - (activeDotIIData?.potential !== null ? activeDotIIData?.potential : 0)) < 3 ? Math.max((activeDotIData?.potential !== null ? activeDotIData?.potential : 230), (activeDotIIData?.potential !== null ? activeDotIIData?.potential : 0)) : Math.abs((activeDotIData?.potential !== null ? activeDotIData?.potential : 230) - (activeDotIIData?.potential !== null ? activeDotIIData?.potential : 0))} V`);
            }
            else setDeviceValue("0V");
        }

        else if (deviceActiveOption === "continuity-10k") setDeviceValue(continuity ? 1 : 0);
        else if (deviceActiveOption === "buzzer") {
            setDeviceValue("ON");
            if (continuity) {
                buzzerSoundRef.current.volume = 0.25;
                buzzerSoundRef.current.play();
            } else {
                buzzerSoundRef.current.pause();
                buzzerSoundRef.current.currentTime = 0;
            }
        }
        else setDeviceValue("ON");

    }, [activeDots, deviceActiveOption, schemeStructure]);

    const findNeighbour = (dotNumber: number) : number | null => {
        const dot = Array.from(document.querySelectorAll(".dot")).find((dot : any) => Number(dot.querySelector("p").innerText || "-1") === dotNumber);
        if (!dot) return null;
        if (dot.classList.contains("left-max") || dot.classList.contains("right")) return dotNumber - 1;
        if (dot.classList.contains("left") || dot.classList.contains("right-max")) return dotNumber + 1;
        return -1;
    };

    const solveDefect = (dotNumber: number, defectType: string) : boolean => {
        if (isTeacher) return false;


        const currentDot = schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === dotNumber);
        const problem = problems.some(problem => problem.point === dotNumber && problem.type === defectType);
        console.log(currentDot, defectType, problems);
        if (!currentDot) return false;

     
        if ((defectType === currentDot?.defect) || problem) {
            if (defectType !== "Scurt circuit") changeSolvedSchemeStructure(dotNumber, true);
            if (defectType === "Scurt circuit") removeShortCircuitRoute(true);
            
            return true;
        }
        setLogs(currLogs => [...currLogs, cookies?.language === "en" ? `Mistake: Solving dot's number ${dotNumber} with defect's type ${defectType}.` : `Greseala: Rezolvarea punctului cu numarul ${dotNumber} cu defectul de tip ${defectType}.`]);

        return false;
    };

    const nextElement = (dotNumber: number) => {
        let currIdx : number = -1;
        schemeStructure.forEach((element: any, idx : number) => {
            if (dotNumber === element.dotNumber) currIdx = idx + 1;
        });

        if (schemeStructure?.[currIdx] && schemeStructure?.[currIdx]?.dotNumber !== 500) return schemeStructure?.[currIdx]?.dotNumber;
        return -1;

    };

    const compareDotIdx = (dot1 : number, dot2 : number, type : string) => {
        let dot1Idx = -1, dot2Idx = -1;

        schemeStructure.forEach((element : { dotNumber: number }, idx : number) => {
            if (element.dotNumber === dot1) dot1Idx = idx;
            if (element.dotNumber === dot2) dot2Idx = idx;
        });

        if (type === 'smaller') return dot1Idx < dot2Idx ? dot1 : dot2;
        if (type === 'bigger') return dot1Idx > dot2Idx ? dot1 : dot2;
        return dot1;
    };

    const isBranchLine = (line1 : string, line2 : string) : boolean => {
        if ((line1.includes(`${line2}(`) && line1.at(-1) === ")") || (line2.includes(`${line1}(`) && line2.at(-1) === ")")) return true;
        return false;
    };
    

    const checkTension = () : boolean => {
        if (isTeacher) return false;

        if (!schemeStructure) return false;
        if (activeDots?.length !== 2) return false;
        const firstActiveDot = activeDots[0];
        const secondActiveDot = activeDots[1];
        const firstActiveDotNumber = Number(firstActiveDot?.querySelector("p")?.innerText || 0);
        const secondActiveDotNumber = Number(secondActiveDot?.querySelector("p")?.innerText);
        let firstActiveDotIdx : number = -1, secondActiveDotIdx : number = -1;
        const firstActiveDotScheme = schemeStructure.find((element : { line: string, dotNumber: number }, idx : number) => {
            const value = element.dotNumber === firstActiveDotNumber;
            if (value) firstActiveDotIdx = idx;
            return value;
        });

        const secondActiveDotScheme = schemeStructure.find((element : { line: string, dotNumber: number }, idx : number) => {
            const value = element.dotNumber === secondActiveDotNumber;
            if (value) secondActiveDotIdx = idx;
            return value;
        });

        if (firstActiveDotScheme?.line === secondActiveDotScheme?.line || isBranchLine(firstActiveDotScheme?.line, secondActiveDotScheme?.line)) return false;
        // let schemeStructureReverse = schemeStructure;
        // schemeStructureReverse.reverse();

        
        const elementsInLineFirst = [firstActiveDotScheme];
        const elementsInLineSecond = [secondActiveDotScheme];
        let copyFirstIdx = firstActiveDotIdx, copySecondIdx = secondActiveDotIdx;

        while (copyFirstIdx >= 0 && schemeStructure[copyFirstIdx - 1]?.dotNumber !== 500 && (schemeStructure[copyFirstIdx - 1]?.line === firstActiveDotScheme.line || (schemeStructure[copyFirstIdx - 1]?.line ? isBranchLine(schemeStructure[copyFirstIdx - 1]?.line, firstActiveDotScheme.line) : false))) {
            elementsInLineFirst.unshift(schemeStructure[copyFirstIdx - 1]);
            copyFirstIdx--;
        }

        copyFirstIdx = firstActiveDotIdx;

   
        while (copySecondIdx >= 0 && schemeStructure[copySecondIdx - 1]?.dotNumber !== 500 && (schemeStructure[copySecondIdx - 1]?.line === secondActiveDotScheme.line || (schemeStructure[copySecondIdx - 1]?.line ? isBranchLine(schemeStructure[copySecondIdx - 1]?.line, secondActiveDotScheme.line) : false))) {
            elementsInLineSecond.unshift(schemeStructure[copySecondIdx - 1]);
            copySecondIdx--;
        }

        copySecondIdx = secondActiveDotIdx;

  
        console.log(elementsInLineFirst, elementsInLineSecond);

        
        console.log(elementsInLineFirst[0]?.dotNumber, elementsInLineFirst[elementsInLineFirst.length - 1]?.dotNumber);
        console.log(elementsInLineSecond[0]?.dotNumber, elementsInLineSecond[elementsInLineSecond.length - 1]?.dotNumber);
        const continuityFirst = checkContinuity(compareDotIdx(elementsInLineFirst[0]?.dotNumber, elementsInLineFirst[elementsInLineFirst.length - 1]?.dotNumber, 'smaller'), compareDotIdx(elementsInLineFirst[0]?.dotNumber, elementsInLineFirst[elementsInLineFirst.length - 1]?.dotNumber, 'bigger'));
        const continuitySecond = checkContinuity(compareDotIdx(elementsInLineSecond[0]?.dotNumber, elementsInLineSecond[elementsInLineSecond.length - 1]?.dotNumber, 'smaller'), compareDotIdx(elementsInLineSecond[0]?.dotNumber, elementsInLineSecond[elementsInLineSecond.length - 1]?.dotNumber, 'bigger'));
        console.log(continuityFirst, continuitySecond);
        return continuityFirst && continuitySecond;
    };

    useEffect(() => {
        console.log(problems);
    }, [problems]);

    const checkContinuity = (firstDot : number | null = null, lastDot : number | null = null) : boolean => {
        if (!schemeStructure) return false;
        const firstActiveDot = activeDots?.[0];
        const secondActiveDot = activeDots?.[1];

        const firstActiveDotNumber = firstDot !== null ? firstDot : Number(firstActiveDot?.querySelector("p")?.innerText || 0);
        const secondActiveDotNumber = lastDot !== null ? lastDot : Number(secondActiveDot?.querySelector("p")?.innerText || 0);
        const firstActiveDotScheme = schemeStructure.find((element : { line: string, dotNumber: number }) => element.dotNumber.toString() === firstActiveDotNumber.toString());
        const secondActiveDotScheme = schemeStructure.find((element : { line: string, dotNumber: number }) => element.dotNumber.toString() === secondActiveDotNumber.toString());
        const firstPotential =  (firstActiveDotScheme?.potential == null ? 20 : firstActiveDotScheme?.potential);
        const secondPotential = (secondActiveDotScheme?.potential == null ? 0 : secondActiveDotScheme?.potential);
        console.log(firstPotential, secondPotential);
        if (firstActiveDotScheme?.line !== secondActiveDotScheme?.line && !isBranchLine(firstActiveDotScheme?.line, secondActiveDotScheme?.line) && (Math.abs(firstPotential - secondPotential) >= 20 || (Math.abs(firstPotential - secondPotential) < 3))) return false;
        let startCheck : boolean = false;

        let firstActiveDotIdx : number = -1, secondActiveDotIdx = -1;
        schemeStructure.forEach((element : { dotNumber: number }, idx : number) => {
            if (element.dotNumber === firstActiveDotNumber && firstActiveDotIdx === -1) firstActiveDotIdx = idx;
            if (element.dotNumber === secondActiveDotNumber && secondActiveDotIdx === -1) secondActiveDotIdx = idx;
        });
        
        console.log(schemeStructure, firstActiveDotIdx, secondActiveDotIdx, firstActiveDotNumber, secondActiveDotNumber);

        if (firstActiveDotIdx === -1 || secondActiveDotIdx === -1) return false;

        const filteredSchemeStructure = schemeStructure.filter((element : { dotNumber: number, line: number}, idx : number) => {
            if (element.line !== schemeStructure[firstActiveDotIdx].line && element.line !== schemeStructure[secondActiveDotIdx].line) return false;
            return ((firstActiveDotNumber % 4 === 3 ? idx >= firstActiveDotIdx : idx >= firstActiveDotIdx) && (secondActiveDotNumber % 4 === 2 ? idx <= secondActiveDotIdx : idx <= secondActiveDotIdx));
        });

        return filteredSchemeStructure.every((element: { line: string, open: boolean, dotNumber: number, defect: string | null, screw: number, solved: boolean }) => {
           
            return (!element.open || (element?.line === "Nul protectie" )) && (element.defect !== "Lipsa tensiune" || element.solved) && (problems.every(problem => problem.type !== "Lipsa tensiune" || problem.point !== element.dotNumber) || element.solved) && element.screw > .5;        
        });
        
    };

    // Device drag

    const startDragDevice = (e: any) => {
       
        if (!deviceScreenRef?.current) return;
        setDeviceDrag(true);
        // alert("e");
        deviceScreenRef.current.style.bottom = `initial`;
        deviceScreenRef.current.style.right = `initial`;
        deviceScreenRef.current.style.left = `${e.clientX - 75}px`;
        deviceScreenRef.current.style.top = `${e.clientY - 50}px`;

    };

    const moveDragDevice = (e : any) => {
        if (!deviceDrag) return;
        if (!deviceScreenRef?.current) return;

        deviceScreenRef.current.style.left = `${e.clientX - 75}px`;
        deviceScreenRef.current.style.top = `${e.clientY - 50}px`;

    };

    const endDragDevice = (e : any) => {
        if (!deviceDrag) return;
        const target = e.target;
        if (!deviceScreenRef.current) return;
        // deviceScreenRef.current.style.left = `initial`;
        // deviceScreenRef.current.style.top = `initial`;
        // deviceScreenRef.current.style.right = `15px`;
        // deviceScreenRef.current.style.bottom = `10px`;
        setDeviceDrag(false);
    };
  
    useEffect(() => {

        const scrollEvent = () => {
            if (Math.abs(window.scrollX - scrollX) >= 0) setScrollX(window.scrollX);
            if (Math.abs(window.scrollY - scrollY) >= 0) setScrollY(window.scrollY);
        };

        window.addEventListener("scroll", scrollEvent);
        return () => window.removeEventListener("scroll", scrollEvent);
    }, []);

    const htmlElement : any = useMemo(() => document.querySelector("html"), []);

    const getDotNumber = (dot : any) : number => {
        return Number(dot.querySelector("p")?.innerText || 0);
    };

    const getSelectedDots = () => {
        return Array.from(document.querySelectorAll(".dot")).filter(dot => dot.classList.contains("activated"));
    };

    const isShortCircuitRoute = () => {
        const selectedDots = getSelectedDots();
        if (selectedDots.length > 1) return;
        const selectedDot : any = selectedDots[0];
        return (selectedDot?.querySelector(".shortcircuit-line") ? true : false);
    };

    const getLastElement = (dotNumber : number) => {
        let start = false;
        console.log(dotNumber, initialSchemeStructure);
        for (let elementIdx : number = initialSchemeStructure.length - 1; elementIdx >= 0; elementIdx--) {
            const element = schemeStructure[elementIdx];
            if (element.dotNumber === dotNumber) start = true;
            if (start) {
                if (element.line !== "Nul protectie" && element.line !== "PE") return element;
            }
        }

        return null;
    };

    const isSameDefect = (currDotNumber : number, defectDotNumber : number) => {
        let currDotNumberIdx = -1, defectDotNumberIdx = -1;

        schemeStructure.forEach((elem : { dotNumber: number }, idx : number) => {
            if (elem.dotNumber === currDotNumber) currDotNumberIdx = idx;
            if (elem.dotNumber === defectDotNumber) defectDotNumberIdx = idx;
        });

        for (let idx = Math.min(currDotNumberIdx, defectDotNumberIdx); idx <= Math.max(currDotNumberIdx, defectDotNumberIdx); idx++) {
            if (schemeStructure[idx]?.dotNumber === 500 || schemeStructure[idx]?.line !== schemeStructure[defectDotNumberIdx]?.line) return false;
        }
        

        return true;
    };
 
    const removeShortCircuitRoute = (fromStudent = false) => {
        const selectedDots = getSelectedDots();
        if (selectedDots.length > 1) return;
        const selectedDot : any = selectedDots[0];
        const shortcircuitLine = selectedDot.querySelector(".shortcircuit-line");
        if (!fromStudent) selectedDot.removeChild(shortcircuitLine);
        const dotNumber : number = Number(selectedDot.querySelector("p").innerText || "1");

        if (!fromStudent) {
            console.log(defects, dotNumber);
            setDefects(currDefects => currDefects.filter((defect : any) => Number(defect.element.querySelector("p").innerText || "1") !== dotNumber));
        }
        
        let last500DotIdx = -1;
        schemeStructure.forEach((element : { dotNumber: number }, idx : number) => element.dotNumber === 500 ? last500DotIdx = idx : null);
        const unfilteredScheme = schemeStructure.map((element : { dotNumber: number, line: string, solved: boolean }, idx: number) => {
            if (idx !== last500DotIdx && element.dotNumber === 500) return null;
            if (isSameDefect(element?.dotNumber, dotNumber) && element.line === "Nul protectie" && element.dotNumber !== 500) return {...element,
                line: getLastElement(element.dotNumber)?.line,
                potential: getLastElement(element.dotNumber)?.potential,
                solved: element.dotNumber === dotNumber ? true : element.solved
            };

            return element;
        });

        console.log(unfilteredScheme.filter((element: any) => element !== null));
        
        changeSchemeStructure(unfilteredScheme.filter((element : any) => element !== null));
    };

    const possibleShortCircuitRoute = () => {

    };

    const createShortCircuitRoute = () => {
        const selectedDots = getSelectedDots();
        if (selectedDots.length > 1) return;
        const selectedDot : any = selectedDots[0];
        const element = selectedDot.parentElement;
        const elementRow = Number(element.getAttribute("row"));
        const elementColumn = Number(element.getAttribute("column"));

        const shortCircuitLine = document.createElement("div");
        shortCircuitLine.classList.add("shortcircuit-line");
        shortCircuitLine.style.position = 'absolute';
        shortCircuitLine.style.width = '800%';
        shortCircuitLine.style.height = '5px';
        shortCircuitLine.style.background = 'linear-gradient(135deg, rgb(254,161,0), rgb(77,181,59))';

        console.log(findSignObject(elementRow, elementColumn - 1));

        let addedConductor = false;

        if (findSignObject(elementRow, elementColumn - 1)?.name?.includes("conductor_lung_protectie")) {
            // Create conductor down
            shortCircuitLine.classList.add("conductor-down");
            shortCircuitLine.style.left = '50%';
            shortCircuitLine.style.transform = 'translateX(-50%) rotateZ(90deg) scale(1)';
            shortCircuitLine.style.top = '-425%';
            addedConductor = true;
        } else if (findSignObject(elementRow, elementColumn + 1)?.name?.includes("conductor_lung_protectie")) {
            // Create conductor up
            shortCircuitLine.classList.add("conductor-up");
            shortCircuitLine.style.left = '50%';
            shortCircuitLine.style.transform = 'translateX(-50%) rotateZ(90deg) scale(1)';
            shortCircuitLine.style.top = '425%';
            addedConductor = true;

        } else if (findSignObject(elementRow - 1, elementColumn)?.name?.includes("conductor_lung_protectie")) {
            // Create conductor left
            shortCircuitLine.classList.add("conductor-left");
            shortCircuitLine.style.left = '-800%';
            shortCircuitLine.style.transform = 'translateY(-50%) scale(1)';
            shortCircuitLine.style.top = '50%';
            addedConductor = true;

        } else if (findSignObject(elementRow + 1, elementColumn)?.name?.includes("conductor_lung_protectie")) {
            // Create conductor right
            shortCircuitLine.classList.add("conductor-right");
            shortCircuitLine.style.left = '100%';
            shortCircuitLine.style.transform = 'translateY(-50%) scale(1)';
            shortCircuitLine.style.top = '50%';
            addedConductor = true;

        }

        if (!addedConductor) return;

        selectedDot.appendChild(shortCircuitLine);

        const dotNumber = Number(selectedDot.querySelector("p").innerText || "1");
        const line = schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === dotNumber)?.line;

        if (isTeacher) {
            setDefects(currDefects => [...currDefects, {
                element: selectedDot,
                type: "Scurt circuit",
                screw: null
            }]);

            let currIdx : number = -1;
            const newSchemeStructure = [];
            for (let idx = 0; idx < schemeStructure.length; idx++) {
                if (schemeStructure[idx].dotNumber === dotNumber) {
                    currIdx = idx;
                    break;
                } else newSchemeStructure.push(schemeStructure[idx]);
            }

            if (currIdx === -1) return;

            newSchemeStructure.push({
                dotNumber: 500,
                line: "Nul protectie",
                defect: null,
                screw: 1,
                solved: false,
                potential: Number(schemeNumber) === 1 ? 3 : 173,
                open: false
            });

            
            for (let idx = currIdx; idx < schemeStructure.length; idx++) {
                const element = schemeStructure[idx];
                if (element.dotNumber >= dotNumber && element.line === line) 
                    newSchemeStructure.push({...element,
                        line: "Nul protectie",
                        potential: Number(schemeNumber) === 1 ? 3 : 173
                    });
                else newSchemeStructure.push(element);
            }

            changeSchemeStructure(newSchemeStructure);
        }

    };

    const scrollVisible = () => {
        return document.body.scrollHeight > document.body.clientHeight || document.body.scrollWidth > document.body.clientWidth;
    };

    const isFirstElement = (currDotInit : any, interval : number) => {
        let currDot = currDotInit;
        console.log(currDot);
        while (!currDot.classList.contains("dot")) currDot = currDot.parentElement;
        console.log(currDot);
        // if (currDot.classList.contains("screw-driver")) return false;
        const dotNumber = Number(currDot?.querySelector("p").innerText || "1");
        console.log(schemeStructure);
        for (let idx = 0; idx < schemeStructure.length; idx++) {
            const element = schemeStructure[idx];
            if (element.dotNumber === dotNumber) { 
                if (idx < interval) return true;
                
                if (schemeStructure[idx - interval].line !== element.line && !isBranchLine(schemeStructure[idx - interval].line, element.line) && element.line !== "Nul protectie" && element.line !== "PE") return true;
                else return false;
            }
        }

        return false;
    };  

    const [activeCheckElectricity, setActiveCheckElectricity] = useState<boolean>(false);

    const checkElectricity = () => {
        if (!activeCheckElectricity) return;
        if (category?.toString() !== "0") return;

    };

    const checkElectricityPredefinedScheme = () => {
        if (!activeCheckElectricity) return;
        if (category?.toString() === "0") return;
        
    };

    const [ rightClicked, setRightClicked, rightClickPoints, setRightClickPoints ] = useContextMenu();

    const scenarioPaperRef = useRef<HTMLDivElement>(null);
    const [activeGuide, setActiveGuide] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number | null>(null);

    let doubleClickScreenCounter : number = 0;  

    const handleAddPage = () => {
        
    };

    const [startAddDrag, setStartAddDrag] = useState<boolean>(false);
    const [counterPage, setCounterPage] = useState<number>(page);

    const changeCounterPage = (e : any) => {
        setCounterPage(e.target.value);
    };

    const gotoPage = () => {
        if (counterPage >= 1 && counterPage <= (totalPages || 1) && counterPage !== page) {
        
            window.history.pushState(window.history.state, "", `/project/${projectId}/${counterPage - 1}`);
            window.location.reload();
        }
    };

    return addSignMenu && category === "0" && false ? <SignsMenu useStore={useStore} key="0" randomCell={firstEmptyCell} isContactSigns={false} /> : true ? (
        <>
            <div 
                className="page-container" 
                ref={pageRef}
                onContextMenu={(e : any) => {
                    if (category !== "0") return;
                    if (e.target?.classList?.contains("option") || e.target?.parentElement?.classList?.contains("option") || e.target?.parentElement?.parentElement?.classList?.contains("option")) return;
                    if (e.target?.classList?.contains("shortcut") || e.target?.parentElement?.classList?.contains("shortcut")) return;
                    e.preventDefault();
                    setRightClicked(true);
                    setRightClickPoints({
                        x: e.pageX,
                        y: e.pageY
                    });
                }}
            >

                {/* {category === "0" && removePage ? <div className="remove-container" onClick={() => {
                        signs.forEach((sign : any) => {
                            if (sign.page === page) removeSign(sign.x, sign.y, sign.page);
                        });
                        
                        setTimeout(() => removePage(page), 0);
                    }}>
                    <FontAwesomeIcon icon={faTimes} className="remove-icon" /> 
                    <h2>{cookies?.language === "en" ? "Remove page" : "Sterge pagina"}</h2>
                </div> : null} */}

                {category === "2" ? (
                    <div className="debug-container">
                        {isTeacher && defects.find(defect => defect.element === activeDot)?.screw == null ? (
                            <div className={`problem-button ${defectsButton && !isFirstElement(activeDot, 1) ? "show" : ""}`} onClick={() => {
                                if (defectsButton && !defects.find(defect => defect.element === activeDot)) {
                                    setActiveDefectsOption(true) 
                                } else if (defectsButton) {
                                    const defectType = defects.find(defect => defect.element === activeDot)?.type;
                                    if (defectType === "Scurt circuit" || defectType === "Short circuit") removeShortCircuitRoute();
                                    else removeDefect(activeDot);
                                }
                            }}>
                                <FontAwesomeIcon icon={faBug} className="bug-icon" />
                                {!defects.find(defect => defect.element === activeDot) ? <p>{cookies?.language === "en" ? "Create defect" : "Creeaza defect"}</p> : <p>{cookies?.langauge === "en" ? "Remove defect" : "Sterge defectul"}</p>}
                            </div>
                        ) : null}

                        <div className={`screw-button ${getSelectedDots()?.length === 1 && document?.querySelector(".dot.activated .screw-driver") ? "show" : ""}`} onClick={() => {
                            if (!(getSelectedDots()?.length === 1 && document?.querySelector(".dot.activated .screw-driver"))) return;
                            document?.querySelector(".dot.activated .screw-driver")?.parentElement?.classList.toggle("true-show");
                        }}>
                            <FontAwesomeIcon icon={faScrewdriver} className="bug-icon" />
                            <p>{cookies?.language === "en" ? "Screwdriver" : " Surubelnita"}</p> 
                        </div>
                        

                        {!isTeacher ? (
                            <div className={`problem-button solve ${getSelectedDots()?.length === 1  ? "show" : ""}`} onClick={() => {
                                setDefectsButton(true);
                                setActiveDefectsOption(true);
                            }}>
                                <FontAwesomeIcon icon={faLightbulb} className="bug-icon" style={{ fontSize: 22 }} />
                                <p>{cookies?.language === "en" ? "Solve defect" : "Rezolva defect"}</p>
                            </div>
                        ) : null}

                    
                        {/* {isTeacher ? <div className={`add-conductor ${getSelectedDots()?.length === 1 && !isFirstElement(!isTeacher ? getSelectedDots()[0] : activeDot, 2)  ? "show" : ""}`} onClick={() => {
                
                            if (isShortCircuitRoute()) removeShortCircuitRoute();
                            else createShortCircuitRoute();
                        }}>
                            <FontAwesomeIcon icon={isShortCircuitRoute() ? faTimes : faPlus} className="bug-icon" style={{ fontSize: 22 }} />
                            {isShortCircuitRoute() ? <p>{cookies?.language === "en" ? "Remove cable short-circuit" : "Sterge cablu scurt-circuit"}</p> : <p>{cookies?.language === "en" ? "Add cable short-circuit" : "Adauga cablu scurt-circuit"}</p>}
                        </div> : null} */}

                        {defectsButton && (!defects.find(defect => defect === activeDot)) && activeDefectsOption ? (
                            <div className="defects-options-container">
                                <div className="options-container">
                                    <h2>{cookies.language === "en" ? "Types of defects" : "Tipuri de defecte"}</h2>
                                    {defectsOptions.map((defectsOption: any, idx: number) => (
                                        !isFirstElement(isTeacher ? activeDot : getSelectedDots()[0], idx === 0 ? 1 : 3) && (!activeDot?.querySelector(".screw-driver-container") || idx === 1) ? <div className={`option ${activeDefectsOptionType === defectsOption ? "active" : ""}`} key={idx} onClick={(e: any) => setActiveDefectsOptionType(defectsOption)}>
                                            <div className="circle">
                                                <FontAwesomeIcon icon={faCheck} className={`circle-icon ${activeDefectsOptionType === defectsOption ? "active" : ""}`} /> 
                                            </div>
                                            <p>{defectsOption}</p>
                                        </div> : null
                                    ))}

                                    <div className="save-button" onClick={(e: any) => {
                                        const element = e.target.classList.contains("save-button") ? e.target : e.target.parentElement;
                                        element.classList.add("clicked");
                                        setTimeout(() => {
                                            const currActiveDot : any = Array.from(document.querySelectorAll(".dot")).filter((dot: any) => dot.classList.contains("activated"))[0];

                                            if (!currActiveDot) return;
                                            if (isTeacher) {
                                                if (activeDefectsOptionType === "Scurt circuit" || activeDefectsOptionType === "Short circuit") {
                                                    if (isShortCircuitRoute()) removeShortCircuitRoute();
                                                    else createShortCircuitRoute();
                                                } else createDefect(activeDot, activeDefectsOptionType);
                                            }

                                            if (!isTeacher) {
                                                if (activeDefectsOptionType === "Scurt circuit" || activeDefectsOptionType === "Short circuit") {
                                                    const neighbour = findNeighbour(Number(currActiveDot.querySelector("p").innerText || "1"))
                                                    if (neighbour !== null && neighbour !== -1) solveDefect(neighbour, activeDefectsOptionType);
                                                }
                                                solveDefect(Number(currActiveDot.querySelector("p").innerText || "1"), activeDefectsOptionType);
                                            }
                                            setActiveDefectsOption(currValue => !currValue);
                                        }, 200);
                                    }}>
                                        <p>{cookies?.langauge === "en" ? "Save" : "Salveaza"}</p>
                                    </div>

                                    <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={() => setActiveDefectsOption(currValue => !currValue)} />
                                </div>

                            </div>
                        ) : null}

                        {defects.length > 0 || !isTeacher ? <div className="start-session-button" onClick={async () => isTeacher ? await startSessionTeacher() : await finishSessionStudent()}>
                            {isTeacher ? <p>{cookies?.language === "en" ? "Start session" : "Incepe sesiunea"}</p> : <p>{cookies?.language === "en" ? "Finish session" : "Termina sesiunea"}</p>}
                            <FontAwesomeIcon icon={faFlagCheckered} className="button-icon" />
                        </div> : null}
                    </div>

                ) : null}

                {activeGuide ? (
                    <div className="steps-guide-container">

                        <div className="close-icon-container" onClick={() => setActiveGuide(false)}>
                            <FontAwesomeIcon className="close-icon" icon={faTimes} />
                        </div>
                        
                        <h2>{cookies?.language === "en" ? "Guide of using" : "Ghid de folosire"}</h2>

                        <div className={`step ${activeStep === 1 ? "active" : ""}`} onClick={() => setActiveStep(1)}>
                            <p>1. {cookies?.language === "en" ? "Creation of a defect" : "Creearea unui defect"}</p>
                        </div>

                        <div className={`step ${activeStep === 2 ? "active" : ""}`} onClick={() => setActiveStep(2)}>
                            <p>2. {cookies?.language === "en" ? "Removing defect" : "Stergerea unui defect"}</p>
                        </div>

                        <div className={`step ${activeStep === 3 ? "active" : ""}`} onClick={() => setActiveStep(3)}>
                            <p>3. {cookies?.language === "en" ? "Creating the session" : "Crearea unei sesiuni"}</p>
                        </div>

                        <div className={`step ${activeStep === 4 ? "active" : ""}`} onClick={() => setActiveStep(4)}>
                            <p>4. {cookies?.language === "en" ? "Joining the session" : "Intrarea in sesiune"}</p>
                        </div>

                    </div>
                ) : null}

                {isTeacher && category === "2" ? <div className="guide-button" onClick={() => setActiveGuide(curr => !curr)}>
                    <FontAwesomeIcon icon={faBookOpen} className="guide-icon" />
                </div> : null}

                <div className={`scenario-paper ${activeStep !== null ? "active" : ""}`} ref={scenarioPaperRef}>
                    {/* <h2>{cookies?.language === "en" ? "" : ""}</h2> */}
                    {activeStep === 1 ? 
                        cookies?.language === "en" ? <p>You are in the debugging mode. <br /> At the moment, you have to create defects on a certain point(s) to test your students. <br /> To create a defect, select a point, and then press the "Create defect" button in the top right. <br /> You can create 2 types of faults: Lack of voltage and Grounding (Short circuit) <br /> After choosing the type of fault, press "Save". </p> : <p>Te afli in modulul de depanare. <br /> In momentul de fata, trebuie sa creezi defecte pe un anumit(e) punct(e) pentru a-ti testa elevii. <br /> Pentru a crea un defect selectati un punct, iar apoi vei apasa in dreapta sus pe butonul "Creeaza defect". <br /> Poti creea 2 tipuri de defecte: Lipsa de tensiune si Punere la masa (Scurt circuit) <br /> Dupa ce ai ales tipul de defect apesi pe "Salveaza". </p>
                    : activeStep === 2 ? 
                        cookies?.language === "en" ? <p>In case you have added a defect by mistake or you've selected the wrong type of defect, you will select the dot for which you want to delete the defect, then you will press in the top-right corner on the "Remove defect" button.</p> : <p>In caz ca ai adaugat un defect din greseala sau ai selectat tipul gresit de defect, selectezi punctul pentru care vrei sa stergi defectul, apoi apesi in coltul din dreapta sus pe "Sterge defect".</p>
                    : activeStep === 3 ? 
                        cookies?.language === "en" ? <p>After you have added the defects on the respective points, to actually begin students' / electricians' testing you will press on the button with "Start the session".</p> : <p>Dupa ce ai adaugat defectele pe punctele respective, pentru a incepe efectiv testarea elevilor / electricienilor vei apasa pe butonul de jos cu "Incepe sesiunea". </p>
                    : activeStep === 4 ?
                        cookies?.language === "en" ? <p>Now that you have created the session, you will be able to see a leaderboard with the students / electricians added by you and statistics about how are they doing in the session (correct, wrong, etc.). Also, you can join the session, as a teacher, as well, by pressing on the button above the leaderboard "Join the session". </p> : <p>Acum ca ai creat sesiunea, vei putea sa vezi un clasament cu elevii / electricieni adaugati de tine si cateva statistici despre cum se descurca in sesiune (corecte, gresite, etc.). De asemenea, poti sa intri si tu, ca profesor, in sesiunea respectiva, apasand pe butonul de deasupra clasamentului "Intra in sesiune".</p>
                    : null}

                    <div className="close-button" onClick={() => setActiveStep(null)}>
                        <FontAwesomeIcon className="close-icon" icon={faTimes}/>
                    </div>

                    {/* {scenario === "shock" ? <img src={electrocutareColeg} alt="electrocutare coleg imagine" /> : null} */}
                </div>


                {category === "2" && !isTeacher ? (
                    <div 
                        className="measurement-device" 
                        
                        onMouseDown={e => !showMeasurmentDevice && startDragDevice(e)}
                        onMouseMove={e => !showMeasurmentDevice && moveDragDevice(e)}
                        onMouseUp={e => !showMeasurmentDevice && endDragDevice(e)}
                        ref={deviceScreenRef}
                        onDoubleClick={() => {
                            doubleClickScreenCounter++;
                            setShowMeasurmentDevice(currValue => !currValue);
                            deviceScreenRef.current.style.bottom = `10px`;
                            deviceScreenRef.current.style.right = `15px`;
                            deviceScreenRef.current.style.left = `initial`;
                            deviceScreenRef.current.style.top = `initial`;
                        }}
                        onClick={(e : any) => {
                            let currentDoubleClickScreenCounter = doubleClickScreenCounter;
                            setTimeout(() => {
                                if (currentDoubleClickScreenCounter !== doubleClickScreenCounter) return;
                                if (e.target.classList.contains("dot") || e.target.classList.contains("screen")) return;
                                const closestElement : any = getClosestElement(e.clientX, e.clientY, document.querySelectorAll(".measurement-device .dot"));
                                if (closestElement) setDeviceActiveOption(closestElement.classList[1]?.slice(4));
                            }, 200);
                          
                        }}
                    >
                        <div 
                            className="screen"
                        >
                            <h2>{deviceValue?.toString()?.length ? deviceValue : deviceActiveOption !== "off" ? "ON" : "OFF"}</h2>
                        </div>
                        {showMeasurmentDevice ? (
                            <div className="device">
                                <img src={aparatDeMasura} alt="" />
                                <div className="arrow-element" style={{ transform: `translate(-50%, -50%) rotateZ(${deviceActiveOption === "off" ? 180 : deviceActiveOption === "acv-200" ? 250 : deviceActiveOption === "acv-750" ? 305 : deviceActiveOption === "buzzer" ? 360 : 440}deg)` }}>
                                    <div className="arrow" />
                                    <div className="triangle" />
                                </div>

                                <div className={`dot dot-off ${deviceActiveOption === "off" ? "active" : ""}`} onClick={() => setDeviceActiveOption("off")} />
                                <div className={`dot dot-acv-200 ${deviceActiveOption === "acv-200" ? "active" : ""}`} onClick={() => setDeviceActiveOption("acv-200")} />
                                <div className={`dot dot-acv-750 ${deviceActiveOption === "acv-750" ? "active" : ""}`} onClick={() => setDeviceActiveOption("acv-750")} />
                                <div className={`dot dot-buzzer ${deviceActiveOption === "buzzer" ? "active" : ""}`} onClick={() => setDeviceActiveOption("buzzer")} />
                                <div className={`dot dot-continuity-10k ${deviceActiveOption === "continuity-10k" ? "active" : ""}`} onClick={() => setDeviceActiveOption("continuity-10k")} />

                            </div>
                        ) : null}
                    </div>
                ) : null}

                <OptionsMenu handleAddPage={handleAddPage} projectId={projectId || ""} page={page} signs={signs} removePage={removePage} printSchemes={printSchemes} addSignPressed={addSignPressed} activateEditName={activateEditName} replaceSignPressed={replaceSignPressed} useStore={useStore} category={category} format={format} />
                <div className="numbers-container">
                    <p className="empty"></p>
                    {columns?.length ? columns.map((column, idx) => (
                        <p key={idx}>{column || ""}</p>
                    )) : null}
                </div>
                <div className="grid-total-container">
                    <div className="letters">
                        <p>A</p>
                        <p>B</p>
                        <p>C</p>
                        <p>D</p>
                        <p>E</p>
                        <p>F</p>
                        <p>G</p>
                        <p>H</p>
                        <p>I</p>
                        <p>J</p>
                        <p>K</p>
                        <p>L</p>
                        <p>M</p>
                        <p>N</p>
                        <p>O</p>
                        <p>P</p>
                        <p>Q</p>
                        <p>R</p>
                        <p>S</p>
                        <p>T</p>
                        {format === "A3" ? (
                            <>
                                <p>U</p>
                                <p>V</p>
                                <p>W</p>
                                <p>X</p>
                                <p>Y</p>
                                <p>Z</p>
                                <p>Z2</p>
                                <p>Z3</p>
                            </>
                        ) : null}
                    </div>
                    <div className="grid-container">
                        <PrintRows 
                            projectName={projectName}
                            COLUMNS={COLUMNS}
                            columns={COLUMNS}
                            useStore={useStore} 
                            isSelectedCell={isSelectedCell}
                            cellRef={cellRef}
                            setSingleElementDrag={setSingleElementDrag}
                            dragElement={dragElement}
                            initiateElement={initiateElement}
                            moveElement={moveElement}
                            stopElement={stopElement}
                            touchInitiateElement={touchInitiateElement} 
                            touchMoveElement={touchMoveElement}
                            category={category}
                            findSign={findSign}
                            findSignObject={findSignObject}  
                            removeSign={removeSign}
                            addSign={addSign}
                            copiedElement={copiedElement}
                            cuttedElement={cuttedElement}
                            isImageLocked={isImageLocked}
                            changeIsSelectedSign={changeIsSelectedSign}
                            removeSelectedSign={removeSelectedSign}
                            changeSelectedCell={changeSelectedCell}
                            dragElementRow={dragElementRow}
                            dragElementColumn={dragElementColumn}
                            rowRef={rowRef}
                            ROWS={ROWS}
                            page={page}
                            problems={problems}
                            currentElectricity={currentElectricity}
                            getElectricity={getElectricity}
                        />
                    </div>
                </div>

                {addSignMenu ? <div className="add-signs-container">
                    {activeOptionAdd !== null ? <h2 className="title">{activeOptionAdd === 0 ? (cookies?.language === "en" ? "Contacts" : "Contacte") : activeOptionAdd === 1 ? (cookies?.language === "en" ? "Conductors" : "Conductori") : activeOptionAdd === 2 ? (cookies?.language === "en" ? "Bobines" : "Bobine") : activeOptionAdd === 3 ? (cookies?.language === "en" ? "Buttons" : "Butoane") : activeOptionAdd === 4 ? (cookies?.language === "en" ? "Engines, Genertors & Transformers" : "Motore, Generatoare & Transformatoare") : activeOptionAdd === 5 ? (cookies?.language === "en" ? "Others" : "Altele") : null}</h2> : null}
                    {activeOptionAdd !== null ? <div className="back-button" onClick={() => setActiveOptionAdd(null)}>
                        <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                        <h2>{cookies?.language === "en" ? "Go back" : "Inapoi"}</h2>
                    </div> : null}

                    <div className="elements" ref={addElementsRef} onScroll={() => addElementsRef.current ? setElementsWindowScrollX(addElementsRef.current.scrollLeft) : null}>
                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(0)}>
                            <div className="element-image">
                                <img src={contactNormalDeschis} alt={cookies?.language === "en" ? "Contacts Image" : "Imagine Contacte" } />
                            </div>
                            <p>{cookies?.language === "en" ? "Contacts" : "Contacte"}</p>
                        </div> : null}

                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(1)}>
                            <div className="element-image">
                                <img src={derivatie_din_conductor_punctat} alt={cookies?.language === "en" ? "Contacts Image" : "Imagine Contacte" } />
                            </div>
                            <p>{cookies?.language === "en" ? "Conductors" : "Conductori"}</p>
                        </div> : null}

                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(2)}>
                            <div className="element-image">
                                <img src={bobinaReleu} alt={cookies?.language === "en" ? "Contacts Image" : "Imagine Contacte" } />
                            </div>
                            <p>{cookies?.language === "en" ? "Bobines" : "Bobine"}</p>
                        </div> : null}

                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(3)}>
                            <div className="element-image">
                                <img src={buton_cu_revenire} alt={cookies?.language === "en" ? "Contacts Image" : "Imagine Contacte" } />
                            </div>
                            <p>{cookies?.language === "en" ? "Buttons" : "Butoane"}</p>
                        </div> : null}

                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(4)}>

                            <div className="element-image">
                                <img src={motor_asincron_trifazat_cu_rotor_cu_inele} alt={cookies?.language === "en" ? "Engines, Generators, Transformers" : "Motoare, Generatoare & Transformatoare"} />
                            </div>

                            <p>{cookies?.language === "en" ? "Engines & Gen" : "Motoare & Gen."}</p>
                        </div> : null}

                        {activeOptionAdd === null ? <div className="element" onClick={() => setActiveOptionAdd(5)}>
                            <div className="element-image">
                                <img src={sirDeClame} alt={cookies?.language === "en" ? "Others" : "Altele"} />
                            </div>
                            <p>{cookies?.language === "en" ? "Others" : "Altele"}</p>
                        </div> : null}

                        {activeOptionAdd !== null ? Object.values(SCHEME_ELEMENTS).map((element : any, idx) => element.category === CATEGORIES[activeOptionAdd] ?  (
                            <div className="element element-category" 
                                onClick={() => addedSign(element)}
                                onDragStart={() => {
                                    setStartAddDrag(true);
                                }}
                                onDragEnd={(event : any) => {
                                    if (!startAddDrag) return;
                                    event.preventDefault();

                                    // Find the element under the pointer
                                    let elementUnderPointer : any = document.elementFromPoint(event.clientX, event.clientY);

                                    while (!elementUnderPointer?.classList.contains("cell") && !elementUnderPointer?.classList?.contains("page-container")) {
                                        elementUnderPointer = elementUnderPointer?.parentElement;
                                    }

                                    console.log(elementUnderPointer);


                                    if (!elementUnderPointer?.classList?.contains("cell")) return;

                                    const x = elementUnderPointer.getAttribute("row");
                                    const y = elementUnderPointer.getAttribute("column");
                                    // const elementSign = elementUnderPointer?.querySelector("img")?.src;
                                    
                                    element.realSource.forEach((source : any, idx : number) => {
                                        if (element?.align === "vertical") addSign(Number(x), Number(y) + idx, page, source, 0, []);
                                        else addSign(Number(x) - idx, Number(y), page, source, 0, []);
                                    });
                        
                                    
                                    setStartAddDrag(false);
                                }}
                                draggable={true}
                            >

                                <div className="name-element" style={{ transform: `translateX(${-elementsWindowScrollX}px)` }}>
                                    <p>{element.name[cookies?.language]}</p>
                                </div>

                                <div className="element-image">
                                    <img 
                                        src={element.imageSource} 
                                        alt={element.name[cookies?.language]} 
                                        
                                    />
                                </div>
                                <p>{element.name[cookies?.language]}</p>
                            </div>
                        ) : null) : null}

                        <div className="element" onClick={() => activeOptionAdd === null ? changeAddSignMenu(false) : setActiveOptionAdd(null)}>

                            <div className="element-image"  style={{ height: "10vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" style={{ fontSize: 40 }} />
                            </div>
                            
                            <p>{cookies?.language === "en" ? "Back" : "Inapoi"}</p>
                        </div>
                    </div>
                </div> : null}

                <div className={`name-sign-container ${nameSignMenu ? "active" : ""}`} ref={nameContainerRef}>
                    {nameSignMenu ? <div className="name-sign">
                        <div className="name-sign-relative">
                            {texts.map((text: any, idx: number) => {
                                
                                
                                return (
                                    <div className="text-container" key={idx}>

                                        <div className="input">
                                            <h2>{language === "ro" ? "Nume: " : "Name"}</h2>
                                            <input type="text" placeholder={language === "ro" ? "Nume..." : "Name..."} value={text?.name?.toString()?.length !== undefined ? text?.name : ""} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { name: string }, textIdx : number) => textIdx === idx ? {...text, name: e.target.value} : text))} />
                                        </div>

                                        <div className="input">
                                            <h2>{language === "ro" ? "Marime font (4 - 30)" : "Font size (4 - 30)"}</h2>
                                            <input type="number" placeholder={language === "ro" ? "Marimea fontului..." : "Font size..."} min={4} max={30} value={text?.fontSize?.toString()?.length !== undefined ? text.fontSize : 14} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { fontSize: number }, textIdx : number) => textIdx === idx ? {...text, fontSize: e.target.value} : text))} />
                                        </div>

                                        <div className="input">
                                            <h2>{language === "ro" ? "Pozitionare Orizontala (mm)" : "Positioning Horizontally (mm)"}</h2>
                                            <input type="number" placeholder={language === "ro" ? "Pozitionare Orizontala" : "Positioning Horizontally"} value={text?.positionX?.toString()?.length !== undefined ? text.positionX : 0} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { positionX: number }, textIdx : number) => textIdx === idx ? {...text, positionX: e.target.value} : text))} />
                                        </div>

                                        <div className="input">
                                            <h2>{language === "ro" ? "Pozitionare Verticala (mm)" : "Positioning Vertically (mm)"}</h2>
                                            <input type="number" placeholder={language === "ro" ? "Pozitionare Verticala" : "Positioning Vertically"} value={text?.positionY?.toString()?.length !== undefined ? text.positionY : 0} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { positionY: number }, textIdx : number) => textIdx === idx ? {...text, positionY: e.target.value} : text))} />
                                        </div>
                                        
                                        <div className="input">
                                            <h2>{language === "ro" ? "Grosimea fontului (100 - 900)" : "Font weight (100 - 900)"}</h2>
                                            <input type="number" placeholder={language === "ro" ? "Grosimea fontului..." : "Font weight..."} min={100} max={900} value={text?.fontWeight?.toString()?.length !== undefined ? text.fontWeight: 600} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { fontWeight: number }, textIdx : number) => textIdx === idx ? {...text, fontWeight: e.target.value} : text))} />
                                        </div>

                                        <div className="input">
                                            <h2>{language === "ro" ? "Rotire (˚)" : "Rotation (˚)"}</h2>
                                            <input type="number" placeholder={language === "ro" ? "Rotire..." : "Rotation..."} min={0} max={360} value={text?.flip?.toString()?.length !== undefined ? text.flip : 0} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { flip: number }, textIdx : number) => textIdx === idx ? {...text, flip: e.target.value} : text))} />
                                        </div>

                                        {/* <div className="input">
                                            <h2>{language === "ro" ? "Color" : "Culoare"}</h2>
                                            <input type="color" placeholder={language === "ro" ? "Color..." : "Culoare..."} value={text.color || "#000000"} onChange={e => setTexts((currTexts : any) => currTexts.map((text : { color: string }, textIdx : number) => textIdx === idx ? {...text, color: e.target.value} : text))} />
                                        </div> */}


                                        <FontAwesomeIcon icon={faTrashCan} className="close-icon" onClick={() => {
                                            setTexts((currTexts : any) => currTexts.filter((text : any, textIdx : number) => textIdx !== idx));
                                            // changeName();

                                        }} />

                                    </div>
                                );
                            })}

                            {/* <div className="input">
                                <h2>{language === "ro" ? "Aliniament: " : "Alignment: "}</h2>
                                <input type="text" value={signName} onChange={e => setSignName(e.target.value)} />
                                <div className="options">
                                    {alignmentOptions.map((option: string, idx: number) => (
                                        <div className={`option ${idx === alignmentIdx ? "active" : ""}`} onClick={() => setAlignmentIdx(idx)} key={idx}>
                                            <p>{option}</p>
                                        </div>
                                    ))}
                                </div>
                            </div> */}

                            <button type="button" onClick={() => setTexts((currTexts : any) => [...currTexts, {}])}>
                                <FontAwesomeIcon icon={faPlus} className="add-icon" />
                                <p>{language === "ro" ? "Adauga inca un text" : "Add one more text"}</p>
                            </button>

                            <button type="button" onClick={() => changeName()}>
                                <p>{language === "ro" ? "Salveaza" : "Save"}</p>
                            </button>

                            <FontAwesomeIcon icon={faTrashCan} className="delete-icon" onClick={() => {
                                setTexts([]);
                            
                            }} />

                            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => {
                                setNameSignMenu(false);
                            }} />
                        </div>
                    </div> : null}
                </div>

                {category !== "2" ? (
                    <div className="project-name" ref={projectNameRef}>
                        <h2
                        contentEditable={category === "0" ? true : false} 
                        spellCheck={false} 
                        onInput={(e : any) => setCurrentProjectName(e.target.innerText)}
                        onKeyDown={(e: any) => e.key === "Enter" ? e.preventDefault() : null}
                        suppressContentEditableWarning={true}
                        >
                            {projectName}
                        </h2>
                    </div>
                ) : null}

                {/* Audios */}

                <audio src={buzzerSound} ref={buzzerSoundRef} autoPlay={false} loop={true} style={{ display: "none" }} />
                <audio src={screwSound} ref={screwSoundRef} autoPlay={false} style={{ display: "none" }} />

                {/* Change current visibility */}

               

             
                <div className={`current-visibility-container ${isCurrentOnScheme ? "is-current" : ""}`}>
                    {/* <div className="current-line"></div>
                    <FontAwesomeIcon icon={isCurrentOnScheme ? faBoltLightning : faPlug} className="current-icon" />
                    <h2>{isCurrentOnScheme ? (cookies?.language === "en" ? "Current is being shown" : "Curentul e afișat ") : (cookies?.language === "en" ? "Current is not being shown" : "Curentul nu e afișat.")}</h2> */}
                    
                    <div 
                        className="shortcut" 
                        onClick={() => {
                            if (scrollVisible()) document.body.classList.remove("grid-scroll");
                            else document.body.classList.add("grid-scroll");
                            let newZoom;
                            if (Number(document.body.getAttribute("zoom")) > 0.9) {
                                    if (document.body.getAttribute("zoom")) 
                                        newZoom = (Number(document.body.getAttribute("zoom")) - 0.1).toString();
                                    if (newZoom) {
                                        document.body.setAttribute("zoom", newZoom);
                                        zoomFunc(newZoom, isGrid, format);
                                    }
                            }
                        
                        }}
                        onContextMenu={(e : any) => {
                            e.preventDefault();
                            document.querySelectorAll(".shortcut")[0]?.classList.toggle("active");

                        }}
                        onBlur={() => {
                            document.querySelectorAll(".shortcut")[0]?.classList.remove("active");
                        }}
                        onMouseLeave={() => {
                            document.querySelectorAll(".shortcut")[0]?.classList.remove("active");
                        }}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon icon={faMinus} className="shortcut-icon" />
                        <p>{cookies?.language === "en" ? "Minimize" : "Micșorează"}</p>
                    </div>
                    
                    <div 
                        className="shortcut" 
                        onClick={(e : any) => {
                            if (page - 1 > 0) {
                                window.history.pushState(window.history.state, "", `/project/${projectId}/${page - 2}`);
                                window.location.reload();
                            }
                        }}
                        onContextMenu={(e : any) => {
                            e.preventDefault();
                            document.querySelectorAll(".shortcut")[1]?.classList.toggle("active");

                        }}
                        onBlur={() => {
                            document.querySelectorAll(".shortcut")[1]?.classList.remove("active");
                        }}
                        onMouseLeave={() => {
                            document.querySelectorAll(".shortcut")[1]?.classList.remove("active");
                        }}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} className="shortcut-icon" />
                        <p>{cookies?.language === "en" ? "Back" : "Înapoi"}</p>
                    </div>

                  

                    <div className="page-counter-container">
                        <input type="number" value={counterPage} onChange={changeCounterPage} onBlur={gotoPage} onKeyDown={(e : any) => {
                            if ((e.key === "Enter" || e.key === "Escape")) gotoPage();
                        }} />
                        <p>/</p>
                        <p>{totalPages}</p>
                    </div>

                    <div 
                        className="shortcut" 
                        onClick={(e : any) => {
            
                            if (page + 1 <= (totalPages || 1)) {
                                window.history.pushState(window.history.state, "", `/project/${projectId}/${page}`);
                                window.location.reload();
                            }
                        }}
                        onContextMenu={(e : any) => {
                            e.preventDefault();

                            document.querySelectorAll(".shortcut")[2]?.classList.toggle("active");
                        }}
                        onBlur={() => {
                            document.querySelectorAll(".shortcut")[2]?.classList.remove("active");
                        }}
                        onMouseLeave={() => {
                            document.querySelectorAll(".shortcut")[2]?.classList.remove("active");
                        }}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon icon={faAngleRight} className="shortcut-icon" />
                        <p>{cookies?.language === "en" ? "Next" : "Înainte"}</p>
                    </div>

                    <div 
                        className="shortcut" 
                        onClick={() => {
                            if (scrollVisible()) document.body.classList.remove("grid-scroll");
                            else document.body.classList.add("grid-scroll");
                         
                            let newZoom;
                            if (Number(document.body.getAttribute("zoom")) < 5) {
                                if (document.body.getAttribute("zoom")) 
                                    newZoom = (Number(document.body.getAttribute("zoom")) + 0.1).toString();
                                else newZoom = "1.1";

                                document.body.setAttribute("zoom", newZoom);
                                zoomFunc(newZoom, isGrid, format);

                            }
                        }}
                        onContextMenu={(e : any) => {
                            e.preventDefault();
                            document.querySelectorAll(".shortcut")[3]?.classList.toggle("active");
                        }}
                        onBlur={() => {
                            document.querySelectorAll(".shortcut")[3]?.classList.remove("active");
                        }}
                        onMouseLeave={() => {
                            document.querySelectorAll(".shortcut")[3]?.classList.remove("active");
                        }}
                        tabIndex={0}
                    >
                        <FontAwesomeIcon icon={faPlus} className="shortcut-icon" />
                        <p>{cookies?.language === "en" ? "Maximize" : "Mărește"}</p>                   
                    </div>

                 
                </div>

                <div className="page-number" ref={pageNumberRef} style={{ display: "none" }}>
                    <h2>{cookies?.language === "en" ? "Page Number" : "Nr. pag:"} {page} / {totalPages}</h2>
                </div>

                {/* 
                    <div className="print-button" onClick={() => printScheme()} ref={printRef}>
                        <FontAwesomeIcon icon={faPrint} className="print-icon" />
                    </div> */}

                {/* {category !== "0" ? <audio src={buzzerSound} ref={buzzerSoundRef}></audio> : null} */}
            </div>
            {rightClicked && getSelectedCell() ? (
                <div className="context-menu" style={{ top: `${rightClickPoints.y - (15 * Number(document.body.getAttribute("zoom")))}px`, left: `${rightClickPoints.x + 15 * Number(document.body.getAttribute("zoom"))}px`  }}>
                    {rightClickData.map((elem, idx) => (
                        <div 
                            key={elem.id} 
                            className={`context-menu-icon ${elem.danger ? "danger" : ""} ${idx === rightClickData.length - 1 ? "flip-option" : ""}`}
                            onClick={() => {
                                if (elem.id === 1 && !copiedElement && !cuttedElement) {
                                    const currentSign : { x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page:number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                                    setCopiedElement(currentSign);
                                    removeSelectedSign();
                                    removeSelectedCell();
                                } else if (elem.id === 2) {
                                    const selectedCells = cellRef.current.filter(cell => cell?.classList.contains("selected"));
                                    if (cuttedElement) {
                                        selectedCells.forEach((cell : any) => {
                                            // const currSign: { x: number, y: number, name: string, flip: number, textName: string, textAlignment: string, fontSize: number, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number }) => sign.x === Number(cell?.getAttribute("column")) && sign.y === Number(cell?.getAttribute("row")));
                                            if (cell?.querySelector("img")) removeSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page);
                                           
                                            addSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page, cuttedElement.name, cuttedElement.flip, cuttedElement.texts, cuttedElement.signColor);
                                        });
                                    } else if (copiedElement) {
                                        selectedCells.forEach(cell => {
                                            // const currSign: { x: number, y: number, name: string, flip: number, textName: string, textAlignment: string, fontSize: number, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number }) => sign.x === Number(cell?.getAttribute("column")) && sign.y === Number(cell?.getAttribute("row")));
                                            if (cell?.querySelector("img")) removeSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page);
                                            addSign(Number(cell?.getAttribute("row")), Number(cell?.getAttribute("column")), page, copiedElement.name, copiedElement.flip, copiedElement.texts, copiedElement.signColor);
                                        });
                                    }
                                  
                                    
                                    if (copiedElement) setCopiedElement(null);
                                    else if (cuttedElement) setCuttedElement(null);
                                } else if (elem.id === 3 && !cuttedElement && !copiedElement) {
                                    const currentSign : { x: number, y: number, name: string, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page: number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                                    setCuttedElement(currentSign);
                                    removeSign(currentSign.x, currentSign?.y, page);
                                    removeSelectedSign();
                                    removeSelectedCell();
                                } else if (elem.id === 4) {
                                    if (!nameSignMenu) {
                                        const currentSign : { x: number, y: number, name: string, page: number, flip: number, texts: any, signColor: string | null } = signs.find((sign: { x: number, y: number, name: string, flip: number, page: number }) => sign.x === selectedCell.x && sign.y === selectedCell.y && sign.page === page);
                                        
                                        removeSign(currentSign?.x, currentSign?.y, page);
                                    }
                                } else if (elem.id === 5) {
                                    console.log(isSelected);
                                    flipSign(isSelected.x, isSelected.y, (isSelected.flip + 90) % 360, isSelected.page);
                                    removeSelectedSign();
                                    changeIsSelectedSign(isSelected.x, isSelected.y, isSelected.page);
                                }
                            }}
                        >
                            {elem.icon}
                            <p>{elem.title}</p>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    ) : <></>;
};

export default memo(Grid);